import React, { useEffect } from "react";
import AgeGroupListing from "./pages/AgeGroupListing";

function AgeGroup(props) {
    const { setActive } = props;
    useEffect(() => {
      //setActive(3);
    }, []);

    return <AgeGroupListing />
};

export default AgeGroup;