import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { darken, lighten, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../../../../redux/features/auth/authSlice";
import { getISODate, localeISODateTime } from "../../../../../shared/utils/localeISODateTime";
import { CustomDatePicker } from "../../../../datepicker/CustomDatePicker";
import { ExportDataGridToExcel } from "../../../../export/ExportDataGridToExcel";
import { TransactionService } from "../../../services/SubService/TransactionService";

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const columns = [
  {
    field: "id",
    headerName: "Id",
    hideable: true,
    flex: 1,
    headerAlign: "center",
    align: "center",
    headerClassName: "researchColmun",
  },
  {
    field: "date",
    headerName: "Date",
    hideable: false,
    flex: 1,
    headerAlign: "center",
    align: "center",
    headerClassName: "researchColmun",
    renderCell: (data) => moment(data.value).format("DD-MM-YYYY HH:mm"),
  },
  {
    field: "site",
    headerName: "Site",
    hideable: false,
    flex: 1,
    headerAlign: "center",
    align: "center",
    headerClassName: "researchColmun",
    renderCell: (data) => data?.value?.description,
  },
  {
    field: "client",
    headerName: "Client",
    hideable: false,
    flex: 1,
    headerAlign: "center",
    align: "center",
    headerClassName: "researchColmun",
    renderCell: (data) => ((data?.value?.firstnametempo || '') + " " + (data?.value?.lastnametempo || '')).trim()
  },
  {
    field: "amount",
    headerName: "Nouveau montant",
    hideable: false,
    flex: 1,
    headerAlign: "center",
    align: "center",
    headerClassName: "researchColmun",
  },
  {
    field: "oldAmount",
    headerName: "Ancien Montant",
    hideable: false,
    flex: 1,
    headerAlign: "center",
    align: "center",
    headerClassName: "researchColmun",
  },
  {
    field: "diff",
    headerName: "Différentiel",
    hideable: false,
    flex: 1,
    headerAlign: "center",
    align: "center",
    headerClassName: "researchColmun",
    renderCell : (data) => {
      let diff = parseFloat(data?.row?.amount) - parseFloat(data?.row?.oldAmount);
      let color = diffColor(data.row);
      return <div style={{ color: color.color, fontWeight: 'bold' }}>{Number(diff)?.toFixed(2)}</div>;
    }
  },
  {
    field: "username",
    headerName: "Effectué par",
    hideable: false,
    flex: 1,
    headerAlign: "center",
    align: "center",
    headerClassName: "researchColmun",
  },
];

const colorScheme = {
  'positive': { color: 'green' },
  'negative': { color: 'red' },
  'neutral': { color: 'orange' }
};

const diffColor = (row) => {
  let diff = parseFloat(row?.amount) - parseFloat(row?.oldAmount);
  let color;
  if (diff > 0) {
    color = colorScheme['positive'];
  } else if (diff < 0) {
    color = colorScheme['negative'];
  } else {
    color = colorScheme['neutral'];
  }
  return color;
}

const Transaction = (props) => {
  const {today} = props;

  const dispatch = useDispatch();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [inputs, setInputs] = useState({
    date: today,
  });

  const [transactions, setTransactions] = useState(null);
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 10,
  });

  const [rowCountState, setRowCountState] = useState(null);
  const [defaultPageNo, setDefaultPageNo] = useState(0);
  const [defaultPageSize, setDefaultPageSize] = useState(10);

  const handleDateChange = (date) => setInputs({date: date});

  const resetInput = () => handleDateChange(today);

  const refresh = (params) => {
    const { pageNo, pageSize } = params || {
      pageNo: defaultPageNo,
      pageSize: defaultPageSize,
    };
    return TransactionService.getTransaction(pageNo, pageSize, getISODate(localeISODateTime(inputs.date)))
      .then((response) => {
        let data = response.data.content;

        setTransactions(
          data.map((row) => {
            const { prepaidTransactionId, ...rest } = row;
            return { id: prepaidTransactionId, ...rest };
          })
        );
        setRowCountState(response.data.totalElements);
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          dispatch(logout());
        }
      });
  };

  useEffect(() => {
    refresh({
      pageNo: 0,
      pageSize: -1,
      inputs: {...inputs, date: getISODate(localeISODateTime(inputs.date))},
    });
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(
      () =>
        refresh({
          pageNo: 0,
          pageSize: -1,
          inputs: {...inputs, date: getISODate(localeISODateTime(inputs.date))},
        }),
      500
    );
    return () => clearTimeout(timeoutId);
  }, [inputs]);


  if (transactions == null) {
    return <></>;
  } else {
    return (
      <>
        <div className="event-container display-relative">
          <div className="event-form">
            <h3
              style={{
                marginTop: "10px",
                fontFamily: "backToSchool",
                fontSize: "65px",
                color: "#bb0000",
              }}
            >
              Transactions :
            </h3>
            <div
              className="row d-flex align-items-center justify-content-between"
              style={{ marginTop: "20px" }}
            >
              <div className="col-6">
                <CustomDatePicker
                  valueDate={inputs.date}
                  handleChange={handleDateChange}
                />
              </div>
              <div className="col-3 d-flex">
                <div className="clearResearch">
                  <Tooltip title="Vider la recherche" placement="top">
                    <button
                      onClick={(e) => {
                        resetInput();
                      }}
                    >
                      <FilterAltOffIcon />
                    </button>
                  </Tooltip>
                </div>
              </div>
              <div className="col-3 d-flex justify-content-end">
                <ExportDataGridToExcel
                  Sélection
                  du
                  type
                  de
                  bon
                  headers={columns}
                  data={transactions?.map((row) => ({
                    id: row?.id,
                    date: moment(row?.date).format("DD-MM-YYYY HH:mm"),
                    amount: row?.amount,
                    site: row?.site?.description,
                    client: (
                      (row?.client?.firstnametempo || "") +
                      " " +
                      (row?.client?.lastnametempo || "")
                    ).trim(),
                  }))}
                  filename="transactions"
                />
              </div>
            </div>
          </div>
          <div className="EventDataGrid">
            <Box
              sx={{
                height:'800px',
                width:'100%',
                // "& .transaction-roger-theme-orange": {
                //   bgcolor: (theme) =>
                //     getBackgroundColor(
                //       theme.palette.warning.main,
                //       theme.palette.mode
                //     ),
                //   "&:hover": {
                //     bgcolor: (theme) =>
                //       getHoverBackgroundColor(
                //         theme.palette.warning.main,
                //         theme.palette.mode
                //       ),
                //   },
                // },
                // "& .transaction-roger-theme-red": {
                //   bgcolor: (theme) =>
                //     getBackgroundColor(
                //       theme.palette.error.main,
                //       theme.palette.mode
                //     ),
                //   "&:hover": {
                //     bgcolor: (theme) =>
                //       getHoverBackgroundColor(
                //         theme.palette.error.main,
                //         theme.palette.mode
                //       ),
                //   },
                // },
                // "& .transaction-roger-theme-green": {
                //   bgcolor: (theme) =>
                //     getBackgroundColor(
                //       theme.palette.success.light,
                //       theme.palette.mode
                //     ),
                //   "&:hover": {
                //     bgcolor: (theme) =>
                //       getHoverBackgroundColor(
                //         theme.palette.success.light,
                //         theme.palette.mode
                //       ),
                //   },
                // },
              }}
            >
              <DataGrid
                rowsPerPageOptions={[rowsState.pageSize]}
                getRowClassName={(params) =>`transaction-roger-theme-${diffColor(params.row).color}`}
                sx={{ backgroundColor: "#fff" }}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      id: false,
                    },
                  },
                }}
                columns={columns}
                rows={transactions}
                rowCount={rowCountState}
                pagination
                showCellRightBorder
                {...rowsState}
                paginationMode="server"
                onPageChange={(page) => {
                  setDefaultPageNo(page);
                  setRowsState((prev) => ({ ...prev, page }));
                  refresh({ pageNo: page, pageSize: defaultPageSize });
                }}
                onPageSizeChange={(pageSize) => {
                  setDefaultPageSize(pageSize);
                  setRowsState((prev) => ({ ...prev, pageSize }));
                  refresh({ pageNo: defaultPageNo, pageSize: pageSize });
                }}
              />
            </Box>
          </div>
        </div>
      </>
    );
  }
}
export default Transaction;
