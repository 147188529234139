import LoadingButton from "@mui/lab/LoadingButton";
import Box from '@mui/material/Box';
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { ExportDataGridToExcel } from "../../../../../export/ExportDataGridToExcel";
import { SecretService } from "../../../../service/SecretService";
const clientColumns = [
    {
        field: "id",
        header: "Id",
        hideable: true,
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "siteName",
        headerName: "Site",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        cellClassName: "tableLeftColumn",
    },
    {
        field: "nbMale",
        headerName: "Hommes",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "percentageMale",
        headerName: "% Hommes",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        renderCell: (data) => data?.value != null ? Number(data?.value)?.toFixed(2) + " %" : "",
    },
    {
        field: "nbFemale",
        headerName: "Femmes",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "percentageFemale",
        headerName: "% Femmes",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        renderCell: (data) => data?.value != null ? Number(data?.value)?.toFixed(2) + " %" : "",
    },
    {
        field: "nbMinors",
        headerName: "Nb Mineurs",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "percentageMinors",
        headerName: "% Mineurs",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        renderCell: (data) => data?.value != null ? Number(data?.value)?.toFixed(2) + " %" : "",
    },
    {
        field: "nbYoungAdults",
        headerName: "Nb 18-25 ans",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "percentageYoungAdults",
        headerName: "% 18-25 ans",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        renderCell: (data) => data?.value != null ? Number(data?.value)?.toFixed(2) + " %" : "",
    },
    {
        field: "avgAge",
        headerName: "Moyenne d'âge",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "cumulatedAvgAge",
        headerName: "Age cumulé croissant",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
]

function Client(props) {

    const {
        rowsState,
        rowCountState,
        selectionModel,
        setSelectionModel,
        defaultPageSize,
        setDefaultPageSize,
        setDefaultPageNo,
        setRowsState,
        defaultPageNo,
        inputs
    } = props

    const [clients, setClients] = useState();
    const [loadingState, setLoadingState] = useState(false);

    const refresh = () => {
        setLoadingState(true)
        SecretService.getClientStat(inputs.startDate, inputs.endDate)
            .then((results) => {
                setClients(results.data);
                setLoadingState(false)
            })
            .catch(err => console.log(err));
    }

    useEffect(() => {
        refresh();
    }, [inputs])
    return (
        <>
            <h3
                style={{
                    position: "relative",
                    fontFamily: "backToSchool",
                    fontSize: "45px",
                    color: "#bb0000",
                    marginTop: "20px",
                }}
            >
                Clients
            </h3>
            {clients != null ?
                (
                    <>
                        <div>
                            <ExportDataGridToExcel
                                headers={clientColumns}
                                data={clients.map(row => ({
                                    "Id": "",
                                    "Site": row?.siteName,
                                    "Hommes": row?.nbMale,
                                    "% Hommes": row?.percentageMale,
                                    "Femmes": row?.nbFemale,
                                    "% Femmes": row?.percentageFemale,
                                    "Nb Mineurs": row?.nbMinors,
                                    "% Mineurs": row?.percentageMinors,
                                    "Nb 18-25 ans": row?.nbYoungAdults,
                                    "% 18-25 ans": row?.percentageYoungAdults,
                                    "Moyenne d'âge": row?.avgAge,
                                    "Age cumulé croissant": row?.cumulatedAvgAge
                                }))}
                                filename={"statistiques_client"}
                            />
                        </div>
                        <div className="EventDataGrid">
                            <DataGrid
                                rowsPerPageOptions={[rowsState.pageSize]}
                                sx={{ backgroundColor: "#fff" }}
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: {
                                            id: false,
                                        },
                                    },
                                }}
                                columns={clientColumns}
                                rows={clients}
                                loading={loadingState}
                                rowCount={rowCountState}
                                showCellRightBorder
                                pagination
                                {...rowsState}
                                paginationMode="server"
                                checkboxSelection={false}
                                selectionModel={selectionModel}
                                onSelectionModelChange={(newSelectionModel) => {
                                    const selectedIDs = new Set(newSelectionModel);
                                    const selectedRows = clients.filter((row) =>
                                        selectedIDs.has(row.id)
                                    );
                                    setSelectionModel(newSelectionModel);
                                }}
                                onPageChange={(page) => {
                                    setDefaultPageNo(page);
                                    setRowsState((prev) => ({ ...prev, page }));
                                    refresh({
                                        pageNo: page,
                                        pageSize: defaultPageSize,
                                        inputs: inputs,
                                    });
                                }}
                                onPageSizeChange={(pageSize) => {
                                    setDefaultPageSize(pageSize);
                                    setRowsState((prev) => ({ ...prev, pageSize }));
                                    refresh({
                                        pageNo: defaultPageNo,
                                        pageSize: pageSize,
                                        inputs: inputs,
                                    });
                                }}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <Box textAlign="center" mt={20}>
                            <LoadingButton loading={true} variant="outlined" disabled>
                                Loading
                            </LoadingButton>
                        </Box>
                    </>
                )
            }
        </>
    )
}

export default Client;