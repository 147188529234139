import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../../../redux/features/auth/authSlice";
import { LaundryItemService } from "../service/LaundryItemService";

import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { DataGrid } from "@mui/x-data-grid";
import ButtonManager from "../../../../shared/components/Button/ButtonManager";
//import "./LaundryItemListing.css";

const columns = [
  { field: "id", headerName: "Id", hideable: true, flex: 1, headerClassName: "researchColmun", headerAlign: "center", align: "center", },
  { field: "description", headerName: "Description", flex: 1, headerClassName: "researchColmun", headerAlign: "center", align: "center", },
  { field: "image", headerName: "Image", flex: 1, headerClassName: "researchColmun", headerAlign: "center", align: "center", 
    renderCell: (data) => (<img src={`data:image/jpeg;base64,${data.row.image}`} alt={data.row.description} style={{height: "100%"}} />)
  },
  { field: "orderItem", headerName: "Ordre", flex: 1, headerClassName: "researchColmun", headerAlign: "center", align: "center", },
  { field: "deleted", headerName: "Actif", flex: 1, headerClassName: "researchColmun", headerAlign: "center", align: "center", 
    renderCell: (data) => (data.row.deleted === false) ? <DoneIcon color="success" /> : <CloseIcon color="warning" /> 
  },
];

const forms = [
  { name: "description", label: "Description" },
  { name: "orderItem", label: "Ordre", type: "number" },
  { name: "deleted", label: "Supprimé", type: "checkbox" },
  { name: "image", label: "Image", type: "fileImage", defaultValue: null},
];

const LaundryItemListing = () => {
  const dispatch = useDispatch();

  const pageSize = 10;
  const [rows, setRows] = useState([]);
  const [select, setSelect] = useState(null);

  const loadData = () => {
    return LaundryItemService.getLaundryItem(-1, 0)
      .then((response) => {
        const data = response.data.content.sort((laundryitem1, laundryitem2) =>
          laundryitem1.orderItem - laundryitem2.orderItem 
        );
        setRows(
          data.map((row) => {
            const { laundryitemId, ...rest } = row;
            return { id: laundryitemId, ...rest };
          })
        );
        let selected = data.filter((laundryitem) => laundryitem.laundryitemId === select.laundryitemId);
        setSelect(selected.length ? selected[0] : null);
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401 
          || err?.response?.status === 403 || err?.response?.status === 401
        ) {
            dispatch(logout());
        }
      });
  };

  useEffect(() => { loadData() }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', width: '100%' }}>
      <h1 style={{ fontFamily: 'backToSchool', fontSize: '65px', color: '#bb0000', marginTop: '4%' }}>Buanderie</h1>

      <div style={{ position: 'absolute', top: 23, left: 270 }}>
        <Link to="/SettingsSelect">
          <IconButton color='primary'>
            <ArrowBackIosNewIcon />
        </IconButton>
      </Link>
    </div>
      <div className="event-container display-relative" style={{ marginTop:'5%' }}>
        {/* <h1 style={{
          fontFamily:'backToSchool',
          fontSize: '65px',
          color:'#bb0000'}}
        >
          Buanderie
        </h1> */}

        <ButtonManager
          isContained={true}
          title={"article de lessive"}
          id={select?.laundryitemId}
          selected={select}
          align={"center"}
          forms={forms}
          createFunction={LaundryItemService.createLaundryItem}
          updateFunction={LaundryItemService.updateLaundryItem}
          deleteFunction={LaundryItemService.deleteLaundryItem}
          requiredFields={[]}
          refresh={loadData}
        />

        <div className="EventDataGrid">
          <DataGrid
            rows={rows}
            columns={columns}
            autoPageSize={true}
            showCellRightBorder
            rowsPerPageOptions={[pageSize]}
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRowData = rows.filter((row) =>
                selectedIDs.has(row?.id?.toString())
              );
              if (selectedRowData.length) {
                const { id, ...rest } = selectedRowData[0];
                setSelect({ laundryitemId: id, ...rest });
              } else {
                setSelect(null);
              }
            }}
            columnVisibilityModel={{
              id: false,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default LaundryItemListing;
