import React, { useEffect } from "react";
import LegalStatusListing from "./pages/LegalStatusListing";

function LegalStatus(props) {
    const { setActive } = props;
    useEffect(() => {
      //setActive(3);
    }, []);

    return <LegalStatusListing />
};

export default LegalStatus;