import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/features/auth/authSlice";
import { Jwt } from "../../../shared/components/auth/jwt/Jwt";
import {
  DateYmdToDmY,
  dateIsValid,
  getISODate,
  getISOTime,
  localeISODateTime,
} from "../../../shared/utils/localeISODateTime";
import { ClientService } from "../../client/service/ClientService";
import { PresenceService } from "../services/PresenceService";

import { TextField } from "@material-ui/core";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AirlineSeatIndividualSuiteIcon from "@mui/icons-material/AirlineSeatIndividualSuite";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import CloseIcon from "@mui/icons-material/Close";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import HourglassBottomRoundedIcon from "@mui/icons-material/HourglassBottomRounded";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import PetsIcon from '@mui/icons-material/Pets';
import LocalLaundryServiceIcon from "@mui/icons-material/LocalLaundryService";
import SearchIcon from "@mui/icons-material/Search";
import ShowerIcon from "@mui/icons-material/Shower";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Grid, Tooltip } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { darken, lighten, useTheme } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import ButtonManager from "../../../shared/components/Button/ButtonManager";
import { removeAccents } from "../../../shared/utils/StringProcessing";
import { BalanceService } from '../../client/service/SubService/BalanceService';
import { ClientSettingsService } from "../../client/service/SubService/ClientSettings";
import { CustomDatePicker } from "../../datepicker/CustomDatePicker";
import { ClosingService } from "../../reports/service/ClosingService";
import { ClientsTUCModal } from "../modals/ClientsTUCModal";
import { VoucherModal } from "../modals/VoucherModal";
import "./PresenceListing.css";
import ClientPartialinformations from "./tabs/PresenceInformation/ClientPartialInformation";

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getIcon = (voucherType, action) => {
  const voucherTypes = {
    Shower: (
      <ShowerIcon
        key={"showerIcon"}
        onClick={action}
        sx={{ cursor: "pointer", margin: "0px 2px" }}
      />
    ),
    Lockerroom: (
      <CheckroomIcon
        key={"checkroomIcon"}
        onClick={action}
        sx={{ cursor: "pointer", margin: "0px 2px" }}
      />
    ),
    Laundry: (
      <LocalLaundryServiceIcon
        key={"localLaundryServiceIcon"}
        onClick={action}
        sx={{ cursor: "pointer", margin: "0px 2px" }}
      />
    ),
    Doctor: (
      <LocalHospitalIcon
        key={"localHospitalIcon"}
        onClick={action}
        sx={{ cursor: "pointer", margin: "0px 2px" }}
      />
    ),
    Veterinary: (
      <PetsIcon
        key={"PetsIcon"}
        onClick={action}
        sx={{ cursor: "pointer", margin: "0px 2px" }}
      />
    ),
    Hairdresser: (
      <ContentCutIcon
        key={"contentCutIcon"}
        onClick={action}
        sx={{ cursor: "pointer", margin: "0px 2px" }}
      />
    ),
    Sleepingbag: (
      <AirlineSeatIndividualSuiteIcon
        key={"airlineSeatIndividualSuiteIcon"}
        onClick={action}
        sx={{ cursor: "pointer", margin: "0px 2px" }}
      />
    ),
  };
  return voucherTypes[voucherType] ?? null;
};

const clientForms = [
  { name: "firstnametempo", label: "Prénom", type: "string", required: true },
  { name: "lastnametempo", label: "Nom", type: "string", required: true },
  {
    name: "sexe",
    label: "Sexe",
    type: "select",
    options: [
      { name: "Homme", value: "Homme" },
      { name: "Femme", value: "Femme" },
      { name: "Autre", value: "Autre" },
    ],
    required: true,
  },
  {
    name: "birthdatetempo",
    label: "Date de naissance",
    type: "date",
    valueDate: "",
    required: true,
  },
  { name: "surnametempo", label: "Surnom", type: "string" },
  { name: "phonetempo", label: "Numéro de tel.", type: "number" },
  { name: "address", label: "Addresse", type: "string" },
  {
    name: "registrationNumbertempo",
    label: "Matricule",
    type: "number",
  },
  {
    name: "costoflivingAllowanceDate",
    label: "Date demande alloc vie chère",
    type: "date",
    valueDate: "",
  },
  {
    name: "workContraindication",
    label: "Contre indication au travail",
    type: "string",
  },
  { name: "financialManagement", label: "Gestion financière", type: "string" },
  { name: "otherServices", label: "Autres services", type: "string" },
  { name: "remark", label: "Remarque", type: "string" },
  { name: "message", label: "Message", type: "string" },
];

const clientInformations = [
  {
    name: "remark",
    label: "remarque",
  },
];

function PresenceListing(props) {
  const {
    client,
    handleSelectClient,
    addNewPresence,
    setAddNewPresence,
    voucher,
    setVoucher,
    open,
    setOpen,
    handleClose,
    selected,
    handleChangeIndex,
    setSelected,
    today,
    setToday,
    presences,
    setPresences,
  } = props;

  const methods = useForm({ mode: "onChange", defaultValues: client });
  const formProps = {
    control: methods.control,
    disabled: true,
  };

  const dispatch = useDispatch();

  const site = useSelector((state) => state.site.site);
  const user = useSelector((state) => state.auth.user);
  const userData = Jwt(user?.token);

  const theme = useTheme();

  const [allPresences, setAllPresences] = useState(null);

  const [totalMeal, setTotalMeal] = useState({
    Clients: 0,
    ATI: 0,
    Atelier: 0,
  });

  const [legalStatus, setLegalStatus] = useState([]);
  const [clientGroups, setClientGroups] = useState([]);
  const [nationality, setNationality] = useState([]);
  const [countries, setCountries] = useState([]);
  const [residents, setResidents] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [accomodations, setAccomodations] = useState([]);

  const [disabledBtn, setDisabledBtn] = useState(true);
  const [openTUCModal, setOpenTUCModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const handleSearch = (event) => setSearch(event.target.value);
  const [allSearchResults, setAllSearchResults] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const handleResetSearch = () => setSearch("");

  const defaultPageSizeInit = 50;

  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: defaultPageSizeInit,
  });
  const [rowCountState, setRowCountState] = useState(10);
  const [selectionModel, setSelectionModel] = useState([]);
  const [defaultPageNo, setDefaultPageNo] = useState(0);
  const [defaultPageSize, setDefaultPageSize] = useState(defaultPageSizeInit);

  const exclusionStatus = (row) => {
    if (row.exclusion) {
      let exclusionDateStart = new Date(row.exclusion?.dateStart + "T00:00:00.000");
      let exclusionDateEnd = new Date(row.exclusion?.dateEnd + "T23:59:59.999");
      if ((exclusionDateStart <= new Date(today)) && (new Date(today) <= exclusionDateEnd)) {
        return "-danger";
      } else {
        return "-warning";
      }
    }
    if (row.firstTime) {
      return "-blue";
    }
    return "";
  };
  
  /*
  const handleFunction = (value) => {
    if (value === "" || value === "." || value === "-") {
      return "0";
    } else {
      return value;
    }
  };
  */

  const forms = [
    {
      name: "balance",
      label: "Balance",
      type: "number",
      inputProps: { inputProps: {step: ".25"} },
      //rules: handleFunction,
    },
  ];
  
  const closingForms = [
    {
      name: "date",
      label: "Date",
      type: "date",
      valueDate: dateIsValid(today) ? today : new Date(),
    },
    { name: "mealClient", label: "Repas Client", type: "number", value: 0 },
    { name: "mealAti", label: "Repas ATI", type: "number", value: 0 },
    { name: "mealAtelier", label: "Repas Atelier", type: "number", value: 0 },
    { name: "mealTotal", label: "Total repas", type: "number", value: 0 },
    {
      name: "dayCenter",
      label: "Présences Centre de Jour",
      type: "number",
      value: 0,
    },
    { name: "remark", label: "Remarque", type: "text" },
  ];

  const columns = [
    {
      field: "id",
      headerName: "Id",
      hideable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "researchColmun",
    },
    {
      field: "fullName",
      headerName: "Nom complet",
      flex: 1,
      maxWidth: 200,
      headerAlign: "center",
      headerClassName: "researchColmun",
      renderCell: (data) => {
        return (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Tooltip title="Supprimer la présence" placement="top">
              <Checkbox
                icon={<DisabledByDefaultRoundedIcon sx={{ color: "red" }} />}
                checkedIcon={
                  <HourglassBottomRoundedIcon sx={{ color: "red" }} />
                }
                fontSize="small"
                sx={{ color: "red" }}
                onClick={() => {
                  setAddNewPresence(false);
                  handleDeletePresence(data.row.id);
                }}
              />
            </Tooltip>
            <span
              style={{
                marginTop: 13,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              {data?.value}{" "}
            </span>
          </Box>
        );
      },
    },
    {
      field: "meal",
      headerName: "Repas",
      flex: 1,
      maxWidth: 70,
      headerAlign: "center",
      align: "center",
      headerClassName: "researchColmun",
      renderCell: (data) => {
        return (
          <Box>
            <IndeterminateCheckBoxIcon
              fontSize="small"
              color="error"
              onClick={() => {
                handleMealChangeQuantity(data.row, data.row.id, -1);
              }}
              sx={{ cursor: "pointer" }}
            />
            <span> {data?.value} </span>
            <AddBoxIcon
              fontSize="small"
              color="success"
              onClick={() => {
                handleMealChangeQuantity(data.row, data.row.id, 1);
              }}
              sx={{ cursor: "pointer" }}
            />
          </Box>
        );
      },
    },
    {
      field: "balance",
      headerName: "Balance",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "researchColmun",
      maxWidth: 120,
      renderCell: (data) => {
          return (
            <>
              <div style={{ fontSize:"15px", marginLeft:"20px" }}>{data?.row?.balance}</div>
              <ButtonManager
                id={data.row.client.clientId}
                onlyIcon={true}
                title={"Balance"}
                selected={data.row}
                align={"center"}
                forms={forms}
                updateFunction={BalanceService.updateBalance}
                site={site}
                requiredFields={["balance"]}
                requiredFieldLabel={["Balance"]}
                refresh={refresh}
              />
            </>
          );
        }
    },
    {
      field: "vouchers",
      headerName: "Bons",
      flex: 1,
      headerAlign: "center",
      align: "left",
      headerClassName: "researchColmun",
      renderCell: (data) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                margin: "0% 5%",
              }}
            >
              <Tooltip title="Ajouter un bon" placement="top">
                <AddBoxIcon
                  fontSize="small"
                  color="success"
                  onClick={() => handleChangeIndex(2)}
                  sx={{ cursor: "pointer" }}
                />
              </Tooltip>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {data?.value?.map((voucher) =>
                getIcon(voucher?.voucherType?.voucherType, (e) => {
                  e.preventDefault();
                  showDialogVoucher(voucher);
                })
              ) || null}
            </div>
          </div>
        );
      },
    },
    {
      field: "events",
      headerName: "Evènements",
      flex: 1,
      headerAlign: "center",
      align: "left",
      headerClassName: "researchColmun",
      renderCell: (data) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                margin: "0% 5%",
              }}
            >
              <Tooltip title="Ajouter une participation à un évènement" placement="top">
                <AddBoxIcon
                  fontSize="small"
                  color="success"
                  onClick={() => handleChangeIndex(3)}
                  sx={{ cursor: "pointer" }}
                />
              </Tooltip>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {data?.value?.map((voucher) =>
                getIcon(voucher?.voucherType?.voucherType, (e) => {
                  e.preventDefault();
                  showDialogVoucher(voucher);
                })
              ) || null}
            </div>
          </div>
        );
      },
    },
    {
      field: "entryHour",
      headerName: "Heure d'arrivée",
      flex: 1,
      maxWidth: 120,
      headerAlign: "center",
      align: "center",
      headerClassName: "researchColmun",
      renderCell: (data) => data?.value?.substring(11, 16) || null,
    },
  ];

  const showDialogVoucher = (voucher) => {
    setVoucher(voucher);
    setOpen(true);
  };

  const handleNumericInput = (e) => {
    e.target.value = e.target.value
      .replace(/[^-0-9.]/g, '')
      .replace(/(\..*?)\..*/g, '$1')
      .replace(/^0[^.]/, '0');
  }

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  const refresh = (params) => {
    const { pageNo, pageSize } = params || {
      pageNo: defaultPageNo,
      pageSize: defaultPageSize,
    };
    setLoading(true);
    return PresenceService.getPresencesBySite(
      0, //pageNo,
      5000, //pageSize,
      dateIsValid(today)
        ? getISODate(localeISODateTime(today))
        : getISODate(localeISODateTime()),
      site?.siteId
    )
      .then((response) => {
        const data = response.data.content
          .sort((p1, p2) => p2?.entryHour?.localeCompare(p1?.entryHour))
          .map((row) => {
            const { presenceId, ...rest } = row;
            return { id: presenceId, ...rest };
          });
        setAllPresences(data);
        setPresences(
          data.filter(
            (presence, index) =>
              index >= pageNo * pageSize && index < pageNo * pageSize + pageSize
          )
        );
        setRowCountState(response.data.totalElements);

        if (selected?.presenceId) {
          let {id, balance, client, ...rest} = data.filter(presence => presence.id == selected.presenceId)[0];
          setSelected({
            presenceId: id,
            balance: balance,
            client: { ...client, balance: balance },
            ...rest,
          });
        }
      })
      .catch((err) => {
        if (
          err?.statusCode === 403 ||
          err?.statusCode === 401 ||
          err?.response?.status === 403 ||
          err?.response?.status === 401
        ) {
          dispatch(logout());
        }
      })
      .finally(() => setLoading(false));
  };

  const handleDeletePresence = (id) => {
    PresenceService.deletePresence(id)
      .then((response) => refresh({ pageNo: defaultPageNo, pageSize: defaultPageSize }))
      .catch((err) => {
        console.log(err);
        if (
          err?.statusCode === 403 ||
          err?.statusCode === 401 ||
          err?.response?.status === 403 ||
          err?.response?.status === 401
        ) {
          dispatch(logout());
        }
      });
  };

  
  const handleAddPresence = () => {
    PresenceService.createPresence(
      {
        client: client,
        presenceDate: dateIsValid(today)
          ? getISODate(localeISODateTime(today))
          : getISODate(localeISODateTime()),
        entryHour: getISOTime(localeISODateTime()),
        site: site,
        meal: 1,
        encoder: userData?.sub
      },
      site?.siteId
    )
      .then((r) => refresh({ pageNo: defaultPageNo, pageSize: defaultPageSize }))
      .catch((err) => {
        setAddNewPresence(false);
        if (
          err?.statusCode === 403 ||
          err?.response?.status === 403 ||
          err?.statusCode === 401 ||
          err?.response?.status === 401
        ) {
          dispatch(logout());
        } else if (
          err?.statusCode === 400 ||
          err?.response?.status === 400 ||
          err.response.status === 404
        ) {
          setAlertMsg(
            "Présence déjà ajoutée aujourd'hui pour " +
            (
              (client?.firstnametempo || " ") +
              " " +
              (client?.lastnametempo || "")
            ).trim()
          );
          setOpenAlert(true);
        }
      });
  };

  const handleMealChangeQuantity = (currentValue, presenceId, qty) => {
    let value = currentValue.meal + qty;
    var balance = parseFloat(currentValue.balance);
    if (value >= 0) {
      PresenceService.updateMealQuantity(
        presenceId,
        value,
        balance,
        site?.siteId
      )
        .then((r) => {
          refresh({ pageNo: defaultPageNo, pageSize: defaultPageSize });
        })
        .catch((err) => console.log(err));
    }
  };

  const handleDateChange = (date) => setToday(date);

  useEffect(() => {
    let searchFields = ["fullName", "entryHour", "birthdatetempo"];
    let searchWords = search?.trim()?.split(" ");
    if (search?.length > 0) {
      let results = allPresences
        ?.map((presence) => ({
          ...presence,
          fullName: presence?.fullName,
          entryHour: presence["entryHour"]?.substring(11, 16),
          birthdatetempo: DateYmdToDmY(presence?.client?.birthdatetempo, "/"),
        }))
        .filter((presence) =>
          searchWords?.every(
            (word) =>
              Object.keys(presence)
                ?.map((field) =>
                  searchFields?.includes(field) &&
                    presence[field] !== undefined &&
                    presence[field] !== null &&
                    removeAccents(presence[field]?.toLowerCase())?.indexOf(
                      removeAccents(word?.toLowerCase())
                    ) > -1
                    ? removeAccents(presence[field]?.toLowerCase())
                    : ""
                )
                .join()
                .indexOf(removeAccents(word?.toLowerCase())) > -1
          )
        );
      setAllSearchResults(results);
      if (results?.length > 0) {
        let res = results
          .filter(
            (presence, index) =>
              index >= defaultPageNo * defaultPageSize &&
              index < defaultPageNo * defaultPageSize + defaultPageSize
          )
          .sort((p1, p2) => p2?.entryHour?.localeCompare(p1?.entryHour))
          .map((row) => {
            const { presenceId, ...rest } = row;
            return { id: presenceId, ...rest };
          });
        setSearchResults(res);
        setRowCountState(results?.length);
      } else {
        setSearchResults([]);
        setRowCountState(allPresences?.length || 10);
      }
    } else {
      setSearchResults(null);
      setRowCountState(allPresences?.length || 10);
    }
  }, [search, presences]);

  useEffect(() => {
    if (dateIsValid(today)) {
      refresh({
        pageNo: defaultPageNo,
        pageSize: defaultPageSize,
      });
    }
  }, [today]);

  useEffect(() => {
    refresh({
      pageNo: defaultPageNo,
      pageSize: defaultPageSize,
    });

    ClientSettingsService.getClientSettings()
      .then(({ data }) => {
        setLegalStatus({
          name: "legalStatus",
          label: "Statut légal",
          type: "select",
          options:
            data?.legalStatuses
              ?.sort((status1, status2) =>
                status1?.description?.localeCompare(status2?.description)
              )
              ?.filter(
                (element) =>
                  element?.description !== "" && element?.deleted !== true
              )
              ?.map((elem) => ({ name: elem?.description, value: elem })) || [],
        });

        setClientGroups({
          name: "clientGroup",
          label: "Groupe Client",
          type: "select",
          options:
            data?.clientGroups
              ?.sort((group1, group2) =>
                group1.description.localeCompare(group2.description)
              )
              ?.map((elem) => ({ name: elem?.description, value: elem })) || [],
        });

        setDrivers({
          name: "driverLicence",
          label: "Permis de conduire",
          type: "select",
          options:
            data?.driverLicences
              ?.sort((a, b) => a?.name?.localeCompare(b?.name))
              ?.filter((element) => element?.name !== "")
              ?.map((elem) => ({ name: elem?.name, value: elem })) || [],
        });

        setNationality({
          name: "nationality",
          label: "Nationalité",
          type: "select",
          options:
            data?.nationalities
              ?.sort((a, b) => a?.description?.localeCompare(b?.description))
              ?.filter(
                (element) =>
                  element?.description !== "" && element?.deleted !== true
              )
              ?.map((elem) => ({ name: elem?.description, value: elem })) || [],
        });

        setCountries({
          name: "originCountry",
          label: "Pays origine",
          type: "select",
          options:
            data?.countries
              ?.sort((a, b) => a?.name?.localeCompare(b?.name))
              ?.filter(
                (element) => element?.name !== "" && element?.deleted !== true
              )
              ?.map((elem) => ({ name: elem?.name, value: elem })) || [],
        });

        setAccomodations({
          name: "accomodation",
          label: "Logement",
          type: "select",
          options:
            data?.accomodations
              ?.sort((a, b) => a?.description?.localeCompare(b?.description))
              ?.filter((element) => element?.description !== "")
              ?.map((elem) => ({ name: elem?.description, value: elem })) || [],
        });

        setResidents({
          name: "resident",
          label: "Resident",
          type: "select",
          options:
            data?.residents
              ?.sort((a, b) => a?.description?.localeCompare(b?.description))
              ?.filter(
                (element) =>
                  element?.description !== "" && element?.deleted !== true
              )
              ?.map((elem) => ({ name: elem?.description, value: elem })) || [],
        });

        setMaritalStatus({
          name: "maritalStatus",
          label: "Etat civil",
          type: "select",
          options:
            data?.maritalStatuses
              ?.sort((a, b) => a?.description?.localeCompare(b?.description))
              ?.filter(
                (element) =>
                  element?.description !== "" && element?.deleted !== true
              )
              ?.map((elem) => ({ name: elem?.description, value: elem })) || [],
        });
      })
      .catch((err) => {
        if (
          err?.statusCode === 403 ||
          err?.statusCode === 401 ||
          err?.response?.status === 403 ||
          err?.response?.status === 401
        ) {
          dispatch(logout());
        }
      });
  }, []);

  useEffect(() => {
    if (addNewPresence === true) {
      handleAddPresence();
      setAddNewPresence(false);
    }
  }, [addNewPresence]);

  if (presences != null) {
    return (
      <div>
        <VoucherModal
          open={open}
          handleClose={handleClose}
          voucher={voucher}
          presence={selected}
          refresh={refresh}
          today={today}
          setVoucher={setVoucher}
        />

        <div style={{ marginTop: "1%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div style={{ width: "60%", margin: "0px 10px" }}>
              <ClientPartialinformations client={selected?.client} />
            </div>
            <div
              style={{
                width: "100%",
                margin: "0px 10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <span style={{ fontFamily: "overlockBold" }}>Site:</span>{" "}
                  {(site && <span>{site?.description}</span>) || (
                    <span>Veuillez sélectionner un site</span>
                  )}
                </div>
                <div>
                  <CustomDatePicker
                    valueDate={today}
                    handleChange={handleDateChange}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ minWidth: "180px" }}>
                  <ButtonManager
                    btnTitle={"nouveau client"}
                    title={"clients"}
                    id={client?.clientId}
                    align={"left"}
                    forms={[
                      { ...clientGroups, required: true },
                      { ...nationality, required: true },
                      ...clientForms,
                      { ...countries },
                      { ...residents },
                      { ...legalStatus },
                      { ...accomodations },
                      { ...drivers },
                      { ...maritalStatus },
                    ]}
                    requiredFields={[
                      "firstnametempo",
                      "lastnametempo",
                      "birthdatetempo",
                      "nationality",
                      "clientGroup",
                      "sexe",
                    ]}
                    requiredFieldLabel={[
                      "Prénom",
                      "Nom",
                      "Date de naissance",
                      "nationalité",
                      "groupe client",
                    ]}
                    preSetInputs={{ sites: [site] }}
                    createFunction={ClientService.createClient}
                    refresh={refresh}
                    handleClose={handleClose}
                    isContained={true}
                  />
                </div>

                <div>
                  <Button
                    variant="contained"
                    onClick={() => setOpenTUCModal(true)}
                    disabled={disabledBtn}
                  >
                    TUC
                  </Button>

                  <ClientsTUCModal
                    open={openTUCModal}
                    handleClose={() => setOpenTUCModal(false)}
                    refresh={refresh}
                    date={
                      dateIsValid(today)
                        ? getISODate(today)
                        : getISODate(new Date())
                    }
                    setDisabledBtn={setDisabledBtn}
                    presences={allPresences}
                  />
                </div>

                <div>
                  <ButtonManager
                    btnTitle={"clôture"}
                    title={"Clôture"}
                    id={"cloture"}
                    align={"left"}
                    preSetInputs={{
                      site: site,
                      user: { userName: userData?.sub },
                      date: dateIsValid(today) ? today : new Date(),
                      manual: true,
                    }}
                    forms={closingForms}
                    createFunction={ClosingService.createClosing}
                    refresh={refresh}
                    requiredFields={["mealClient", "mealAti", "mealAtelier"]}
                    requiredFieldLabel={[
                      "Repas Client",
                      "Repas TUC",
                      "Repas Atelier",
                    ]}
                    isContained={true}
                    setTotalMeal={setTotalMeal}
                    totalMeal={totalMeal}
                    customError={"Clôture déjà réalisée"}
                  />
                </div>
              </div>
              <Box sx={{ display: "flex" }}>
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#fff",
                    width: "100%",
                    borderRadius: "5px",
                  }}
                >
                  <Grid container>
                    <Grid item xs={1}>
                      <SearchIcon />
                    </Grid>
                    <Grid item xs={11}>
                      <TextField
                        name={"search"}
                        placeholder="Recherche sur le nom, heure d'arrivée, date de naissance (jj/mm/aaaa)..."
                        inputProps={{
                          "aria-label": "search",
                        }}
                        onChange={handleSearch}
                        value={search}
                      />
                    </Grid>
                  </Grid>
                </div>
                <Tooltip title="Effacer la recherche">
                  <IconButton
                    aria-label="close"
                    onClick={handleResetSearch}
                    sx={{ color: "#000" }}
                  >
                    <FilterAltOffIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </div>
          </div>
        </div>

        <Collapse in={openAlert}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {alertMsg}
          </Alert>
        </Collapse>

        <Box
          sx={{
            height: 400,
            width: "100%",
            "& .super-app-theme-warning": {
              bgcolor: (theme) =>
                getBackgroundColor(
                  theme.palette.warning.main,
                  theme.palette.mode
                ),
              "&:hover": {
                bgcolor: (theme) =>
                  getHoverBackgroundColor(
                    theme.palette.warning.main,
                    theme.palette.mode
                  ),
              },
            },
            "& .super-app-theme-danger": {
              bgcolor: (theme) =>
                getBackgroundColor(
                  theme.palette.error.main,
                  theme.palette.mode
                ),
              "&:hover": {
                bgcolor: (theme) =>
                  getHoverBackgroundColor(
                    theme.palette.error.main,
                    theme.palette.mode
                  ),
              },
            },
            "& .super-app-theme-blue": {
              bgcolor: (theme) =>
                getBackgroundColor(
                  theme.palette.primary.light,
                  theme.palette.mode
                ),
              "&:hover": {
                bgcolor: (theme) =>
                  getHoverBackgroundColor(
                    theme.palette.primary.light,
                    theme.palette.mode
                  ),
              },
            },
            "& .super-app-theme-success": {
              bgcolor: (theme) =>
                getBackgroundColor(
                  theme.palette.success.light,
                  theme.palette.mode
                ),
              "&:hover": {
                bgcolor: (theme) =>
                  getHoverBackgroundColor(
                    theme.palette.success.light,
                    theme.palette.mode
                  ),
              },
            },
          }}
        >
          <DataGrid
            sx={{ backgroundColor: "#fff" }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
            getRowClassName={(params) =>
              `super-app-theme${exclusionStatus(params.row.client)}`
            }
            loading={loading}
            columns={columns}
            rows={searchResults ? searchResults : presences}
            rowCount={rowCountState}
            pagination
            rowsPerPageOptions={[defaultPageSize]}
            showCellRightBorder
            {...rowsState}
            paginationMode="server"
            selectionModel={selectionModel}
            onPageChange={(page) => {
              setDefaultPageNo(page);
              setRowsState((prev) => ({ ...prev, page }));
              refresh({ pageNo: page, pageSize: defaultPageSize });
            }}
            onPageSizeChange={(pageSize) => {
              setDefaultPageSize(pageSize);
              setRowsState((prev) => ({ ...prev, pageSize }));
              refresh({ pageNo: defaultPageNo, pageSize: pageSize });
            }}
            onSelectionModelChange={(newSelectionModel) => {
              const selectedIDs = new Set(newSelectionModel);
              const selectedRows = searchResults
                ? searchResults?.filter((row) => selectedIDs.has(row.id))
                : presences?.filter((row) => selectedIDs.has(row.id));
              if (selectedRows.length && selectedRows[0] !== null) {
                const { id, balance, client, ...rest } = selectedRows[0];
                setSelected({
                  presenceId: id,
                  balance: balance,
                  client: { ...client, balance: balance },
                  ...rest,
                });
                handleSelectClient(selectedRows[0].client);
              }
              setSelectionModel(newSelectionModel);
            }}
          />
        </Box>
      </div>
    );
  } else {
    return (
      <>
        <Box textAlign="center" mt={40}>
          <LoadingButton loading={true} variant="outlined" disabled>
            Fetch data
          </LoadingButton>
        </Box>
      </>
    );
  }
}

export default PresenceListing;
