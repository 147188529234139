import React from "react";

function DeleteForm(props) {
    const { title } = props;

    return (
        <>
            Etes-vous sûr de vouloir supprimer : { title.toLowerCase() } ?
        </>
    )
}
export default DeleteForm;