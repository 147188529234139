import instance from "../../../shared/components/auth/service/AxiosInstance";

export const EventService = {
  getEvents: (pageNo, pageSize, inputs) =>
    instance.get(
      process.env.REACT_APP_API_URL +
        "event/?pageSize=" +
        pageSize +
        "&pageNo=" +
        pageNo +
        "&dateEvent=" +
        inputs.dateEvent +
        "&dateLimitInscription=" +
        inputs.dateLimitInscription,
    ),

  createEvent: (event) =>
    instance.post(process.env.REACT_APP_API_URL + "event/", event),

  updateEvent: (id, Event) =>
    instance.put(process.env.REACT_APP_API_URL + "event/" + id, Event),

  deleteEvent: (id) =>
    instance.delete(process.env.REACT_APP_API_URL + "event/" + id),

  getEventUser: (id, pageNo, pageSize) =>
    instance.get(
      process.env.REACT_APP_API_URL +
        "event/clients/" +
        id +
        "/?pageSize=" +
        pageSize +
        "&pageNo=" +
        pageNo,
    ),

  createInscription: (clientId, eventId) =>
    instance.post(
      process.env.REACT_APP_API_URL + "inscription/",
      {
        clientId: clientId,
        eventId: eventId,
      },
    ),

    deleteInscription : (clientId, eventId) => 
      instance.delete(
        process.env.REACT_APP_API_URL + "inscription/client/"+clientId+"/event/"+eventId
      ),
};
