import React, { useEffect } from "react";
import EventListing from "./pages/EventListing";


function Event(props) {
    const { setActive } = props;
    useEffect(() => {
      //setActive(3);
    }, []);

    return(
        <>
            <EventListing/>
        </>
    )
}
export default Event;