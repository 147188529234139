import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "../language/Components/LanguageSelector";
import { Client } from "./components/Client";
import { Animal } from "./components/Animal";
import { Consultations } from "./components/Consultations";
import { Box} from '@mui/material';
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Planning } from "./components/Planning";

export const Veterinary = () => {

    const { t, i18n } = useTranslation();

    const [menu, setMenu] = useState('planning');
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [selectedAnimal, setSelectedAnimal] = useState(null);

    useEffect(() => {
        if (menu === 'planning') {
            setSelectedAnimal(null);
        }
    }, [menu]);

    useEffect(() => {
        i18n.changeLanguage('de');
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                width: '100%',
                margin: '0 20px',
            }}
        >
            <Box display={'flex'}>
                <Box width={'34%'} display={'flex'} justifyContent={'flex-start'}>
                    {(menu !== 'planning') && 
                        <IconButton 
                            color="primary" 
                            aria-label={t('veterinary.backToPlanning')}
                            onClick={() => setMenu('planning')}
                        >
                            <ArrowBackIosNewIcon /> {t('veterinary.backToPlanning')}
                        </IconButton>
                    }
                </Box>
                <Box width={'33%'} display={'flex'} justifyContent={'flex-start'}>
                    <h1
                        style={{
                            fontFamily: "backToSchool",
                            fontSize: "65px",
                            color: "#bb0000",
                            textAlign: "center",
                            width: "100%",
                        }}
                    >
                        {t('veterinary.title')}
                    </h1>
                </Box>
                <Box width={'33%'} display={'flex'} justifyContent={'flex-end'}>
                    <LanguageSelector />
                </Box>
            </Box>
            {(menu !== 'planning') &&
                <>
                    <Box width={'100%'} display="flex">
                        <Client clientId={selectedAppointment?.presence?.clientId} />
                    </Box>
                    <hr />
                    <Box width={'100%'}>
                        <Animal 
                            clientId={selectedAppointment?.presence?.clientId} 
                            selectedAnimal={selectedAnimal}
                            setSelectedAnimal={setSelectedAnimal}
                        />
                    </Box>
                    <hr />
                    <Box width={'100%'}>
                        <Consultations selectedAnimal={selectedAnimal} />
                    </Box>
                </>
            }
            {(menu === 'planning') && 
                <Box
                    sx={{
                        flexGrow: 1,
                    }}
                >
                    <h4>{t('veterinary.planning')}</h4>
                    <Planning 
                        selectedAppointment={selectedAppointment}
                        setSelectedAppointment={setSelectedAppointment}
                        setMenu={setMenu}
                    />
                </Box>
            }
        </Box>
    );
};