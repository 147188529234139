import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/features/auth/authSlice";

import { VoucherTypeService } from "../services/SubService/VoucherTypeService";
import { VoucherService } from "../services/VoucherService";

import { styled } from "@mui/material/styles";
import { DateYmdToDmY, compareDates, getISODate, localeISODateTime } from "../../../shared/utils/localeISODateTime";
import { SiteService } from "../../site/service/SiteService";

const NextDate = styled("div")(({ theme }) => ({
  position: 'absolute',
  top: -25,
  left: 0,
  right: 0,
  color: 'red',
  fontSize: 'smaller'
}));

export const VoucherTypeList = (props) => {
  const { handleNext, voucher, setVoucher, client, today } = props;

  const [currDate, setCurrDate] = useState(getISODate(today)) || getISODate(localeISODateTime());

  const dispatch = useDispatch();

  const [site, setSite] = useState(useSelector((state) => state.site));

  const [isLocalStorageSiteUpdated, setIsLocalStorageSiteUpdated] = useState(false);
  const [voucherTypes, setVoucherTypes] = useState([]);
  const [lastVouchers, setLastVouchers] = useState(null);

  useEffect(() => {
    SiteService.getSite(site?.site?.siteId)
    .then((response) => {
      localStorage.setItem("site", JSON.stringify(response.data));
      setSite(response.data);
      setIsLocalStorageSiteUpdated(true);
    }).catch((err) => {
        console.log(err);
        if (
          err?.statusCode === 403 ||
          err?.statusCode === 401 ||
          err?.response?.status === 403 ||
          err?.response?.status === 401
        ) {
          dispatch(logout());
        }
      });
  }, [])

  useEffect(() => {
    VoucherTypeService.getVoucherTypes(0, -1)
      .then((response) => {
        setVoucherTypes(response.data.content)
      })
      .catch((err) => {
        console.log(err);
        if (
          err?.statusCode === 403 ||
          err?.statusCode === 401 ||
          err?.response?.status === 403 ||
          err?.response?.status === 401
        ) {
          dispatch(logout());
        }
      });
  }, [])

  useEffect(() => {
    if (client) {
      VoucherService.getClientLastVouchers(client?.clientId)
        .then(response => {
          setLastVouchers(response.data);
        })
        .catch(err => console.log(err))
    }
  }, [client]);

  if (voucherTypes !== [] && isLocalStorageSiteUpdated === true) {
    return (
      <>
        <div className="row gy-3">
          {voucherTypes
            .sort((voucherType1, voucherType2) =>
              voucherType1?.description.localeCompare(voucherType2?.description)
            )
            .filter(
              (voucherType) =>
                site?.[(voucherType?.voucherType).toLowerCase()] === true ||
                site?.[(voucherType?.voucherType).toLowerCase()] > 0
            )
            .map((voucherType) => {
              let nextRdvDate = lastVouchers?.filter(voucher => voucher?.voucherType?.voucherTypeId === voucherType.voucherTypeId)[0]?.nextRdvDate;
              let nextRdvDateIsPassed = (nextRdvDate && compareDates(currDate, nextRdvDate)) || ((nextRdvDate === null) || (nextRdvDate === undefined));
              return (
                <div key={voucherType?.voucherTypeId} className="col-4 position-relative my-4">
                  {!nextRdvDateIsPassed && <NextDate>{ DateYmdToDmY(nextRdvDate, '.') }</NextDate>}
                  <Button
                    variant={"contained"}
                    onClick={(e) => {
                      e.preventDefault();
                      setVoucher({ ...voucher, voucherType: voucherType });
                      handleNext();
                    }}
                    sx={{
                      width: '180px',
                      backgroundColor: (nextRdvDateIsPassed ? '#1565c0' : 'red')
                    }}
                  >
                    {voucherType.description.substring(4)}
                  </Button>
                </div>
            )
            })}
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="row gy-3">Auncun service de bon disponible pour ce site</div>
      </>
    );
  }
};
