import React, { useEffect } from "react";
import CountryListing from "./pages/CountryListing";

function Country(props) {
    const { setActive } = props;
    useEffect(() => {
      //setActive(3);
    }, []);

    return <CountryListing />
};


export default Country;