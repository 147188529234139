import instance from "../../../../shared/components/auth/service/AxiosInstance";

export const ExclusionService = {
  createExclusion: (Exclusion) =>
    instance.post(process.env.REACT_APP_API_URL + "exclusion/", Exclusion),

  updateExclusion: (id, Exclusion) =>
    instance.put(process.env.REACT_APP_API_URL + "exclusion/" + id, Exclusion),

  deleteExclusion: (id) =>
    instance.delete(process.env.REACT_APP_API_URL + "exclusion/" + id),
};
