import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../../../../redux/features/auth/authSlice';

import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import CanvasJSReact from '../../../../../canvasjs.react';
import { ClientService } from "../../../service/ClientService";
import './ClientFrequentation.css';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function ClientFrequentation(props) {
  const { client } = props;

  const dispatch = useDispatch();

  const [clientName, setClientName] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [nbPresence, setNbPresence] = useState(50);
  const handleChange = (event) => {
    setNbPresence(event.target.value);
  };

  const listNbPresences = [
    { value: 10, label: '10' },
    { value: 30, label: '30' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 500, label: '500' }
  ]

  const copy = (array) => {
    let newArray = [];
    for (let i = 0; i < array.length; i++) {
      newArray.push({ label: array[i], y: 0 });
    }
    return (newArray.reverse());
  }

  useEffect(() => {
    let placeName = [];
    let tmpData = [];
    let tmpDate = [];
    if (client != null) {
      setLoading(true);
      setClientName(client.firstnametempo);
      ClientService.getClientAllPresences(0, -1, client.clientId)
        .then(({ data }) => {
          let tmpDataPoint = [];
          for (let i = 0; i < data.content.length; i++) {
            let date = data.content[i].presenceDate.substr(0, data.content[i].presenceDate.length - 14);
            if (tmpDate.indexOf(date) == -1) {
              tmpDate.push(data.content[i].presenceDate.substr(0, data.content[i].presenceDate.length - 14));
              tmpDataPoint.push({ label: tmpDate, y: 0 });
            }
          }

          for (let i = 0; i < data.content.length; i++) {
            let place = data.content[i].site.description;
            let date = data.content[i].presenceDate.substr(0, data.content[i].presenceDate.length - 14);
            if (placeName.indexOf(place) === -1) {
              placeName.push(place);
              tmpData.push({
                type: 'line',
                showInLegend: true,
                name: place,
                dataPoints: copy(tmpDate),
              })
            }
            let placePresence = tmpData.find(elem => elem.name === place);
            let presenceAtDate = placePresence.dataPoints.find(elem2 => elem2.label === date);
            presenceAtDate.y++;
          }
          setGraphData(tmpData.reverse());
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);

          if (err?.statusCode === 403 || err?.statusCode === 401
            || err?.response?.status === 403 || err?.response?.status === 401
          ) {
            dispatch(logout());
          }
          setLoading(false);
        });
    }
  }, [client, nbPresence]);


  const options = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2",
    title: {
      text: "Fréquentation pour" + (clientName == null ? " le client" : " : " + clientName)
    },
    axisY: {
      title: "Nb présences"
    },
    toolTip: {
      shared: true
    },
    data: graphData
  }


  if (client === null) {
    return (
      <p>Pas de client sélectionné</p>
    )
  } else {
    if (loading) {
      return (
          <Box textAlign="center" mt={40}>
            <LoadingButton loading={true} variant="outlined" disabled>
              Fetch data
            </LoadingButton>
          </Box>
      );
    } else {
      return <CanvasJSChart options={options} />
    }
  }
};

export default ClientFrequentation;