import instance from "../../../../shared/components/auth/service/AxiosInstance"

export const MaritalStatusService = {

    getMaritalStatus: (pageNo, pageSize) =>
        instance.get(process.env.REACT_APP_API_URL + "MaritalStatus/" +
            '?pageSize=' + pageSize +
            '&pageNo=' + pageNo
        ),

    getMaritalStatusById: (id) => instance.get(process.env.REACT_APP_API_URL + "MaritalStatus/" + id),

    createMaritalStatus: (maritalstatus) =>
        instance.post(process.env.REACT_APP_API_URL + 'MaritalStatus/',
            {
                description: maritalstatus?.description,
                deleted: (maritalstatus?.deleted ? maritalstatus.deleted : false)
            }
        ),

    updateMaritalStatus: (id, maritalstatus) =>
        instance.put(process.env.REACT_APP_API_URL + 'MaritalStatus/' + id, 
            {
                maritalStatusId: maritalstatus?.maritalStatusId,
                description: maritalstatus?.description,
                deleted: (maritalstatus?.deleted ? maritalstatus.deleted : false)
            }
        ),

    deleteMaritalStatus: (id) =>
        instance.delete(process.env.REACT_APP_API_URL + 'MaritalStatus/' + id
        ),
}