import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { 
    Button, 
    ButtonGroup, 
    Box, 
    Typography 
} from '@mui/material';

export const LanguageSelector = () => {

    const { t, i18n } = useTranslation();

    const [selected, setSelected] = useState(i18n.language);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setSelected(lng);
    };

    useEffect(() => {
        setSelected(i18n.language);
    }, [i18n.language]);

    return (
        <Box>
            <Typography variant="h6" sx={{marginRight: '10px'}}>
                {t('language')}
            </Typography>
            <ButtonGroup variant="outlined" size="small" aria-label="language selector group">
                <Button 
                    variant={selected === 'fr' ? 'contained' : 'outlined'} 
                    onClick={() => changeLanguage('fr')}
                >
                    FR
                </Button>
                <Button 
                    variant={selected === 'de' ? 'contained' : 'outlined'} 
                    onClick={() => changeLanguage('de')}
                >
                    DE
                </Button>
            </ButtonGroup>
        </Box>
    );
};