import instance from "../../../../shared/components/auth/service/AxiosInstance"

export const DriverLicenceService = {

    getDriverLicence: (pageNo, pageSize) =>
        instance.get(process.env.REACT_APP_API_URL + "driverLicence/" +
            '?pageSize=' + pageSize +
            '&pageNo=' + pageNo
        ),

    getDriverLicenceById: (id) => instance.get(process.env.REACT_APP_API_URL + "driverLicence/" + id),

    createDriverLicence: (driverlicence) =>
        instance.post(process.env.REACT_APP_API_URL + 'driverLicence/',
            driverlicence
        ),

    updateDriverLicence: (id, driverlicence) =>
        instance.put(process.env.REACT_APP_API_URL + 'driverLicence/' + id, 
            driverlicence
        ),

    deleteDriverLicence: (id) =>
        instance.delete(process.env.REACT_APP_API_URL + 'driverLicence/' + id
        ),
}