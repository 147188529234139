import { TextField } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from '@mui/icons-material/Download';
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/features/auth/authSlice";
import ControlledField from "../../../shared/components/controlledField/ControlledField";
import { ResearchClientService } from "../../client/service/ResearchClientService";
import PreviewPdf from '../../presence/modals/PreviewPdf';
import { EventService } from "../services/EventService";

import "./ModalGrid.css";

const columns = [
  {
    field: "id",
    hideable: true,
    headerName: "ID",
    headerAlign: "center",
    headerClassName: "eventListingTheme",
    flex: 1,
  },
  {
    field: "firstnametempo",
    headerName: "Prénom",
    align: "center",
    headerAlign: "center",
    headerClassName: "eventListingTheme",
    flex: 1,
  },
  {
    field: "lastnametempo",
    headerName: "Nom",
    align: "center",
    headerAlign: "center",
    headerClassName: "eventListingTheme",
    flex: 1,
  },
  {
    field: "birthdatetempo",
    headerName: "Date de naissance",
    align: "center",
    headerAlign: "center",
    headerClassName: "eventListingTheme",
    flex: 1,
    renderCell: (data) => moment(data.value).format("DD-MM-YYYY"),
  },
];


function ModalGrid(props) {
  const { eventId, event, presence } = props;

  const [openPreviewPdf, setOpenPreviewPdf] = useState(false);

  const handleOpenPreviewPdf = () => {
    getAllClients();
  }

  const handleClosePreviewPdf = () => {
    setOpenPreviewPdf(false);
  }

  const dispatch = useDispatch();

  const [isDisabled, setIsDisabled] = useState(true);
  const [research, setResearch] = useState('');
  const [researchData, setResearchData] = useState(presence ? [presence.client?.firstnametempo + " " + presence?.client?.lastnametempo] : []);

  const [clientIdSelected, setClientIdSelected] = useState([]);

  const [message, setMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [isLoadingAdd, setIsLoadingAdd] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const [inputs, setInputs] = useState({
    id: eventId,
  });

  const methods = useForm({ 
    mode: "onChange",
    defaultValues: {
      research: (presence?.client ? presence.client?.firstnametempo + " " + presence?.client?.lastnametempo : ""),
      resultat: (presence?.client ? presence.client?.firstnametempo + " " + presence?.client?.lastnametempo : ""),
    },
  });
  const formProps = {
    control: methods.control,
  };

  const [rowCountState, setRowCountState] = useState(null);
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 10,
  });

  const [clients, setClients] = useState([]);

  const [defaultPageNo, setDefaultPageNo] = useState(0);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [selectionModel, setSelectionModel] = useState([]);

  const [select, setSelect] = useState(null);
  const [selected, setSelected] = useState();

  const getClientId = (inputs) => {
    let clientId = "";
    clientIdSelected.forEach((client) => {
      if (client.fullname == inputs.resultat) {
        clientId = client.id;
      }
    });
    return clientId;
  };

  const deleteClient = () => (inputs) => {
    setIsLoadingDelete(true);
    EventService.deleteInscription(selected.id, event.id)
      .then((result) => {
        setIsLoadingDelete(false);
        refresh({
          pageNo: defaultPageNo,
          pageSize: defaultPageSize,
          inputs: inputs,
        });
      })
      .catch((err) => {
        console.log(err);
        setMessage(err);
        if (err?.statusCode === 403 || err?.statusCode === 401 ||
          err?.response?.status === 403 || err?.response?.status === 401) {
          dispatch(logout());
          setIsLoadingDelete(false);
        }
      });
  };

  const addClient = () => (inputs) => {
    setIsLoadingAdd(true);
    let clientId = getClientId(inputs);
    if (rowCountState + 1 > event.maxParticipant) {
      setMessage('Nombre maximum de participant atteint.');
      setOpenAlert(true);
      setIsLoadingAdd(false);
    } else {
      EventService.createInscription(clientId, event.id)
        .then((result) => {
          setIsLoadingAdd(false);
          refresh({
            pageNo: defaultPageNo,
            pageSize: defaultPageSize,
            inputs: inputs,
          });
        })
        .catch((err) => {
          console.log(err);
          if (err?.statusCode === 400 || err?.response?.status === 400) {
            setIsLoadingAdd(false);
            setMessage(inputs?.resultat + ' est déjà inscrit à cet évènement.');
            setOpenAlert(true);
          } else if (err?.statusCode === 403 || err?.statusCode === 401 ||
            err?.response?.status === 403 || err?.response?.status === 401) {
            dispatch(logout());
            setIsLoadingAdd(false);
          }
        });
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setResearch(value);
  };

  const getAllClients = () => {
    EventService.getEventUser(inputs.id, 0, rowCountState)
      .then((results) => {
        let rows = results.data.content.map((row) => {
          const { clientId, ...rest } = row;
          return { id: clientId, ...rest };
        });
        setClients(rows);
        setOpenPreviewPdf(true);
      })
      .catch((err) => {
        console.log(err);
        if (err?.statusCode === 403 || err?.statusCode === 401
          || err?.response?.status === 403 || err?.response?.status === 401
        ) {
          dispatch(logout());
        }
      });
  };

  const refresh = ({ pageNo = defaultPageNo, pageSize = defaultPageSize }) => {
    EventService.getEventUser(inputs.id, pageNo, pageSize)
      .then((results) => {
        setRowCountState(results.data.totalElements);
        let rows = results.data.content.map((row) => {
          const { clientId, ...rest } = row;
          return { id: clientId, ...rest };
        });
        setClients(rows);
      })
      .catch((err) => {
        console.log(err);
        if (err?.statusCode === 403 || err?.statusCode === 401
          || err?.response?.status === 403 || err?.response?.status === 401
        ) {
          dispatch(logout());
        }
      });
  };

  const loadUsers = (
    { pageNo, pageSize, research } = {
      pageNo: rowsState.page,
      pageSize: rowsState.pageSize,
    }
  ) => {
    ResearchClientService.getClients(pageNo, pageSize, research).then(
      (response) => {
        const data = response.data.content;
        let datas = data.map((row) => {
          const { firstnametempo, lastnametempo } = row;
          return firstnametempo + " " + lastnametempo;
        });
        setResearchData(datas);
        setIsDisabled(datas.length ? false : true);

        let datas2 = data.map((row) => {
          const { clientId, firstnametempo, lastnametempo } = row;
          return {
            id: clientId,
            fullname: firstnametempo + " " + lastnametempo,
          };
        });
        setClientIdSelected(datas2);
      }
    );
  };

  useEffect(() => {
    refresh({
      pageNo: defaultPageNo,
      pageSize: defaultPageSize,
    });
    if (presence?.client) {
      setResearch(presence?.client?.firstnametempo + " " + presence?.client?.lastnametempo);
    }
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(
      () =>
        loadUsers({
          research: research,
          pageNo: defaultPageNo,
          pageSize: defaultPageSize,
        }),
      500
    );
    return () => clearTimeout(timeoutId);
  }, [research]);

  return (
    <>
      <div className="row">
        <div className="col">
          <h4 style={{ fontFamily: 'overlockBold' }}>Recherche :</h4>
          <TextField
            color="primary"
            id="research"
            name="research"
            type="text"
            label="Recherche"
            variant="outlined"
            onChange={handleChange}
          />
        </div>
        <div className="col">
          <h4 style={{ fontFamily: 'overlockBold' }}>Résultat : </h4>
          <ControlledField
            name="resultat"
            label="Resultat"
            disabled={isDisabled}
            options={researchData}
            {...formProps}
          />
          <Tooltip title="Ajouter le participant" placement='left'>
            <LoadingButton
              loading={isLoadingAdd}
              onClick={methods.handleSubmit(addClient())}
            >
              <AddIcon fontSize="small" />
            </LoadingButton>
          </Tooltip>
          <Tooltip title="Supprimer le participant" placement='right'>
            <LoadingButton
              loading={isLoadingDelete}
              onClick={methods.handleSubmit(deleteClient())}
            >
              <DeleteIcon fontSize="small" sx={{ color: '#d32f2f' }} />
            </LoadingButton>
          </Tooltip>
        </div>
      </div>



      <Collapse in={openAlert}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      </Collapse>



      <div className="ModalDataGrid">
        <DataGrid
          rowsPerPageOptions={[rowsState.pageSize]}
          sx={{ backgroundColor: '#fff' }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          columns={columns}
          rows={clients}
          rowCount={rowCountState}
          pagination
          {...rowsState}
          paginationMode="server"
          checkboxSelection={false}
          selectionModel={selectionModel}
          onSelectionModelChange={(newSelectionModel) => {
            const selectedIDs = new Set(newSelectionModel);
            const selectedRows = clients.filter((row) =>
              selectedIDs.has(row.id)
            );

            if (selectedRows[0] != null) {
              setSelect(selectedRows[0]);
              let tempSelected = {
                id: selectedRows[0].id,
                firstnametempo: selectedRows[0].firstnametempo,
                lastnametempo: selectedRows[0].lastnametempo,
              };
              setSelected(tempSelected);
            } else {
              setSelect(null);
            }

            setSelectionModel(newSelectionModel);
          }}
          onPageChange={(page) => {
            setDefaultPageNo(page);
            setRowsState((prev) => ({ ...prev, page }));
            refresh({
              pageNo: page,
              pageSize: defaultPageSize,
              inputs: inputs,
            });
          }}
          onPageSizeChange={(pageSize) => {
            setDefaultPageSize(pageSize);
            setRowsState((prev) => ({ ...prev, pageSize }));
            refresh({
              pageNo: defaultPageNo,
              pageSize: pageSize,
              inputs: inputs,
            });
          }}
        />
      </div>


      <div style={{ display: "flex", justifyContent: "center" }}>
        <Tooltip title="Générer un PDF" placement="top">
          <IconButton sx={{ color: "#1976d2" }} onClick={handleOpenPreviewPdf}>
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      </div>
      <PreviewPdf
        handleClosePreviewPdf={handleClosePreviewPdf}
        openPreviewPdf={openPreviewPdf}
        type="event"
        clients={clients}
        event={event}
      />

    </>
  );
}

export default ModalGrid;
