import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/features/auth/authSlice";
import { useTranslation } from "react-i18next";
import { localeISODateTime, getISODate } from "../../../shared/utils/localeISODateTime";
import { CustomDatePicker } from "../../../components/datepicker/CustomDatePicker";
import { VeterinaryService } from "../services/VeterinaryService";
//import { VoucherTypeService } from "../../../components/presence/services/SubService/VoucherTypeService";
import { Box } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";

export const Planning = (props) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const columns = [
        {
            field: "id",
            headerName: "Id",
            hideable: true,
        },
        {
            field: "rdvHour",
            headerName: t('hour'),
            headerClassName: "researchColmun",
            headerAlign: "center",
            align: "center",
            flex: 1,
            width: 100,
            renderCell: (data) => data.value.substring(0, 5)
        },
        {
            field: "presence",
            headerName: t('client'),
            headerClassName: "researchColmun",
            headerAlign: "center",
            align: "center",
            flex: 2,
            renderCell: (data) => data?.value?.fullName,
        },
    ];

    const {
        setSelectedAppointment,
        setMenu,
    } = props;

    const currentPage = 0;
    const pageSize = 50;
    const [rows, setRows] = useState([]);
    const site = useSelector((state) => state.site.site);
    const [date, setDate] = useState(localeISODateTime());

    const handleDateChange = (date) => setDate(getISODate(date));

    const loadData = () => {
        VeterinaryService.getAppointements(site?.siteId, "lM", getISODate(date), currentPage, pageSize)
            .then((response) => {
                setRows(
                    response?.data
                        ?.sort((a, b) => a?.rdvHour?.localeCompare(b?.rdvHour))
                        ?.map((row) => {
                            const { voucherId, ...rest } = row;
                            return { id: voucherId, ...rest };
                        })
                );
            })
            .catch((err) => {
                if (
                    err?.statusCode === 403 ||
                    err?.statusCode === 401 ||
                    err?.response?.status === 403 ||
                    err?.response?.status === 401
                ) {
                    dispatch(logout());
                }
                console.log(err)
          });
    };

    useEffect(() => {
        loadData();
    }, [, date]);

    /*
    useEffect(() => {
        VoucherTypeService.getVoucherTypes(0, -1)
        .then((response) => {
          console.log(response.data.content)
        });
    }, []);
    */

    return (
        <Box
            sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box alignSelf={'flex-end'}>
                <CustomDatePicker
                    valueDate={date}
                    handleChange={handleDateChange}
                />
            </Box>
            <Box
                sx={{height: '75vh',}}
            >
                <DataGrid
                    sx={{ backgroundColor: "#fff" }}
                    rows={rows}
                    columns={columns}
                    showCellRightBorder
                    autoPageSize={true}
                    rowsPerPageOptions={[pageSize]}
                    onSelectionModelChange={(ids) => {
                        const selectedIDs = new Set(ids);
                        const selectedRowData = rows.filter((row) =>
                            selectedIDs.has(row.id.toString())
                        );
                        if (selectedRowData.length) {
                            const { id, ...rest } = selectedRowData[0];
                            setSelectedAppointment({ appointmentId: id, ...rest });
                            setMenu('info');
                        } else {
                            setSelectedAppointment(null);
                        }
                    }}
                    columnVisibilityModel={{
                        id: false,
                    }}
                />
            </Box>
        </Box>
    );
};