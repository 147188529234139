import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DataGrid } from "@mui/x-data-grid";
import { Controller, useForm } from "react-hook-form";
import ControlledField from "../../../../../shared/components/controlledField/ControlledField";

import { Jwt } from "../../../../../shared/components/auth/jwt/Jwt";
import { DateYmdToDmY } from "../../../../../shared/utils/localeISODateTime";
import { ClientService } from "../../../service/ClientService";
import { EmergencyService } from "../../../service/SubService/EmergencyService";

import "./ClientInformation.css";


const balanceInformationField = [
  {
    name: "balance",
    type: "number",
    label: "Balance",
  },
]
const otherInformationFields = [
  {
    name: "otherServices",
    label: "Autre service",
  },
];

const emergencyInformationFields = [
  {
    name: "fullname",
    label: "Nom",
  },
  {
    name: "phone",
    label: "Tel",
  },
  {
    name: "link",
    label: "Lien",
  },
];

const clientInformationFields = [
  {
    name: "firstnametempo",
    label: "Prénom",
  },
  {
    name: "lastnametempo",
    label: "Nom",
  },
  {
    name: "surnametempo",
    label: "Surnom",
  },
  {
    name:"sexe",
    label:"Sexe",
    options:["Homme","Femme", "Autre"]
  },
  {
    name: "birthdatetempo",
    label: "Date de naissance",
    type: "date",
  },
  {
    name: "registrationNumbertempo",
    label: "Matricule",
    type: "number",
  },
  {
    name: "phonetempo",
    label: "Numéro de tel.",
    type: "number",
  },
  {
    name: "address",
    label: "Adresse",
  },
  {
    name: "costoflivingAllowanceDate",
    label: "Demande allocation vie chère",
    type: "date",
  },
];

function ClientInformation(props) {
  const {
    client,
    clientBalance,
    clientEmergencyContacts,
    handleSelectClient,
    setClient,
    drivers,
    legalStatus,
    clientGroups,
    accomodations,
    residents,
    countries,
    nationalities,
    maritalStatus,
  } = props;

  const site = useSelector((state) => state.site.site);
  const user = useSelector((state) => state.auth.user);
  const userData = Jwt(user.token);

  const dispatch = useDispatch();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [select, setSelect] = useState(null);

  const [isUpdated, setIsUpdated] = useState(true);

  const [formatingClient, setFormatingClient] = useState(false);

  const methods = useForm({ mode: "onChange", defaultValues: client });
  const formProps = {
    control: methods.control,
    disabled: isUpdated,
  };

  const DisabledformProps = {
    control: methods.control,
    disabled: true,
  };

  useEffect(() => {
    methods.reset(client);
  }, [
    drivers,
    legalStatus,
    clientGroups,
    accomodations,
    clientEmergencyContacts,
    residents,
    countries,
    nationalities,
    maritalStatus,
  ]);

  const updateClient = (id) => (inputs) => {
    if (isUpdated === false) {
      ClientService.updateClient(id, inputs)
        .then((response) => {
          handleSelectClient(response.data);
          setClient(response.data);
        });
      EmergencyService.updateEmergencyInfo(
        clientEmergencyContacts?.contactEmergencyId,
        id,
        { fullname: inputs?.fullname, link: inputs?.link, phone: inputs?.phone }
      );
    }
    setIsUpdated(!isUpdated);
  };

  if (
    client !== null &&
    (
      drivers === null ||
      accomodations === null ||
      clientGroups === null ||
      legalStatus === null ||
      maritalStatus === null ||
      nationalities === null ||
      drivers === null)
  ) {
    return <>Chargement</>;
  } else if (client === null) {
    return <>Pas de client sélectionné</>;
  } else {
    return (
      <>
        <form
          onSubmit={methods.handleSubmit(updateClient(client?.clientId))}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="btn-row" style={{ padding: "1%" }}>
            {!isUpdated ? (
              <div className="createClientBtn mx-3">
                <button
                  onClick={() => {
                    methods.reset();
                  }}
                >
                  Quitter l'édition
                </button>
              </div>
            ) : null}
            <div className="createClientBtn">
              <button type="submit">
                {isUpdated ? "Modifier client" : "Enregistrer client"}
              </button>
            </div>
          </div>

          <br />
          <div className="row">
            <div
              className="col"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div className="clientInformation" style={{ margin: "1% 0%" }}>
                <h4 style={{ fontFamily:'overlockBold' }}>
                  <PersonPinIcon sx={{ color: "#1976d2", marginBottom:'9px' }} /> Informations client
                </h4>
                <Box noValidate automplete="off">
                  {clientInformationFields.map((field) => {
                    return (
                      <ControlledField
                        key={field?.name}
                        name={field?.name}
                        type={field?.type}
                        label={field?.label}
                        options={field?.options}
                        {...formProps}
                      />
                    );
                  })}
                  <ControlledField
                    {...countries}
                    {...formProps}
                  />
                  <ControlledField
                    {...nationalities}
                    {...formProps}
                  />
                  <ControlledField
                    {...residents}
                    {...formProps}
                  />
                  <ControlledField
                    {...legalStatus}
                    {...formProps}
                  />
                  <ControlledField
                    {...accomodations}
                    {...formProps}
                  />
                  <ControlledField
                    {...drivers}
                    {...formProps}
                  />
                  <ControlledField
                    {...clientGroups}
                    {...formProps}
                  />
                  <ControlledField
                    {...maritalStatus}
                    {...formProps}
                  />
                </Box>
              </div>
              <div className="clientInformation" style={{ margin: "1% 0%" }}>
                <h4 style={{ fontFamily:'overlockBold' }}>
                  <CreditCardIcon sx={{ color: "#1976d2", marginBottom:'9px' }} /> Services
                </h4>
                {balanceInformationField?.map((field) => {
                  return (
                    <ControlledField
                      disabled={field?.disabled}
                      key={field?.name}
                      name={field?.name}
                      label={field?.label}
                      type={field?.type}
                      options={field?.option}
                      required={field?.required}
                      rules={field?.rules}
                      {...DisabledformProps}
                    />
                  );
                })}

                {otherInformationFields?.map((field) => {
                  return (
                    <ControlledField
                      disabled={field?.disabled}
                      key={field?.name}
                      name={field?.name}
                      label={field?.label}
                      type={field?.type}
                      options={field?.option}
                      required={field?.required}
                      rules={field?.rules}
                      {...formProps}
                    />
                  );
                })}
              </div>
            </div>

            <div
              className="col"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div className="clientInformation" style={{ margin: "1% 0%" }}>
                <h4 style={{ fontFamily:'overlockBold' }}>
                  <LocalHospitalIcon sx={{ color: "#1976d2", marginBottom:'9px' }} /> Informations d'urgence
                </h4>
                {emergencyInformationFields.map((field) => {
                  return (
                    <ControlledField
                      key={field?.name}
                      name={field?.name}
                      label={field?.label}
                      type={field?.type}
                      options={field?.option}
                      required={field?.required}
                      rules={field?.rules}
                      {...formProps}
                    />
                  );
                })}
              </div>

              <div className="clientInformation" style={{ margin: "1% 0%" }}>
                <h4 style={{ fontFamily:'overlockBold' }}>
                  <MoreVertRoundedIcon sx={{ color: "#1976d2", marginBottom:'9px' }} /> Autres informations
                </h4>
                <hr />
                <div style={{ display: 'flex' }}>
                  <h6>Date création: {DateYmdToDmY(client?.dateCreation)} {client?.dateCreation?.substring(11, 16)}</h6>
                </div>
                <hr />
                <span>Gestion financière :</span>
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <TextareaAutosize
                      onBlur={onBlur}
                      disabled={isUpdated}
                      onChange={onChange}
                      value={value == null ? "" : value}
                      ref={ref}
                      color="primary"
                      variant="outlined"
                      style={{ width: "100%" }}
                      aria-label="minimum height"
                      minRows={2}
                      placeholder="Gestion financière..."
                      label="Gestion financière"
                    />
                  )}
                  control={methods.control}
                  name="financialManagement"
                />
                <span>Contre indication de travail :</span>
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <TextareaAutosize
                      onBlur={onBlur}
                      disabled={isUpdated}
                      onChange={onChange}
                      value={value == null ? "" : value}
                      ref={ref}
                      color="primary"
                      variant="outlined"
                      style={{ width: "100%" }}
                      aria-label="minimum height"
                      minRows={2}
                      placeholder="Contre indication de travail..."
                      label="Contre indication de travail"
                    />
                  )}
                  control={methods.control}
                  name="workContraindication"
                />
                <span>Remarque :</span>
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <TextareaAutosize
                      onBlur={onBlur}
                      disabled={isUpdated}
                      onChange={onChange}
                      value={value == null ? "" : value}
                      ref={ref}
                      color="primary"
                      variant="outlined"
                      style={{ width: "100%" }}
                      aria-label="minimum height"
                      minRows={2}
                      placeholder="Remarque..."
                      label="Remarque"
                    />
                  )}
                  control={methods.control}
                  name="remark"
                />
                <div className="position-relative">
                  <Tooltip title="Historique des messages popup">
                    <span className="position-absolute top-0 end-0">
                      <SpeakerNotesIcon
                        sx={{'&:hover': {cursor: 'pointer'}, }}
                        onClick={() => setOpen(true)}
                      />
                    </span>
                  </Tooltip>

                  <span >Message :</span>
                  <Controller
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <TextareaAutosize
                        onBlur={onBlur}
                        disabled={isUpdated}
                        onChange={onChange}
                        value={value == null ? "" : value}
                        ref={ref}
                        color="primary"
                        variant="outlined"
                        style={{ width: "100%" }}
                        aria-label="minimum height"
                        minRows={2}
                        placeholder="Message..."
                        label="message"
                      />
                    )}
                    control={methods.control}
                    name="message"
                  />
                </div>

                <span>Message popup : </span>
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Checkbox
                      name={"popup"}
                      disabled={isUpdated}
                      checked={Boolean(value)}
                      onBlur={onBlur}
                      onChange={onChange}
                    />
                  )}
                  name={"popup"}
                  {...formProps}
                />

                <span>Décédé(e) :</span>
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Checkbox
                      name={"deceased"}
                      disabled={isUpdated}
                      checked={Boolean(value)}
                      onBlur={onBlur}
                      onChange={onChange}
                    />
                  )}
                  name={"deceased"}
                  {...formProps}
                />
              </div>
            </div>
          </div>
        </form>

        <Dialog fullWidth maxWidth="lg" onClose={handleClose} open={open}>
          <DialogTitle sx={{ fontFamily:'overlockBold' }}>
            Historique des messages
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div className="EventDataGrid">
              <DataGrid
                //getRowHeight={() => 'auto'}
                rows={
                  client?.messages?.sort((msg1, msg2) => (new Date(msg2?.dateRecord) - new Date(msg1?.dateRecord)))
                    ?.map((row) => {
                      const { messageId, ...rest } = row;
                      return { id: messageId, user: rest?.user, ...rest };
                    })
                }
                columns={[
                  { field: "id", headerName: "Id", hideable: true, headerClassName: "researchColmun", headerAlign: "center", align: "left", },
                  { field: "dateRecord", headerName: "Date", headerClassName: "researchColmun", headerAlign: "center", align: "center", width: 200, },
                  { field: "content", headerName: "Message", headerClassName: "researchColmun", headerAlign: "center", align: "center", flex: 1, 
                    renderCell: (params) =>  (
                      <Tooltip title={<span style={{whiteSpace: 'pre-line'}}>{params?.row?.content}</span>}>
                        <span style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                          {params?.row?.content}
                        </span>
                      </Tooltip>
                    ),
                  },
                  { field: "user.username", headerName: "Créé par", headerClassName: "researchColmun", headerAlign: "center", align: "center", width: 250,
                    renderCell: (data) => data?.row?.user?.userName
                  },
                ]}
                autoPageSize={true}
                showCellRightBorder
                rowsPerPageOptions={[10]}
                onSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRowData = client?.messages?.filter((row) =>
                    selectedIDs.has(row?.id?.toString())
                  );
                  if (selectedRowData.length) {
                    const { id, ...rest } = selectedRowData[0];
                    setSelect({ accomodationId: id, ...rest });
                  } else {
                    setSelect(null);
                  }
                }}
                columnVisibilityModel={{
                  id: false,
                }}
              />
            </div>
          </DialogContent>
        </Dialog>

      </>
    );
  }
}

export default ClientInformation;
