import CloseIcon from '@mui/icons-material/Close';
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import { logout } from "../../redux/features/auth/authSlice";
import ClientTab from "./pages/ClientTab";
import ResearchClient from "./pages/ResearchClients";
import { BalanceService } from "./service/SubService/BalanceService";
import { ClientSettingsService } from "./service/SubService/ClientSettings";
import { EmergencyService } from "./service/SubService/EmergencyService";

import { localeISODateTime } from "../../shared/utils/localeISODateTime";
import "./Client.css";

function Client(props) {
  const { setActive } = props;
  useEffect(() => {
    //setActive(2);
  }, []);

  const dispatch = useDispatch();
  const location = useLocation();
  const state = location.state;

  const [today, setToday] = useState(localeISODateTime());

  const [client, setClient] = useState(state);
  const [clientBalance, setClientBalance] = useState(null);
  const [clientEmergencyContacts, setClientEmergencyContacts] = useState(null);

  const [legalStatus, setLegalStatus] = useState([]);
  const [clientGroups, setClientGroups] = useState([]);
  const [nationality, setNationality] = useState([]);
  const [residents, setResidents] = useState([]);
  const [countries, setCountries] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [accomodations, setAccomodations] = useState([]);

  const [open, setOpen] = useState(false);

  const handleDrawer = () => {
    setOpen(!open);
  };

  function handleSelectClient(client) {
    if (client != null) {
      EmergencyService.getEmergencyInfo(client?.clientId)
        .then((res) => {
          setClientEmergencyContacts(res?.data);
          client.fullname = res?.data.fullname;
          client.phone = res?.data?.phone;
          client.link = res?.data?.link;
          setClientBalance(client.balance);
          setClient(client);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const getSelectOptions = () => {
    ClientSettingsService.getClientSettings()
      .then(({ data }) => {
        setLegalStatus({
          name: "legalStatus",
          label: "Statut légal",
          type: "select",
          options:
            data?.legalStatuses
              ?.sort((status1, status2) =>
                status1?.description?.localeCompare(status2?.description)
              )
              ?.filter(
                (element) =>
                  element?.description !== "" && element?.deleted !== true
              )
              ?.map((elem) => ({ name: elem?.description, value: elem })) || [],
        });

        setClientGroups({
          name: "clientGroup",
          label: "Groupe Client",
          type: "select",
          options:
            data?.clientGroups
              ?.sort((group1, group2) =>
                group1.description.localeCompare(group2.description)
              )
              ?.map((elem) => ({ name: elem?.description, value: elem })) || [],
        });

        setDrivers({
          name: "driverLicence",
          label: "Permis de conduire",
          type: "select",
          options:
            data?.driverLicences
              ?.sort((a, b) => a?.name?.localeCompare(b?.name))
              ?.filter((element) => element?.name !== "")
              ?.map((elem) => ({ name: elem?.name, value: elem })) || [],
        });

        setNationality({
          name: "nationality",
          label: "Nationalité",
          type: "select",
          options:
            data?.nationalities
              ?.sort((a, b) => a?.description?.localeCompare(b?.description))
              ?.filter(
                (element) =>
                  element?.description !== "" && element?.deleted !== true
              )
              ?.map((elem) => ({ name: elem?.description, value: elem })) || [],
        });

        setCountries({
          name: "originCountry",
          label: "Pays origine",
          type: "select",
          options:
            data?.countries
              ?.sort((a, b) => a?.name?.localeCompare(b?.name))
              ?.filter(
                (element) => element?.name !== "" && element?.deleted !== true
              )
              ?.map((elem) => ({ name: elem?.name, value: elem })) || [],
        });

        setAccomodations({
          name: "accomodation",
          label: "Logement",
          type: "select",
          options:
            data?.accomodations
              ?.sort((a, b) => a?.description?.localeCompare(b?.description))
              ?.filter((element) => element?.description !== "")
              ?.map((elem) => ({ name: elem?.description, value: elem })) || [],
        });

        setResidents({
          name: "resident",
          label: "Resident",
          type: "select",
          options:
            data?.residents
              ?.sort((a, b) => a?.description?.localeCompare(b?.description))
              ?.filter(
                (element) =>
                  element?.description !== "" && element?.deleted !== true
              )
              ?.map((elem) => ({ name: elem?.description, value: elem })) || [],
        });

        setMaritalStatus({
          name: "maritalStatus",
          label: "Etat civil",
          type: "select",
          options:
            data?.maritalStatuses
              ?.sort((a, b) => a?.description?.localeCompare(b?.description))
              ?.filter(
                (element) =>
                  element?.description !== "" && element?.deleted !== true
              )
              ?.map((elem) => ({ name: elem?.description, value: elem })) || [],
        });
      })
      .catch((err) => {
        dispatch(logout());
      });
  };

  useEffect(() => {
    getSelectOptions();
  }, []);

  useEffect(() => {
    if (client === null && state === null) {
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    if (state != null) {
      setClient(state);
      BalanceService.getBalance(state?.clientId)
        .then((r) => {
          setClientBalance(r.data.balance);
          client.balance = r.data.balance;
        })
        .catch((err) => {
          setClient(state);
          console.log(err);
          if (
            err?.statusCode === 403 ||
            err?.statusCode === 401 ||
            err?.response?.status === 403 ||
            err?.response?.status === 401
          ) {
            dispatch(logout());
          }
        });
      EmergencyService.getEmergencyInfo(state?.clientId)
        .then((res) => {
          setClientEmergencyContacts(res?.data);
          client.fullname = res?.data.fullname;
          client.phone = res?.data?.phone;
          client.link = res?.data?.link;
          setClient(state);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  if (
    drivers.length === 0 ||
    clientGroups.length === 0 ||
    drivers.length === 0 ||
    legalStatus.length === 0 ||
    accomodations.length === 0 ||
    residents.length === 0 ||
    countries.length === 0 ||
    nationality.length === 0 ||
    maritalStatus.length === 0
  ) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: "overlockBold",
            fontSize: "50px",
            marginTop: "30vh",
          }}
        >
          Chargement...
        </Typography>
      </div>
    );
  } else {
    return (
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Box>
          <Drawer
            sx={{
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: "40%",
                backgroundColor: "#eaeaea",
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="right"
            open={open}
          >
            <IconButton onClick={handleDrawer} sx={{ maxWidth: "50px" }}>
              <CloseIcon color="error" />
            </IconButton>
            <ResearchClient
              showButton={true}
              handleSelectClient={handleSelectClient}
              setOpenDrawer={setOpen}
              openDrawer={open}
              legalStatus={legalStatus}
              nationality={nationality}
              countries={countries}
              clientGroups={clientGroups}
              residents={residents}
              drivers={drivers}
              accomodations={accomodations}
              maritalStatus={maritalStatus}
              today={today}
            />
          </Drawer>
        </Box>
        <ClientTab
          setClient={setClient}
          handleSelectClient={handleSelectClient}
          client={client}
          clientBalance={clientBalance}
          setClientBalance={setClientBalance}
          clientEmergencyContacts={clientEmergencyContacts}
          setClientEmergencyContacts={setClientEmergencyContacts}
          handleDrawer={handleDrawer}
          open={open}
          drivers={drivers}
          legalStatus={legalStatus}
          clientGroups={clientGroups}
          accomodations={accomodations}
          residents={residents}
          countries={countries}
          nationalities={nationality}
          maritalStatus={maritalStatus}
        />
      </div>
    );
  }
}
export default Client;
