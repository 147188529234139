import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import LoadingButton from "@mui/lab/LoadingButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Controller, useForm } from "react-hook-form";
import ControlledField from "../../../../../shared/components/controlledField/ControlledField";
import { EmergencyService } from "../../../../client/service/SubService/EmergencyService";
import Dialog from '@mui/material/Dialog';

const clientInformationFields = [
  {
    name: "firstnametempo",
    label: "Prénom",
  },
  {
    name: "lastnametempo",
    label: "Nom",
  },
  {
    name: "surnametempo",
    label: "Surnom",
  },
  {
    name: "sexe",
    label: "Sexe",
  },
  {
    name: "birthdatetempo",
    label: "Date de naissance",
    type: "date",
  },
  {
    name: "registrationNumbertempo",
    label: "Matricule",
  },
  {
    name: "phonetempo",
    label: "Numéro de Tel.",
    type: "number",
  },
  {
    name: "address",
    label: "Adresse",
  },
  {
    name: "costoflivingAllowanceDate",
    label: "Demande Allocation Vie Chère",
    type: "date",
  },
  {
    name: "originCountry.name",
    label: "Pays d'origine",
  },
  {
    name: "nationality.description",
    label: "Nationatité",
  },
  {
    name: "resident.description",
    label: "Résident",
  },
  {
    name: "legalStatus.description",
    label: "Status Légal",
  },
  {
    name: "accomodation.description",
    label: "Logement",
  },
  {
    name: "clientGroup.description",
    label: "Groupe client",
  },
  {
    name: "maritalStatus.description",
    label: "Etat civil",
  },
  {
    name: "otherServices",
    label: "Autre services",
  },
  {
    name: "financialManagement",
    label: "Gestion financière",
  },
  {
    name: "workContraindication",
    label: "Contre indication de travail",
  },
  {
    name: "remark",
    label: "Remarque",
  },
];

const contactEmergencyFields = [
  {
    name: "contactEmergency.fullname",
    label: "Nom",
  }, {
    name: "contactEmergency.phone",
    label: "Tel",
  }, {
    name: "contactEmergency.link",
    label: "Lien",
  },
];

function MoreClientInfo(props) {
  const { open, setOpen, client } = props;

  const methods = useForm({ mode: "onChange", defaultValues: client });
  const formProps = {
    control: methods.control,
    disabled: true
  };

  useEffect(() => {
    methods.reset(client);
  }, [client]);

  useEffect(() => {
    if (client != null) {
      EmergencyService.getEmergencyInfo(client?.clientId)
        .then((res) => {
          client.contactEmergency = { fullname: res?.data.fullname, phone: res?.data?.phone, link: res?.data?.link }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [client]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          sx={{ width: "100%", height: "100%" }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle id="responsive-dialog-title" sx={{ fontFamily: 'backToSchool', textAlign:"center", fontSize: '40px', color: '#bb0000' }}>Informations client</DialogTitle>
          <DialogContent>
            <Box component="form" noValidate automplete="off" sx={{ textAlign:"center", backgroundColor:"paper", width:"430px" }}>
              {clientInformationFields.map((field) => {
                return (
                  <Tooltip key={field?.name} title={client?.[field?.name] != null && client?.[field?.name].length > 50
                    ? client?.[field?.name]
                    : ''}>
                    <div>
                      <ControlledField
                        name={field?.name}
                        type={field?.type}
                        label={field?.label}
                        {...formProps}
                      />
                    </div>
                  </Tooltip>
                );
              })}
              <p style={{ marginBottom: 0, fontFamily: 'overlockBold' }}>Personne de contact d'urgence</p>
              {contactEmergencyFields.map((field) => {
                return (
                  <Tooltip key={field?.name} title={client?.[field?.name] != null && client?.[field?.name].length > 50
                    ? client?.[field?.name]
                    : ''}>
                    <div>
                      <ControlledField
                        name={field?.name}
                        type={field?.type}
                        label={field?.label}
                        {...formProps}
                      />
                    </div>
                  </Tooltip>
                );
              })}
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default MoreClientInfo;