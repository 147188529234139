import instance from "../../../shared/components/auth/service/AxiosInstance";
import { Jwt } from "../../../shared/components/auth/jwt/Jwt";

export const ClientService = {
  getClients: (pageNo, pageSize, research) =>
    instance.get(
      process.env.REACT_APP_API_URL +
        "client/?pageSize=" +
        pageSize +
        "&pageNo=" +
        pageNo +
        "&research=" +
        research,
    ),

  getClientById: (clientId) =>
    instance.get(
      process.env.REACT_APP_API_URL +
        "client/" +
        clientId
    ),

  getClientPresences: (pageNo, pageSize, id, dateStart, dateEnd) =>
    instance.get(
      process.env.REACT_APP_API_URL +
        "presence/client/?pageSize=" +
        pageSize +
        "&pageNo=" +
        pageNo +
        "&id=" +
        id +
        ((dateStart.length > 0) ? ("&dateStart=" + dateStart) : '') +
        ((dateEnd.length > 0) ? ("&dateEnd=" + dateEnd) : '')
        ,
    ),


  getClientAllPresences: (pageNo, pageSize, id) =>
    instance.get(
      process.env.REACT_APP_API_URL +
        "presence/client/" +
        id +
        "?pageSize=" +
        pageSize +
        "&pageNo=" +
        pageNo,
  ),

  getClientContract: (pageNo, pageSize, id) =>
    instance.get(
      process.env.REACT_APP_API_URL +
        "contract/client/?pageSize=" +
        pageSize +
        "&pageNo=" +
        pageNo +
        "&id=" +
        id,
    ),

  getClientExclusion: (pageNo, pageSize, id) =>
    instance.get(
      process.env.REACT_APP_API_URL +
        "exclusion/client/" +
        id +
        "?pageSize=" +
        pageSize +
        "&pageNo=" +
        pageNo,
    ),

  getClientsTUCBySite: (date, siteId) => 
    instance.get(process.env.REACT_APP_API_URL + "client/ATI/site/" + siteId + "/date/" + date),

  createClient: (client) =>
    instance.post(process.env.REACT_APP_API_URL + "client/", 
      client, 
    ),

  updateClient: (id, client) =>
    instance.put(process.env.REACT_APP_API_URL + "client/" + id, 
      client, 
    ),

  deleteClient: (id) =>
    instance.delete(process.env.REACT_APP_API_URL + "client/" + id),

  mergeClients: (clientToRemoveId, clientToKeepId) =>
    instance.post(process.env.REACT_APP_API_URL + "client/merge/keep",
      {
        clientToRemoveId: clientToRemoveId,
        clientToKeepId: clientToKeepId
      }
    ),
};
