import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LanguageIcon from '@mui/icons-material/Language';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import IconButton from '@mui/material/IconButton';
import React, { useEffect } from 'react';
import { useSelector } from "react-redux";

import bgPicture from './stemm.jpg';

function Home(props) {
  const site = useSelector((state) => state?.site?.site) || null;
  const { setActive } = props;
  useEffect(() => {
    setActive(0);
  }, []);

  return (
    <>
      <div style={{ position: 'relative', width: '100%', marginTop:'30px' }}>
        <img src={bgPicture} style={{ width: '40%', margin: '10% 0% 0% 10%', boxShadow: '-6px 6px 10px #696969' }} />
        <h1 style={{ position: 'absolute', top: '63%', left: '67%', transform: 'translate(-50%, -50%)', fontSize: '80px', letterSpacing: '14px' }}><font style={{ color: 'white', textShadow: '0px 0px 10px #000000' }}>B</font>ienvenue</h1>
        <h5 style={{ position: 'absolute', top: '85%', left: '67%', transform: 'translate(-50%, -50%)', fontSize: '25px', letterSpacing: '10px' }}>{site?.welcometitle}</h5>
      </div>
      <div style={{ position: 'fixed', width: "100%", textAlign: 'right', bottom: 0 }}>
        <a href='https://www.instagram.com/stemm.vun.der.strooss/?igshid=YmMyMTA2M2Y=' target='_blank'>
          <IconButton sx={{ color: '#a03d8d' }}>
            <InstagramIcon />
          </IconButton>
        </a>
        <a href='http://stemm.lu/' target='_blank'>
          <IconButton sx={{ color: '#696969' }}>
            <LanguageIcon />
          </IconButton>
        </a>
        <a href='https://www.facebook.com/people/St%C3%ABmm-vun-der-Strooss/100064333585657/' target='_blank'>
          <IconButton sx={{ color: '#267ef0' }}>
            <FacebookIcon />
          </IconButton>
        </a>
        <a href='https://www.youtube.com/channel/UCqCTp0FJ3i4p65zzke3bw6g' target='_blank'>
          <IconButton sx={{ color: '#f70000' }}>
            <YouTubeIcon />
          </IconButton>
        </a>
        <a href='https://www.linkedin.com/company/st%C3%ABmm-vun-der-strooss/' target='_blank'>
          <IconButton sx={{ color: '#0a61b7' }}>
            <LinkedInIcon />
          </IconButton>
        </a>
      </div>
    </>
  )
}

export default Home;