import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/features/auth/authSlice";
import { useTranslation } from "react-i18next";
import { Box } from '@mui/material';
import { computeAgeWithMonths, formatDateString } from "../../../shared/utils/localeISODateTime";
import { VeterinaryService } from "../services/VeterinaryService";
import ButtonManager from "../../../shared/components/Button/ButtonManager";
import ControlledField from "../../../shared/components/controlledField/ControlledField";

export const Animal = (props) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const {
        clientId,
        selectedAnimal,
        setSelectedAnimal
    } = props;

    const forms = [
        { name: "name", label: t('name'), type: "string", required: true },
        { name: "birthdate", label: t('birthdate'), type: "date", },
        { name: "microchip", label: t('veterinary.microchip'), type: "string", },
        { name: "passport", label: t('veterinary.passport'), type: "string", },
        { name: "breed", label: t('veterinary.breed'), type: "string", },
        { name: "gender", label: t('veterinary.gender'), type: "select", 
            options: [
              {name: t('veterinary.male'), value: "M"}, 
              {name: t('veterinary.female'), value: "F"},
            ],
            //default: (selectedAnimal ? selectedAnimal?.gender : ''),
            required: true,
        },
        { name: "neutered", label: t('veterinary.neutered'), type: "checkbox" },
        { name: "deceased", label: t('veterinary.deceased'), type: "checkbox" },
    ];

    const [animals, setAnimals] = useState([]);
    const [selectedAnimalId, setSelectedAnimalId] = useState(null);
    const [age, setAge] = useState(null);

    const methods = useForm({ mode: "onChange", defaultValues: selectedAnimal });
    const formProps = {
        control: methods.control,
        disabled: false,
    };

    const loadData = () => {
        VeterinaryService.getAnimals(clientId)
          .then((response) => {
            setAnimals(response.data);
            let selected = response.data?.filter((animal) => animal?.animalId === selectedAnimalId)?.[0];
            setSelectedAnimal(selected);
            setAge(selected?.birthdate ? computeAgeWithMonths(selected.birthdate) : null);
          })
          .catch((err) => {
            if (
              err?.statusCode === 403 ||
              err?.statusCode === 401 ||
              err?.response?.status === 403 ||
              err?.response?.status === 401
            ) {
              dispatch(logout());
            }
          });
      };
    
    useEffect(() => {
        if (clientId) {
            loadData();
        }
    }, [, clientId]);

    useEffect(() => {
      if (selectedAnimalId) {
        VeterinaryService.getAnimalById(selectedAnimalId)
          .then((response) => {
            setSelectedAnimal(response.data);
            setAge(response.data?.birthdate ? computeAgeWithMonths(response.data.birthdate) : null);
          })
          .catch((err) => {
            if (
              err?.statusCode === 403 ||
              err?.statusCode === 401 ||
              err?.response?.status === 403 ||
              err?.response?.status === 401
            ) {
              dispatch(logout());
            }
          });
      }
    }, [selectedAnimalId]);


    return (
        <Box display={'flex'} flexWrap={'wrap'}>
            <Box width={'34%'}>
                <Box>
                  <h4>{t('veterinary.animal')}</h4>
                    <ButtonManager
                        onlyIcon={true}
                        title={t('veterinary.animal')}
                        id={selectedAnimalId}
                        selected={selectedAnimal}
                        align={"center"}
                        forms={forms}
                        createFunction={VeterinaryService.createAnimal}
                        updateFunction={VeterinaryService.updateAnimal}
                        requiredFields={["name", "gender"]}
                        requiredFieldLabel={[t("name"), t("veterinary.gender")]}
                        refresh={loadData}
                        preSetInputs={{client: { clientId: clientId } }}
                    />
                </Box>
                <Box marginRight={'30px'}>
                  <ControlledField
                      name="animals"
                      label={t('veterinary.animals')}
                      type="select"
                      options={
                        animals?.sort((a, b) => {
                                  if (a.deceased === b.deceased) {
                                    return a.name.localeCompare(b.name);
                                  }
                                  return a.deceased ? 1 : -1;
                                })
                                ?.map(animal => ({
                                  name: animal.name + (animal?.breed ? ' [ ' + animal?.breed + ' ]' : '') + " " + (animal?.deceased ? ' ( ' + t('veterinary.deceased') + ' )' : ''),
                                  value: animal.animalId
                                })) || []
                      }
                      {...formProps}
                      setSelected={setSelectedAnimalId}
                  />
                </Box>
            </Box>
            <Box width={'33%'}>
                <p><b>{t('veterinary.microchip')}:</b> {selectedAnimal?.microchip}</p>
                <p><b>{t('veterinary.passport')}:</b> {selectedAnimal?.passport}</p>
                <p><b>{t('veterinary.gender')}:</b> {(selectedAnimal?.gender) 
                                                ? ((selectedAnimal.gender === "F") ?t('veterinary.female') : t('veterinary.male'))
                                                : ''
                                              }
                </p>
            </Box>
            <Box width={'33%'}>
                <p>
                  <b>{t('birthdate')}:</b> {selectedAnimal?.birthdate ? formatDateString(selectedAnimal?.birthdate) : ""} &nbsp; 
                  {selectedAnimal?.birthdate && <>({ age?.years } {age?.years > 1 ? t('years') : t('year')} { age?.months } {age?.months > 1 ? t('months') : t('month')})</>}
                </p>
                <p><b>{t('veterinary.breed')}:</b> {selectedAnimal?.breed}</p>
                <p><b>{t('veterinary.neutered')}:</b> {selectedAnimal?.neutered 
                                                        ? ((selectedAnimal.neutered) ? t('yes') : t('no'))
                                                        : ((selectedAnimal?.neutered === false) ? t('no') : '')
                                                      }
                </p>
            </Box>
        </Box>
    );
};