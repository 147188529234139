import instance from "../../../../shared/components/auth/service/AxiosInstance"

export const ResidentService = {

    getResident: (pageNo, pageSize) =>
        instance.get(process.env.REACT_APP_API_URL + "Resident/" +
            '?pageSize=' + pageSize +
            '&pageNo=' + pageNo
        ),

    getResidentById: (id) => instance.get(process.env.REACT_APP_API_URL + "Resident/" + id),

    createResident: (resident) =>
        instance.post(process.env.REACT_APP_API_URL + 'Resident/',
            {
                description: resident?.description,
                deleted: (resident?.deleted ? resident.deleted : false)
            }
        ),

    updateResident: (id, resident) =>
        instance.put(process.env.REACT_APP_API_URL + 'Resident/' + id, 
            {
                residentId: resident?.residentId,
                description: resident?.description,
                deleted: (resident?.deleted ? resident.deleted : false)
            }
        ),

    deleteResident: (id) =>
        instance.delete(process.env.REACT_APP_API_URL + 'Resident/' + id
        ),
}