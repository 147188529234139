import { Box, Grid } from "@material-ui/core";
import EngineeringIcon from '@mui/icons-material/Engineering';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PeopleIcon from '@mui/icons-material/People';
import PollIcon from '@mui/icons-material/Poll';
import ReportIcon from '@mui/icons-material/Report';
import SummarizeIcon from '@mui/icons-material/Summarize';
import Button from '@mui/material/Button';
import React from "react";
import { Link } from 'react-router-dom';

const tokenRoles = localStorage.getItem("roles");

const publics = ["Statistiques"];
const publicLinkTos = ["/Stat"];
const publicIcons = [<PollIcon />];

const restricteds = ["Rapport Clients", "Rapport Contrats", "Rapport Exclusion", "Rapport Présences", "Rapport Clôture"];
const restrictedLinkTos = ['/ReportClient', '/ReportContract', '/ReportExclusion', '/ReportPresence', '/ReportClosing'];
const restrictedIcons = [<PeopleIcon />, <EngineeringIcon />, <ReportIcon />, <FileDownloadIcon />, <SummarizeIcon />];

function GestionSelect(props) {


  const tabDisplay = (names, links, icons) => {
    return (
      <>
        {names?.map((setting, index) =>
          <Grid key={setting} item xs>
            <Link to={links[index]} underline='none'>
              <Button variant="contained" startIcon={icons[index]} sx={{ width: '240px', margin: '2% 0%' }}>
                {setting}
              </Button>
            </Link>
          </Grid>
        )}
      </>
    )
  }
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%", textAlign: "center", padding: '2%' }}>
      <h1 className="mb-4 text-center" style={{ fontFamily: 'backToSchool', fontSize: '65px', color: '#bb0000' }}>Gestion Roger</h1>
      <Box className="mx-auto w-75" sx={{ flexGrow: 1, marginTop: '7%' }}>
        <Grid container spacing={3}>
          {tabDisplay(publics, publicLinkTos, publicIcons)}

          {tokenRoles?.includes("ADMIN", "EDUCATEUR", "ENCADRE") &&
            <>{tabDisplay(restricteds, restrictedLinkTos, restrictedIcons)} </>
          }
        </Grid>
      </Box>
    </div>
  );
}

export default GestionSelect;