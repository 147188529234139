import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/features/auth/authSlice";
import { useTranslation } from "react-i18next";
import { Box } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import { Consultation } from "./Consultation";
import ButtonManager from "../../../shared/components/Button/ButtonManager";
import { VeterinaryService } from "../services/VeterinaryService";
import { getISODate, getISOTime } from "../../../shared/utils/localeISODateTime";

export const Consultations = (props) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const {
        selectedAnimal
    } = props;

    const forms = [
        { name: "datetime", label: t('date'), type: "date", valueDate: new Date(), required: true },
        { name: "anamnesis", label: t('veterinary.anamnesis'), type: "string", required: true },
        { name: "symptoms", label: t('veterinary.symptoms'), type: "string", required: true },
        { name: "diagnosis", label: t('veterinary.diagnosis'), type: "string", required: true },
        { name: "treatment", label: t('veterinary.treatment'), type: "string", required: true },
    ];

    const columns = [
        {
            field: "id",
            headerName: "Id",
            hideable: true,
        },
        {
            field: "datetime",
            headerName: t('date'),
            headerClassName: "researchColmun",
            headerAlign: "center",
            align: "center",
            flex: 1,
            width: 140,
            renderCell: (data) => getISODate(data.value) + " " + getISOTime(data.value).substring(0, 5),
        },
        {
            field: "anamnesis",
            headerName: t('veterinary.anamnesis'),
            headerClassName: "researchColmun",
            headerAlign: "center",
            align: "center",
            flex: 1,
            width: 140,
        },
        {
            field: "symptoms",
            headerName: t('veterinary.symptoms'),
            headerClassName: "researchColmun",
            headerAlign: "center",
            align: "center",
            flex: 1,
            width: 140,
        },
        {
            field: "diagnosis",
            headerName: t('veterinary.diagnosis'),
            headerClassName: "researchColmun",
            headerAlign: "center",
            align: "center",
            flex: 1,
            width: 140,
        },
        {
            field: "treatment",
            headerName: t('veterinary.treatment'),
            headerClassName: "researchColmun",
            headerAlign: "center",
            align: "center",
            flex: 1,
            width: 140,
        },
    ]

    const currentPage = 0;
    const pageSize = 50;
    const [rows, setRows] = useState([]);
    const [selectedConsultation, setSelectedConsultation] = useState(null);
    const [previousData, setPreviousData] = useState(null);

    const getLastConsultation = (animalId) => {
        if (animalId) {
            VeterinaryService.getLastConsultation(animalId)
                .then(({ data }) => {
                    let { consultationId, datetime, ...rest } = data;
                    setPreviousData({ ...rest });
                })
                .catch((err) => console.log(err));
        }
    }

    const loadData = () => {
        VeterinaryService.getConsultations(selectedAnimal?.animalId, currentPage, pageSize)
            .then((response) => {
                const data = response.data.content;
                setRows(
                    data
                        ?.sort((a, b) => b?.datetime?.localeCompare(a?.datetime))
                        ?.map((row) => {
                        const { consultationId, ...rest } = row;
                        return { id: consultationId, ...rest };
                    })
                );
                setSelectedConsultation(data.filter((consultation) => consultation?.consultationId === selectedConsultation?.consultationId)?.[0]);
                
            })
            .catch((err) => {
                if (
                    err?.statusCode === 403 ||
                    err?.statusCode === 401 ||
                    err?.response?.status === 403 ||
                    err?.response?.status === 401
                ) {
                    dispatch(logout());
                }
          });

        getLastConsultation(selectedAnimal?.animalId);
    };

    useEffect(() => {
        if (selectedAnimal) {
            loadData();
        }
        if (selectedAnimal?.animalId) {
            getLastConsultation(selectedAnimal?.animalId);
        }
    }, [selectedAnimal]);
    
    return (
        <>
            <Box display={'flex'} justifyContent={'space-between'}>
                <h4>
                    {t('veterinary.consultations')}
                    {selectedAnimal?.name 
                        ? (" - " + selectedAnimal.name + (selectedAnimal?.breed ? " [" + selectedAnimal.breed + "]" : '')) 
                        : ""
                    }
                </h4>
                <ButtonManager
                    onlyIcon={true}
                    title={t('veterinary.consultation')}
                    id={selectedConsultation?.consultationId}
                    selected={selectedConsultation}
                    align={"center"}
                    forms={forms}
                    createFunction={VeterinaryService.createConsultation}
                    updateFunction={VeterinaryService.updateConsultation}
                    requiredFields={["datetime", "anamnesis", "symptoms", "diagnosis", "treatment"]}
                    requiredFieldLabel={[t("date"), t("veterinary.anamnesis"), t("veterinary.symptoms"), t("veterinary.diagnosis"), t("veterinary.treatment")]}
                    refresh={loadData}
                    preSetInputs={{animal: { animalId: selectedAnimal?.animalId } }}
                    optionalData={previousData}
                />
            </Box>
            <Box width={'100%'}>
                <div style={{'height': '30vh',}}>
                    <DataGrid
                        sx={{ backgroundColor: "#fff" }}
                        rows={rows}
                        columns={columns}
                        showCellRightBorder
                        autoPageSize={true}
                        rowsPerPageOptions={[pageSize]}
                        onSelectionModelChange={(ids) => {
                        const selectedIDs = new Set(ids);
                        const selectedRowData = rows.filter((row) =>
                            selectedIDs.has(row.id.toString())
                        );
                        if (selectedRowData.length) {
                            const { id, ...rest } = selectedRowData[0];
                            setSelectedConsultation({ consultationId: id, ...rest });
                        } else {
                            setSelectedConsultation(null);
                        }
                        }}
                        columnVisibilityModel={{
                        id: false,
                        }}
                    />
                </div>
            </Box>
            {selectedConsultation && 
                <Box marginTop={'10px'}>
                    <Consultation selectedConsultation={selectedConsultation} />
                </Box>
            }
        </>
    );
};