import React, { useState } from "react";

import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import HistoryToggleOffOutlinedIcon from '@mui/icons-material/HistoryToggleOffOutlined';
import PersonPinIcon from "@mui/icons-material/PersonPin";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import AgeStat from "./tabs/age/AgeStat";
import FrequentationStat from "./tabs/frequentation/FrequentationStat";
import GroupedStat from "./tabs/grouped/GroupedStat";
import MaritalStatusStat from "./tabs/maritalStatus/MaritalStatusStat";
import NationalityStat from "./tabs/nationality/NationalityStat";
import ResidenceStat from "./tabs/residence/ResidenceStat";
import SexStat from "./tabs/sex/SexStat";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 7 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function StatTab(props) {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <Box style={{ height: "100%", width: "100%", margin: "0 auto" }}>
        <AppBar position="relative">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="scrollable"
          >
            <Tab
              icon={<PersonPinIcon />}
              iconPosition="start"
              label="Age"
              {...a11yProps(0)}
            />
            <Tab
              icon={<AccountBoxOutlinedIcon />}
              iconPosition="start"
              label="Sexe"
              {...a11yProps(1)}
            />
            <Tab
              icon={<FlagOutlinedIcon />}
              iconPosition="start"
              label="Nationalité"
              {...a11yProps(2)}
            />
            <Tab
              icon={<CottageOutlinedIcon />}
              iconPosition="start"
              label="Résidence"
              {...a11yProps(3)}
            />
            <Tab
              icon={<BookOutlinedIcon />}
              iconPosition="start"
              label="Etat civil"
              {...a11yProps(4)}
            />
            <Tab
              icon={<HistoryToggleOffOutlinedIcon />}
              iconPosition="start"
              label="Fréquentation"
              {...a11yProps(5)}
            />
            <Tab
              icon={<GroupWorkIcon />}
              iconPosition="start"
              label="Regroupée"
              {...a11yProps(6)}
            />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
          style={{ height: "100%", width: "100%" }}
        >
          <TabPanel
            value={value}
            index={0}
            dir={theme.direction}
            style={{ height: "100%", width: "100%" }}
          >
            <AgeStat />
          </TabPanel>

          <TabPanel
            value={value}
            index={1}
            dir={theme.direction}
            style={{ height: "100%", width: "100%" }}
          >
            <SexStat />
          </TabPanel>

          <TabPanel
            value={value}
            index={2}
            dir={theme.direction}
            style={{ height: "100%", width: "100%" }}
          >
            <NationalityStat />
          </TabPanel>

          <TabPanel
            value={value}
            index={3}
            dir={theme.direction}
            style={{ height: "100%", width: "100%" }}
          >
            <ResidenceStat />
          </TabPanel>

          <TabPanel
            value={value}
            index={4}
            dir={theme.direction}
            style={{ height: "100%", width: "100%" }}
          >
            <MaritalStatusStat />
          </TabPanel>

          <TabPanel
            value={value}
            index={5}
            dir={theme.direction}
            style={{ height: "100%", width: "100%" }}
          >
            <FrequentationStat />
          </TabPanel>

          <TabPanel
            value={value}
            index={6}
            dir={theme.direction}
            style={{ height: "100%", width: "100%" }}
          >
            <GroupedStat />
          </TabPanel>

        </SwipeableViews>
      </Box>
      <div style={{ position: "absolute", top: 96, left: 270 }}>
        <Link to="/gestionSelect">
          <IconButton color="primary">
            <ArrowBackIosNewIcon />
          </IconButton>
        </Link>
      </div>
    </>
  );
}

export default StatTab;
