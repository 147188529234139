import React, { useEffect } from "react";
import AccomodationListing from "./pages/AccomodationListing";

function Accomodation(props) {
    const { setActive } = props;
    useEffect(() => {
      //setActive(3);
    }, []);

    return <AccomodationListing />
};

export default Accomodation;