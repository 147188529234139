import instance from "../../../../shared/components/auth/service/AxiosInstance"

export const JobService = {

    getJob: (pageNo, pageSize) =>
        instance.get(process.env.REACT_APP_API_URL + "job/" +
            '?pageSize=' + pageSize +
            '&pageNo=' + pageNo
        ),

    getJobById: (id) => instance.get(process.env.REACT_APP_API_URL + "job/" + id),

    createJob: (job) =>
        instance.post(process.env.REACT_APP_API_URL + 'job/',
            job
        ),

    updateJob: (id, job) =>
        instance.put(process.env.REACT_APP_API_URL + 'job/' + id, 
            job
        ),

    deleteJob: (id) =>
        instance.delete(process.env.REACT_APP_API_URL + 'job/' + id
        ),
}