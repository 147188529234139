import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from "@mui/icons-material/Close";
import { Alert } from "@mui/material";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { getISODate, localeISODateTime } from "../../utils/localeISODateTime";
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from "react-redux";

import "./LoadingButton.css";

function LoadButtonDialog(props) {
  const { t } = useTranslation();
  
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState({type: 'error', content: ""});
  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const {
    isDisabled,
    color,
    endIcon,
    title,
    titleForm,
    form,
    resetInput,
    handleSumbitAction,
    dialogIcon,
    onlyIcon,
    GetTotalMeal,
    isContained,
    requiredFieldLabel,
    inputs,
    setInputs,
    customError
  } = props;

  const today = localeISODateTime();
  const site = useSelector((state) => state.site.site);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
      return;
    setMessage({type: '', content: ""});
    setOpen(false);
    setOpenAlert(false);
    setLoading(false);
    setLoadingCreate(false);
    if (resetInput) {
      resetInput();
    }
  };

  async function handleRequest() {
    return await handleSumbitAction();
  }

  const handleSubmit = () => {
    setLoadingCreate(true);
    setMessage({type: 'info', content: "Traitement en cours..."});
    setOpenAlert(true);
    if (handleSumbitAction === null || handleSumbitAction === undefined) {
      handleClose();
    } else {
      let promise = handleRequest();
      promise.then(result => {
        if (result?.success === true) {
          setMessage({
            type: "success",
            content: t("request.success"),
          });
          setTimeout(() => {
            setMessage({});
            handleClose();
          }, 1500);
        } else if (!!customError) {
          setMessage({ type: "error", content: customError });
        } else {
          if (result?.err === "NO_DATA") {
            setMessage({ type: "error", content: t("request.empty-fields") });
          } else if (result?.err === "REQUIRED_FIELDS") {
            setMessage({
              type: "error",
              content:
                t('request.failed-fields') +
                requiredFieldLabel?.join(", "),
            });
          } else if (result?.err === "REQUEST_FAILED") {
            if (
              (result?.statusCode === 400 ||
                result?.response?.httpStatusCode === 400) &&
              result?.response?.apiStatusCode === "ENTITY_ALREADY_EXISTS"
            ) {
              setMessage({
                type: "error",
                content: t('request.already-exists'),
              });
            } else {
              setMessage({
                type: "error",
                content: t("request.failed") + ": " + result?.response?.message,
              });
            }
          } else {
            setMessage({
              type: "error",
              content:
                t("request.error"),
            });
          }
          //setOpenAlert(true);
        }
      })
      promise.catch(err => setMessage({type: "error", content: err}))
    }
    setLoadingCreate(false);
  }

  const handleClick = () => {
    setMessage(null);
    setOpen(true);
    setLoading(true);
    if (GetTotalMeal) {
      GetTotalMeal(getISODate(
          localeISODateTime(inputs?.date)
        ));
    }
  }

  return (
    <>
      <Tooltip title={onlyIcon === true ? title : ""} placement="top">
        <LoadingButton
          disabled={isDisabled}
          color={color}
          onClick={handleClick}
          loading={loading}
          variant={isContained ? "contained" : "text"}
          size="larger"
        >
          {onlyIcon === true ? endIcon : title}
        </LoadingButton>
      </Tooltip>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" textAlign="center" sx={{ fontFamily:'overlockBold' }}>
          {dialogIcon}
          <br />
          {titleForm}
        </DialogTitle>

        <DialogContent>{form}</DialogContent>

        <Box sx={{ width: '90%', margin: '0 auto' }}>
          <Collapse in={openAlert}>
            {(message?.type) &&
              <Alert severity={message?.type}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenAlert(false);
                      setMessage({type: 'error', content: ''});
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {message?.content || ''}
              </Alert>
            }
          </Collapse>
        </Box>
        <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
          <Button autoFocus onClick={handleClose} color={"error"} variant="contained">
            {t('action.cancel')}
          </Button>
          <LoadingButton onClick={handleSubmit} loading={loadingCreate} autoFocus variant="contained">
            {title}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default LoadButtonDialog;
