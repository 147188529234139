import { Grid } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PreviewPdf from "../../../components/presence/modals/PreviewPdf";
import { VoucherConfigService } from "../../../components/presence/services/SubService/VoucherConfigService";
import { VoucherService } from "../../../components/presence/services/VoucherService";
import { capitalizeFirstLetter } from "../../utils/StringProcessing";
import {
  DateYmdToDmY,
  compareDates,
  getISODate,
  getISOTime,
  localeISODateTime,
} from "../../utils/localeISODateTime";

import { CustomDatePicker } from "../../../components/datepicker/CustomDatePicker";
import "./Scheduler.css";

const timeUnit = 5; // Minimum time slot (default: 5 minutes)

const roundToNearest = (value, interval, option = "up") => {
  if (option === "up") {
    return Math.round(Math.ceil(value / interval) * interval);
  } else if (option === "down") {
    return Math.round(Math.floor(value / interval) * interval);
  } else {
    return Math.round((value / interval) * interval);
  }
};

const getTimeString = (i, scheduleStart, interval) => {
  let tmpDate = new Date(2000, 0, 1, scheduleStart[0], scheduleStart[1], 0);
  tmpDate.setMinutes(tmpDate.getMinutes() + i * interval);
  return ("0" + tmpDate.getHours() + ":0" + tmpDate.getMinutes()).replace(
    /\d(\d\d)/g,
    "$1"
  );
};

const hourToCssMargin = (hourhhmmss, scheduleStart) => {
  let tmp = hourhhmmss?.split(":");
  let scheduleStartArr = scheduleStart?.split(":");
  return (tmp?.[0] - scheduleStartArr?.[0]) * 60 + (tmp?.[1] - scheduleStartArr?.[1]);
};

const getTotalIntervals = (timeStart, timeEnd, intervalDuration) => {
  return Math.floor(
    (+timeEnd[0] * 60 + +timeEnd[1] - (+timeStart[0] * 60 + +timeStart[1])) /
    intervalDuration
  );
};

const defaultConfig = {
  scheduleStart: "09:00:00",
  scheduleEnd: "17:30:00",
  intervalPlanning: 30,
  nextDays: null,
  num: 1,
};

const nowDate = localeISODateTime();

const getTimeRange = (voucherType) => (voucherType === "Doctor") ? 17 : 30;


export const Scheduler = (props) => {
  const {
    selectedVoucherType,
    voucher,
    setVoucher,
    handleClosePlanning,
    modal,
    today,
  } = props;
  const { site } = useSelector((state) => state.site);

  const [voucherTimeRange, setVoucherTimeRange] = useState(getTimeRange(voucher?.voucherType?.voucherType) || 30);

  const [openPreviewPdf, setOpenPreviewPdf] = useState(false);

  const handleOpenPreviewPdf = () => {
    setOpenPreviewPdf(true);
  };

  const handleClosePreviewPdf = () => {
    setOpenPreviewPdf(false);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [rdvDate, setRdvDate] = useState(getISODate(localeISODateTime(today)));

  const [voucherType, setVoucherType] = useState(selectedVoucherType);
  const [voucherTypes, setVoucherTypes] = useState([]);
  const [slots, setSlots] = useState(
    []
  ); /* Number of available slots, depends on timeUnit */
  const [timeline, setTimeline] = useState(
    []
  ); /* List of hours to be displayed on the left-side */

  const [config, setConfig] =
    useState(
      defaultConfig
    ); /* Configuration corresponding to selected voucher type */
  const [configs, setConfigs] = useState(
    []
  ); /* Configurations for current site vouchers */

  const [events, setEvents] = useState([]);
  const [info, setInfo] = useState("");
  const [openInfo, setOpenInfo] = useState(false);
  const handleClose = (event) => setOpenInfo(false);

  const handleChangeDate = (date) => setRdvDate(date)

  const handleChangeCategory = (event) => {
    let selectedType = JSON.parse(event.target.value);
    setVoucherType(selectedType);
    setVoucherTimeRange(getTimeRange(selectedType?.voucherType));
  }

  const handleSelectSlot = (timeSlot, index) => {
    if (setVoucher) {
      let data = voucher;
      if (voucher?.voucherType?.voucherType === "Shower" || voucher?.voucherType?.voucherType === "Lockerroom" || voucher?.voucherType?.voucherType === "Laundry") {
        data = { ...voucher, num: index };
      }
      setVoucher({
        ...data,
        rdvDate: rdvDate,
        rdvHour: timeSlot + ":00",
      });
      handleClosePlanning();
    }
  };

  useEffect(() => {
    VoucherConfigService.getAllVoucherConfig()
      .then((response) => {
        let siteConfigs = response.data.content.filter(
          (config) => config?.site?.siteId === site?.siteId
        );
        let voucherTypeList = siteConfigs
          .map((config) => config?.voucherType)
          .sort((config1, config2) =>
            config1?.description.localeCompare(config2?.description)
          );
        // setVoucherTypes(voucherTypeList);
        setVoucherType(selectedVoucherType || voucherTypeList[0]); // first of list is selected as default
        setConfigs(siteConfigs);
      })
      .catch((err) => console.log(err));

    VoucherConfigService.getVoucherType(0, 40)
      .then((response) => {
        setVoucherTypes(response.data.content)
      })
      .catch((err) => console.log(err));

  }, [site]);

  useEffect(() => {
    let voucherConfig = configs.filter(
      (config) =>
        config?.voucherType?.voucherTypeId === voucherType?.voucherTypeId
    );
    setConfig({
      scheduleStart:
        voucherConfig[0]?.scheduleStart || defaultConfig.scheduleStart,
      scheduleEnd: voucherConfig[0]?.scheduleEnd || defaultConfig.scheduleEnd,
      intervalPlanning:
        voucherConfig[0]?.intervalPlanning || defaultConfig.intervalPlanning,
      nextDays: voucherConfig[0]?.maxRdvDay || defaultConfig.nextDays,
      num: voucherConfig[0]?.num || defaultConfig.num,
    });

    if (voucherType && rdvDate) {
      VoucherService.getVouchersBySiteAndTypeAndDate(
        site.siteId,
        voucherType.voucherTypeId,
        getISODate(localeISODateTime(rdvDate))
      )      
        .then((response) => {
          let eventList = response.data;
          return eventList;
        })
        .then((eventList) => {
          if (voucherType?.voucherType === "Shower") {
            //if Shower is selected, then also retrieve Lockerroom data
            VoucherConfigService.getVoucherType(0, 40)
              .then((response) => {
                let voucherTypeList = response.data.content;
                return voucherTypeList;
              })
              .then((voucherTypeList) => {
                VoucherService.getVouchersBySiteAndTypeAndDate(
                  site.siteId,
                  voucherTypeList?.filter(voucherType => voucherType?.voucherType === "Lockerroom")?.[0]?.voucherTypeId,
                  getISODate(localeISODateTime(rdvDate))
                )
                .then((res) => {
                    setEvents([...eventList, ...res?.data]);
                  })
                  .catch((err) => console.log(err));
              })
              .catch((err) => console.log(err));    
        } else {
          setEvents(eventList);
        }
      })
      .catch((err) => console.log(err));
    }
  }, [ , voucherType, rdvDate, site, configs]);

  useEffect(() => {
    let start = config.scheduleStart.split(":");
    start[1] = roundToNearest(start[1], config.intervalPlanning, "down");

    let end = config.scheduleEnd.split(":");
    end[1] = roundToNearest(end[1], config.intervalPlanning, "up");

    let totalIntervals =
      getTotalIntervals(start, end, config.intervalPlanning) + 1;

    setTimeline(
      Array(totalIntervals)
        .fill()
        .map((_, i) => getTimeString(i, start, config.intervalPlanning))
    );

    setSlots(
      Array((totalIntervals - 1) * (config.intervalPlanning / timeUnit))
        .fill()
        .map((_, i) => ({ time: getTimeString(i, start, timeUnit) }))
    );
  }, [config]);

  return (
    <>
      <div className="scheduler">
        <div className="header">
          <div className="typeee">
            <label htmlFor="voucherType">Catégorie:&nbsp;</label>
            <select
              name="voucherType"
              value={JSON.stringify(voucherType)}
              onChange={handleChangeCategory}
            >
              {voucherTypes.map((type) => (
                <option key={type?.description} value={JSON.stringify(type)}>
                  {type?.description?.substring(4)}
                </option>
              ))}
            </select>
          </div>
          <div>
            <CustomDatePicker
              valueDate={rdvDate}
              handleChange={handleChangeDate}
            />
          </div>
        </div>
        <div className="planning">
          <div className="planning-content">
            <ul className="events">
              {Array.from(
                Array(
                  voucherType?.voucherType === "Shower" ? (site?.shower + site?.lockerroom) :
                    voucherType?.voucherType === "Laundry" ? site?.laundry :
                      voucherType?.voucherType === "Lockerroom" ? site?.lockerroom : 1
                ).keys()
              ).map((i) => (
                <li className="group" key={"group-" + i}>
                  <div
                    className="group-header"
                    style={{
                      marginTop: config.intervalPlanning - 1,
                      marginBottom: "4%",
                    }}
                  >
                    {
                      (((voucherType?.voucherType !== "Shower") 
                        || ((voucherType?.voucherType === "Shower") && (i < site?.shower))
                      ) &&
                        capitalizeFirstLetter(voucherType?.description.substring(4)) + " " + (i + 1)
                      ) || (
                        "Vestiaire " + (i - site?.shower + 1)
                      )
                    }
                  </div>
                  <div className="group-events">
                    {rdvDate === getISODate(nowDate) &&
                      compareDates(
                        getISODate(nowDate) + " " + getISOTime(nowDate),
                        rdvDate + " " + config?.scheduleStart
                      ) === true &&
                      compareDates(
                        getISODate(nowDate) + " " + getISOTime(nowDate),
                        rdvDate + " " + config?.scheduleEnd
                      ) === false && modal ? (
                        <span
                          className="currentTimeBar"
                          style={{
                            top: hourToCssMargin(
                              getISOTime(nowDate),
                              config.scheduleStart
                            ),
                            marginLeft: modal === true ? "" : "-50%",
                          }}
                        ></span>
                      )
                      : null
                    }
                    <ul className="time-slots">
                      {slots?.map((slot, k) => (
                        <li
                          key={"slot-" + k}
                          onClick={() => handleSelectSlot(slot?.time, i + 1)}
                          className={
                            "info-tooltip slot border-" +
                            (((k + 1) * timeUnit) % config.intervalPlanning ===
                              0
                              ? "darker"
                              : "lighter")
                          }
                          style={{ height: timeUnit }}
                        >
                          {(k * timeUnit) % config.intervalPlanning === 0 ? (
                            <>
                              <div className="bar">
                                <div
                                  style={{
                                    display: 'flex',
                                    /*marginLeft: modal === true ? "" : "-100%",*/
                                  }}
                                >
                                  {slot.time}
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          <div className="tooltiptext">{slot?.time}</div>
                        </li>
                      ))}
                    </ul>
                    <ul>
                      {events.map((event) => {
                        if (
                          (voucherType?.voucherType === "Shower" && event?.voucherType?.voucherType === "Shower" && event?.num === i + 1) 
                          || 
                          (voucherType?.voucherType === "Shower" && event?.voucherType?.voucherType === "Lockerroom" && (event?.num === (i - site?.shower + 1)))
                          ||
                          (voucherType?.voucherType === "Lockerroom" && ((event?.num === i + 1) || (event?.num === null)))
                          ||
                          (voucherType?.voucherType === "Laundry" && ((event?.num === i + 1) || (event?.num === null)))
                          ||
                          (event?.num === null)
                        ) {
                          return (
                            <li key={JSON.stringify(event)}>
                              <span
                                className="event"
                                style={{
                                  zIndex: 10,
                                  right: 0,
                                  textAlign: "center",
                                  top: hourToCssMargin(
                                    event?.rdvHour,
                                    config?.scheduleStart
                                  ),
                                  border: '1px solid blue',
                                  height: voucherTimeRange,
                                  marginLeft: modal === true ? "" : "-50%",
                                }}
                                onClick={() => {
                                  setInfo(event);
                                  setOpenInfo(true);
                                }}
                              >
                                {`[${event?.rdvHour?.substring(0, 5)}] ${event?.presence?.fullName}`}
                              </span>
                            </li>
                          );
                        } else {
                           return null;
                        }
                      })}
                    </ul>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Tooltip title="Générer un PDF" placement="top">
          <IconButton onClick={handleOpenPreviewPdf} sx={{ color: "#1976d2" }}>
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      </div>
      <PreviewPdf
        handleClosePreviewPdf={handleClosePreviewPdf}
        openPreviewPdf={openPreviewPdf}
        voucher={voucher}
        rdvDate={rdvDate}
        type="planning"
        timeline={timeline}
        config={config}
        voucherType={voucherType}
        site={site}
        today={today}
        hourToCssMargin={hourToCssMargin}
        slots={slots}
        handleSelectSlot={handleSelectSlot}
        timeUnit={timeUnit}
        events={events}
        setInfo={setInfo}
        setOpenInfo={setOpenInfo}
      />

      <Dialog fullScreen={fullScreen} onClose={handleClose} open={openInfo}>
        <DialogTitle>
          <h1
            style={{
              fontFamily: "backToSchool",
              fontSize: "65px",
              color: "#bb0000",
              textAlign: "center"
            }}
          >
            Bon
          </h1>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item>
              <h4 style={{ fontWeight: "bolder" }}>Informations :</h4>
              <ul>
                <li>Client : {info?.presence?.fullName}</li>
                <li>
                  {" "}
                  Date : {DateYmdToDmY(info?.rdvDate)},{" "}
                  {info?.rdvHour?.substring(0, 5)}
                </li>
                <li>Prochain RDV : {DateYmdToDmY(info?.nextRdvDate) || ""}</li>
                <li>Remarque : {info?.remark}</li>
              </ul>
            </Grid>
            <Grid item>
              {info?.voucherLaundryItems?.length > 0 && (
                <>
                  <h4 style={{ fontWeight: "bolder" }}>Liste des vêtements :</h4>
                  <ul>
                    {info?.voucherLaundryItems?.map((voucherLaundryItem) => (
                      <li key={JSON.stringify(voucherLaundryItem)}>
                        {voucherLaundryItem?.laundryItem?.description} (
                        {voucherLaundryItem?.quantity})
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
