import React, {useState, useEffect} from "react";

import { TextField } from "@material-ui/core";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider, frFR } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { fr } from 'date-fns/locale';

import { dateIsValid } from "../../shared/utils/localeISODateTime";


export const CustomDatePicker = (props) => {

    const {valueDate, handleChange} = props;
    const {name} = props || "date";

    const handleDateChange = (newValue) => {
        if ((handleChange !== null) && (handleChange !== undefined) 
            && (typeof handleChange === 'function')
            && (((dateIsValid(newValue)) && (newValue?.getFullYear() > 1000)) || (newValue === ''))
        ) {
            handleChange(newValue);
        } else {
            handleChange(null);
        }
    }

    return (
        <>
            <LocalizationProvider 
                dateAdapter={AdapterDateFns}
                adapterLocale={fr}
                localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
            >
              <DesktopDatePicker
                label=""
                key="today"
                name={name}
                value={valueDate === '' ? null : valueDate}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
                onChange={handleDateChange}
                dayOfWeekFormatter={(day) => `${day}.`}
                views={['year', 'day']}
              />
            </LocalizationProvider>
        </>
    );
}