import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../../redux/features/auth/authSlice";
import ButtonManager from "../../../shared/components/Button/ButtonManager";
import { Jwt } from "../../../shared/components/auth/jwt/Jwt";
import {
  DateYmdToDmY,
  dateIsValid,
  getISODate,
  localeISODateTime,
} from "../../../shared/utils/localeISODateTime";
import { ClosingService } from "../service/ClosingService";
import { ReportService } from "../service/ReportService";
import { ExportToFile } from "../utils/ExportToFile";
import { DateSelector } from "./selectors/DateSelector";
import { SiteSelector } from "./selectors/SiteSelector";

const today = getISODate(localeISODateTime());

const closingForms = [
  { name: "date", label: "Date", type: "date", valueDate: (dateIsValid(today) ? today : new Date()) },
  { name: "mealClient", label: "Repas Client", type: "number", value: 0 },
  { name: "mealAti", label: "Repas ATI", type: "number", value: 0 },
  { name: "mealAtelier", label: "Repas Atelier", type: "number", value: 0 },
  { name: "mealTotal", label: "Total repas", type: "number", value: 0 },
  { name: "dayCenter", label: "Présences Centre de Jour", type: "number", value: 0 },
  { name: "remark", label: "Remarque", type: "text" },
];

const ReportClosing = (props) => {
  const { setActive } = props;
  useEffect(() => {
    //setActive(4);
  }, []);

  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const userData = Jwt(user?.token);

  const [totalMeal, setTotalMeal] = useState({ Clients:0, ATI:0, Atelier:0 });

  const [inputs, setInputs] = useState({
    dateStart: today,
    dateEnd: today,
    sites: [],
  });
  const [message, setMessage] = useState("");

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selected, setSelected] = useState();
  const [select, setSelect] = useState({});
  const [rows, setRows] = useState([]);
  const [rowCountState, setRowCountState] = useState(null);
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 10,
  });

  const [printLoading, setPrintLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  
  const refresh = (searchParams) => {
    setSubmitLoading(true);
    ReportService.getReportClosing({
      dateStart: searchParams?.dateStart || inputs.dateStart,
      dateEnd: searchParams?.dateEnd || inputs.dateEnd,
      status: searchParams?.status || inputs.status,
      sites: searchParams?.sites || inputs.sites,
    })
      .then((response) => {
        setRows(response.data?.sort((c1, c2) => new Date(c1.date) - new Date(c2.date)))
        setRowCountState(response.data.length);
        setSubmitLoading(false);
        setMessage('');
      })
      .catch((err) => setMessage("Erreur: " + err));
      setSubmitLoading(false);
  };

  const handlePrint = () => {
    setPrintLoading(true);
    refresh(inputs);
    setPrintLoading(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    let val = event.target.value;
    try { 
      val = JSON.parse(event.target.value);
    } catch (err) {}
    let value = event.target.type === "checkbox" ? event.target.checked : val;

    //Checkbox group
    if (
      document.querySelectorAll("input[name=" + name + "]").length > 1 &&
      event.target.type === "checkbox"
    ) {
      value = [];
      document
        .querySelectorAll("input[name=" + name + "]:checked")
        .forEach((input) => value.push(JSON.parse(input.value)));
    }

    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = () => {
    ReportService.getReportClosing(inputs)
      .then((response) => {
        let rows = response.data
          .sort(
            (c1, c2) =>
              c1.date.localeCompare(c2.date) ||
              c1?.site?.description.localeCompare(c2?.site?.description)
          )
          .map((row) => {
            let { closingId, site, date, user, ...rest } = row;
            return {
              id: closingId,
              site: site?.description,
              date: getISODate(localeISODateTime(date)),
              ...rest,
              user: { userName: userData?.sub },
            };
          });
        ExportToFile(
          rows,
          "closings_" + new Date().toISOString().split(".")[0]
        );
        setMessage("Export de " + response.data?.length + " ligne(s)");
      })
      .catch((err) => {
        setMessage("Erreur lors de l'exportation des données: " + err);
        if (
          err?.statusCode === 403 ||
          err?.statusCode === 401 ||
          err?.response?.status === 403 ||
          err?.response?.status === 401
        ) {
          dispatch(logout());
        }
      });
  };

  const columns = [
    {
      field: "date",
      headerName: "Date",
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (data) => <span>{DateYmdToDmY(data.value.substring(0, 10), ".")}</span>,
    },
    {
      field: "site",
      headerName: "Site",
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (data) => {
        return (data.value.description);
      }
    },
    {
      field: "mealAti",
      headerName: "Repas Ati",
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "mealClient",
      headerName: "Repas Client",
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "mealAtelier",
      headerName: "Repas Atelier",
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "mealTotal",
      headerName: "Repas Total",
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "dayCenter",
      headerName: "Centre Jour",
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "edit",
      headerName: "Editer",
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      renderCell: (data) => (
        <ButtonManager
          onlyIcon={true}
          title={"Clôture"}
          id={selected?.closingId}
          preSetInputs={{...selected, manual: true, user: { userName: userData?.sub }}}
          selected={selected}
          align={"center"}
          forms={closingForms}
          updateFunction={ClosingService.updateClosing}
          requiredFields={[
            "mealClient",
            "mealAti",
            "mealAtelier",
          ]}
          requiredFieldLabel={[
            "Repas Client",
            "Repas TUC",
            "Repas Atelier"
          ]}
          refresh={refresh}
          setTotalMeal={setTotalMeal}
          totalMeal={totalMeal}
        />
      ),
    },
  ];

  return (
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', width: '100%' }}>
        <h1 style={{ fontFamily: 'backToSchool', fontSize: '65px', color: '#bb0000', marginTop: '4%' }}>Rapport Clôture</h1>
        <div style={{ position: "absolute", top: 23, left: 270 }}>
        <Link to="/gestionSelect">
          <IconButton color="primary">
            <ArrowBackIosNewIcon />
          </IconButton>
        </Link>
      </div>
      <div
        className="event-container display-relative"
        style={{ marginTop: "5%", height:'810px' }}
      >
        <form onSubmit={handleSubmit}>
          <Box>
            <h1 style={{ fontFamily:'overlockBold' }}>Rechercher</h1>
            <div style={{ margin:'0px 130px' }}>
              <DateSelector
                setInputs={setInputs}
              />
              <SiteSelector handleChange={handleChange} type="checkbox" setInputs={setInputs} inputs={inputs} />
            </div>
            <LoadingButton
              variant="contained"
              sx={{ alignSelf: "center", marginRight: '1% 2% 1% 0%' }}
              onClick={handlePrint}
            >
              Rechercher
            </LoadingButton>
            <LoadingButton
              variant="contained"
              sx={{ alignSelf: "center", margin: '1% 0% 1% 2%' }}
              onClick={handleSubmit}
            >
              Exporter
            </LoadingButton>
          </Box>
        </form>
        <Typography sx={{ textAlign: "center" }}>{message}</Typography>
        <DataGrid
          rowsPerPageOptions={[rowsState.pageSize]}
          sx={{ backgroundColor: '#fff', height: "62%" }}
          getRowId={(row) => row.closingId}
          columns={columns}
          rows={rows}
          rowCount={rowCountState}
          showCellRightBorder
          pagination
          {...rowsState}
          paginationMode="server"
          selectionModel={selectionModel}
          onSelectionModelChange={(newSelectionModel) => {
            const selectedIDs = new Set(newSelectionModel);
            setSelected((rows.filter((row) => selectedIDs.has(row.closingId)))?.[0]);
            setSelectionModel(newSelectionModel);
          }}
        />
      </div>
    </div>
  );
};

export default ReportClosing;
