import LoadingButton from "@mui/lab/LoadingButton";
import Box from '@mui/material/Box';
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { ExportDataGridToExcel } from "../../../../../export/ExportDataGridToExcel";
import { SecretService } from "../../../../service/SecretService";
const voucherColumns = [
    {
        field: "id",
        header: "Id",
        hideable: true,
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "siteName",
        headerName: "Site",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        cellClassName: "tableLeftColumn",
    },
    {
        field: "Sleepingbag",
        headerName: "Sac de couchage",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "Shower",
        headerName: "Douche",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "Lockerroom",
        headerName: "Vestiaire",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "Laundry",
        headerName: "Buanderie",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "Doctor",
        headerName: "Docteur",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "Hairdresser",
        headerName: "Coiffeur",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
]


function Voucher(props) {

    const {
        rowsState,
        rowCountState,
        selectionModel,
        setSelectionModel,
        defaultPageSize,
        setDefaultPageSize,
        setDefaultPageNo,
        setRowsState,
        defaultPageNo,
        inputs
    } = props


    const [vouchers, setVouchers] = useState();
    const [loadingState, setLoadingState] = useState(false);

    const refresh = () => {
        setLoadingState(true);
        SecretService.getVoucherStat(inputs.startDate, inputs.endDate)
            .then((results) => {
                let datas = results.data;
                datas.forEach((data) => {
                    data.voucherStats.forEach((voucherStat) => {
                        Object.defineProperty(data, voucherStat.name, {
                            value: voucherStat.quantity,
                        });
                    })
                })
                setVouchers(results.data);
                setLoadingState(false);
            })
            .catch(err => console.log(err));
    }
    useEffect(() => {
        refresh()
    }, [inputs])

    return (
        <>
            <h3
                style={{
                    position: "relative",
                    fontFamily: "backToSchool",
                    fontSize: "45px",
                    color: "#bb0000",
                    marginTop: "20px",
                }}
            >
                Bons
            </h3>
            {vouchers != null ?
                (
                    <>
                        <div>
                            <ExportDataGridToExcel
                                headers={voucherColumns}
                                data={vouchers.map(row => ({
                                    "Id": "",
                                    "Site": row?.siteName,
                                    "Sac de couchage": row?.Sleepingbag,
                                    "Douche": row?.Shower,
                                    "Vestiaire": row?.Lockerroom,
                                    "Buanderie": row?.Laundry,
                                    "Docteur": row?.Doctor,
                                    "Coiffeur": row?.Hairdresser
                                }))}
                                filename={"statistiques_bon"}
                            />
                        </div>
                        <div className="EventDataGrid">
                            <DataGrid
                                rowsPerPageOptions={[rowsState.pageSize]}
                                sx={{ backgroundColor: "#fff" }}
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: {
                                            id: false,
                                        },
                                    },
                                }}
                                columns={voucherColumns}
                                rows={vouchers}
                                loading={loadingState}
                                rowCount={rowCountState}
                                showCellRightBorder
                                pagination
                                {...rowsState}
                                paginationMode="server"
                                checkboxSelection={false}
                                selectionModel={selectionModel}
                                onSelectionModelChange={(newSelectionModel) => {
                                    const selectedIDs = new Set(newSelectionModel);
                                    const selectedRows = vouchers.filter((row) =>
                                        selectedIDs.has(row.id)
                                    );
                                    setSelectionModel(newSelectionModel);
                                }}
                                onPageChange={(page) => {
                                    setDefaultPageNo(page);
                                    setRowsState((prev) => ({ ...prev, page }));
                                    refresh();
                                }}
                                onPageSizeChange={(pageSize) => {
                                    setDefaultPageSize(pageSize);
                                    setRowsState((prev) => ({ ...prev, pageSize }));
                                    refresh();
                                }}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <Box textAlign="center" mt={20}>
                            <LoadingButton loading={true} variant="outlined" disabled>
                                Loading
                            </LoadingButton>
                        </Box>
                    </>
                )
            }
        </>
    )
}

export default Voucher;