import instance from "../../../../shared/components/auth/service/AxiosInstance"

export const NationalityService = {

    getNationality: (pageNo, pageSize) =>
        instance.get(process.env.REACT_APP_API_URL + "Nationality/" +
            '?pageSize=' + pageSize +
            '&pageNo=' + pageNo
        ),

    getNationalityById: (id) => instance.get(process.env.REACT_APP_API_URL + "Nationality/" + id),

    createNationality: (nationality) =>
        instance.post(process.env.REACT_APP_API_URL + 'Nationality/',
            {
                description: nationality?.description,
                code: nationality?.code,
                deleted: (nationality?.deleted ? nationality.deleted : false)
            }
        ),

    updateNationality: (id, nationality) =>
        instance.put(process.env.REACT_APP_API_URL + 'Nationality/' + id, 
            {
                nationalityId: nationality?.nationalityId,
                description: nationality?.description,
                code: nationality?.code,
                deleted: (nationality?.deleted ? nationality.deleted : false)
            }
        ),

    deleteNationality: (id) =>
        instance.delete(process.env.REACT_APP_API_URL + 'Nationality/' + id
        ),
}