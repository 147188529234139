import React, { useState, useEffect } from "react";
import { ClientGroupSelector } from "./selectors/ClientGroupSelector";
import { SiteSelector } from "./selectors/SiteSelector";
import { Typography, Box } from "@mui/material";
import { ReportService } from "../service/ReportService";
import { ExportToFile } from "../utils/ExportToFile";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/features/auth/authSlice";
import { Link } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import IconButton from "@mui/material/IconButton";
import { DataGrid } from "@mui/x-data-grid";
import { ClientService } from "../../client/service/ClientService";
import { refreshToken } from "../../../shared/components/auth/service/AxiosInstance";


const ReportClient = (props) => {
    const { setActive } = props;
    useEffect(() => {
      //setActive(4);
    }, []);

    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selected, setSelected] = useState();
    const [select, setSelect] = useState({});
    const [rows, setRows] = useState([]);
    const [rowCountState, setRowCountState] = useState(null);
    const [rowsState, setRowsState] = useState({
        page: 0,
        pageSize: 10,
      });

    const [printLoading, setPrintLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    const [inputs, setInputs] = useState({clientGroups: [], sites: []});
    const [message, setMessage] = useState("");

    const handleChange = (event) => {
        const name = event.target.name;
        let val = event.target.value;
        try {
            val = JSON.parse(event.target.value);
        } catch (err) {}
        let value = event.target.type === "checkbox" ? event.target.checked : val;

        //Checkbox group
        if ((document.querySelectorAll("input[name=" + name + "]").length > 1) && (event.target.type === "checkbox")) {
            value = [];
            document
                .querySelectorAll("input[name=" + name + "]:checked")
                .forEach((input) => value.push(JSON.parse(input.value)));
        }

        setInputs((values) => ({ ...values, [name]: value }));
        // refresh(currentPage, pageSize);
    }

    const refresh = (searchParams) => {
        ReportService.getReportClient(inputs)
          .then((response) => {
            setRows(response.data)
            setRowCountState(response.data.length);
          })
          .catch((err) => setMessage("Erreur: " + err));
      };

    const handleSubmit = () => {
        setSubmitLoading(true);
        ReportService.getReportClient(inputs)
            .then(response => {
                let rows = response.data
                    .sort((client1, client2) => client1?.lastnametempo?.localeCompare(client2.lastnametempo))
                    .map(row => {
                        let {
                            clientId,
                            lastnametempo,
                            firstnametempo,
                            surnametempo,
                            registrationNumbertempo,
                            phonetempo,
                            birthdatetempo,
                            clientGroup,
                            nationality,
                            sexe,
                            maritalStatus,
                            legalStatus,
                            resident,
                            accomodation,
                            sites,
                            originCountry,
                            remark,
                            popup
                        } = row;
                        return ({
                            "Id": clientId,
                            "Nom": lastnametempo,
                            "Prénom": firstnametempo,
                            "Surnom": surnametempo,
                            "Matricule": registrationNumbertempo,
                            "Téléphone": phonetempo,
                            "Date nais.": birthdatetempo,
                            "Groupe": clientGroup,
                            "Nationalité": nationality?.description,
                            "Sexe": sexe,
                            "Etat civil": maritalStatus,
                            "Statut légal": legalStatus,
                            "Résident": resident?.description,
                            "Logement": accomodation,
                            "Site": sites?.map(site => site.description).join(','),
                            "Pays d'origine": originCountry?.name,
                            "Remarque": remark,
                            "Popup": popup
                        })
                    })
                ExportToFile(rows, "clients_" + new Date().toISOString().split('.')[0]);
                setMessage("Export de " + (rows?.length ? rows.length : 0) + " ligne(s)");
            setSubmitLoading(false);

            })
            .catch(err => {
                console.log(err);
                if (err?.statusCode === 403 || err?.statusCode === 401
                    || err?.response?.status === 403 || err?.response?.status === 401
                ) {
                    dispatch(logout());
                }
            setSubmitLoading(false);
            });
    }

    const handlePrint = () => {
      setPrintLoading(true);
      refresh(inputs);
      setPrintLoading(false);
    };

    const columns = [
        {
          field: "firstnametempo",
          headerName: "Prénom",
          headerClassName: "researchColmun",
          headerAlign: "center",
          align: "center",
          flex: 1,
        },
        {
          field: "lastnametempo",
          headerName: "Nom",
          headerClassName: "researchColmun",
          headerAlign: "center",
          align: "center",
          flex: 1,
        },
        {
          field: "clientGroup",
          headerName: "Groupe client",
          headerClassName: "researchColmun",
          headerAlign: "center",
          align: "center",
          flex: 1,
            renderCell: (data) => {
                return (data.value.description);
            }
        },
        {
            field: "sites",
            headerName: "Site de rattachement",
            headerClassName: "researchColmun",
            headerAlign: "center",
            align: "center",
            flex: 1,
            renderCell: (data) => {
                let listSite = "";
                data.value.map((site) => {
                    listSite += ', ' + site.description;
                })
                return (listSite.substr(2, listSite.length))
            }
        },
    ];

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <h1
          style={{
            fontFamily: "backToSchool",
            fontSize: "65px",
            color: "#bb0000",
            marginTop: "4%",
          }}
        >
          Rapport Clients
        </h1>
        <div
          className="event-container display-relative"
          style={{ marginTop: "5%", height:'810px' }}
        >
          <div style={{ position: "absolute", top: 23, left: 270 }}>
            <Link to="/gestionSelect">
              <IconButton color="primary">
                <ArrowBackIosNewIcon />
              </IconButton>
            </Link>
          </div>
          <form onSubmit={handleSubmit}>
            <Box>
              <h1 style={{ fontFamily: "overlockBold" }}>Rechercher</h1>
              <div style={{ margin:'0px 130px' }}>
                <ClientGroupSelector handleChange={handleChange} setInputs={setInputs}/>
                <SiteSelector handleChange={handleChange} type="checkbox" inputs={inputs} setInputs={setInputs}/>
              </div>

              <LoadingButton
                loading={printLoading}
                variant="contained"
                sx={{ alignSelf: "center", marginRight: "1% 2% 1% 0%" }}
                onClick={handlePrint}
              >
                Rechercher
              </LoadingButton>
              <LoadingButton
                loading={submitLoading}
                variant="contained"
                sx={{ alignSelf: "center", margin: "1% 0% 1% 2%" }}
                onClick={handleSubmit}
              >
                Exporter
              </LoadingButton>
            </Box>
          </form>
          <Typography sx={{ textAlign: "center" }}>{message}</Typography>
          <DataGrid
            rowsPerPageOptions={[rowsState.pageSize]}
            sx={{ backgroundColor: "#fff", height: "65%" }}
            getRowId={(row) => row.clientId}
            columns={columns}
            rows={rows}
            rowCount={rowCountState}
            showCellRightBorder
            pagination
            {...rowsState}
            paginationMode="server"
            onPageChange={(page) => {
              setCurrentPage(page);
              setRowsState((prev) => ({ ...prev, page }));
              refresh(page, pageSize);
            }}
          />
        </div>
      </div>
    );
}

export default ReportClient;