import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/features/auth/authSlice";
import { useTranslation } from "react-i18next";
import { formatDateString } from "../../../shared/utils/localeISODateTime";
import { ClientService } from "../../../components/client/service/ClientService";
import { Box } from '@mui/material';

export const Client = (props) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const {
        clientId
    } = props;

    const [client, setClient] = useState(null);

    useEffect(() => {
        if (clientId) {
            ClientService.getClientById(clientId)
                .then((response) => {
                    setClient(response.data);
                })
                .catch((err) => {
                    if (
                        err?.statusCode === 403 ||
                        err?.statusCode === 401 ||
                        err?.response?.status === 403 ||
                        err?.response?.status === 401
                    ) {
                        dispatch(logout());
                    }
              });
        }
    }, [clientId]);

    return (
        <Box width={'100%'} >
            <h4>{t('veterinary.client')}</h4>
            <Box display={'flex'} flexWrap={'wrap'}>
                <Box width={'34%'}><h5>{client?.firstnametempo} {(client?.lastnametempo)?.toUpperCase()}</h5></Box>
                <Box width={'33%'}><b>{t('birthdate')}:</b> {formatDateString(client?.birthdatetempo)}</Box>
                <Box width={'33%'}><b>{t('veterinary.phone')}:</b> {client?.phonetempo}</Box>
            </Box>
        </Box>
    );
};

