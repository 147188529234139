import LoadingButton from "@mui/lab/LoadingButton";
import Box from '@mui/material/Box';
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { ExportDataGridToExcel } from "../../../../../export/ExportDataGridToExcel";
import { SecretService } from "../../../../service/SecretService";
const serviceColumns = [
    {
        field: "id",
        header: "Id",
        hideable: true,
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "siteName",
        headerName: "Site",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        cellClassName: "tableLeftColumn",
    },
    {
        field: "nbMeal",
        headerName: "Repas",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "nbPresent",
        headerName: "Présences",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "nbPresentDOW",
        headerName: "En semaine",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "nbPresentDOR",
        headerName: "Le week-end",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "nbMultipleSite",
        headerName: "Multi-site",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "tuc",
        headerName: "TUC",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "ati",
        headerName: "ATI",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "clients",
        headerName: "Clients",
        flex: 1,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
]

function Service(props) {

    const {
        rowsState,
        rowCountState,
        selectionModel,
        setSelectionModel,
        defaultPageSize,
        setDefaultPageSize,
        setDefaultPageNo,
        setRowsState,
        defaultPageNo,
        inputs
    } = props

    const [services, setServices] = useState();
    const [loadingState, setLoadingState] = useState(false);

    const refresh = () => {
        setLoadingState(true)
        SecretService.getServiceStat(inputs.startDate, inputs.endDate)
            .then((results) => {
                let datas = results.data;
                datas.forEach((data) => {
                    data.clientGroups.forEach((clientGroup) => {
                        switch (clientGroup.name) {
                            case "TUC":
                                data.tuc = clientGroup?.quantity;
                            case "ATI":
                                data.ati = clientGroup?.quantity;
                                break;
                            case "Clients":
                                data.clients = clientGroup?.quantity;
                                break;
                            case "Atelier":
                                data.atelier = clientGroup?.quantity;
                                break;
                        }
                    });
                    setServices(results.data);
                    setLoadingState(false);
                })
            })
            .catch(err => console.log(err));
    }
    useEffect(() => {
        refresh()
    }, [inputs])

    return (
        <>
            <h3
                style={{
                    position: "relative",
                    fontFamily: "backToSchool",
                    fontSize: "45px",
                    color: "#bb0000",
                    marginTop: "20px",
                }}
            >
                Services
            </h3>
            {services != null ?
                (
                    <>
                        <div>
                            <ExportDataGridToExcel
                                headers={serviceColumns}
                                data={services?.map(row => ({
                                    "Id": "",
                                    "Site": row?.siteName,
                                    "Repas": row?.nbMeal,
                                    "Présences": row?.nbPresent,
                                    "En semaine": row?.nbPresentDOW,
                                    "Le week-end": row?.nbPresentDOR,
                                    "Multi-site": row?.nbMultipleSite,
                                    "TUC": row?.tuc,
                                    "ATI": row?.ati,
                                    "Clients": row?.clients
                                }))}
                                filename={"statistiques_service"}
                            />
                        </div>
                        <div className="EventDataGrid">
                            <DataGrid
                                rowsPerPageOptions={[rowsState.pageSize]}
                                sx={{ backgroundColor: "#fff" }}
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: {
                                            id: false,
                                        },
                                    },
                                }}
                                columns={serviceColumns}
                                rows={services}
                                loading={loadingState}
                                rowCount={rowCountState}
                                showCellRightBorder
                                pagination
                                {...rowsState}
                                paginationMode="server"
                                checkboxSelection={false}
                                selectionModel={selectionModel}
                                onSelectionModelChange={(newSelectionModel) => {
                                    const selectedIDs = new Set(newSelectionModel);
                                    const selectedRows = services.filter((row) =>
                                        selectedIDs.has(row.id)
                                    );
                                    setSelectionModel(newSelectionModel);
                                }}
                                onPageChange={(page) => {
                                    setDefaultPageNo(page);
                                    setRowsState((prev) => ({ ...prev, page }));
                                    refresh();
                                }}
                                onPageSizeChange={(pageSize) => {
                                    setDefaultPageSize(pageSize);
                                    setRowsState((prev) => ({ ...prev, pageSize }));
                                    refresh();
                                }}
                            />
                        </div>
                    </>
                ) :
                (
                    <>
                      <Box textAlign="center" mt={20}>
                            <LoadingButton loading={true} variant="outlined" disabled>
                                Loading
                            </LoadingButton>
                        </Box>
                    </>
                )

            }

        </>
    )
}

export default Service;