import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { Box, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../../../../redux/features/auth/authSlice";
import { ClientService } from "../../../service/ClientService";

import { dateIsValid, getISODate, localeISODateTime } from "../../../../../shared/utils/localeISODateTime";
import { CustomDatePicker } from "../../../../datepicker/CustomDatePicker";


const columns = [
  {
    field: "id",
    headerName: "Id",
    hideable: true,
    flex: 1,
    defaultVisible: false,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "meal",
    headerName: "Nombre de repas",
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "entryHour",
    headerName: "Heure d'arrivée",
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    renderCell: (data) => data?.value?.substring(11, 16) || "",
  },
  {
    field: "presenceDate",
    headerName: "Date",
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    renderCell: (data) => moment(data.value).format("DD-MM-YYYY"),
    filterEditorProps: (props, { index }) => {
      return {
        dateFormat: "DD-MM-YYYY",
        cancelButton: false,
        highlightWeekends: false,
        placeholder:
          index === 1
            ? "Created date is before..."
            : "Created date is after...",
      };
    },
  },
];

function ClientHistorique(props) {
  const { client } = props;

  const dispatch = useDispatch();

  const todaystr = getISODate(localeISODateTime());

  const [focus, setFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);

  const [presences, setPresences] = useState(null);
  const [selected, setSelected] = useState(client);

  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 10,
  });
  const [rowCountState, setRowCountState] = useState(0);
  const [selectionModel, setSelectionModel] = useState([]);
  const [defaultPageNo, setDefaultPageNo] = useState(0);
  const [defaultPageSize, setDefaultPageSize] = useState(10);

  const [inputs, setInputs] = useState({dateStart: null, dateEnd: null});

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const refresh = ({ pageNo, pageSize }) => {
    return ClientService.getClientPresences(
      pageNo,
      pageSize,
      client?.clientId,
      (dateIsValid(inputs?.dateStart) ? getISODate(localeISODateTime(inputs?.dateStart)) : ''),
      (dateIsValid(inputs?.dateEnd) ? getISODate(localeISODateTime(inputs?.dateEnd)) : '')
    ).then((response) => {
      const data = response.data.content;
      setPresences(
        data.map((row) => {
          const { presenceId, ...rest } = row;
          return { id: presenceId, ...rest };
        })
      );
      setRowCountState(response.data.totalElements);
    })
    .catch(err => {
      if (err?.statusCode === 403 || err?.statusCode === 401
        || err?.response?.status === 403 || err?.response?.status === 401
      ) {
        dispatch(logout());
      }
    });
  };

  useEffect(() => {
    if (presences != null && client != null) {
      var hauteur=document.getElementById("historiqueDataGrid").offsetHeight;
      if (hauteur > defaultPageSize) {
        setDefaultPageSize(Math.round((hauteur - 100)/52.9))
        refresh({pageNo:defaultPageNo, pageSize:Math.round((hauteur - 100)/52.9)});
      }
    }
  }, []);

  useEffect(() => {
    if (client?.clientId) {
      refresh({
        pageNo: defaultPageNo,
        pageSize: defaultPageSize,
      });
    }
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(
      () =>
        refresh({
          pageNo: defaultPageNo,
          pageSize: defaultPageSize,
        }),
      1000
    );
    return () => clearTimeout(timeoutId);
  }, [inputs]);

  if (presences != null && client != null) {
    return (
      <>
      <div style={{ display:'flex', flexDirection:'column', textAlign: "center", alignItems: "center", justifyContent: "center", width: "90%" }}>
        <div style={{ marginBottom:'1%' }}>
          <h5 style={{ margin: 5 }}>Recherche dans l'historique de {(client.firstnametempo || "") + " " + (client.lastnametempo || "")} : </h5>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ width: "40%", display: "flex", justifyContent:'center', textAlign:'center', alignItems:'center' }}>
              <span style={{ fontSize:'20px', fontFamily:'overlockBold', width:'30%', wordWrap:'none' }}>Début:</span>
              <CustomDatePicker
                  key="dateStart"
                  name="dateStart"
                  valueDate={inputs?.dateStart || ''}
                  handleChange={(newValue) => dateIsValid(newValue) || (newValue === '') ? setInputs({...inputs, dateStart: newValue}) : setInputs({...inputs, dateStart: ''})}
              />
            </Box>
            <Box sx={{ width: "40%", display: "flex", justifyContent:'center', textAlign:'center', alignItems:'center' }}>
              <span style={{ fontSize:'20px', fontFamily:'overlockBold', width:'30%', wordWrap:'none' }}>Fin:</span>
              <CustomDatePicker
                  key="dateEnd"
                  name="dateEnd"
                  valueDate={inputs?.dateEnd || ''}
                  handleChange={(newValue) => dateIsValid(newValue) || (newValue === '') ? setInputs({...inputs, dateEnd: newValue}) : setInputs({...inputs, dateEnd: ''})}
              />
            </Box>
            <Tooltip title="Effacer les champs" placement="top">
              <FilterAltOffIcon onClick={() => setInputs({...inputs, dateStart: '', dateEnd: ''}) } />
            </Tooltip>
          </div>
        </div>
        <div className="clientHistoric">
          <div style={{ width: "100%" }} id='historiqueDataGrid'>
            <DataGrid
              rowsPerPageOptions={[rowsState.pageSize]}
              sx={{ backgroundColor: '#fff', height:'70vh' }}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
                },
              }}
              columns={columns}
              rows={presences}
              rowCount={rowCountState}
              pagination
              {...rowsState}
              showCellRightBorder
              paginationMode="server"
              selectionModel={selectionModel}
              onPageChange={(page) => {
                setDefaultPageNo(page);
                setRowsState((prev) => ({ ...prev, page }));
                refresh({ pageNo: page, pageSize: defaultPageSize });
              }}
              onSelectionModelChange={(newSelectionModel) => {
                const selectedIDs = new Set(newSelectionModel);
                const selectedRows = presences.filter((row) =>
                  selectedIDs.has(row.id)
                );

                if (selectedRows.length && selectedRows[0] !== null) {
                  const { id, ...rest } = selectedRows[0];
                  setSelected({ presenceId: id, ...rest });
                } else {
                  setSelected(null);
                }
                setSelectionModel(newSelectionModel);
              }}
            />
          </div>
        </div>
        </div>
      </>
    );
  } else {
    return <>Pas de client sélectionné</>;
  }
}

export default ClientHistorique;
