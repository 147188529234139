import instance from "../../../../shared/components/auth/service/AxiosInstance"

export const CountryService = {

    getCountry: (pageNo, pageSize) =>
        instance.get(process.env.REACT_APP_API_URL + "Country/" +
            '?pageSize=' + pageSize +
            '&pageNo=' + pageNo
        ),

    getCountryById: (id) => instance.get(process.env.REACT_APP_API_URL + "Country/" + id),

    createCountry: (country) =>
        instance.post(process.env.REACT_APP_API_URL + 'Country/',
            {
                name: country?.name,
                code: country?.code,
                deleted: (country?.deleted ? country.deleted : false)
            }
        ),

    updateCountry: (id, country) =>
        instance.put(process.env.REACT_APP_API_URL + 'Country/' + id, 
            {
                countryId: country?.countryId,
                name: country?.name,
                code: country?.code,
                deleted: (country?.deleted ? country.deleted : false)
            }
        ),

    deleteCountry: (id) =>
        instance.delete(process.env.REACT_APP_API_URL + 'Country/' + id
        ),
}