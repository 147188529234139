export const imageProcess = (imgToProcess) => {
    const MAX_WIDTH = 120;
    const MAX_HEIGHT = 120;
    const QUALITY = 1; // 0 ~ 1 (1=100%)

    return new Promise((resolve, reject) => {
        let reader = new FileReader(); 
        reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
                let canvas = document.createElement('canvas');
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                canvas.width = width;
                canvas.height = height;

                canvas.getContext("2d").drawImage(img, 0, 0, width, height);
                let processedImg = canvas.toDataURL('image/jpeg', QUALITY);
                
                resolve(processedImg.split(',')[1]);
            }
            img.src = e.target.result;
        }
        reader.readAsDataURL(imgToProcess);

        reader.onerror = error => reject(error);
    });
}