import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../../redux/features/auth/authSlice";
import { RoleService } from "../../role/service/RoleService";
import { SiteService } from "../../site/service/SiteService";
import { UserService } from "../service/UserService";

import ButtonManager from "../../../shared/components/Button/ButtonManager";

import "./UserListing.css";

import { DataGrid } from "@mui/x-data-grid";

const columns = [
  {
    field: "id",
    headerName: "Id",
    hideable: true,
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "userName",
    headerName: "Login",
    flex: 1,
    width: 150,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "name",
    headerName: "Nom",
    flex: 1,
    width: 250,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "accountEnabled",
    headerName: "Actif",
    align: "center",
    flex: 1,
    width: 200,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    renderCell: (data) =>
      data?.value ? (
        <DoneIcon color="success" />
      ) : (
        <CloseIcon color="warning" />
      ),
  },
  {
    field: "canChangePassword",
    headerName: "Peut modifer son mdp",
    align: "center",
    flex: 1,
    width: 200,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    renderCell: (data) =>
      data?.value ? (
        <DoneIcon color="success" />
      ) : (
        <CloseIcon color="warning" />
      ),
  },
  {
    field: "roleList",
    headerName: "Role(s)",
    flex: 1,
    width: 410,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    renderCell: (data) =>
      (data?.value)
        .map((role) => role.description)
        .sort((role1, role2) => role1.localeCompare(role2))
        .join(", "),
  },
  {
    field: "lastAttempt",
    headerName: "Derniere connexion",
    flex: 1,
    width: 250,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    renderCell:(data) =>
      (data.value != null ? data.value : "Jamais")
  },
];

const forms = [
  { name: "name", label: "Nom", type: "string" },
  { name: "userName", label: "Identifiant", type: "string" },
  { name: "password", label: "Mot de passe", type: "password" },
  { name: "accountEnabled", label: "Actif", type: "checkbox" },
  { name: "canChangePassword", label: "Peut modifier son mot de passe", type: "checkbox" },
];

const UserListing = () => {
  const dispatch = useDispatch();

  const currentPage = 0;
  const pageSize = -1;
  const [rows, setRows] = useState([]);
  const [select, setSelect] = useState(null);
  const [roles, setRoles] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [sites, setSites] = useState([]);
  const [userSites, setUserSites] = useState([]);

  const loadData = () => {
    return UserService.getUsers(currentPage, pageSize)
      .then((response) => {
        const data = response.data.content.sort((user1, user2) =>
          user1.name.localeCompare(user2.name)
        );
        setRows(
          data.map((row) => {
            const { userId, ...rest } = row;
            return { id: userId, ...rest };
          })
        );
        let selected = data.filter((user) => user.userId === select.userId);
        setSelect(selected.length ? selected[0] : null);
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401 
          || err?.response?.status === 403 || err?.response?.status === 401
        ) {
            dispatch(logout());
        }
      });
  };

  useEffect(() => {
    UserService.getUsers(currentPage, pageSize)
      .then((response) => {
        setRows(
          response.data.content
            .sort((user1, user2) => user1?.name?.localeCompare(user2.name))
            .map((row) => {
              const { userId, ...rest } = row;
              return { id: userId, ...rest };
            })
        );
      })
      .catch((err) => {
        console.log(err);
        if (err?.statusCode === 403 || err?.statusCode === 401 
          || err?.response?.status === 403 || err?.response?.status === 401
        ) {
            dispatch(logout());
        }
      });

    RoleService.getRoles(0, 50)
      .then((response) => {
        setRoles(response.data.content);
        setUserRoles(
          response.data.content.map((role) => ({
            key: role.roleId,
            name: "roleList",
            label: role.description,
            type: "checkbox",
            value: JSON.stringify(role),
            defaultChecked:
              select?.roleList?.filter(
                (userRole) => userRole.roleId === role.roleId
              )?.length > 0 || false,
          }))
        );
      })
      .catch((err) => console.log(err));

    SiteService.getSites(0, -1)
      .then((response) => {
        setSites(response.data.content);
        setUserSites(
          response.data.content.map((site) => ({
            key: site.siteId,
            name: "siteList",
            label: site.description,
            type: "checkbox",
            value: JSON.stringify(site),
            defaultChecked:
              select?.siteList?.filter(
                (userSite) => userSite.siteId === site.siteId
              )?.length > 0 || false,
          }))
        );
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (select) {
      setUserRoles(
        roles.map((role) => ({
          key: role.roleId,
          name: "roleList",
          label: role.description,
          type: "checkbox",
          value: JSON.stringify(role),
          defaultChecked:
            select?.roleList?.filter(
              (userRole) => userRole.roleId === role.roleId
            )?.length > 0 || false,
        }))
      );

      setUserSites(
        sites.map((site) => ({
          key: site.siteId,
          name: "siteList",
          label: site.description,
          type: "checkbox",
          value: JSON.stringify(site),
          defaultChecked:
            select?.siteList?.filter(
              (userSite) => userSite.siteId === site.siteId
            )?.length > 0 || false,
        }))
      );
    }
  }, [select, roles, sites]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        textAlign: "center",
        padding: "2%",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Link to="/SettingsSelect">
          <IconButton color='primary'>
            <ArrowBackIosNewIcon />
          </IconButton>
        </Link>
        <h1
          style={{
            fontFamily: "backToSchool",
            fontSize: "65px",
            color: "#bb0000",
            textAlign: "center",
            width: "100%",
          }}
        >
          Utilisateurs
        </h1>
      </div>
      <div className="event-container display-relative">
        <ButtonManager
          isContained={true}
          title={"utilisateur"}
          id={select?.userId}
          selected={select}
          align={"center"}
          forms={[...forms, ...userRoles, ...userSites]}
          createFunction={UserService.createUser}
          updateFunction={UserService.updateUser}
          deleteFunction={UserService.deleteUser}
          requiredFields={["name", "userName", "password"]}
          requiredFieldLabel={["Nom", "Login", "Mot de passe"]}
          refresh={loadData}
        />

        <div className="EventDataGrid">
          <DataGrid
            sx={{ backgroundColor: "#fff" }}
            rows={rows}
            columns={columns}
            autoPageSize={true}
            showCellRightBorder
            rowsPerPageOptions={[pageSize]}
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRowData = rows.filter((row) =>
                selectedIDs.has(row?.id?.toString())
              );
              if (selectedRowData.length) {
                const { id, ...rest } = selectedRowData[0];
                setSelect({ userId: id, ...rest });
              } else {
                setSelect(null);
              }
            }}
            columnVisibilityModel={{
              id: false,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UserListing;
