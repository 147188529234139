import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../../../../redux/features/auth/authSlice";
import ButtonManager from "../../../../../shared/components/Button/ButtonManager";
import LoadButtonDialog from "../../../../../shared/components/Button/LoadButtonDialog";
import Form from "../../../../../shared/components/modals/Form";
import { JobService } from "../../../../job/service/JobService";
import { SiteService } from "../../../../site/service/SiteService";
import { ClientService } from "../../../service/ClientService";
import { ContractService } from "../../../service/SubService/ContractService";
import "./ClientContract.css";

const columns = [
  {
    field: "id",
    header: "Id",
    hideable: true,
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "startDate",
    headerName: "Début",
    headerAlign: "center",
    flex: 1,
    headerClassName: "researchColmun",
    align: "center",
    renderCell: (data) =>
      data.value ? moment(data.value).format("DD-MM-YYYY") : null,
  },
  {
    field: "endDate",
    headerName: "Fin",
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    renderCell: (data) =>
      data.value ? moment(data.value).format("DD-MM-YYYY") : null,
  },
  {
    field: "appointmentDate",
    headerName: "Date de rdv",
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    renderCell: (data) =>
      data.value ? moment(data.value).format("DD-MM-YYYY") : null,
  },
  {
    field: "referrerStemm",
    headerName: "Référent Stemm",
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "referrerSnas",
    headerName: "Référent SNAS",
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "cityArisOnis",
    headerName: "Ville Aris Onis",
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
  },
];

const forms = [
  { name: "startDate", label: "Début", type: "date" },
  { name: "endDate", label: "Fin", type: "date" },
  { name: "appointmentDate", label: "Date de rendez-vous", type: "date" },
  { name: "referrerStemm", label: "Référent Stemm", type: "string" },
  { name: "referrerSnas", label: "Référent Snas", type: "string" },
  { name: "cityArisOnis", label: "Ville Aris Onis", type: "string" },
  { name: "scheduleStart", label: "Horaire de début", type: "time" },
  { name: "scheduleEnd", label: "Horaire de fin", type: "time" },
  { name: "hoursPerWeek", label: "Nb heures/semaine", type: "string" },
  {
    name: "reasonEndContract",
    label: "Raison de fin de contrat",
    type: "string",
  },
  { name: "activityAfterStemm", label: "Activité après Stemm", type: "string" },
];

function ClientContract(props) {
  const { client } = props;
  const dispatch = useDispatch();
  const [contracts, setContracts] = useState(null);
  const [selected, setSelected] = useState(null);
  const [previousData, setPreviousData] = useState(null);

  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 10,
  });
  const [rowCountState, setRowCountState] = useState(0);
  const [selectionModel, setSelectionModel] = useState([]);
  const [defaultPageNo, setDefaultPageNo] = useState(0);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [preSetInputs, setPreSetInputs] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [sites, setSites] = useState([]);

  const refresh = (params) => {
    let { pageNo, pageSize } = params || {
      pageNo: defaultPageNo,
      pageSize: defaultPageSize,
    };
    return ClientService.getClientContract(pageNo, pageSize, client.clientId)
      .then((response) => {
        const data = response.data.content;
        setContracts(
          data.map((row) => {
            const { contractId, ...rest } = row;
            return { id: contractId, ...rest };
          })
        );
        setRowCountState(response.data.totalElements);
        setSelected(
          data.filter(
            (contract) => contract.contractId === selected?.contractId
          )[0]
        );
      })
      .catch((err) => {
        if (
          err?.statusCode === 403 ||
          err?.statusCode === 401 ||
          err?.response?.status === 403 ||
          err?.response?.status === 401
        ) {
          dispatch(logout());
        }
      });
  };

  useEffect(() => {
    if (client != null && contracts != null) {
      var hauteur = document.getElementById("historiqueDataGrid").offsetHeight;
      if (hauteur > defaultPageSize && Math.round((hauteur - 100) / 52.9) > 0) {
        setDefaultPageSize(Math.round((hauteur - 100) / 52.9));
        refresh({
          pageNo: defaultPageNo,
          pageSize: Math.round((hauteur - 100) / 52.9),
        });
      }
    }
  }, [client]);

  useEffect(() => {
    if (client?.clientId) {
      setPreSetInputs({
        client: { ...client },
      });
      refresh({
        pageNo: defaultPageNo,
        pageSize: defaultPageSize,
      });
    }
  }, [client]);

  useEffect(() => {
    if (client != null) {
      JobService.getJobs(0, -1)
        .then((response) => {
          let jobList = response.data.content
            .sort((job1, job2) =>
              job1.description.localeCompare(job2.description)
            )
            .filter(
              (element) =>
                element?.description !== "" && element?.deleted !== true
            )
            .map((row) => {
              const { jobId, description, ...rest } = row;
              return { value: row, name: description };
            });
          setJobs({
            name: "job",
            label: "Poste",
            type: "select",
            required: true,
            values: jobList,
            selected: "",
          });
        })
        .catch((err) => console.log(err));

      SiteService.getSites(0, -1)
        .then((response) => {
          let siteList = response.data.content.sort((job1, job2) =>
            job1?.description?.localeCompare(job2?.description)
          );
          setSites({
            name: "sites",
            label: "sites",
            type: "select",
            values: siteList.map((row) => {
              const { jobId, description, ...rest } = row;
              return { value: row, name: description };
            }),
            selected: "",
          });
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    if (client != null && contracts != null) {
      ContractService.getLastContract(client?.clientId)
        .then(({ status, data }) => {
          let { contractId, startDate, endDate, appointmentDate, ...rest } =
            data;
          setPreviousData({ ...rest });
        })
        .catch((err) => console.log(err));
    }
  }, [contracts]);

  const requiredFields = [
    "job",
    "sites",
    "firstContact",
    "appointmentDate",
    "scheduleStart",
    "scheduleEnd",
    "startDate",
    "endDate",
  ];

  const requiredFieldLabel = [
    "Poste",
    " Site",
    " Premier contact",
    " Date de Rendez-vous ",
    " Horaire de début",
    " Horaire de fin",
    " Début",
    " Fin",
  ];

  if (client != null && contracts != null) {
    return (
      <>
        <ButtonManager
          onlyIcon={true}
          title={"contract"}
          id={selected?.contractId}
          selected={selected}
          align={"center"}
          preSetInputs={{ client }}
          forms={[
            {
              ...jobs,
              selected: selected?.job ? selected.job : jobs?.selected,
            },
            {
              ...sites,
              selected:
                selected?.sites?.length > 0
                  ? selected?.sites[0]
                  : sites?.selected,
            },
            {
              name: "firstContact",
              label: "Premier contact",
              required: true,
              type: "select",
              values: [
                { name: "OK", value: "OK" },
                { name: "ATTENTE", value: "ATTENTE" },
                { name: "NON_RETENU", value: "NON_RETENU" },
              ],
              selected: selected?.firstContact ? selected?.firstContact : "",
            },
            ...forms,
          ]}
          requiredFields={requiredFields}
          requiredFieldLabel={requiredFieldLabel}
          createFunction={ContractService.createContract}
          updateFunction={selected ? ContractService.updateContract : null}
          deleteFunction={selected ? ContractService.deleteContract : null}
          refresh={refresh}
          optionalData={previousData}
        />
        <div className="clientContract">
          <div style={{ width: "100%" }} id="historiqueDataGrid">
            {(contracts?.length > 0 && (
              <DataGrid
                rowsPerPageOptions={[rowsState.pageSize]}
                sx={{ backgroundColor: "#fff", height: "70vh" }}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      id: false,
                    },
                  },
                }}
                columns={[
                  ...columns,
                  {
                    field: "details",
                    headerName: "Détails",
                    flex: 1,
                    headerClassName: "researchColmun",
                    headerAlign: "center",
                    align: "center",
                    renderCell: (params) => {
                      return (
                        <LoadButtonDialog
                          onlyIcon
                          isDisabled={params.row != null ? false : true}
                          title={"Ouvrir"}
                          titleForm={"Détail du contrat"}
                          endIcon={<ReadMoreIcon />}
                          dialogIcon={<ReadMoreIcon color="primary" />}
                          handleSumbitAction={null}
                          form={
                            <Form
                              forms={[
                                {
                                  ...jobs,
                                  selected: selected?.job
                                    ? selected.job
                                    : jobs?.selected,
                                },
                                {
                                  ...sites,
                                  selected:
                                    selected?.sites?.length > 0
                                      ? selected?.sites[0]
                                      : sites?.selected,
                                },
                                {
                                  name: "firstContact",
                                  label: "Premier contact",
                                  required: true,
                                  type: "select",
                                  values: [
                                    { name: "OK", value: "OK" },
                                    { name: "ATTENTE", value: "ATTENTE" },
                                    { name: "NON_RETENU", value: "NON_RETENU" },
                                  ],
                                  selected: selected?.firstContact
                                    ? selected?.firstContact
                                    : "",
                                },
                                ...forms,
                              ]}
                              defaultValue={params.row}
                            />
                          }
                        />
                      );
                    },
                  },
                ]}
                rows={contracts}
                rowCount={rowCountState}
                showCellRightBorder
                pagination
                {...rowsState}
                paginationMode="server"
                selectionModel={selectionModel}
                onPageChange={(page) => {
                  setDefaultPageNo(page);
                  setRowsState((prev) => ({ ...prev, page }));
                  refresh({ pageNo: page, pageSize: defaultPageSize });
                }}
                onPageSizeChange={(pageSize) => {
                  setDefaultPageSize(pageSize);
                  setRowsState((prev) => ({ ...prev, pageSize }));
                  refresh({ pageNo: defaultPageNo, pageSize: pageSize });
                }}
                onSelectionModelChange={(newSelectionModel) => {
                  const selectedIDs = new Set(newSelectionModel);
                  const selectedRows = contracts.filter((row) =>
                    selectedIDs.has(row.id)
                  );

                  if (selectedRows.length && selectedRows[0] !== null) {
                    const { id, ...rest } = selectedRows[0];
                    setSelected({ contractId: id, ...rest });
                  } else {
                    setSelected(null);
                  }
                  setSelectionModel(newSelectionModel);
                }}
              />
            )) || (
              <p>
                {client.firstnametempo} {client.lastnametempo} n'a pas de
                contrat
              </p>
            )}
          </div>
        </div>
      </>
    );
  } else {
    return <>Pas de client sélectionné</>;
  }
}

export default ClientContract;
