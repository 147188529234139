import React, { useEffect } from "react";
import SiteListing from "./pages/SiteListing";

function Site(props) {
    const { setActive } = props;
    useEffect(() => {
      //setActive(3);
    }, []);

    return (
        <>
            <SiteListing />
        </>
    )
}
export default Site;