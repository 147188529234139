import { TextField } from "@material-ui/core";
import PersonPinIcon from '@mui/icons-material/PersonPin';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Jwt } from "../../../shared/components/auth/jwt/Jwt";
import { UserService } from "../service/UserService";
import './UserSettings.css';

import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useDispatch } from "react-redux";
import { logout } from "../../../redux/features/auth/authSlice";

const initState = {
  new1: "",
  new2: ""
}

const UserSettings = (props) => {
  const { setActive } = props;
  useEffect(() => {
//setActive(6);
  }, []);

  const { user: currentUser } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const userData = Jwt(currentUser.token);

  const [msg, setMsg] = useState("");

  const [user, setUser] = useState(null);

  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [passwordData, setPasswordData] = useState(initState);

  const handleOpen = () => {
    setLoading(true);
    setOpen(true);
  }
  const handleClose = () => {
    setLoading(false);
    setOpen(false);
    setPasswordData(initState);
    setMsg("");
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({
      ...passwordData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (passwordData.new1.length && (passwordData.new1 === passwordData.new2)) {
      UserService.updateUserPassword({
        userId: userData?.sub,
        passwordHash: passwordData.new2
      })
        .then(response => handleClose())
        .catch(err => {
          setMsg("Error: password could not be updated.");
          if (err?.statusCode === 403 || err?.statusCode === 401 
            || err?.response?.status === 403 || err?.response?.status === 401
          ) {
              dispatch(logout());
          }
      });
    } else {
      setMsg("Password does not match or password cannot be null");
    }
  }

  useEffect(() => {
    UserService.getUserByUserName(userData?.sub)
    .then(response => {
      setUser(response.data);
    })
    .catch(err => {
      if (err?.statusCode === 403 || err?.statusCode === 401
        || err?.response?.status === 403 || err?.response?.status === 401
      ) {
        dispatch(logout());
      }
    });
  }, []);

  const style = {
    display: "flex",
    width: '100%',
    flexDirection: "column",
    backgroundColor: 'rgba(218, 215, 215, 0.8)',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '400px',
    borderRadius:'10px',
    boxShadow:'-1px 1px 10px #c4c4c4',
    padding: '20px 100px 20px 100px',
    marginTop: '5%',
  }

  return (
    <div style={{ display: "flex", width: '100%', flexDirection: "column", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
      <h1 style={{ marginTop:'3%', minWidth: '300px', fontFamily:'backToSchool', fontSize: '65px', color:'#bb0000' }}>Mon compte</h1>
      <div style={style}>
        <h1 className="text-center my-2"><PersonPinIcon color="primary" sx={{ fontSize: 100 }} /></h1>
        <p><b>Nom d'utilisateur</b> : {userData?.sub}</p>
        <p><b>Nom</b> : {currentUser.username}</p>
        {user?.defaultSite &&
          <p><b>Site par défault</b> : {user?.defaultSite?.description}</p>
        }
        <br />
        {userData.canChangePassword &&
          <LoadingButton
            onClick={handleOpen}
            endIcon={<EditIcon />}
            loading={loading}
            loadingPosition="end"
            variant="contained"
            sx={{ maxWidth: '200px' }}
          >
            Changer de mot de passe
          </LoadingButton>
        }
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" textAlign="center">
          <EditIcon color="success" />
          <br></br>
          Modifier le mot de passe
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              id="new1"
              name="new1"
              type="password"
              label="Nouveau mot de passe"
              variant="outlined"
              value={passwordData.new1}
              onChange={handleInputChange}
            />
            <br />
            <TextField
              id="new2"
              name="new2"
              type="password"
              label="Confirmation du mot de passe"
              variant="outlined"
              value={passwordData.new2}
              onChange={handleInputChange}
            />
          </form>
          <br />
          <p>{msg}</p>
        </DialogContent>
        <DialogActions sx={{ display:'flex', flexDirection:'row', justifyContent:'space-evenly' }}>
          <Button variant="contained" color='error' onClick={handleClose} autoFocus>Annuler</Button>
          <Button variant="contained" onClick={handleSubmit} autoFocus>Mettre à jour</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UserSettings;