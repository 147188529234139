import axios from "axios";
import { authHeader } from "../../../../shared/components/auth/auth-header";

export const VoucherTypeService = {
    getVoucherTypes: (pageNo, pageSize) =>
        axios.get(process.env.REACT_APP_API_URL + 'VoucherType/' + 
            '?pageSize=' + pageSize +
            '&pageNo=' + pageNo,
            { headers: authHeader() }
        ),
}