import React, { useEffect, useState } from "react";

import { TextField } from "@material-ui/core";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@mui/x-data-grid";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../../redux/features/auth/authSlice";
import { StatService } from "../../../service/StatService";

import { ExportDataGridToExcel } from "../../../../export/ExportDataGridToExcel";


const columns = [
  {
    field: "id",
    header: "Id",
    hideable: true,
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "type",
    headerName: "Statut",
    align: "center",
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    renderCell: (data) => data?.value || "Non renseigné",
  },
  {
    field: "number",
    headerName: "Nombre",
    align: "center",
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "male",
    headerName: "Homme",
    align: "center",
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "female",
    headerName: "Femme",
    align: "center",
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "other",
    headerName: "Autre",
    align: "center",
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "percentage",
    headerName: "%",
    align: "center",
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    renderCell: (data) => {
      let n = data.row.percentage
      return n.toFixed(2) + ' %';
    }
  },
  {
    field: "malePercentage",
    headerName: "Hommes %",
    align: "center",
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    renderCell: (data) => {
      let n = data.row.malePercentage
      return n.toFixed(2) + ' %';
    }
  },
  {
    field: "femalePercentage",
    headerName: "Femmes %",
    align: "center",
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    renderCell: (data) => {
      let n = data.row.femalePercentage
      return n.toFixed(2) + ' %';
    }
  },
];

function MaritalStatusStat() {
  const [stat, setStat] = useState();
  const site = useSelector((state) => state.site.site);

  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1;
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  var defaultStart = yyyy + "-01-01";
  var defaultEnd = yyyy + "-12-31";

  const [inputs, setInputs] = useState({
    startDate: defaultStart,
    endDate: defaultEnd,
  });

  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 20,
  });
  const [rowCountState, setRowCountState] = useState(0);
  const [selectionModel, setSelectionModel] = useState([]);
  const [defaultPageNo, setDefaultPageNo] = useState(0);
  const [defaultPageSize, setDefaultPageSize] = useState(20);

  const [focus, setFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const dispatch = useDispatch();

  const refresh = () => {
    if (inputs.startDate == null) inputs.startDate = "";
    if (inputs.endDate == null) inputs.endDate = "";
    StatService.getLegalstatus(inputs.startDate, inputs.endDate, site.siteId)
      .then((results) => {
        setRowCountState(results.data.totalElements || rowCountState);
        setStat(results.data);
      })
      .catch((err) => {
        console.log(err);
        if (err?.statusCode === 403 || err?.statusCode === 401
          || err?.response?.status === 403 || err?.response?.status === 401
        ) {
          dispatch(logout());
        }
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(
      () =>
        refresh({
          inputs: inputs,
        }),
      500
    );
    return () => clearTimeout(timeoutId);
  }, [inputs]);

  const handleChangeStart = (newValue) => {
    if (newValue == null) {
      setInputs({
        dateEvent: null,
        dateLimitInscription: null,
      });
    } else {
      const yyyy = newValue.getFullYear();
      let mm = newValue.getMonth() + 1;
      let dd = newValue.getDate();
      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;
      var datestr = yyyy + "-" + mm + "-" + dd;
      setInputs({
        startDate: datestr,
        endDate: inputs.endDate,
      });
    }
  };

  const handleChangeEnd = (newValue) => {
    if (newValue == null) {
      setInputs({
        dateEvent: null,
        dateLimitInscription: null,
      });
    } else {
      const yyyy = newValue.getFullYear();
      let mm = newValue.getMonth() + 1;
      let dd = newValue.getDate();
      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;
      var datestr = yyyy + "-" + mm + "-" + dd;
      setInputs({
        startDate: inputs.startDate,
        endDate: datestr,
      });
    }
  };

  const resetInput = () => {
    setInputs({
      startDate: null,
      endDate: null,
    });
  }

  if (stat == null) {
    return (
      <>
        <Box textAlign="center" mt={20}>
          <LoadingButton loading={true} variant="outlined" disabled>
            Loading
          </LoadingButton>
        </Box>
      </>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          textAlign: "center",
          padding: "2%",
        }}
      >
        <div className="event-form">
          <h3 style={{ position: 'relative', fontFamily: 'backToSchool', fontSize: '65px', color: '#bb0000' }}>Statistiques d'état civil</h3>
          <div className="row" style={{ marginTop: "50px", alignItems: 'center' }}>
            <div className="col-4">
              <h6 style={{ marginBottom: "0px" }}>Date de début :</h6>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  value={inputs.startDate}
                  inputFormat="dd/MM/yyyy"
                  onChange={handleChangeStart}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  renderInput={(params) => <TextField {...params} />}
                  type={hasValue || focus ? "date" : "text"}
                />
              </LocalizationProvider>
            </div>
            <div className="col-4">
              <div style={{ marginLeft: "10%" }}>
                <h6 style={{ marginBottom: "0px" }}>
                  Date de fin :
                </h6>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    value={inputs.endDate}
                    inputFormat="dd/MM/yyyy"
                    onChange={handleChangeEnd}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    renderInput={(params) => <TextField {...params} />}
                    type={hasValue || focus ? "date" : "text"}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="col-2">
              <div className="clearResearch">
                <Tooltip title="Vider la recherche" placement="top">
                  <button
                    onClick={(e) => {
                      resetInput();
                    }}
                  >
                    <FilterAltOffIcon />
                  </button>
                </Tooltip>
              </div>
            </div>
            <div className="col-2 d-flex justify-content-end align-items-center">
              <ExportDataGridToExcel
                headers={columns}
                data={stat}
                filename={"statistiques_etat_civils"}
              />
            </div>
          </div>
        </div>
        <div className="EventDataGrid">
          <DataGrid
            rowsPerPageOptions={[rowsState.pageSize]}
            sx={{ backgroundColor: "#fff" }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
            columns={columns}
            rows={stat}
            rowCount={rowCountState}
            showCellRightBorder
            pagination
            {...rowsState}
            paginationMode="server"
            checkboxSelection={false}
            selectionModel={selectionModel}
            onSelectionModelChange={(newSelectionModel) => {
              const selectedIDs = new Set(newSelectionModel);
              const selectedRows = stat.filter((row) =>
                selectedIDs.has(row.id)
              );

              //   if (selectedRows[0] != null) {
              //     setSelect(selectedRows[0]);
              //     var selected = {
              //       id: selectedRows[0].id,
              //       description: selectedRows[0].description,
              //       dateEvent: selectedRows[0].dateEvent,
              //       dateLimitInscription: selectedRows[0].dateLimitInscription,
              //       maxParticipant: selectedRows[0].maxParticipant,
              //     };
              //     setSelected(selected);
              //   } else {
              //     setSelect(null);
              //   }

              setSelectionModel(newSelectionModel);
            }}
            onPageChange={(page) => {
              setDefaultPageNo(page);
              setRowsState((prev) => ({ ...prev, page }));
              refresh({
                pageNo: page,
                pageSize: defaultPageSize,
                inputs: inputs,
              });
            }}
            onPageSizeChange={(pageSize) => {
              setDefaultPageSize(pageSize);
              setRowsState((prev) => ({ ...prev, pageSize }));
              refresh({
                pageNo: defaultPageNo,
                pageSize: pageSize,
                inputs: inputs,
              });
            }}
          />
        </div>
      </div>
    );
  }
}

export default MaritalStatusStat;
