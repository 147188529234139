import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../../../../redux/features/auth/authSlice";
import ButtonManager from "../../../../../shared/components/Button/ButtonManager";
import { ClientService } from "../../../service/ClientService";
import { ExclusionService } from "../../../service/SubService/ExclusionService";
import "./ClientExclusion.css";

const columns = [
  {
    field: "id",
    headerName: "Id",
    hideable: true,
    flex: 1,
    defaultVisible: false,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "dateStart",
    headerName: "Début",
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    renderCell: (data) => moment(data.value).format("DD-MM-YYYY"),
    filterEditorProps: (props, { index }) => {
      return {
        dateFormat: "DD-MM-YYYY",
        cancelButton: false,
        highlightWeekends: false,
        placeholder:
          index === 1
            ? "Created date is before..."
            : "Created date is after...",
      };
    },
  },
  {
    field: "dateEnd",
    headerName: "Fin",
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    renderCell: (data) => moment(data.value).format("DD-MM-YYYY"),
    filterEditorProps: (props, { index }) => {
      return {
        dateFormat: "DD-MM-YYYY",
        cancelButton: false,
        highlightWeekends: false,
        placeholder:
          index === 1
            ? "Created date is before..."
            : "Created date is after...",
      };
    },
  },
  {
    field: "remark",
    headerName: "Remarque",
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
  },
];

const forms = [
  { name: "dateStart", label: "Début", type: "date", valueDate: new Date() },
  { name: "dateEnd", label: "Fin", type: "date" },
  { name: "remark", label: "Remarque", type: "string" },
];

function ClientExclusion(props) {
  const { client } = props;

  const dispatch = useDispatch();

  const [exclusions, setExclusions] = useState(null);
  const [selected, setSelected] = useState(null);
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 10,
  });
  const [rowCountState, setRowCountState] = useState(0);
  const [selectionModel, setSelectionModel] = useState([]);
  const [defaultPageNo, setDefaultPageNo] = useState(0);
  const [defaultPageSize, setDefaultPageSize] = useState(10);

  const refresh = (params) => {
    let { pageNo, pageSize } = params || {
      pageNo: defaultPageNo,
      pageSize: defaultPageSize,
    };
    return ClientService.getClientExclusion(pageNo, pageSize, client?.clientId)
      .then((response) => {
        const data = response.data.content;
        setExclusions(
          data.map((row) => {
            const { exclusionId, ...rest } = row;
            return { id: exclusionId, ...rest };
          })
        );
        let selectedExclusion = data.filter(
          (exclusion) => exclusion.exclusionId === selected?.exclusionId
        )[0];
        setSelected(selectedExclusion);
        setRowCountState(response.data.totalElements);
      })
      .catch((err) => {
        if (
          err?.statusCode === 403 ||
          err?.statusCode === 401 ||
          err?.response?.status === 403 ||
          err?.response?.status === 401
        ) {
          dispatch(logout());
        }
      });
  };

  useEffect(() => {
    if (client?.clientId) {
      refresh({
        pageNo: defaultPageNo,
        pageSize: defaultPageSize,
      });
    }
  }, []);

  if (exclusions != null && client != null) {
    return (
      <>
        {exclusions.length === 0
          ? client.firstnametempo + " n'a jamais été exclus"
          : ""}
        <>
          <ButtonManager
            onlyIcon={true}
            title={"exclusion"}
            id={selected?.exclusionId}
            selected={selected}
            align={"center"}
            forms={forms}
            preSetInputs={{ client: { clientId: client.clientId } }}
            createFunction={ExclusionService.createExclusion}
            updateFunction={selected ? ExclusionService.updateExclusion : null}
            deleteFunction={selected ? ExclusionService.deleteExclusion : null}
            requiredFields={["dateStart", "dateEnd"]}
            requiredFieldLabel={["Date de début", "Date de fin"]}
            refresh={refresh}
          />

          <div className="clientHistoric">
            <div style={{ width: "100%" }} id="historiqueDataGrid">
              <DataGrid
                rowsPerPageOptions={[rowsState.pageSize]}
                sx={{ backgroundColor: "#fff", height: "70vh" }}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      id: false,
                    },
                  },
                }}
                columns={columns}
                rows={exclusions}
                rowCount={rowCountState}
                showCellRightBorder
                pagination
                {...rowsState}
                paginationMode="server"
                selectionModel={selectionModel}
                onPageChange={(page) => {
                  setDefaultPageNo(page);
                  setRowsState((prev) => ({ ...prev, page }));
                  refresh({ pageNo: page, pageSize: defaultPageSize });
                }}
                onSelectionModelChange={(newSelectionModel) => {
                  const selectedIDs = new Set(newSelectionModel);
                  const selectedRows = exclusions.filter((row) =>
                    selectedIDs.has(row.id)
                  );

                  if (selectedRows.length && selectedRows[0] !== null) {
                    const { id, ...rest } = selectedRows[0];
                    setSelected({ exclusionId: id, ...rest });
                  } else {
                    setSelected(null);
                  }
                  setSelectionModel(newSelectionModel);
                }}
              />
            </div>
          </div>
        </>
      </>
    );
  } else {
    return <>Pas de client sélectionné</>;
  }
}

export default ClientExclusion;
