import instance from "../../../shared/components/auth/service/AxiosInstance";

export const StatService = {
  getAge: (startDate, endDate, siteId) =>
    instance.get(
      process.env.REACT_APP_API_URL +
      "stat/age?startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&siteId=" +
      siteId
    ),

  getSexe: (startDate, endDate, siteId) =>
    instance.get(
      process.env.REACT_APP_API_URL +
      "stat/sexe?startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&siteId=" +
      siteId
    ),

  getNationality: (startDate, endDate, siteId) =>
    instance.get(
      process.env.REACT_APP_API_URL +
      "stat/nationality?startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&siteId=" +
      siteId
    ),

  getResident: (startDate, endDate, siteId) =>
    instance.get(
      process.env.REACT_APP_API_URL +
      "stat/resident?startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&siteId=" +
      siteId
    ),

  getLegalstatus: (startDate, endDate, siteId) =>
    instance.get(
      process.env.REACT_APP_API_URL +
      "stat/legalstatus?startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&siteId=" +
      siteId
    ),

  getFrequentation: (startDate, endDate, siteId) =>
    instance.get(
      process.env.REACT_APP_API_URL + "stat/frequentation?startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&siteId=" +
      siteId
    ),

  getGrouped: (startDate, endDate) =>
    instance.get(
      process.env.REACT_APP_API_URL +
      "stat/grouped?startDate=" +
      startDate +
      "&endDate=" +
      endDate
    ),
};
