import React, {useState} from "react";
import {
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Box,
} from "@mui/material";
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { dateIsValid } from "../../utils/localeISODateTime";

function Form(props) {
  const { defaultValue, handleChange, forms, inputs } = props;

  const [focus, setFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const [image, setImage] = useState(null);
  const cancelImg = event => setImage(null);

  const handleChangeImg = event => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    } else {
      setImage(null);
    }
    handleChange(event);
  }

  const handleDateChange = (newValue, form) => {
    if ((form.name != null) && (newValue != null)
          && (dateIsValid(newValue))
          && (newValue.getFullYear() > 1000)
    ) {
      form.valueDate = newValue;
    }
    handleChange({target:{name: form.name, value: newValue}});
  }

  const getFormType = (form) => {
    let formTypes = {
      checkbox: () => (
        <div key={form?.label}>
          <FormControlLabel
            control={
              <Checkbox
                key={form?.key ? form.key : form.name}
                autoFocus
                name={form.name}
                defaultChecked={
                  defaultValue
                    ? form.hasOwnProperty("defaultChecked")
                      ? form.defaultChecked
                      : defaultValue
                      ? defaultValue[form.name]
                      : false
                    : false
                }
                onChange={handleChange}
                size="small"
                value={form?.value}
                required={form?.required || false}
              />
            }
            label={form.label}
          />
          <br />
        </div>
      ),
      select: () => (
        <div key={form?.name}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" required={form?.required}>{form.label}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id={form.name}
              name={form.name}
              value={
                (
                  (Array.isArray(inputs?.[form.name]) && (inputs?.[form.name]?.length > 0)) 
                  ? JSON.stringify(inputs?.[form.name]?.[0])
                  : JSON.stringify(inputs?.[form.name]) 
                )
                    || JSON.stringify(form?.default) 
                    || JSON.stringify(form?.selected)
                    || ''
              }
              label={form.label}
              onChange={handleChange}
              type="select"
              required={form?.required || false}
            >
              {(form?.values || form?.options)?.map((option) => (
                <MenuItem key={option.name} value={JSON.stringify(option.value)}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      ),
      date: () => {
        return (
          <div key={form?.name} style={{ marginTop:'4px' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                key={form?.name}
                // autoFocus
                name={form?.name}
                value={inputs?.[form.name] || form?.valueDate || defaultValue?.[form.name] || null}
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {handleDateChange(newValue, form)}}
                onFocus={onFocus}
                onBlur={onBlur}
                renderInput={(params) => <TextField {...params} />}
                type={hasValue || focus ? "date" : "text"}
                label={form.label}
                required={form?.required || false}
              />
            </LocalizationProvider>
          </div>
        )
      },

      fileImage: () => (
        <div key={form?.name}>
          <IconButton color="primary" aria-label="upload picture" component="label" sx={{padding: 0, marginRight: 1}}>
            <input key={form.name} name={form.name} hidden accept="image/*" type="file" onChange={handleChangeImg} />
            <PhotoCamera />
          </IconButton>

          {(image || defaultValue?.[form.name]) &&
            <Box
              component="img"
              sx={{ height: 80, maxHeight: { xs: 80, md: 100 }, }}
              alt={form.name}
              src={image || `data:image/jpeg;base64,${defaultValue?.[form.name]}`}
            />
          }

          {image &&
          <IconButton color="error" aria-label="cancel picture" component="label" onClick={(e) => { cancelImg(e); handleChange({target: {name: form.name, value: defaultValue?.[form.name]}}); }}>
            <BackspaceIcon />
          </IconButton>
          }
        </div>
      ),

      default: () => (
        <TextField
          key={form.name}
          value={
            (inputs?.[form.name] !== undefined)
            ? inputs[form.name]
            : (defaultValue?.[form.name]
              ? defaultValue[form.name] 
              : (form?.defaultValue ? form.defaultValue : '')
              )
          }
          disabled={form?.disabled || false}
          autoFocus
          margin="dense"
          name={form.name}
          label={form.label}
          type={form?.type}
          fullWidth
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          InputProps={{
            autoComplete: "new-password",
            form: {
              autoComplete: "off",
            },
            ...form.inputProps,
          }}
          required={form?.required || false}
        />
      ),
    };
    return (formTypes[form?.type] || formTypes["default"])();
  };

  return <>{forms.map((form) => getFormType(form))}</>;
}

export default Form;
