import React, { useEffect, useState } from "react";
import { ClientGroupService } from "../../../settings/client-group/service/ClientGroupService";
import {
    Box,
    Typography,
    FormGroup,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { useDispatch } from "react-redux";
import { logout } from "../../../../redux/features/auth/authSlice";
import Select from 'react-select';

export const ClientGroupSelector = (props) => {

    const { handleChange, setInputs } = props;

    const dispatch = useDispatch();

    const [groups, setGroups] = useState(null);
    const [option, setOption] = useState(null);

    const handleChangeGroupClient = (e) => {
        setInputs((values) => ({ ...values, ["clientGroups"]: e }));
    }

    useEffect(() => {
        ClientGroupService.getClientGroup(0, -1)
            .then(response => {
                let groups = response.data.content;
                setGroups(response.data.content)
                let array = [];
                for (let i = 0; i < groups.length; i++) {
                    array.push({
                        value:groups[i].description,
                        label:groups[i].description,
                        description:groups[i].description,
                        clientGroupId:groups[i].clientGroupId,
                    })
                }
                setOption(array);
            })
            .catch(err => {
                console.log(err);
                if (err?.statusCode === 403 || err?.statusCode === 401
                    || err?.response?.status === 403 || err?.response?.status === 401
                ) {
                    dispatch(logout());
                }
            });
    }, []);


    return (
        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center', textAlign:'center', alignItems:'center'}}>
            <span style={{ fontSize:'20px', fontFamily:'overlockBold', width:'30%', wordWrap:'none' }}>Groupes clients:</span>
            <div style={{ width:'100%' }}>
                <Select
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isMulti
                    options={option}
                    onChange={handleChangeGroupClient}
                    placeholder={"Sélectioner le(s) groupe(s) clients"}
                />
            </div>
        </Box>
    );
}