import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../../../redux/features/auth/authSlice";
import { DriverLicenceService } from "../service/DriverLicenceService";

import { DataGrid } from "@mui/x-data-grid";
import ButtonManager from "../../../../shared/components/Button/ButtonManager";
//import "./DriverLicenceListing.css";

const columns = [
  { field: "id", headerName: "Id", hideable: true, flex: 1, headerClassName: "researchColmun", headerAlign: "center", align: "center", },
  { field: "code", headerName: "Code", flex: 1, headerClassName: "researchColmun", headerAlign: "center", align: "center", },
  { field: "name", headerName: "Nom", flex: 1, headerClassName: "researchColmun", headerAlign: "center", align: "center", },
  { field: "description", headerName: "Description", flex: 1, headerClassName: "researchColmun", headerAlign: "center", align: "center", },
];

const forms = [
    { name: "code", label: "Code" },
    { name: "name", label: "Nom" },
    { name: "description", label: "Description" },
];

const DriverLicenceListing = () => {
  const dispatch = useDispatch();

  const pageSize = 10;
  const [rows, setRows] = useState([]);
  const [select, setSelect] = useState(null);

  const loadData = () => {
    return DriverLicenceService.getDriverLicence(-1, 0)
      .then((response) => {
        const data = response.data.content.sort((driverlicence1, driverlicence2) =>
          (driverlicence1.code).localeCompare(driverlicence2.code)
        );
        setRows(
          data.map((row) => {
            const { driverLicenceId, ...rest } = row;
            return { id: driverLicenceId, ...rest };
          })
        );
        let selected = data.filter((driverlicence) => driverlicence.driverLicenceId === select.driverLicenceId);
        setSelect(selected.length ? selected[0] : null);
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401 
          || err?.response?.status === 403 || err?.response?.status === 401
        ) {
            dispatch(logout());
        }
      });
  };

  useEffect(() => { loadData() }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', width: '100%' }}>
      <h1 style={{ fontFamily: 'backToSchool', fontSize: '65px', color: '#bb0000', marginTop: '4%' }}>Permis de conduire</h1>
      <div style={{ position: 'absolute', top: 23, left: 270 }}>
        <Link to="/SettingsSelect">
          <IconButton color='primary'>
            <ArrowBackIosNewIcon />
          </IconButton>
        </Link>
      </div>
      <div className="event-container display-relative" style={{ marginTop:'5%' }}>
        <ButtonManager
          isContained={true}
          title={"permis de conduire"}
          id={select?.driverLicenceId}
          selected={select}
          align={"center"}
          forms={forms}
          createFunction={DriverLicenceService.createDriverLicence}
          updateFunction={DriverLicenceService.updateDriverLicence}
          deleteFunction={DriverLicenceService.deleteDriverLicence}
          requiredFields={["name", "description"]}
          requiredFieldLabel={["nom", "Description"]}
          refresh={loadData}
        />

        <div className="EventDataGrid">
          <DataGrid
            rows={rows}
            columns={columns}
            autoPageSize={true}
            showCellRightBorder
            rowsPerPageOptions={[pageSize]}
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRowData = rows.filter((row) =>
                selectedIDs.has(row?.id?.toString())
              );
              if (selectedRowData.length) {
                const { id, ...rest } = selectedRowData[0];
                setSelect({ driverLicenceId: id, ...rest });
              } else {
                setSelect(null);
              }
            }}
            columnVisibilityModel={{
              id: false,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DriverLicenceListing;
