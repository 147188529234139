import React, { useState, useEffect } from "react";
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import Button from '@mui/material/Button';

import { LaundryItemService } from "../services/SubService/LaundryItemService";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";

export const LaundryItem = (props) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const { voucher, voucherLaundryItems, setVoucherLaundryItems } = props;

    const [laundryItems, setLaundryItems] = useState([]);

    const [inputs, setInputs] = useState({});

    const handleChange = (e, item) => {
        const name = e.target.name;
        const value = e.target.value;
        const id = item?.laundryitemId;
        setInputs((values) => ({ ...values, [name]: id + '|' + value + '|' + item?.image }));
    };

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let voucherLaundryList = Object.keys(inputs).map((key, index) =>
            ({
                voucher: {voucherId: (voucher?.voucherId || null)},
                laundryItem: {
                    description: key,
                    laundryitemId: inputs[key]?.split('|')[0],
                    image: inputs[key]?.split('|')[2]
                },
                quantity: inputs[key]?.split('|')[1]
            })
        );
        setVoucherLaundryItems(voucherLaundryList);
        handleClose();
    };

    useEffect(() => {
        LaundryItemService.getLaundryItems(0, -1)
            .then(response => setLaundryItems(
                response.data.content
                    ?.filter(element => element?.description !== '' && element?.deleted !== true)
                    ?.sort((item1, item2) => item1.orderItem - item2.orderItem)))
            .catch(err => console.log(err))
    }, []);

    useEffect(() => {
        setInputs(voucherLaundryItems?.reduce((obj, item) => ({...obj, [item.laundryItem.laundryitemId]: item.quantity}), {}));
    }, [voucherLaundryItems]);

    return (
        <>
        <h6>Liste des vêtements
            <IconButton
                aria-label="Ajouter un vêtement"
                onClick={() => setOpen(true)}
                sx={{ color: '#1976d2' }}
            >
                <PlaylistAddIcon />
            </IconButton>
        </h6>

        {(voucherLaundryItems?.length > 0) &&
            <Box>
                <ul className="voucher-items">
                {voucherLaundryItems?.map(voucherLaundryItem => {
                    if (voucherLaundryItem?.quantity) {
                        return (
                            <li key={voucherLaundryItem.laundryItem.laundryitemId}>
                                {/*
                                <Box
                                    component="img"
                                    sx={{ maxWidth: 35 }}
                                    alt=""
                                    src={`data:image/jpeg;base64,${voucherLaundryItem?.laundryItem?.image}`}
                                />
                                */}
                                <span>{voucherLaundryItem.laundryItem.description}  ({voucherLaundryItem.quantity})</span>
                            </li>
                        );
                    } else {
                        return null;
                    }
                })}
                </ul>
            </Box>
        }

        <Dialog fullScreen={fullScreen} onClose={handleClose} open={open}>
            <DialogTitle><span>Liste des articles de buanderie</span>

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

            </DialogTitle>
            <DialogContent>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                    sx={{textAlign: 'center'}}
                >
                    {laundryItems?.map(item =>{
                        return (
                            <Box key={item?.laundryitemId} sx={{ display: 'flex', lineHeight: 35, maxHeight: 35 }}>
                                <TextField
                                    key={item?.laundryitemId}
                                    label={item?.description}
                                    name={item?.description}
                                    type="number"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    onChange={(e) => {handleChange(e, item)}}
                                    defaultValue={voucherLaundryItems?.filter(voucherLaundryItem => voucherLaundryItem.laundryItem?.description === item.description)[0]?.quantity}
                                />
                                <Box
                                    component="img"
                                    sx={{ maxWidth: 35 }}
                                    alt=""
                                    src={`data:image/jpeg;base64,${item?.image}`}
                                />
                            </Box>
                        )}
                    )}
                    <Button type="submit" onClick={handleSubmit}>Ajouter les vêtements sélectionnés</Button>
                </Box>
            </DialogContent>
        </Dialog>
        </>
    );
}