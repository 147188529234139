import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from "react";
import { VoucherForm } from "./VoucherForm";

export const VoucherModal = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const { handleClose, voucher, presence, open, refresh, today, setVoucher } = props;

    const [openPreviewPdf, setOpenPreviewPdf] = useState(false);

    const handleOpenPreviewPdf = () => {
        setOpenPreviewPdf(true);
    }

    const handleClosePreviewPdf = () => {
        setOpenPreviewPdf(false);
    }

    return (
        <>
            <Dialog fullScreen={fullScreen} onClose={handleClose} open={open}>
                <DialogTitle style={{ fontFamily:'backToSchool', fontSize: '65px', color:'#bb0000', textAlign:'center' }}>
                    {voucher?.voucherType?.description}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <h2 style={{textAlign:'center'}}>{voucher?.presence?.fullName}</h2>
                    <VoucherForm
                        voucher={voucher}
                        presence={presence}
                        handleClose={handleClose}
                        handleClosePreviewPdf={handleClosePreviewPdf}
                        openPreviewPdf={openPreviewPdf}
                        refresh={refresh}
                        today={today}
                        setVoucher={setVoucher}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}
