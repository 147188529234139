import instance from "../../../../shared/components/auth/service/AxiosInstance"

export const AccomodationService = {

    getAccomodation: (pageNo, pageSize) =>
        instance.get(process.env.REACT_APP_API_URL + "Accomodation/" +
            '?pageSize=' + pageSize +
            '&pageNo=' + pageNo
        ),

    getAccomodationById: (id) => 
        instance.get(process.env.REACT_APP_API_URL + "Accomodation/" + id),

    createAccomodation: (accomodation) =>
        instance.post(process.env.REACT_APP_API_URL + 'Accomodation/',
        accomodation
        ),

    updateAccomodation: (id, accomodation) =>
        instance.put(process.env.REACT_APP_API_URL + 'Accomodation/' + id, 
        accomodation
        ),

    deleteAccomodation: (id) =>
        instance.delete(process.env.REACT_APP_API_URL + 'Accomodation/' + id
        ),
}