import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import IconButton from '@mui/material/IconButton';
import { logout } from "../../../../redux/features/auth/authSlice";
import { AgeGroupService } from "../service/AgeGroupService";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TextField from "@material-ui/core/TextField";
import Button from "@mui/material/Button";
import "./AgeGroupListing.css";

const AgeGroupListing = (props) => {

    const dispatch = useDispatch();
    const [settings, setSettings] = useState(null);
    const [newValue, setNewValue] = useState('');

    const handleChangeNewValue = event => setNewValue(event.target.value);

    const handleDel = valueToDelete => {
        let filteredList = settings?.val?.split('||')?.filter(setting => setting !== valueToDelete);
        setSettings({...settings, val: filteredList.join('||')});
    }

    const handleAdd = () => {
        let values = settings?.val?.split('||');
        values.push(newValue);
        setNewValue("");
        setSettings({...settings, val: values?.sort((a, b) => a.localeCompare(b)).join('||')});
    }

    const handleSave = () => {
        AgeGroupService.updateAgeGroup(settings?.settingId, settings)
            .then(response => setSettings(response.data))
            .catch(err => console.log(err));
    }

    useEffect(() => {
        AgeGroupService.getAgeGroups()
            .then(response => setSettings(response.data))
            .catch(err => {
                if (err?.statusCode === 403 || err?.statusCode === 401
                    || err?.response?.status === 403 || err?.response?.status === 401
                ) {
                    dispatch(logout());
                }
            });
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', width: '100%' }}>
            <h1 style={{ fontFamily: 'backToSchool', fontSize: '65px', color: '#bb0000', marginTop: '4%' }}>Tranches d'âges</h1>
            <div className="event-container display-relative" style={{ marginTop: '5%' }}>

                <div style={{ position: 'absolute', top: 23, left: 270 }}>
                    <Link to="/SettingsSelect">
                        <IconButton color='primary'>
                            <ArrowBackIosNewIcon />
                        </IconButton>
                    </Link>
                </div>
                <ul className="list-settings">
                    {settings?.val && (settings?.val?.split('||'))?.map(settingValue =>
                        <li key={settingValue}>
                            <span>{settingValue}</span>
                            <DeleteForeverIcon
                                sx={{ color: "red", cursor: "pointer" }}
                                onClick={() => handleDel(settingValue)}
                            />
                        </li>
                    )}
                    <li key={"new"}>
                        <TextField
                            id="newSetting"
                            name="newSetting"
                            type="text"
                            variant="standard"
                            size="medium"
                            placeholder="Nouvelle valeur"
                            value={newValue}
                            onChange={handleChangeNewValue}
                        />
                        <AddCircleIcon
                            sx={{ color: "#307558", cursor: "pointer" }}
                            onClick={handleAdd}
                        />
                    </li>
                </ul>

                <Button onClick={handleSave} variant="contained">
                    Enregistrer les modifications
                </Button>
            </div>
        </div>
    );
}

export default AgeGroupListing;