import React, { useEffect } from "react";
import ClientGroupListing from "./pages/ClientGroupListing";

function ClientGroup(props) {
    const { setActive } = props;
    useEffect(() => {
      //setActive(3);
    }, []);

    return <ClientGroupListing />
};

export default ClientGroup;