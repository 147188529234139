import instance from "../../../../shared/components/auth/service/AxiosInstance";

export const ContractService = {

    getLastContract: (clientId) =>
        instance.get(process.env.REACT_APP_API_URL + 'contract/last/client/' + clientId),

    createContract: (Contract) =>
        instance.post(process.env.REACT_APP_API_URL + 'contract/', 
            {
                ...Contract,
                sites: (Array.isArray(Contract?.sites) ? Contract.sites : [Contract?.sites]),
                siteNotRetained: Contract?.siteNotRetained,
            }
        ),

    updateContract: (id, Contract) =>
        instance.put(process.env.REACT_APP_API_URL + 'contract/' + id, 
            {
                ...Contract,
                sites: (Array.isArray(Contract?.sites) ? Contract.sites : [Contract?.sites]),
                siteNotRetained: Contract?.siteNotRetained,
            }
        ),

    deleteContract: (id) =>
        instance.delete(process.env.REACT_APP_API_URL + 'contract/' + id
        ),
}