import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { TextField } from "@mui/material";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MoreClientInfo from './moreClientInfo';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import "./ClientPartialInformation.css";

function ClientPartialinformations(props) {
  const { client } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="partialInformation">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <h3 style={{ fontFamily: 'overlockBold' }}>
            Informations
          </h3>
          {client != null &&
            <>
              <Tooltip title="Plus d'informations">
                <IconButton sx={{ color: '#1976d2' }} onClick={() => setOpen(true)}>
                  <ReadMoreIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Aller à la page client">
                <Link to="/Clients" state={client}>
                  <IconButton sx={{ color: '#1976d2' }}>
                    <SupervisorAccountIcon />
                  </IconButton>
                </Link>
              </Tooltip>
            </>
          }
        </div>
        <TextField
          value={client?.firstnametempo || ""}
          color="primary"
          variant="outlined"
          name="firstnametempo"
          label="Prénom"
          disabled={true}
        />
        <TextField
          value={client?.lastnametempo || ""}
          color="primary"
          variant="outlined"
          name="lastnametempo"
          label="Nom"
          disabled={true}
        />
        <TextField
          value={client?.clientGroup?.description || ""}
          color="primary"
          variant="outlined"
          name="clientGroup"
          label="Group Client"
          disabled={true}
        />
        <TextField
          value={client ? client.balance : ""}
          color="primary"
          variant="outlined"
          name="balance"
          label="Balance"
          disabled={true}
        />
      </div>
      {client != null &&
        <MoreClientInfo
          open={open}
          setOpen={setOpen}
          client={client}
        />
      }
    </>
  );
}

export default ClientPartialinformations;
