import React from 'react';
import LoginShared from '../../shared/components/auth/page/Login';
import { useSelector } from "react-redux";

function Login() {
    const site = useSelector((state) => state.site.site) || null;
    let backGroundColor ="linear-gradient(240deg, #003366 0%, #ccccff 100%)"
    let navigateTo = ("/SiteSelect");
    return (
        <>
            <LoginShared
                backGroundColor={backGroundColor}
                navigateTo={navigateTo}
            />
        </>
    )
}

export default Login;