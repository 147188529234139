import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../../../redux/features/auth/authSlice";
import { NationalityService } from "../service/NationalityService";

import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { DataGrid } from "@mui/x-data-grid";
import ButtonManager from "../../../../shared/components/Button/ButtonManager";
//import "./NationalityListing.css";

const columns = [
  { field: "id", headerName: "Id", hideable: true, flex: 1, headerClassName: "researchColmun", headerAlign: "center", align: "center", },
  { field: "description", headerName: "Description", flex: 1, headerClassName: "researchColmun", headerAlign: "center", align: "center", },
  { field: "code", headerName: "Code", flex: 1, headerClassName: "researchColmun", headerAlign: "center", align: "center", },
  { field: "deleted", headerName: "Actif", flex: 1, headerClassName: "researchColmun", headerAlign: "center", align: "center", 
      renderCell: (data) => (data.value === false) ? <DoneIcon color="success" /> : <CloseIcon color="warning" /> 
  },
];

const forms = [
  { name: "description", label: "Description", },
  { name: "code", label: "Code", },
  { name: "deleted", label: "Supprimé", type: "checkbox" },
];

const NationalityListing = () => {
  const dispatch = useDispatch();

  const pageSize = 10;
  const [rows, setRows] = useState([]);
  const [select, setSelect] = useState(null);

  const loadData = () => {
    return NationalityService.getNationality(-1, 0)
      .then((response) => {
        const data = response.data.content.sort((a, b) => {
            if(a.description === "" || a.description === null) return 1;
            if(b.description === "" || b.description === null) return -1;
            if(a.description === b.description) return 0;
            return a.description.localeCompare(b.description);
        });
        setRows(
          data.map((row) => {
            const { nationalityId, ...rest } = row;
            return { id: nationalityId, ...rest };
          })
        );
        let selected = data.filter((nationality) => nationality.nationalityId === select.nationalityId);
        setSelect(selected.length ? selected[0] : null);
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401
          || err?.response?.status === 403 || err?.response?.status === 401
        ) {
            dispatch(logout());
        }
      });
  };

  useEffect(() => { loadData() }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', width: '100%' }}>
      <h1 style={{ fontFamily: 'backToSchool', fontSize: '65px', color: '#bb0000', marginTop: '4%' }}>Nationalités</h1>

      <div style={{ position: 'absolute', top: 23, left: 270 }}>
        <Link to="/SettingsSelect">
        <IconButton color='primary'>
          <ArrowBackIosNewIcon />
        </IconButton>
      </Link>
    </div>
      <div className="event-container display-relative" style={{ marginTop:'5%' }}>
        <ButtonManager
          isContained={true}
          title={"nationalité"}
          id={select?.nationalityId}
          selected={select}
          align={"center"}
          forms={forms}
          createFunction={NationalityService.createNationality}
          updateFunction={NationalityService.updateNationality}
          deleteFunction={NationalityService.deleteNationality}
          requiredFields={["description", "code"]}
          requiredFieldLabel={["Description", "Code"]}
          refresh={loadData}
        />

        <div className="EventDataGrid">
          <DataGrid
            rows={rows}
            columns={columns}
            autoPageSize={true}
            showCellRightBorder
            rowsPerPageOptions={[pageSize]}
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRowData = rows.filter((row) =>
                selectedIDs.has(row?.id?.toString())
              );
              if (selectedRowData.length) {
                const { id, ...rest } = selectedRowData[0];
                setSelect({ nationalityId: id, ...rest });
              } else {
                setSelect(null);
              }
            }}
            columnVisibilityModel={{
              id: false,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NationalityListing;
