import instance from "../../../../shared/components/auth/service/AxiosInstance"

export const AgeGroupService = {

    getAgeGroups: () =>
        instance.get(process.env.REACT_APP_API_URL + "Settings/name/AgeGroup"),

    updateAgeGroup: (id, settings) =>
        instance.put(process.env.REACT_APP_API_URL + 'Settings/' + id, 
            settings
        ),
}