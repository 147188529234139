import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState } from "react";
import { VoucherForm } from "../../../modals/VoucherForm";
import { VoucherTypeList } from "../../../modals/VoucherTypeList";

const steps = ["Sélection du type de bon", "Informations"];

function Bon(props) {
  const { presence, refresh, handleChangeIndex, today } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [voucher, setVoucher] = useState(null);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () =>
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  const handleReset = () => setActiveStep(0);

  function handleClick() {
    setOpen(true);
    setLoading(true);
  }

  const handleClose = () => {
    handleReset();
    setOpen(false);
    setLoading(false);
    setVoucher(null);
  };

  if (presence == null) {
    return <>Selectionner une présence pour pouvoir ajouter un bon</>;
  } else {
    return (
      <>
        {(activeStep > 0 &&
          <div style={{ position: 'absolute', top: 23 }}>
            <IconButton color='primary' onClick={(e) => {
              e.preventDefault();
              handleBack();
            }}>
              <ArrowBackIosNewIcon />
            </IconButton>
          </div>
        )}
        {voucher?.voucherType?.description || "Ajout d'un bon "} pour{" "}
        {presence?.fullName}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <br />
        {activeStep === 0 && (
          <VoucherTypeList
            handleNext={handleNext}
            voucher={voucher}
            setVoucher={setVoucher}
            client={presence?.client}
            today={today}
          />
        )}
        {activeStep === 1 && (
          <VoucherForm
            handleChangeIndex={handleChangeIndex}
            voucher={voucher}
            setVoucher={setVoucher}
            presence={presence}
            formAction={"add"}
            handleClose={handleClose}
            refresh={refresh}
            today={today}
          />
        )}
      </>
    );
  }
}

export default Bon;