import { Box, Grid } from "@material-ui/core";
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { logout } from "../../../redux/features/auth/authSlice";
import { select } from "../../../redux/features/site/siteSlice";
import { Jwt } from "../../../shared/components/auth/jwt/Jwt";
import { UserService } from "../../user/service/UserService";
import { SiteService } from "../service/SiteService";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(3),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const SiteSelect = (props) => {
  const { setActive } = props;
  useEffect(() => {
    //setActive(7);
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { site: selectedSite } = useSelector((state) => state.site);
  const [user, setUser] = useState(null);
  const { user: currentUser } = useSelector((state) => state?.auth) || null;
  const userData = Jwt(currentUser?.token);

  const [sites, setSites] = useState([]);
  const [defaultSite, setDefaultSite] = useState(null);
  const [checked, setChecked] = useState(false);

  const handleSiteSelect = (siteId) => {
    SiteService.getSite(siteId)
      .then(response => {
        dispatch(select(siteId)).then((r) => {
          navigate("/");
        });
      })
      .catch(err => {
        if (err?.statusCode === 403 || err?.statusCode === 401
          || err?.response?.status === 403 || err?.response?.status === 401
        ) {
          dispatch(logout());
        }
      });
  }

  useEffect(() => {
    SiteService.getSites(0, -1)
      .then(response => setSites(
        response.data.content
          ?.filter(element => element?.description !== '' && element?.deleted !== true)
          ?.sort((site1, site2) => site1.description.localeCompare(site2.description))
      ))
  }, []);

  useEffect(() => {
    UserService.getUserByUserName(userData?.sub)
      .then(response => {
        setUser(response.data);
        if (response.data.defaultSite === null
          || (selectedSite != null && selectedSite.siteId != response?.data?.defaultSite?.siteId)) {
          setChecked(false);
        } else {
          setChecked(true);
          if (selectedSite === null && response.data.defaultSite !== null) {
            handleSiteSelect(response.data.defaultSite.siteId);
          }
        }
      })
      .catch(err => {
        if (err?.statusCode === 403 || err?.statusCode === 401
          || err?.response?.status === 403 || err?.response?.status === 401
        ) {
          dispatch(logout());
        }
      });
  }, []);

  const handleChange = (e) => {
    if (selectedSite !== null && checked === false) {
      setDefaultSite(selectedSite);
      user.defaultSite = selectedSite;
    } else {
      setDefaultSite(null);
      user.defaultSite = null;
    }
    setChecked(!checked);
    UserService.updateUser(user.userId, user);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%", textAlign: "center", padding: '2%' }}>
      <h1 className="mb-4 text-center" style={{ fontFamily: 'backToSchool', fontSize: '65px', color: '#bb0000' }}>Sélection de Site</h1>
      <Box className="mx-auto w-75" sx={{ flexGrow: 1, marginTop: '7%' }}>
        <Grid container spacing={1}>
          {
            sites?.map(site =>
              <Grid key={site.siteId} item xs onClick={() => handleSiteSelect(site.siteId)}>
                <Button variant="contained" sx={{ width: '240px', margin: '2% 0%' }} color={((site?.description.localeCompare(selectedSite?.description) === 0) ? "success" : "primary")}>
                  {site.description}
                </Button>
              </Grid>
            )
          }
        </Grid>
      </Box>
      <div style={{ position: 'absolute', bottom: 10, left: 300 }}>
        <FormControlLabel control={<Checkbox checked={checked} disable={selectedSite === null ? "true" : "false"} />} label="Définir par défault" onChange={handleChange} />
      </div>
    </div>
  );
}

export default SiteSelect;