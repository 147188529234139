import React from "react";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import { darken, lighten, useTheme } from "@mui/material/styles";

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);



export const ColorLegend = (props) => {

    const theme = useTheme();

    return (
        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography>Légende des couleurs</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Box 
                    sx={{ 
                        backgroundColor: getBackgroundColor(
                        theme.palette.primary.light,
                            theme.palette.mode
                        ), 
                        height: '20px', width: '30px', marginRight: '10px'
                    }}
                >
                </Box>
                <span>Première inscription</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Box 
                    sx={{ 
                        backgroundColor: getBackgroundColor(
                            theme.palette.success.light,
                            theme.palette.mode
                        ), 
                        height: '20px', width: '30px', marginRight: '10px'
                    }}
                >
                </Box>
                <span>Présent(e)</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Box 
                    sx={{ 
                        backgroundColor: getBackgroundColor(
                            theme.palette.secondary.light,
                            theme.palette.mode
                        ), 
                        height: '20px', width: '30px', marginRight: '10px'
                    }}
                >
                </Box>
                <span>TUC (non-présent(e))</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Box 
                    sx={{ 
                        backgroundColor: getBackgroundColor(
                            theme.palette.warning.main,
                            theme.palette.mode
                        ), 
                        height: '20px', width: '30px', marginRight: '10px'
                    }}
                >
                </Box>
                <span>A déjà été exclu(e)</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Box 
                    sx={{ 
                        backgroundColor: getBackgroundColor(
                            theme.palette.error.main,
                            theme.palette.mode
                        ), 
                        height: '20px', width: '30px', marginRight: '10px'
                    }}
                >
                </Box>
                <span>Exclusion en cours</span>
            </div>
            </AccordionDetails>
        </Accordion>
    );
}