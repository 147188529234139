import React, { useState } from "react";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SearchIcon from "@mui/icons-material/Search";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import Bon from "./pages/tabs/Bon/Bon";
import Planning from "./pages/tabs/Planning/Planning";
import Information from "./pages/tabs/PresenceInformation/Information";
import EventListing from "../event/pages/EventListing";
import Transaction from "./pages/tabs/Transaction/Transaction";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 5 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function PresenceTab(props) {
  const theme = useTheme();
  const {
    client,
    handleSelectClient,
    refresh,
    handleDrawer,
    addNewPresence,
    setAddNewPresence,
    today,
    presences,
    setPresences,
    setToday,
  } = props;

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const [voucher, setVoucher] = useState(null);
  const [selected, setSelected] = useState();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setVoucher(null);
  };

  return (
    <Box style={{ height: "100%", width: "100%", margin: "0 auto" }}>
      <AppBar position="relative" sx={{display:'block-flex', alignItems:'center'}}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="scrollable"
        >
          <Tab
            icon={<PersonPinIcon />}
            iconPosition="start"
            label="Presence"
            {...a11yProps(0)}
          />
          <Tab
            icon={<CalendarTodayIcon />}
            iconPosition="start"
            label="Planning"
            {...a11yProps(1)}
          />
          <Tab
            icon={<ConfirmationNumberIcon />}
            iconPosition="start"
            label="Bon"
            {...a11yProps(2)}
          />
          <Tab
            icon={<LocalActivityIcon />}
            iconPosition="start"
            label="Evènement"
            {...a11yProps(3)}
          />
          <Tab
            icon={<ReceiptLongIcon />}
            iconPosition="start"
            label="Transaction"
            {...a11yProps(4)}
          />
        </Tabs>
        <Tooltip title="Rechercher un client">
          <IconButton onClick={handleDrawer} sx={{ color: "#bc270d" }}>
            <SearchIcon />
          </IconButton>
        </Tooltip>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
        style={{ height: "100%", width: "100%" }}
      >
        <TabPanel
          value={value}
          index={0}
          dir={theme.direction}
          style={{ height: "100%", width: "100%" }}
        >
          <h1
            style={{
              fontFamily: "backToSchool",
              fontSize: "65px",
              color: "#bb0000",
            }}
          >
            Presence
          </h1>
          <Information
            client={client}
            handleSelectClient={handleSelectClient}
            addNewPresence={addNewPresence}
            setAddNewPresence={setAddNewPresence}
            voucher={voucher}
            setVoucher={setVoucher}
            open={open}
            handleChangeIndex={handleChangeIndex}
            setOpen={setOpen}
            handleClose={handleClose}
            selected={selected}
            setSelected={setSelected}
            today={today}
            presences={presences}
            setPresences={setPresences}
            setToday={setToday}
          />
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          dir={theme.direction}
          style={{ height: "100%", width: "100%" }}
        >
          <h1
            style={{
              fontFamily: "backToSchool",
              fontSize: "65px",
              color: "#bb0000",
            }}
          >
            Planning
          </h1>
          <Planning today={today} />
        </TabPanel>
        <TabPanel
          value={value}
          index={2}
          dir={theme.direction}
          style={{ height: "100%", width: "100%" }}
        >
          <h1
            style={{
              fontFamily: "backToSchool",
              fontSize: "65px",
              color: "#bb0000",
            }}
          >
            Bon(s)
          </h1>
          <Bon
            handleClose={handleClose}
            handleChangeIndex={handleChangeIndex}
            voucher={voucher}
            presence={selected}
            open={open}
            refresh={refresh}
            today={today}
          />
        </TabPanel>
        <TabPanel
          value={value}
          index={3}
          dir={theme.direction}
          style={{ height: "100%", width: "100%" }}
        >
          <EventListing presence={selected} />
        </TabPanel>
        <TabPanel
          value={value}
          index={4}
          dir={theme.direction}
          style={{ height: "100%", width: "100%" }}
        >
          <Transaction today={today} />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}

export default PresenceTab;
