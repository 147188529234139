import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import {
  Document,
  Image,
  PDFDownloadLink,
  PDFViewer,
  Page,
  Text,
} from "@react-pdf/renderer";
import React, { useEffect, useRef, useState } from "react";
import "../../../shared/components/scheduler/Scheduler.css";
import { DateYmdToDmY } from "../../../shared/utils/localeISODateTime";
import { LaundryItemService } from "../services/SubService/LaundryItemService";
import Laundry_bon from "./Laundry_bon.jpg";
import Vestiaire_bon from "./Vestiaire_bon.jpg";
import LogoStemm from "./logoStemm.png";

var date = new Date().getDate();
if (date.toString().length === 1) {
  var date = "0" + date.toString();
}
var month = new Date().getMonth() + 1;
if (month.toString().length === 1) {
  var month = "0" + month.toString();
}
var year = new Date().getFullYear();
var hours = new Date().getHours();
if (hours.toString().length === 1) {
  var hours = "0" + hours.toString();
}
var min = new Date().getMinutes();
if (min.toString().length === 1) {
  var min = "0" + min.toString();
}

const formatDate = (date) => {
  let yyyy = date.split("-")[0];
  let mm = date.split("-")[1];
  let dd = date.split("-")[2];
  return dd + "/" + mm + "/" + yyyy;
};

function PlanningPdf(props) {
  const { voucherType, events, rdvDate, site, itemList } = props;

  if (
    voucherType?.voucherType === "Shower" ||
    voucherType?.voucherType === "Lockerroom" ||
    voucherType?.voucherType === "Laundry"
  ) {
    return (
      <Document>
        <Page size="A4">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "5%",
            }}
          >
            <Image src={LogoStemm} style={{ width: "25%", height: "65px" }} />
            <Text
              style={{ textAlign: "center", fontSize: "8px", margin: "5%" }}
            >
              le, {date}/{month}/{year}, {hours}:{min}
            </Text>
          </div>

          <Text
            style={{
              fontWeight: "bold",
              margin: "5px 25px",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Planning {voucherType?.description.substr(4)} du{" "}
            {formatDate(rdvDate)}
          </Text>
          {itemList.map((i) => {
            if (i.length > 0) {
              return (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "20px",
                      textAlign: "center",
                      justifyContent: "space-around",
                      border: "1px solid #000",
                      margin: "40px 20px 0px 20px",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "bold",
                        margin: "5px 25px",
                        fontSize: "14px",
                      }}
                    >
                      Heure de RDV
                    </Text>
                    <Text
                      style={{
                        fontWeight: "bold",
                        margin: "5px 25px",
                        fontSize: "14px",
                      }}
                    >
                      Nom complet
                    </Text>
                    <Text
                      style={{
                        fontWeight: "bold",
                        margin: "5px 25px",
                        fontSize: "14px",
                      }}
                    >
                      N°
                    </Text>
                  </div>
                  {i.map((event) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          textAlign: "center",
                          justifyContent: "space-around",
                          border: "1px solid #000",
                          borderTop: "none",
                          margin: "0px 20px 0px 20px",
                        }}
                      >
                        <Text
                          style={{
                            fontWeight: "bold",
                            margin: "5px 25px",
                            fontSize: "10px",
                            width: "100%",
                          }}
                        >
                          {event?.rdvHour}
                        </Text>
                        <Text
                          style={{
                            fontWeight: "bold",
                            margin: "5px 25px",
                            fontSize: "10px",
                            width: "100%",
                          }}
                        >
                          {event?.presence?.fullName}
                        </Text>
                        <Text
                          style={{
                            fontWeight: "bold",
                            margin: "5px 25px",
                            fontSize: "10px",
                            width: "100%",
                          }}
                        >
                          {event?.num}
                        </Text>
                      </div>
                    );
                  })}
                </>
              );
            }
          })}
        </Page>
      </Document>
    );
  } else {
    {
      events.sort((a, b) => (a.rdvHour > b.rdvHour ? 1 : -1));
    }
    return (
      <Document>
        <Page>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "5%",
            }}
          >
            <Image src={LogoStemm} style={{ width: "25%", height: "65px" }} />
            <Text
              style={{ textAlign: "center", fontSize: "8px", margin: "5%" }}
            >
              le, {date}/{month}/{year}, {hours}:{min}
            </Text>
          </div>

          <Text
            style={{
              fontWeight: "bold",
              margin: "5px 25px",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Planning {voucherType?.description.substr(4)} du{" "}
            {formatDate(rdvDate)}
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "20px",
              textAlign: "center",
              justifyContent: "space-around",
              border: "1px solid #000",
              margin: "40px 20px 0px 20px",
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                margin: "5px 25px",
                fontSize: "14px",
              }}
            >
              Heure de RDV
            </Text>
            <Text
              style={{
                fontWeight: "bold",
                margin: "5px 25px",
                fontSize: "14px",
              }}
            >
              Nom complet
            </Text>
          </div>
          {events.map((event) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "center",
                  justifyContent: "space-around",
                  border: "1px solid #000",
                  borderTop: "none",
                  margin: "0px 20px 0px 20px",
                }}
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    margin: "5px 25px",
                    fontSize: "10px",
                    width: "100%",
                  }}
                >
                  {event?.rdvHour}
                </Text>
                <Text
                  style={{
                    fontWeight: "bold",
                    margin: "5px 25px",
                    fontSize: "10px",
                    width: "100%",
                  }}
                >
                  {event?.presence?.fullName}
                </Text>
              </div>
            );
          })}
        </Page>
      </Document>
    );
  }
}

function EventPdf(props) {
  const { clients, event } = props;

  if (clients !== null) {
    let countPage = 1;
    return (
      <Document>
        <Page>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "5%",
            }}
          >
            <Image src={LogoStemm} style={{ width: "25%", height: "65px" }} />
            <Text
              style={{ textAlign: "center", fontSize: "8px", margin: "5%" }}
            >
              le, {date}/{month}/{year}, {hours}:{min}
            </Text>
          </div>

          <Text style={{ textAlign: "center", fontSize: "35px" }}>
            {event?.description}
          </Text>
          <Text style={{ textAlign: "center", fontSize: "20px" }}>
            {event?.dateEvent}
          </Text>

          <div style={{ marginTop: "75px" }}>
            <Text
              style={{
                margin: "1% 6% 0% 10%",
                fontSize: "11px",
                wordWrap: "normal",
              }}
            >
              Date limite d'inscription : {event?.dateLimitInscription}
            </Text>
            <Text
              style={{
                margin: "1% 6% 0% 10%",
                fontSize: "11px",
                wordWrap: "normal",
              }}
            >
              Nb total participant : {clients.length}
            </Text>
            <Text
              style={{
                margin: "1% 6% 0% 10%",
                fontSize: "11px",
                wordWrap: "normal",
              }}
            >
              Nb participant max : {event?.maxParticipant}
            </Text>
            <Text
              style={{
                margin: "1% 6% 0% 10%",
                fontSize: "11px",
                wordWrap: "normal",
              }}
            >
              Remarque : {event?.description}
            </Text>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "20px",
              textAlign: "center",
              justifyContent: "space-around",
              border: "1px solid #000",
              margin: "40px 30px 0px 30px",
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                margin: "5px 25px 0px 60px",
                fontSize: "14px",
              }}
            >
              Nom
            </Text>
            <Text
              style={{
                fontWeight: "bold",
                margin: "5px 25px 0px 110px",
                fontSize: "14px",
              }}
            >
              Prénom
            </Text>
            <Text
              style={{
                fontWeight: "bold",
                margin: "5px 25px 0px 60px",
                fontSize: "14px",
              }}
            >
              Date de naissance
            </Text>
          </div>
          {clients.map((client, index) => {
            if (index === 18) {
              return (
                <>
                  <Text
                    style={{
                      display: "fixed",
                      justifyContent: "right",
                      fontSize: "7px",
                      textAlign: "right",
                      margin: "30px 40px 30px 0px",
                    }}
                  >
                    page {countPage++}
                  </Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      textAlign: "center",
                      justifyContent: "space-around",
                      border: "1px solid #000",
                      margin: "30px 30px 0px 30px",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "bold",
                        margin: "5px 25px",
                        fontSize: "10px",
                        width: "100%",
                      }}
                    >
                      {client?.lastnametempo}
                    </Text>
                    <Text
                      style={{
                        fontWeight: "bold",
                        margin: "5px 25px",
                        fontSize: "10px",
                        width: "100%",
                      }}
                    >
                      {client?.firstnametempo}
                    </Text>
                    <Text
                      style={{
                        fontWeight: "bold",
                        margin: "5px 25px",
                        fontSize: "10px",
                        width: "100%",
                      }}
                    >
                      {client?.birthdatetempo}
                    </Text>
                  </div>
                </>
              );
            } else if ((index - 17) % 34 === 0 && index > 18) {
              return (
                <>
                  <Text
                    style={{
                      display: "fixed",
                      justifyContent: "right",
                      fontSize: "7px",
                      textAlign: "right",
                      margin: "30px 40px 30px 0px",
                    }}
                  >
                    page {countPage++}
                  </Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      textAlign: "center",
                      justifyContent: "space-around",
                      border: "1px solid #000",
                      margin: "30px 30px 0px 30px",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "bold",
                        margin: "5px 25px",
                        fontSize: "10px",
                        width: "100%",
                      }}
                    >
                      {client?.lastnametempo}
                    </Text>
                    <Text
                      style={{
                        fontWeight: "bold",
                        margin: "5px 25px",
                        fontSize: "10px",
                        width: "100%",
                      }}
                    >
                      {client?.firstnametempo}
                    </Text>
                    <Text
                      style={{
                        fontWeight: "bold",
                        margin: "5px 25px",
                        fontSize: "10px",
                        width: "100%",
                      }}
                    >
                      {client?.birthdatetempo}
                    </Text>
                  </div>
                </>
              );
            } else if (clients.length === index + 1) {
              return (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      textAlign: "center",
                      justifyContent: "space-around",
                      border: "1px solid #000",
                      borderTop: "none",
                      margin: "0px 30px 0px 30px",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "bold",
                        margin: "5px 25px",
                        fontSize: "10px",
                        width: "100%",
                      }}
                    >
                      {client?.lastnametempo}
                    </Text>
                    <Text
                      style={{
                        fontWeight: "bold",
                        margin: "5px 25px",
                        fontSize: "10px",
                        width: "100%",
                      }}
                    >
                      {client?.firstnametempo}
                    </Text>
                    <Text
                      style={{
                        fontWeight: "bold",
                        margin: "5px 25px",
                        fontSize: "10px",
                        width: "100%",
                      }}
                    >
                      {client?.birthdatetempo}
                    </Text>
                  </div>
                  <Text
                    style={{
                      display: "fixed",
                      justifyContent: "right",
                      fontSize: "7px",
                      textAlign: "right",
                      margin: "30px 40px 30px 0px",
                    }}
                  >
                    page {countPage++}
                  </Text>
                </>
              );
            } else {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    textAlign: "center",
                    justifyContent: "space-around",
                    border: "1px solid #000",
                    borderTop: "none",
                    margin: "0px 30px 0px 30px",
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      margin: "5px 25px",
                      fontSize: "10px",
                      width: "100%",
                    }}
                  >
                    {client?.lastnametempo}
                  </Text>
                  <Text
                    style={{
                      fontWeight: "bold",
                      margin: "5px 25px",
                      fontSize: "10px",
                      width: "100%",
                    }}
                  >
                    {client?.firstnametempo}
                  </Text>
                  <Text
                    style={{
                      fontWeight: "bold",
                      margin: "5px 25px",
                      fontSize: "10px",
                      width: "100%",
                    }}
                  >
                    {client?.birthdatetempo}
                  </Text>
                </div>
              );
            }
          })}
        </Page>
      </Document>
    );
  } else {
    return <></>;
  }
}

function LaundryBonPdf(props) {
  const { voucher, site, laundryItems, allLaundryItems, getQuantity } = props;

  return (
    <Document>
      <Page>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "5%",
          }}
        >
          <Image src={LogoStemm} style={{ width: "25%", height: "65px" }} />
          <Text style={{ textAlign: "center", fontSize: "8px", margin: "5%" }}>
            le, {date}/{month}/{year}, {hours}:{min}
          </Text>
        </div>

        <Text style={{ textAlign: "center", fontSize: "35px" }}>
          {voucher?.voucherType?.description}
        </Text>
        <div style={{ marginTop: "75px" }}>
          <Text
            style={{
              margin: "1% 6% 0% 10%",
              fontSize: "11px",
              wordWrap: "normal",
            }}
          >
            N° de bon : {voucher?.bonNo}
          </Text>
          <Text
            style={{
              margin: "1% 6% 0% 10%",
              fontSize: "11px",
              wordWrap: "normal",
            }}
          >
            Pour M./Mme. : {voucher?.presence?.fullName}
          </Text>
          <Text
            style={{
              margin: "1% 6% 0% 10%",
              fontSize: "11px",
              wordWrap: "normal",
            }}
          >
            Date de RDV : {voucher?.rdvDate}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Heure
            de RDV : {voucher?.rdvHour}
          </Text>
          <Text
            style={{
              margin: "1% 6% 0% 10%",
              fontSize: "11px",
              wordWrap: "normal",
            }}
          >
            N° : {voucher?.num}
          </Text>
          <Text
            style={{
              margin: "1% 6% 0% 10%",
              fontSize: "11px",
              wordWrap: "normal",
            }}
          >
            Responsable : {voucher?.manager}
          </Text>
          <Text
            style={{
              margin: "1% 6% 0% 10%",
              fontSize: "11px",
              wordWrap: "normal",
            }}
          >
            Remarque : {voucher?.remark}
          </Text>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            marginTop: "5%",
          }}
        >
          {allLaundryItems.map((item, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: index === 8 ? "10%" : "0%",
                  justifyContent: "center",
                  margin: "0px 0px",
                }}
              >
                <div
                  style={{
                    width: "30px",
                    alignItems: "center",
                    justifyContent: "right",
                    margin: "0px 0px",
                  }}
                >
                  <Image
                    src={"data:image/jpeg;base64," + item?.image}
                    style={{ maxWidth: "20px", maxHeight: "20px" }}
                  />
                </div>
                <div
                  style={{
                    border: "0.5px solid #000",
                    height: "20px",
                    borderTop: index != 0 ? "none" : "0.5px solid #000",
                    width: "150px",
                    textAlign: "center",
                    justifyContent: "center",
                    margin: "0px 0px",
                  }}
                >
                  <Text
                    key={item?.laundryItem?.laundryitemId}
                    style={{ fontSize: "11px", wordWrap: "normal" }}
                  >
                    {item?.description}
                  </Text>
                </div>
                <div
                  style={{
                    border: "0.5px solid #000",
                    height: "20px",
                    borderTop: index != 0 ? "none" : "0.5px solid #000",
                    borderLeft: "none",
                    width: "50px",
                    textAlign: "center",
                    justifyContent: "center",
                    margin: "0px 0px",
                  }}
                >
                  <Text
                    key={item?.laundryItem?.laundryitemId}
                    style={{ fontSize: "11px", wordWrap: "normal" }}
                  >
                    {getQuantity(item)}
                  </Text>
                </div>
              </div>
            );
          })}
        </div>

        <div
          style={{
            borderTop: "1px dashed black",
            width: "100%",
            marginTop: "7%",
          }}
        >
          <Text
            style={{
              margin: "1% 6% 0% 10%",
              fontSize: "11px",
              marginBottom: "0%",
              marginTop: "3%",
            }}
          >
            N° de bon: {voucher?.bonNo}
          </Text>
          <Text
            style={{
              margin: "1% 6% 0% 10%",
              fontSize: "11px",
              marginBottom: "0%",
            }}
          >
            Nom : {voucher?.presence?.fullName}
          </Text>
          <Text
            style={{
              margin: "1% 6% 0% 10%",
              fontSize: "11px",
              marginBottom: "0%",
            }}
          >
            Date : {voucher?.rdvDate}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Heure
            de RDV : {voucher?.rdvHour}
          </Text>
          <Text
            style={{
              margin: "1% 6% 0% 10%",
              fontSize: "11px",
              marginBottom: "0%",
            }}
          >
            N° : {voucher?.num}
          </Text>
          <Text
            style={{
              margin: "1% 6% 0% 10%",
              fontSize: "8px",
              marginBottom: "0%",
            }}
          >
            REMARQUE : La Stëmm Vun der Strooss ne peut être tenue responsable
            pour d’éventuels dégâts causés aux vêtements
          </Text>
          <Text
            style={{
              margin: "1% 6% 0% 10%",
              fontSize: "11px",
              marginBottom: "30px",
            }}
          >
            Signature Client :
          </Text>
        </div>
        <div style={{ position: "relative" }}>
          <Image
            src={Laundry_bon}
            style={{
              marginTop: "135px",
              marginLeft: "-113px",
              width: "135%",
              transform: "rotate(-90deg)",
            }}
          />
          <div
            style={{
              position: "absolute",
              left: "0",
              top: "100px",
              transform: "rotate(-90deg)",
            }}
          >
            <Text>{DateYmdToDmY(voucher?.rdvDate, "/")}</Text>
          </div>
          <div
            style={{
              position: "absolute",
              left: 0,
              bottom: "32px",
              width: "250px",
              height: "30px",
              transform: "rotate(-90deg)",
            }}
          >
            <div
              style={{
                position: "relative",
                width: "250px",
                height: "30px",
                textAlign: "left",
              }}
            >
              <Text style={{ fontSize: "14px" }}>
                {voucher?.presence?.fullName}
              </Text>
            </div>
          </div>
        </div>
      </Page>
    </Document>
  );
}

function AllBonPdf(props) {
  const { voucher, site, laundryItems, allLaundryItems, getQuantity } = props;

  let num = [voucher?.num];

  return (
    <Document>
      <Page
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "5%",
            }}
          >
            <Image src={LogoStemm} style={{ width: "25%", height: "65px" }} />
            <Text
              style={{ textAlign: "center", fontSize: "8px", margin: "5%" }}
            >
              le, {date}/{month}/{year}, {hours}:{min}
            </Text>
          </div>

          <Text style={{ textAlign: "center", fontSize: "35px" }}>
            {voucher?.voucherType?.description}
          </Text>
          <div style={{ marginTop: "75px" }}>
            <Text
              style={{
                margin: "1% 6% 0% 10%",
                fontSize: "11px",
                wordWrap: "normal",
              }}
            >
              N° de bon : {voucher?.bonNo}
            </Text>
            <Text
              style={{
                margin: "1% 6% 0% 10%",
                fontSize: "11px",
                wordWrap: "normal",
              }}
            >
              Pour M./Mme. : {voucher?.presence?.fullName}
            </Text>
            <Text
              style={{
                margin: "1% 6% 0% 10%",
                fontSize: "11px",
                wordWrap: "normal",
              }}
            >
              Date de RDV : {voucher?.rdvDate}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Heure
              de RDV : {voucher?.rdvHour}
            </Text>
            <div>
              {num.map((item) => {
                if (item != null) {
                  return (
                    <Text
                      style={{
                        margin: "1% 6% 0% 10%",
                        fontSize: "11px",
                        wordWrap: "normal",
                      }}
                    >
                      N° : {item}
                    </Text>
                  );
                }
              })}
            </div>
            <Text
              style={{
                margin: "1% 6% 0% 10%",
                fontSize: "11px",
                wordWrap: "normal",
              }}
            >
              Responsable : {voucher?.manager}
            </Text>
            <Text
              style={{
                margin: "1% 6% 0% 10%",
                fontSize: "11px",
                wordWrap: "normal",
              }}
            >
              Remarque : {voucher?.remark}
            </Text>
          </div>
        </div>

        <div
          style={{
            borderTop: "1px dashed black",
            width: "100%",
            marginTop: "7%",
            position: "fixed",
            bottom: "0",
          }}
        >
          <Text
            style={{
              margin: "1% 6% 0% 10%",
              fontSize: "11px",
              marginBottom: "0%",
              marginTop: "3%",
            }}
          >
            N° de bon: {voucher?.bonNo}
          </Text>
          <Text
            style={{
              margin: "1% 6% 0% 10%",
              fontSize: "11px",
              marginBottom: "0%",
            }}
          >
            Nom : {voucher?.presence?.fullName}
          </Text>
          <Text
            style={{
              margin: "1% 6% 0% 10%",
              fontSize: "11px",
              marginBottom: "0%",
            }}
          >
            Date : {voucher?.rdvDate}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Heure
            de RDV : {voucher?.rdvHour}
          </Text>
          <div>
            {num.map((item) => {
              if (item != null) {
                return (
                  <Text
                    style={{
                      margin: "1% 6% 0% 10%",
                      fontSize: "11px",
                      marginBottom: "0%",
                    }}
                  >
                    N° : {voucher?.num}
                  </Text>
                );
              }
            })}
          </div>
          <Text
            style={{
              margin: "1% 6% 0% 10%",
              fontSize: "8px",
              marginBottom: "0%",
            }}
          >
            REMARQUE : La Stëmm Vun der Strooss ne peut être tenue responsable
            pour d’éventuels dégâts causés aux vêtements
          </Text>
          <Text
            style={{
              margin: "1% 6% 0% 10%",
              fontSize: "11px",
              marginBottom: "100px",
            }}
          >
            Signature Client :
          </Text>
        </div>
        {voucher?.voucherType?.voucherType === "Lockerroom" && (
          <Image
            src={Vestiaire_bon}
            style={{
              marginTop: "135px",
              marginLeft: "-105px",
              width: "135%",
              transform: "rotate(-90deg)",
            }}
          />
        )}
      </Page>
    </Document>
  );
}

function PreviewPdf(props) {
  const bons = useRef();
  const planning = useRef();
  const {
    type,
    actionBon,
    openPreviewPdf,
    rdvDate,
    event,
    clients,
    voucher,
    voucherLaundryItems,
    handleClosePreviewPdf,
    voucherType,
    site,
    handleClose,
    saveBonAfterPdf,
    events,
  } = props;

  const [laundryItems, setLaundryItems] = useState([]);
  const [allLaundryItems, setAllLaundryItems] = useState([{}]);

  useEffect(() => {
    if (voucherLaundryItems != null) {
      setLaundryItems(voucherLaundryItems);
    }
  }, [voucherLaundryItems]);

  const handleValidation = (e) => {
    voucher.site = site;
    voucher.laundryItems = voucherLaundryItems;
    saveBonAfterPdf(voucher);
  };

  useEffect(() => {
    LaundryItemService.getLaundryItems(0, -1)
      .then((response) =>
        setAllLaundryItems(
          response.data.content.sort(
            (item1, item2) => item1.orderItem - item2.orderItem
          )
        )
      )
      .catch((err) => console.log(err));
  }, []);

  const style = {
    display: "flex",
    cursor: "default",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    backgroundColor: "#fff",
    border: "0px solid #000",
    borderRadius: "5px",
    padding: 10,
  };

  const createItemList = (type, site) => {
    const preList = [];
    for (let i = 0; i < site[type.toLowerCase()]; i++) {
      preList.push([]);
    }
    return preList;
  };

  if (type === "planning") {
    if (
      voucherType?.voucherType === "Shower" ||
      voucherType?.voucherType === "Laundry" ||
      voucherType?.voucherType === "Lockerroom"
    ) {
      const itemList = createItemList(voucherType?.voucherType, site);
      {
        try {
          events.sort((a, b) => (a.num > b.num ? 1 : -1));
          for (let i = 0; i < events.length; i++) {
            itemList[events[i].num - 1].push(events[i]);
          }
          for (let j = 0; j < itemList.length; j++) {
            itemList[j].sort((a, b) => (a.rdvHour > b.rdvHour ? 1 : -1));
          }
        } catch (error) {
          //
        }
      }
      return (
        <Modal open={openPreviewPdf} onClose={handleClosePreviewPdf}>
          <div style={style}>
            <IconButton
              aria-label="close"
              onClick={handleClosePreviewPdf}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            {/*
            <h3 style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>Aperçu du PDF</h3>
            <div style={{ margin: '30px 30px 30px 30px', border: '1px solid #000', width: '397px', height: '562px' }}>
              <div style={{ padding: '4% 2% 0% 2%' }} ref={planning} id="divToPrint">

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '0% 4px 0% 4%' }}>
                  <img src={LogoStemm} style={{ width: '25%' }} />
                  <p style={{ fontSize: 6, marginRight: '2%' }}>
                    le, {date}/{month}/{year}, {hours}:{min}
                  </p>
                </div>

                <p style={{ fontWeight: 'bold', textAlign: 'center', margin: '5px 25px', fontSize: '20px' }}>Planning {voucherType?.description.substr(4)} du {formatDate(rdvDate)}</p>
                {itemList.map((i, index) => {
                  if (i.length > 0) {
                    return (
                      <>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-evenly', marginTop: '20px', border: '1px solid #000' }}>
                          <p style={{ fontWeight: 'bold', textAlign: 'center', margin: '5px 25px', fontSize: '12px' }}>Heure de RDV</p>
                          <p style={{ fontWeight: 'bold', textAlign: 'center', margin: '5px 25px', fontSize: '12px' }}>Nom complet</p>
                          <p style={{ fontWeight: 'bold', textAlign: 'center', margin: '5px 25px', fontSize: '12px' }}>N°</p>
                        </div>
                        {events.map((event) => {
                          if (event?.num === index + 1)
                            return (
                              <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', justifyContent: 'space-around', width: '100%', border: '1px solid #000', borderTop: 'none' }}>
                                <p style={{ fontWeight: 'bold', textAlign: 'center', margin: '5px 25px', fontSize: '10px' }}>{event?.rdvHour}</p>
                                <p style={{ fontWeight: 'bold', textAlign: 'center', margin: '5px 25px', fontSize: '10px' }}>{event?.presence?.fullName}</p>
                                <p style={{ fontWeight: 'bold', textAlign: 'center', margin: '5px 25px', fontSize: '10px' }}>{event?.num}</p>
                              </div>
                            );
                        })}
                      </>
                    )
                  }
                })}
              </div>
            </div>
            */}
            <PDFViewer width="300" height="470" showToolbar>
              <PlanningPdf
                rdvDate={rdvDate}
                voucherType={voucherType}
                events={events}
                site={site}
                itemList={itemList}
              />
            </PDFViewer>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Tooltip title="Enregistrer le PDF" placement="top">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <PDFDownloadLink
                    document={
                      <PlanningPdf
                        rdvDate={rdvDate}
                        voucherType={voucherType}
                        events={events}
                        site={site}
                        itemList={itemList}
                      />
                    }
                    // onClick={actionBon === 'create' ? handleValidation : () => { }}
                    fileName={
                      "Planning " + voucherType?.description.substr(4) + ".pdf"
                    }
                    style={{
                      textDecoration: "none",
                      padding: "10px",
                      color: "#1976d2",
                      backgroundColor: "#fff",
                      borderRadius: "30px",
                    }}
                  >
                    <SaveIcon />
                  </PDFDownloadLink>
                </div>
              </Tooltip>
            </div>
          </div>
        </Modal>
      );
    } else {
      events.sort((a, b) => (a.rdvHour > b.rdvHour ? 1 : -1));
      return (
        <Modal open={openPreviewPdf} onClose={handleClosePreviewPdf}>
          <div style={style}>
            <IconButton
              aria-label="close"
              onClick={handleClosePreviewPdf}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            {/*
            <h3 style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>Aperçu du PDF</h3>
            <div style={{ margin: '30px 30px 30px 30px', border: '1px solid #000', width: '397px', height: '562px' }}>
              <div style={{ padding: '4% 2% 0% 2%' }} ref={planning} id="divToPrint">

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '0% 4px 0% 4%' }}>
                  <img src={LogoStemm} style={{ width: '25%' }} />
                  <p style={{ fontSize: 6, marginRight: '2%' }}>
                    le, {date}/{month}/{year}, {hours}:{min}
                  </p>
                </div>

                <p style={{ fontWeight: 'bold', textAlign: 'center', margin: '5px 25px', fontSize: '20px' }}>Planning {voucherType?.description.substr(4)} du {formatDate(rdvDate)}</p>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-evenly', marginTop: '20px', border: '1px solid #000' }}>
                  <p style={{ fontWeight: 'bold', textAlign: 'center', margin: '5px 25px', fontSize: '14px' }}>Heure de RDV</p>
                  <p style={{ fontWeight: 'bold', textAlign: 'center', margin: '5px 25px', fontSize: '14px' }}>Nom complet</p>
                </div>
                {events.map((event) => {
                  return (
                    <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', justifyContent: 'space-around', width: '100%', border: '1px solid #000', borderTop: 'none' }}>
                      <p style={{ fontWeight: 'bold', textAlign: 'center', margin: '5px 25px', fontSize: '10px' }}>{event?.rdvHour}</p>
                      <p style={{ fontWeight: 'bold', textAlign: 'center', margin: '5px 25px', fontSize: '10px' }}>{event?.presence?.fullName}</p>
                    </div>
                  );
                })}

              </div>
            </div>
              */}
            <PDFViewer width="300" height="470" showToolbar>
              <PlanningPdf
                rdvDate={rdvDate}
                voucherType={voucherType}
                events={events}
              />
            </PDFViewer>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Tooltip title="Enregistrer le PDF" placement="top">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <PDFDownloadLink
                    document={
                      <PlanningPdf
                        rdvDate={rdvDate}
                        voucherType={voucherType}
                        events={events}
                      />
                    }
                    fileName={
                      "Planning " + voucherType?.description.substr(4) + ".pdf"
                    }
                    style={{
                      textDecoration: "none",
                      padding: "10px",
                      color: "#1976d2",
                      backgroundColor: "#fff",
                      borderRadius: "30px",
                    }}
                  >
                    <SaveIcon />
                  </PDFDownloadLink>
                </div>
              </Tooltip>
            </div>
          </div>
        </Modal>
      );
    }
  } else if (type === "bon") {
    if (voucher?.voucherType?.voucherType === "Laundry") {
      const getQuantity = (item) => {
        for (let i = 0; i < laundryItems.length; i++) {
          if (laundryItems[i]?.laundryItem?.description === item?.description) {
            return laundryItems[i]?.quantity;
          }
        }
        return 0;
      };

      return (
        <Modal open={openPreviewPdf} onClose={handleClosePreviewPdf}>
          <div style={style}>
            <IconButton
              aria-label="close"
              onClick={handleClosePreviewPdf}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            {/*
            <h3 style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>Aperçu du PDF</h3>
            <div style={{ margin: '30px 30px 30px 30px', border: '1px solid #000', width: '397px', height: '562px' }}>
              <div style={{ padding: '6% 2%' }} ref={bons} id="divToPrint">
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '0% 4px 6% 4%' }}>
                  <img src={LogoStemm} style={{ width: '25%' }} />
                  <p style={{ fontSize: 6, marginRight: '2%' }}>
                    le, {date}/{month}/{year}, {hours}:{min}
                  </p>
                </div>
                <h5 style={{ display: 'flex', justifyContent: 'center', marginBottom: '5%' }}>{voucher?.voucherType?.description}</h5>
                <div style={{ margin: '0% 5%', padding: 10, fontSize: 8 }}>
                  N° de bon : {voucher?.bonNo}<br />
                  Pour M./Mme. : {voucher?.presence?.fullName}<br />
                  Date de RDV : {voucher?.rdvDate}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Heure de RDV : {voucher?.rdvHour}<br />
                  N° : {voucher?.num}<br />
                  Remarque : {voucher?.remark}<br />
                  <div style={{ marginTop: '3%' }}>
                    {allLaundryItems.map((item, index) => {
                      return (
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: (index === 8 ? '10%' : '0%'), justifyContent: 'center', margin: '0px 0px' }}>
                          <div style={{ width: '30px', alignItems: 'center', justifyContent: 'right', margin: '0px 0px' }}>
                            <img src={"data:image/jpeg;base64," + item?.image} style={{ maxWidth: '16px', maxHeight: '16px' }} />
                            <img src={item?.src} style={{ maxWidth: '16px', maxHeight: '16px' }} />

                          </div>
                          <div style={{ border: '0.5px solid #000', height: '16px', borderTop: (index != 0 ? 'none' : '0.5px solid #000'), width: '150px', textAlign: 'center', justifyContent: 'center', margin: '0px 0px' }}>
                            <p key={item?.laundryItem?.laundryitemId} style={{ fontSize: '9px', wordWrap: 'normal', marginsBottom: '0%' }}>{item?.description}</p>
                          </div>
                          <div style={{ border: '0.5px solid #000', height: '16px', borderTop: (index != 0 ? 'none' : '0.5px solid #000'), borderLeft: 'none', width: '50px', textAlign: 'center', justifyContent: 'center', margin: '0px 0px' }}>
                            <p key={item?.laundryItem?.laundryitemId} style={{ fontSize: '9px', wordWrap: 'normal', marginsBottom: '0%' }}>{getQuantity(item)}</p>
                          </div>
                        </div>
                      )
                    })}
                  </div>

                  <div style={{ borderTop: '1px dashed black', width: '100%', height: '100px', marginTop: '7%' }}>
                    <p style={{ fontSize: '8px', marginBottom: '0%', marginTop: '3%' }}>N° de bon: {voucher?.bonNo}</p>
                    <p style={{ fontSize: '8px', marginBottom: '0%' }}>Nom : {voucher?.presence?.fullName}</p>
                    <p style={{ fontSize: '8px', marginBottom: '0%' }}>Date de RDV: {voucher?.rdvDate}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Heure de RDV : {voucher?.rdvHour}</p>
                    <p style={{ fontSize: '8px', marginBottom: '0%' }}>N° : {voucher?.num}</p>
                    <p style={{ fontSize: '5px', marginBottom: '0%' }}>REMARQUE : La Stëmm Vun der Strooss ne peut être tenue responsable pour d’éventuels dégâts causés aux vêtements</p>
                    <p style={{ fontSize: '8px' }}>Signature Client :</p>
                  </div>
                </div>
              </div>
            </div>
                  */}
            <PDFViewer width="300" height="470" showToolbar>
              <LaundryBonPdf
                voucher={voucher}
                getQuantity={getQuantity}
                site={site}
                laundryItems={laundryItems}
                allLaundryItems={allLaundryItems}
              />
            </PDFViewer>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Tooltip title="Enregistrer le PDF" placement="top">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <PDFDownloadLink
                    document={
                      <LaundryBonPdf
                        voucher={voucher}
                        getQuantity={getQuantity}
                        site={site}
                        laundryItems={laundryItems}
                        allLaundryItems={allLaundryItems}
                      />
                    }
                    onClick={
                      actionBon === "create" ? handleValidation : () => {}
                    }
                    fileName={voucher?.voucherType?.description + ".pdf"}
                    style={{
                      textDecoration: "none",
                      padding: "10px",
                      color: "#1976d2",
                      backgroundColor: "#fff",
                      borderRadius: "30px",
                    }}
                  >
                    <SaveIcon />
                  </PDFDownloadLink>
                </div>
              </Tooltip>
            </div>
          </div>
        </Modal>
      );
    } else {
      const styleNum =
        voucher?.voucherType?.description === "Bon douche" ||
        voucher?.voucherType?.description === "Bon vestiaire" ||
        voucher?.voucherType?.description === "Bon buanderie"
          ? { display: "flex" }
          : { display: "none" };
      const styleNum2 =
        voucher?.voucherType?.description === "Bon douche" ||
        voucher?.voucherType?.description === "Bon vestiaire" ||
        voucher?.voucherType?.description === "Bon buanderie"
          ? { display: "flex", fontSize: "8px", marginBottom: "0%" }
          : { display: "none" };

      return (
        <Modal open={openPreviewPdf} onClose={handleClosePreviewPdf}>
          <div style={style}>
            <IconButton
              aria-label="close"
              onClick={handleClosePreviewPdf}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            {/*
            <h3 style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>Aperçu du PDF</h3>
            <div style={{ margin: '30px 30px 30px 30px', border: '1px solid #000', width: '397px', height: '562px' }}>
              <div style={{ padding: '6% 2%' }} ref={bons} id="divToPrint">
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '0% 4px 6% 4%' }}>
                  <img src={LogoStemm} style={{ width: '25%' }} />
                  <p style={{ fontSize: 6, marginRight: '2%' }}>
                    le, {date}/{month}/{year}, {hours}:{min}
                  </p>
                </div>
                <h5 style={{ display: 'flex', justifyContent: 'center', marginBottom: '5%' }}>{voucher?.voucherType?.description}</h5>
                <div style={{ margin: '0% 5%', padding: 10, fontSize: 8 }}>
                  N° de bon : {voucher?.bonNo}<br />
                  Pour M./Mme. : {voucher?.presence?.fullName}<br />
                  Date de RDV : {voucher?.rdvDate}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Heure de RDV : {voucher?.rdvHour}<br />
                  <t style={styleNum}>N° : {voucher?.num}</t>
                  Remarque : {voucher?.remark}<br />

                  <div style={{ borderTop: '1px dashed black', marginTop: '7%', position: 'fixed', bottom: 130 }}>
                    <p style={{ fontSize: '8px', marginBottom: '0%', marginTop: '3%' }}>N° de bon : {voucher?.bonNo}</p>
                    <p style={{ fontSize: '8px', marginBottom: '0%' }}>Nom : {voucher?.presence?.fullName}</p>
                    <p style={{ fontSize: '8px', marginBottom: '0%' }}>Date de RDV : {voucher?.rdvDate}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Heure de RDV : {voucher?.rdvHour}</p>
                    <p style={styleNum2}>N° : {voucher?.num}</p>
                    <p style={{ fontSize: '5px', marginBottom: '0%' }}>REMARQUE : La Stëmm Vun der Strooss ne peut être tenue responsable pour d’éventuels dégâts causés aux vêtements</p>
                    <p style={{ fontSize: '8px' }}>Signature Client :</p>
                  </div>
                </div>
              </div>
            </div>
            */}
            <PDFViewer width="300" height="470" showToolbar>
              <AllBonPdf
                voucher={voucher}
                site={site}
                laundryItems={laundryItems}
              />
            </PDFViewer>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Tooltip title="Enregistrer le PDF" placement="top">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <PDFDownloadLink
                    document={
                      <AllBonPdf
                        voucher={voucher}
                        site={site}
                        laundryItems={laundryItems}
                      />
                    }
                    onClick={
                      actionBon === "create" ? handleValidation : () => {}
                    }
                    fileName={voucher?.voucherType?.description + ".pdf"}
                    style={{
                      textDecoration: "none",
                      padding: "10px",
                      color: "#1976d2",
                      backgroundColor: "#fff",
                      borderRadius: "30px",
                    }}
                  >
                    <SaveIcon />
                  </PDFDownloadLink>
                </div>
              </Tooltip>
            </div>
          </div>
        </Modal>
      );
    }
  } else if (type === "event") {
    return (
      <Modal open={openPreviewPdf} onClose={handleClosePreviewPdf}>
        <div style={style}>
          <IconButton
            aria-label="close"
            onClick={handleClosePreviewPdf}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {/*
          <h3 style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>Aperçu du PDF</h3>
          <div style={{ margin: '30px 30px 30px 30px', border: '1px solid #000', width: '397px', height: '562px' }}>
            <div style={{ padding: '6% 2%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '0% 4px 6% 4%' }}>
                <img src={LogoStemm} style={{ width: '25%' }} />
                <p style={{ fontSize: 6, marginRight: '2%' }}>
                  le, {date}/{month}/{year}, {hours}:{min}
                </p>
              </div>
              <h5 style={{ display: 'flex', justifyContent: 'center', fontSize: '20px' }}>{event?.description}</h5>
              <h6 style={{ display: 'flex', justifyContent: 'center', marginBottom: '6%', fontSize: '12px' }}>{event?.dateEvent}</h6>
              <p style={{ fontSize: '10px', lineHeight: '18px', marginLeft: '3%' }}>
                Date limite d'inscription : {event?.dateLimitInscription}
                <br />
                Nb total participants : {clients.length}
                <br />
                Nb participants max : {event?.maxParticipant}
                <br />
                Remarque : {event?.description}
                <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', justifyContent: 'space-around', border: '1px solid #000', margin: '10px 20px 0px 20px' }}>
                  <p style={{ fontWeight: 'bold', margin: '2px 20px 0px 40px', fontSize: '10px' }}>Nom</p>
                  <p style={{ fontWeight: 'bold', margin: '2px 20px 0px 60px', fontSize: '10px' }}>Prénom</p>
                  <p style={{ fontWeight: 'bold', margin: '2px 20px 0px 20px', fontSize: '10px' }}>Date de naissance</p>
                </div>
                {clients.map((client, index) => {
                  if (index <= 11)
                    return (
                      <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', justifyContent: 'space-around', border: '1px solid #000', borderTop: 'none', margin: '0px 20px 0px 20px' }}>
                        <p style={{ margin: '0px 20px', fontSize: '8px', width: '100%' }}>{client?.lastnametempo}</p>
                        <p style={{ margin: '0px 20px', fontSize: '8px', width: '100%' }}>{client?.firstnametempo}</p>
                        <p style={{ margin: '0px 20px', fontSize: '8px', width: '100%' }}>{client?.birthdatetempo}</p>
                      </div>
                    );
                })}
              </p>
            </div>
          </div>
              */}
          <PDFViewer width="300" height="470" showToolbar>
            <EventPdf clients={clients} event={event} />
          </PDFViewer>
          <div style={{ marign: "0px 0px" }}>
            Page 1 sur {Math.ceil((clients.length - 17) / 34) + 1}
          </div>
          <Tooltip title="Enregistrer le PDF" placement="top">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <PDFDownloadLink
                document={<EventPdf clients={clients} event={event} />}
                fileName="Evènement.pdf"
                style={{
                  textDecoration: "none",
                  padding: "10px",
                  color: "#1976d2",
                  backgroundColor: "#fff",
                  borderRadius: "30px",
                }}
              >
                <SaveIcon />
              </PDFDownloadLink>
            </div>
          </Tooltip>
        </div>
      </Modal>
    );
  }
}

export default PreviewPdf;
