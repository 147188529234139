import instance from "../../../../shared/components/auth/service/AxiosInstance"

export const LaundryItemService = {

    getLaundryItem: (pageNo, pageSize) =>
        instance.get(process.env.REACT_APP_API_URL + "laundry/" +
            '?pageSize=' + pageSize +
            '&pageNo=' + pageNo
        ),

    getLaundryItemById: (id) => 
        instance.get(process.env.REACT_APP_API_URL + "laundry/" + id),

    createLaundryItem: (laundryitem) =>
        instance.post(process.env.REACT_APP_API_URL + 'laundry/',
        {   
            ...laundryitem, 
            deleted: (laundryitem?.deleted ? true : false)
        }
        ),

    updateLaundryItem: (id, laundryitem) =>
        instance.put(process.env.REACT_APP_API_URL + 'laundry/' + id, 
        {   
            ...laundryitem, 
            deleted: (laundryitem?.deleted ? true : false)
        }
        ),

    deleteLaundryItem: (id) =>
        instance.delete(process.env.REACT_APP_API_URL + 'laundry/' + id
        ),
}