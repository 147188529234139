import instance from "../../../shared/components/auth/service/AxiosInstance";

export const ReportService = {

    getReportClient: (search) =>
        instance.post(process.env.REACT_APP_API_URL + "report/client",
        search
    ),

    getReportContract: (search) =>
        instance.post(process.env.REACT_APP_API_URL + "report/contract",
        search
    ),

    getReportExclusion: (search) =>
        instance.post(process.env.REACT_APP_API_URL + "report/exclusion",
        search
    ),

    getReportPresence: (search) =>
        instance.post(process.env.REACT_APP_API_URL + "report/presence",
        search
    ),

    getReportClosing: (search) =>
        instance.post(process.env.REACT_APP_API_URL + "report/closing",
        search
    ),

}