import React, { useState, useEffect } from "react";
import { DateSelector } from "./selectors/DateSelector";
import { SiteSelector } from "./selectors/SiteSelector";
import { Typography, Box, Button } from "@mui/material";
import { ReportService } from "../service/ReportService";
import { ExportToFile } from "../utils/ExportToFile";
import {
  localeISODateTime,
  getISODate,
  DateYmdToDmY,
  getISOTime,
} from "../../../shared/utils/localeISODateTime";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/features/auth/authSlice";
import { Link } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import IconButton from "@mui/material/IconButton";
import { DataGrid } from "@mui/x-data-grid";
import LoadingButton from "@mui/lab/LoadingButton";

const today = getISODate(localeISODateTime());

const ReportPresence = (props) => {
  const { setActive } = props;
  useEffect(() => {
    //setActive(4);
  }, []);

  const dispatch = useDispatch();

  const [inputs, setInputs] = useState({
    dateStart: today,
    dateEnd: today,
    sites: [],
  });

  const [message, setMessage] = useState("");

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selected, setSelected] = useState();
  const [select, setSelect] = useState({});
  const [rows, setRows] = useState([]);
  const [rowCountState, setRowCountState] = useState(null);
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 10,
  });

  const [printLoading, setPrintLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    let val = event.target.value;
    try {
      val = JSON.parse(event.target.value);
    } catch (err) {}
    let value = event.target.type === "checkbox" ? event.target.checked : val;

    //Checkbox group
    if (
      document.querySelectorAll("input[name=" + name + "]")?.length > 1 &&
      event.target.type === "checkbox"
    ) {
      value = [];
      document
        .querySelectorAll("input[name=" + name + "]:checked")
        .forEach((input) => value.push(JSON.parse(input.value)));
    }

    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handlePrint = () => {
    setPrintLoading(true);
    refresh(inputs);
    setPrintLoading(false);
  };

  const handleSubmit = () => {
    setSubmitLoading(true);
    ReportService.getReportPresence(inputs)
      .then((response) => {
        ExportToFile(
          response.data
            ?.sort(
              (p1, p2) =>
                p1?.site?.description.localeCompare(p2?.site?.description) ||
                p1?.entryHour.localeCompare(p2?.entryHour)
            )
            .map((row) => {
              const { site, presenceDate, entryHour, client, meal, vouchers } =
                row;
              return {
                Site: site?.description,
                Date: DateYmdToDmY(presenceDate, "."),
                "Heure d'entrée": entryHour?.substring(11, 16),
                Nom: client?.lastnametempo?.toUpperCase(),
                Prénom: client?.firstnametempo,
                Surnom: client?.surnametempo,
                Repas: meal || "x",
                Vestiaire: vouchers?.filter(
                  (voucher) =>
                    voucher?.voucherType?.voucherType === "Lockerroom"
                )?.length
                  ? "x"
                  : "",
                Douche: vouchers?.filter(
                  (voucher) => voucher?.voucherType?.voucherType === "Shower"
                )?.length
                  ? "x"
                  : "",
                Buanderie: vouchers?.filter(
                  (voucher) => voucher?.voucherType?.voucherType === "Laundry"
                )?.length
                  ? "x"
                  : "",
                Docteur: vouchers?.filter(
                  (voucher) => voucher?.voucherType?.voucherType === "Doctor"
                )?.length
                  ? "x"
                  : "",
                Coiffeur: vouchers?.filter(
                  (voucher) =>
                    voucher?.voucherType?.voucherType === "Hairdresser"
                )?.length
                  ? "x"
                  : "",
                SacDeCouchage: vouchers?.filter(
                  (voucher) =>
                    voucher?.voucherType?.voucherType === "Sleepingbag"
                )?.length
                  ? "x"
                  : "",
              };
            }),
          "presences_" + new Date().toISOString().split(".")[0]
        );
        setMessage(
          "Export de " +
            (response.data?.length ? response.data.length : 0) +
            " ligne(s)"
        );
        setSubmitLoading(false);
      })
      .catch((err) => {
        setMessage("Erreur: " + err);
        if (
          err?.statusCode === 403 ||
          err?.statusCode === 401 ||
          err?.response?.status === 403 ||
          err?.response?.status === 401
        ) {
          dispatch(logout());
        }
        setSubmitLoading(false);
      });
  };

  const refresh = (searchParams) => {
    ReportService.getReportPresence({
      dateStart: searchParams?.dateStart || inputs.dateStart,
      dateEnd: searchParams?.dateEnd || inputs.dateEnd,
      status: searchParams?.status || inputs.status,
      sites: searchParams?.sites || inputs.sites,
    })
      .then((response) => {
        setRows(response.data);
        setRowCountState(response.data.length);
      })
      .catch((err) => setMessage("Erreur: " + err));
  };

  const columns = [
    {
      field: "fullName",
      headerName: "Nom complet",
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "presenceDate",
      headerName: "Date / Heure",
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (data) => (
        <span>
          {DateYmdToDmY(data.row.presenceDate.substring(0, 10), ".") +
            " " +
            data.row.entryHour.substring(11, 16)}
        </span>
      ),
    },
    {
      field: "meal",
      headerName: "Nb repas",
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "site",
      headerName: "Site",
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (data) => <span>{data.value.description}</span>,
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <h1
        style={{
          fontFamily: "backToSchool",
          fontSize: "65px",
          color: "#bb0000",
          marginTop: "4%",
        }}
      >
        Rapport Présences
      </h1>

      <div style={{ position: "absolute", top: 23, left: 270 }}>
        <Link to="/gestionSelect">
          <IconButton color="primary">
            <ArrowBackIosNewIcon />
          </IconButton>
        </Link>
      </div>
      <div
        className="event-container display-relative"
        style={{ marginTop: "5%", height:'810px' }}
      >
        <form onSubmit={handleSubmit}>
          <Box>
            <h1 style={{ fontFamily: "overlockBold" }}>Rechercher</h1>
            <div style={{ margin:'0px 130px'}}>
                <DateSelector
                  setInputs={setInputs}
                />
                <SiteSelector handleChange={handleChange} type={"checkbox"} setInputs={setInputs} inputs={inputs} />
            </div>
            <LoadingButton
              variant="contained"
              sx={{ alignSelf: "center", marginRight: "1% 2% 1% 0%" }}
              loading={printLoading}
              onClick={handlePrint}
            >
              Rechercher
            </LoadingButton>
            <LoadingButton
              variant="contained"
              sx={{ alignSelf: "center", margin: "1% 0% 1% 2%" }}
              onClick={handleSubmit}
              loading={submitLoading}
            >
              Exporter
            </LoadingButton>
          </Box>
        </form>
        <Typography sx={{ textAlign: "center" }}>{message}</Typography>
        <DataGrid
          rowsPerPageOptions={[rowsState.pageSize]}
          sx={{ backgroundColor: "#fff", height: "60%" }}
          getRowId={(row) => row.presenceId}
          columns={columns}
          rows={rows}
          rowCount={rowCountState}
          showCellRightBorder
          pagination
          {...rowsState}
          paginationMode="server"
        />
      </div>
    </div>
  );
};

export default ReportPresence;
