import axios from "axios";

export const AuthService = {
    
    signin: (authData) => axios.post(process.env.REACT_APP_API_URL + "auth/signin",
        authData
    ),

    logout: () => {
        localStorage.removeItem("user")
        localStorage.removeItem("site")
        localStorage.removeItem("roles")
    }

}
