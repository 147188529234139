import instance from "../../../../shared/components/auth/service/AxiosInstance";

export const EmergencyService = {
    getEmergencyInfo: (clientId) =>
        instance.get(
            process.env.REACT_APP_API_URL +
            "contactEmergency/client/" +
            clientId,
        ),

    updateEmergencyInfo: (id, clientId, amount) =>
        instance.put(
            process.env.REACT_APP_API_URL +
            "contactEmergency/" +
            id +
            "/" +
            clientId,
            amount
        ),

};
