import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from '@mui/material/Tooltip';
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../../redux/features/auth/authSlice";
import { capitalizeAllWords } from "../../../shared/utils/StringProcessing";
import { DateYmdToDmY, compareDates, getISODate, localeISODateTime } from "../../../shared/utils/localeISODateTime";
import { ReportService } from "../service/ReportService";
import { ExportToFile } from "../utils/ExportToFile";
import { DateSelector } from "./selectors/DateSelector";

const today = getISODate(localeISODateTime());

const columns = [
    { field: "id", headerName: "Id", hideable: true, },
    {
        field: "dateStart", headerName: "Début", headerClassName: "researchColmun", headerAlign: "center", align: "center", width: 100,
        renderCell: (data) => DateYmdToDmY(data?.value, '.') || null
    },
    {
        field: "dateEnd", headerName: "Fin", headerClassName: "researchColmun", headerAlign: "center", align: "center", width: 100,
        renderCell: (data) => DateYmdToDmY(data?.value, '.') || null
    },
    {
        field: "fullname", headerName: "Nom (surnom)", headerClassName: "researchColmun", headerAlign: "center", align: "center", flex: 1,
        renderCell: (data) => (data.row.client?.lastnametempo?.toUpperCase() + " " + capitalizeAllWords(data.row.client?.firstnametempo)).trim()
            + (data.row.client?.surnametempo ? " (" + data.row.client.surnametempo + ")" : "")
    },
    {
        field: "site", headerName: "Site référence", headerClassName: "researchColmun", headerAlign: "center", align: "center", width: 200,
        renderCell: (data) => data.row.client?.sites?.map(site => site?.description).join(',')
    },
    {
        field: "remark", headerName: "Remarque", headerClassName: "researchColmun", headerAlign: "center", align: "left", flex: 1,
        renderCell: (data) => <Tooltip title={data?.value} placement="top-start"><span>{data?.value}</span></Tooltip>
    },
];

const ReportExclusion = (props) => {
    const { setActive } = props;
    useEffect(() => {
      //setActive(4);
    }, []);

    const dispatch = useDispatch();

    const [inputs, setInputs] = useState({ dateStart: today, dateEnd: today });
    const [message, setMessage] = useState("");

    const pageSize = 50;
    const [rows, setRows] = useState([]);
    const [select, setSelect] = useState({});
    const [loading, setLoading] = useState(false);

    const handleExport = () => {
        setLoading(true);
        ExportToFile(
            rows?.map(row => {
                const { client, dateStart, dateEnd, remark } = row;
                return {
                    "Nom": client?.lastnametempo?.toUpperCase(),
                    "Prénom": client?.firstnametempo,
                    "Surnom": client?.surnametempo,
                    "Début": dateStart,
                    "Fin": dateEnd,
                    "Remarques": remark,
                    "Site": (client?.Sites?.length > 0 ? client.sites[0].description : "")
                }
            }),
            "exclusions_" + new Date().toISOString().split('.')[0]
        );
        setMessage("Export de " + (rows?.length ? rows.length : 0) + " ligne(s)");
        setLoading(false);
    }

    const handleChange = (event) => {
        const name = event.target.name;
        let val = event.target.value;
        try {
            val = JSON.parse(event.target.value);
        } catch (err) { }
        let value = event.target.type === "checkbox" ? event.target.checked : val;

        //Checkbox group
        if ((document.querySelectorAll("input[name=" + name + "]").length > 1) && (event.target.type === "checkbox")) {
            value = [];
            document
                .querySelectorAll("input[name=" + name + "]:checked")
                .forEach((input) => value.push(JSON.parse(input.value)));
        }

        setInputs((values) => ({ ...values, [name]: value }));
    }

    const refresh = () => {
        ReportService.getReportExclusion(inputs)
            .then(response => {
                const data = response.data?.sort((exclusion1, exclusion2) =>
                    exclusion1.client?.lastnametempo?.localeCompare(exclusion2.client?.lastnametempo)
                    || compareDates(exclusion1?.dateStart, exclusion2.dateStart)
                );
                setRows(
                    data?.map((row) => {
                        const { exclusionId, ...rest } = row;
                        return { id: exclusionId, ...rest };
                    }) || []
                );
                let selected = data?.filter((exclusion) => exclusion?.exclusionId === select?.exclusionId);
                setSelect(selected?.length ? selected[0] : {});
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                if (err?.statusCode === 403 || err?.statusCode === 401
                    || err?.response?.status === 403 || err?.response?.status === 401
                ) {
                    dispatch(logout());
                }
            });
    }

    const handleSubmit = () => {
        refresh();
    }

    useEffect(() => {
        refresh();
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', width: '100%' }}>
            <h1 style={{ fontFamily: 'backToSchool', fontSize: '65px', color: '#bb0000', marginTop: '4%' }}>Rapport Exclusions</h1>
            <div style={{ position: "absolute", top: 23, left: 270 }}>
                <Link to="/gestionSelect">
                    <IconButton color="primary">
                        <ArrowBackIosNewIcon />
                    </IconButton>
                </Link>
            </div>
            <div className="event-container display-relative" style={{ marginTop: '5%', height:'810px' }}>
                <form onSubmit={handleSubmit} style={{ marginBottom: 15 }}>
                    <Box>
                        <h1 style={{ fontFamily:'overlockBold' }}>Rechercher</h1>
                        <div style={{ margin:'0px 130px' }}>
                            <DateSelector
                                setInputs={setInputs}
                            />
                        </div>
                        <Button
                            variant="contained"
                            sx={{ alignSelf: "center", margin:'2% 2% 0% 0%' }}
                            onClick={handleSubmit}
                        >
                            Rechercher
                        </Button>

                        <LoadingButton
                            color={"primary"}
                            onClick={handleExport}
                            loading={loading}
                            loadingPosition="end"
                            endIcon={<FileDownloadIcon />}
                            variant={"contained"}
                            sx={{ margin:'2% 0% 0% 2%' }}
                        >
                            Exporter
                        </LoadingButton>
                    </Box>
                </form>

                <DataGrid
                    rows={rows}
                    columns={columns}
                    showCellRightBorder
                    autoPageSize={true}
                    rowsPerPageOptions={[pageSize]}
                    onSelectionModelChange={(ids) => {
                        const selectedIDs = new Set(ids);
                        const selectedRowData = rows.filter((row) =>
                            selectedIDs.has(row.id.toString())
                        );
                        if (selectedRowData?.length > 0) {
                            const { id, ...rest } = selectedRowData[0];
                            setSelect({ siteId: id, ...rest });
                        } else {
                            setSelect(null);
                        }
                    }}
                    columnVisibilityModel={{
                        id: false,
                    }}
                    sx={{ backgroundColor: "#fff", width: "100%", height: "68%" }}
                />
            </div>
        </div>
    );
}

export default ReportExclusion;