import instance from "../../../../shared/components/auth/service/AxiosInstance";

export const TransactionService = {
  getTransactions: (pageNo, pageSize, date) =>
    instance.get(
      process.env.REACT_APP_API_URL +
        "Prepaid/transaction" +
        "?pageSize=" +
        pageSize +
        "&pageNo=" +
        pageNo +
        "&date=" +
        date
    ),

  getTransactionsByClientSite: (pageNo, pageSize, date, clientId, siteId) =>
    instance.get(
      process.env.REACT_APP_API_URL +
        "Prepaid/transaction/client/" +
        clientId +
        "?pageSize=" +
        pageSize +
        "&pageNo=" +
        pageNo +
        "&date=" +
        date +
        "&site="+
        siteId 
    ),

  getTransaction: (id) =>
    instance.get(process.env.REACT_APP_API_URL + "Prepaid/transaction/" + id),

  createTransaction: (transaction) =>
    instance.post(
      process.env.REACT_APP_API_URL + "Prepaid/transaction",
      transaction
    ),
};
