import instance from "../../../shared/components/auth/service/AxiosInstance";

export const RoleService = {

    getRoles: (pageNo, pageSize) =>
        instance.get(process.env.REACT_APP_API_URL + "role/" +
            '?pageSize=' + pageSize +
            '&pageNo=' + pageNo
        ),
    
    createRole: (role) =>
    instance.post(process.env.REACT_APP_API_URL + 'role/',
        role
    ),

    updateRole: (id, role) =>
        instance.put(process.env.REACT_APP_API_URL + 'role/' + id, 
            role
        ),

    deleteRole: (id) =>
        instance.delete(process.env.REACT_APP_API_URL + 'role/' + id
        ),
}