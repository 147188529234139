import instance from "../../../../shared/components/auth/service/AxiosInstance"

export const ClientGroupService = {

    getClientGroup: (pageNo, pageSize) =>
        instance.get(process.env.REACT_APP_API_URL + "ClientGroup/" +
            '?pageSize=' + pageSize +
            '&pageNo=' + pageNo
        ),

    getClientGroupById: (id) =>
        instance.get(process.env.REACT_APP_API_URL + "ClientGroup/" + id),

    createClientGroup: (clientGroup) =>
        instance.post(process.env.REACT_APP_API_URL + 'ClientGroup/',
        clientGroup
        ),

    updateClientGroup: (id, clientGroup) =>
        instance.put(process.env.REACT_APP_API_URL + 'ClientGroup/' + id, 
        clientGroup
        ),

    deleteClientGroup: (id) =>
        instance.delete(process.env.REACT_APP_API_URL + 'ClientGroup/' + id
        ),
}