import { authHeader } from "../../../../shared/components/auth/auth-header";
import instance from "../../../../shared/components/auth/service/AxiosInstance"

export const VoucherConfigService = {

    getAllVoucherConfig: () => instance.get(process.env.REACT_APP_API_URL + 'voucherconfig/'
    ),

    getVoucherConfigByVoucherTypeAndSite: (voucherId, siteId) => 
    instance.get(process.env.REACT_APP_API_URL + 'voucherconfig/' + 
            "vouchertype/" + voucherId +
            "/site/" + siteId
        ),

    getVoucherType:(pageNo, pageSize) => 
       instance.get(process.env.REACT_APP_API_URL + "VoucherType/"+
        "?pageNo="+pageNo+
        "&pageSize="+pageSize
       ),
}