import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from '@mui/material';
import { formatDateString, getISODate } from "../../../shared/utils/localeISODateTime";

export const Consultation = (props) => {

    const { t } = useTranslation();

    const {
        selectedConsultation,
    } = props;

    return (
        <>
            <h4>{t('veterinary.consultation')}: {t('veterinary.detailed-view')}</h4>
            <Box className="consultation-details">
                <p><b>{t('date')}:</b> {formatDateString(selectedConsultation?.datetime)}</p>

                <Box><b>{t('veterinary.anamnesis')}:</b></Box>
                <p>{selectedConsultation?.anamnesis}</p>

                <Box><b>{t('veterinary.symptoms')}:</b></Box>
                <p>{selectedConsultation?.symptoms}</p>

                <Box><b>{t('veterinary.diagnosis')}:</b></Box>
                <p>{selectedConsultation?.diagnosis}</p>

                <Box><b>{t('veterinary.treatment')}:</b></Box>
                <p>{selectedConsultation?.treatment}</p>
            </Box>
        </>
    );
};