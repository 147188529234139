import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../../redux/features/auth/authSlice";

import Input from '@mui/material/Input';
import './Login.css';

const initValues = {
  login: "",
  password: ""
}

function Login(props) {

  const [authData, setAuthData] = useState(initValues);
  const [showPassword, setShowPassword] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const { isLoggedIn } = useSelector((state) => state.auth);

  const { backGroundColor, navigateTo } = props
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAuthData({
      ...authData,
      [name]: value,
    });
  };


  const handleShowPassword = (e) => {
    setShowPassword(show => !show);
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(login(authData))
      .unwrap()
      .then(() => {
        navigate(navigateTo);
        window.location.reload();
      })
      .catch(({error}) => {
        setAuthData({
          ...authData,
          ['login']: '',
          ['password']: '',
        });
        if (error?.statusCode === 403 || error?.statusCode === 401 
          || error?.response?.status === 403 || error?.response?.status === 401
        ) {
          setErrMsg(error?.response?.data ? error.response.data : "Connexion refusée");
        } else {
          setErrMsg("Identifiant ou mot de passe incorrect")
        }
      });
  };

  return (
    <>
      <div className="container px-4 py-5 mx-auto">
        <div className="card card0">
          <div className="d-flex flex-lg-row flex-column-reverse">
            <div className="card card1">
              <div className="row justify-content-center my-auto">
                <div className="col-md-8 col-10 my-5">
                  <div className="mb-5 text-center heading">
                    <img className="logo" src={require("../logo/stemm.png")} />
                  </div>
                  <h3 style={{ fontFamily:'backToSchool', fontSize: '60px', color:'#bb0000', textAlign:'center', marginBottom:10 }}>
                    Roger
                  </h3>
                  <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                      <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'right', width: '100%', margin: '6px 0' }}>
                        <label htmlFor="fname" style={{ margin: 8 }}>Identifiant</label>
                        <input
                          type="text"
                          color="white"
                          id="login"
                          name="login"
                          label="Identifiant"
                          variant="outlined"
                          value={authData.login}
                          onChange={handleInputChange}
                          style={{ width: "60%" }}
                        />
                      </div>
                      <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'right', width: '100%', margin: '6px 0' }}>
                        <label htmlFor="fname" style={{ margin: 8 }}>Mot de passe</label>
                        <input
                          color="white"
                          id="password"
                          name="password"
                          type="password"
                          label="Mot de passe"
                          variant="outlined"
                          value={authData.password}
                          onChange={handleInputChange}
                          style={{ width: "60%" }}
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center my-3 px-3">
                      <button className="btn-block btn-color" style={{ width: '200px' }} onClick={handleSubmit}>Connexion</button>
                    </div>
                  </form>
                  <Typography align="center" color="error">{errMsg}</Typography>
                </div>
              </div>
            </div>
            <div className="card card2">
              <div className="my-auto mx-md-5 px-md-5 right">
                <h3 className="text-white">Nous sommes plus qu'une entreprise</h3>
                <small className="text-white">Stëmm vun der Strooss a pour objet de réaliser et de promouvoir toutes les activités qui sont en rapport avec l’information, la représentation et la défense des intérêts des couches sociales exclues ou à risque d’exclusion. Le service s’adresse à des personnes adultes ayant des problèmes d’ordre médico-psycho-social.
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <script type='text/javascript' src='https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/js/bootstrap.bundle.min.js'></script>
    </>
  );
}

export default Login;