import instance from "../../../shared/components/auth/service/AxiosInstance"

export const PresenceService = {
    getPresences: (pageNo, pageSize, date) =>
        instance.get(process.env.REACT_APP_API_URL +
            'presence/?pageSize=' + pageSize +
            '&pageNo=' + pageNo +
            '&date=' + date,
        ),

    getPresencesBySite: (pageNo, pageSize, date, siteId) =>
        instance.get(process.env.REACT_APP_API_URL +
            'presence/site/' + siteId +
            '/?pageSize=' + pageSize +
            '&pageNo=' + pageNo +
            '&date=' + date,
    ),

    getPresenceIdsBySite: (date, siteId) =>
        instance.get(process.env.REACT_APP_API_URL +
            'presence/site/' + siteId +
            '/clients/?date=' + date,
    ),

    createPresence: (Presence, siteId) =>
        instance.post(process.env.REACT_APP_API_URL + 'presence/site/' + siteId, Presence),

    deletePresence: (id) =>
        instance.delete(process.env.REACT_APP_API_URL + 'presence/' + id),

    updateMealQuantity: (id, quantity, balance, siteId) =>
        instance.put(process.env.REACT_APP_API_URL + 'presence/' + id + '/meal/' + quantity + '/' + balance + '/site/' + siteId,
            {},
        ),

    createAllPresences: (presences) =>
        instance.post(process.env.REACT_APP_API_URL + 'presence/presences/ATI', presences),
}