import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    Box,
    Typography
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { logout } from "../../../redux/features/auth/authSlice";
import ButtonManager from "../../../shared/components/Button/ButtonManager";
import { capitalizeAllWords } from "../../../shared/utils/StringProcessing";
import {
    DateYmdToDmY,
    getISODate,
    localeISODateTime,
    monthDiffBetweenDates,
} from "../../../shared/utils/localeISODateTime";
import { ContractService } from "../../client/service/SubService/ContractService";
import { JobService } from "../../job/service/JobService";
import { SiteService } from "../../site/service/SiteService";
import { ReportService } from "../service/ReportService";
import { ExportToFile } from "../utils/ExportToFile";
import { DateSelector } from "./selectors/DateSelector";
import { SiteSelector } from "./selectors/SiteSelector";

import "./ReportContract.css";

const today = getISODate(localeISODateTime());

const forms = [
  { name: "startDate", label: "Start", type: "date" },
  { name: "endDate", label: "End", type: "date" },
  { name: "appointmentDate", label: "Appointment Date", type: "date" },
  { name: "referrerStemm", label: "Referrer Stemm", type: "string" },
  { name: "referrerSnas", label: "Referrer Snas", type: "string" },
  { name: "cityArisOnis", label: "City Aris Onis", type: "string" },
  { name: "scheduleStart", label: "Schedule Start", type: "time" },
  { name: "scheduleEnd", label: "Schedule End", type: "time" },
  { name: "hoursPerWeek", label: "Hours per week", type: "string" },
  { name: "reasonEndContract", label: "End Contract reason", type: "string" },
//  { name: "yearsMapImpot", label: "Years of Impot", type: "string" },
//  { name: "siteNotRetained", label: "Site Not Retained", type: "string" },
  { name: "activityAfterStemm", label: "Activity After Stemm", type: "string" },
//  { name: "referrerNotRetained", label: "Referrer not retained", type: "string", },
//  { name: "reasonNotRetained", label: "Reason not retained", type: "string" },
];

const requiredFields = [
  "job",
  "sites",
  "firstContact",
  "appointmentDate",
  "scheduleStart",
  "scheduleEnd",
  "startDate",
  "endDate",
  // "reasonEndContract",
  // "activityAfterStemm",
  // "referrerNotRetained",
  // "reasonNotRetained",
];

const requiredFieldLabel = [
  "Poste",
  " Site",
  " Premier contact",
  " Date de Rendez-vous ",
  " Horaire de début",
  " Horaire de fin",
  " Début",
  " Fin",
  // " Raison de fin de contrat",
  // " Activité après Stemm",
  // " Référent non retenu",
  // " Raison non retenue",
];

const ReportContract = (props) => {
  const { setActive } = props;
  useEffect(() => {
    //setActive(4);
  }, []);

  const dispatch = useDispatch();

  const [inputs, setInputs] = useState({
    dateStart: today,
    dateEnd: today,
    status: "OK",
    sites: [],
  });
  const [jobs, setJobs] = useState([]);
  const [sites, setSites] = useState([]);
  const [message, setMessage] = useState("");

  const [printLoading, setPrintLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [previousData, setPreviousData] = useState(null);

  const currentPage = 0;
  const pageSize = 50;
  const [rows, setRows] = useState([]);
  const [select, setSelect] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    let val = event.target.value;
    try {
      val = JSON.parse(event.target.value);
    } catch (err) {}
    let value = event.target.type === "checkbox" ? event.target.checked : val;

    //Checkbox group
    if (
      document.querySelectorAll("input[name=" + name + "]").length > 1 &&
      event.target.type === "checkbox"
    ) {
      value = [];
      document
        .querySelectorAll("input[name=" + name + "]:checked")
        .forEach((input) => value.push(JSON.parse(input.value)));
    }

    setInputs((values) => ({ ...values, [name]: value }));
  };

  const refresh = (searchParams) => {
    ReportService.getReportContract({
      dateStart: searchParams?.dateStart || inputs.dateStart,
      dateEnd: searchParams?.dateEnd || inputs.dateEnd,
      status: searchParams?.status || inputs.status,
      sites: searchParams?.sites || inputs.sites,
    })
      .then((response) => {
        const data = response.data.content?.sort(
          (contract1, contract2) =>
            contract1?.sites[0]?.description?.localeCompare(
              contract2?.sites[0]?.description
            ) || contract1?.startDate?.localeCompare(contract2?.startDate)
        );
        setRows(
          data?.map((row) => {
            const { contractId, ...rest } = row;
            return { id: contractId, ...rest };
          }) || []
        );
        let selected = data?.filter(
          (contract) => contract?.contractId === select?.contractId
        );
        setSelect(selected?.length ? selected[0] : {});
      })
      .catch((err) => setMessage("Erreur: " + err));
  };

  const handleSearch = () => {
    setPrintLoading(true);
    refresh();
    setPrintLoading(false);
  };

  const handleChangeContractType = (e) => {
    setInputs((values) => ({ ...values, ["status"]: e.value }));
  }

  const handleExport = () => {
    setSubmitLoading(true);
    refresh(inputs);
    ExportToFile(
      rows?.map((row) => {
        const {
          client,
          sites,
          appointmentDate,
          referrerStemm,
          referrerSnas,
          cityArisOnis,
          firstContact,
          //reasonNotRetained,
          //referrerNotRetained,
          //siteNotRetained,
          job,
          scheduleStart,
          scheduleEnd,
          hoursPerWeek,
          startDate,
          endDate,
          reasonEndContract,
          activityAfterStemm,
          //yearsMapImpot,
        } = row;
        return {
          Nom:
            (
              client.lastnametempo.toUpperCase() +
              " " +
              capitalizeAllWords(client.firstnametempo)
            ).trim() +
            " (" +
            client.surnametempo +
            ")",
          "Date RDV": appointmentDate,
          "Site de référence":
            sites.length > 0
              ? sites.map((site) => site.description).join(",")
              : "",
          "Référent Stëmm": referrerStemm,
          "Référent ARIS-ONIS": referrerSnas,
          "Ville ARIS-ONIS": cityArisOnis,
          "Premier contact": firstContact,
          //"Non retenu - Motif": reasonNotRetained,
          //"Non retenu - Référent": referrerNotRetained,
          //"Non retenu - Site": siteNotRetained,
          "Poste occupé": job?.description,
          "Heure début": scheduleStart,
          "Heure fin": scheduleEnd,
          "Nombre heures / semaine": hoursPerWeek,
          "Début contrat": startDate,
          "Fin contrat": endDate,
          "Motif fin contrat": reasonEndContract,
          "Activité après Stëmm": activityAfterStemm,
          //"Carte d'impôt remis": yearsMapImpot,
        };
      }),
      "contrats_" + new Date().toISOString().split(".")[0]
    );
    setMessage("Export de " + (rows?.length ? rows.length : 0) + " ligne(s)");
    setSubmitLoading(false);
  };

  useEffect(() => {
    JobService.getJobs(0, -1)
      .then((response) => {
        let jobList = response.data.content
          .sort((job1, job2) =>
            job1?.description?.localeCompare(job2?.description)
          )
          .map((row) => {
            const { jobId, description, ...rest } = row;
            return { value: row, name: description };
          });
        setJobs({
          name: "job",
          label: "Job",
          type: "select",
          values: jobList,
          selected: jobList[0].value,
        });
      })
      .catch((err) => {
        console.log(err);
        if (
          err?.statusCode === 403 ||
          err?.statusCode === 401 ||
          err?.response?.status === 403 ||
          err?.response?.status === 401
        ) {
          dispatch(logout());
        }
      });

    SiteService.getSites(0, -1)
      .then((response) => {
        let siteList = response.data.content.sort((job1, job2) =>
          job1?.description?.localeCompare(job2?.description)
        );
        setSites({
          name: "sites",
          label: "sites",
          type: "select",
          values: siteList.map((row) => {
            const { jobId, description, ...rest } = row;
            return { value: row, name: description };
          }),
          selected: siteList[0].value,
        });
        refresh({ ...inputs, sites: siteList });
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (select?.client?.clientId) {
      ContractService.getLastContract(select?.client?.clientId)
        .then(({status, data}) => {
          let { contractId, startDate, endDate, appointmentDate, ...rest } = data;
          setPreviousData({ ...rest });
        })
        .catch(err => console.log(err));
      }
  }, [select]);

  const columns = [
    { field: "id", headerName: "Id", hideable: true },
    {
      field: "fullname",
      headerName: "Nom",
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "left",
      flex: 1.5,
      renderCell: (data) =>
        (
          (data.row.client?.lastnametempo?.toUpperCase() || "") +
          " " +
          (capitalizeAllWords(data.row.client?.firstnametempo) || "")
        ).trim() +
        (data.row.client?.surnametempo
          ? " (" + data.row.client.surnametempo + ")"
          : ""),
    },
    {
      field: "site",
      headerName: "Site réf.",
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (data) =>
        data.row.sites && (
          <span>
            {data.row.sites.map((site) => site.description).join(",")}
          </span>
        ),
    },
    {
      field: "job",
      headerName: "Poste",
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (data) =>
        data.row.job && <span>{data.row.job.description}</span>,
    },
    {
      field: "referrerStemm",
      headerName: "Référent",
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Début",
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (data) => DateYmdToDmY(data.value, "."),
    },
    {
      field: "endDate",
      headerName: "Fin",
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (data) => DateYmdToDmY(data.value, "."),
    },
    {
      field: "count",
      headerName: "Décompte mois",
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      renderCell: (data) => (
        <span>
          {monthDiffBetweenDates(new Date(), new Date(data?.row?.endDate))}
        </span>
      ),
    },
    {
      field: "hoursPerWeek",
      headerName: "h/semaine",
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
    },
    {
      field: "edit",
      headerName: "Editer",
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      renderCell: (data) => (
        <ButtonManager
          onlyIcon={true}
          title={"contract"}
          id={select?.contractId}
          selected={select}
          align={"center"}
          preSetInputs={{ firstContact: select?.firstContact || "ATTENTE" }}
          forms={[
            { ...jobs, default: select?.job ? select.job : jobs.selected },
            {
              ...sites,
              default:
                select?.sites?.length > 0 ? select.sites[0] : sites.selected,
            },
            {
              name: "firstContact",
              label: "First Contact",
              type: "select",
              values: [
                { name: "OK", value: "OK" },
                { name: "ATTENTE", value: "ATTENTE" },
                { name: "NON_RETENU", value: "NON_RETENU" },
              ],
              default: select?.firstContact ? select.firstContact : "ATTENTE",
            },
            ...forms,
          ]}
          updateFunction={ContractService.updateContract}
          requiredFields={requiredFields}
          requiredFieldLabel={requiredFieldLabel}
          refresh={refresh}
        />
      ),
    },
    {
      field: "create",
      headerName: "Créer",
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      renderCell: (data) => (
        <ButtonManager
          onlyIcon={true}
          title={"contract"}
          selected={select}
          align={"center"}
          preSetInputs={{client: select?.client}}
          forms={[
            { ...jobs, default: select?.job ? select.job : jobs.selected },
            {
              ...sites,
              default:
                select?.sites?.length > 0 ? select.sites[0] : sites.selected,
            },
            {
              name: "firstContact",
              label: "First Contact",
              type: "select",
              values: [
                { name: "OK", value: "OK" },
                { name: "ATTENTE", value: "ATTENTE" },
                { name: "NON_RETENU", value: "NON_RETENU" },
              ],
              default: select?.firstContact ? select.firstContact : "",
            },
            ...forms,
          ]}
          createFunction={ContractService.createContract}
          requiredFields={requiredFields}
          requiredFieldLabel={requiredFieldLabel}
          refresh={refresh}
          optionalData={previousData}
        />
      ),
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <h1
        style={{
          fontFamily: "backToSchool",
          fontSize: "65px",
          color: "#bb0000",
          marginTop: "4%",
        }}
      >
        Rapport Contrats
      </h1>
      <div style={{ position: "absolute", top: 23, left: 270 }}>
        <Link to="/gestionSelect">
          <IconButton color="primary">
            <ArrowBackIosNewIcon />
          </IconButton>
        </Link>
      </div>
      <div
        className="event-container display-relative"
        style={{ marginTop: "5%", height:'840px' }}
      >
        <Typography sx={{ textAlign: "center" }}>{message}</Typography>
        <form onSubmit={handleSearch}>
          <Box>
            <h1 style={{ fontFamily: "overlockBold" }}>Rechercher</h1>
            <Box>
              <div style={{ margin:'0px 130px' }}>
                <DateSelector
                  setInputs={setInputs}
                />
                <SiteSelector handleChange={handleChange} inputs={inputs} setInputs={setInputs}/>
                <div style={{ display:'flex', flexDirection:'row', justifyContent:'center', textAlign:'center', alignItems:'center', marginBottom:'20px' }}>
                  <span style={{ fontSize:'20px', fontFamily:'overlockBold', width:'30%', wordWrap:'none' }}>Type de contrat:</span>
                  <div style={{ width:'100%' }}>
                    <Select
                      defaultValue={{value:"OK", label:"Contrats en cours"}}
                      classNamePrefix="select"
                      options={
                        [{value:"OK", label:"Contrats en cours"},
                        {value:"A_TERME", label:"Contrats qui viennent à terme"},
                        {value:"NON_RETENU", label:"Listing des non retenus"},
                        {value:"ATTENTE", label:"En attente"}]
                      }
                      onChange={handleChangeContractType}
                      placeholder={"Type de contrat"}
                    />
                  </div>
                </div>
              </div>

            </Box>

            <LoadingButton
              loading={printLoading}
              variant="contained"
              sx={{ alignSelf: "center", marginRight: "1% 2% 1% 0%" }}
              onClick={handleSearch}
            >
              Rechercher
            </LoadingButton>
            <LoadingButton
              variant="contained"
              sx={{ alignSelf: "center", margin: "1% 0% 1% 2%" }}
              loading={submitLoading}
              onClick={handleExport}
            >
              Exporter
            </LoadingButton>
          </Box>
        </form>

        <DataGrid
          rows={rows}
          columns={columns}
          showCellRightBorder
          autoPageSize={true}
          rowsPerPageOptions={[pageSize]}
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = rows.filter((row) =>
              selectedIDs.has(row.id.toString())
            );
            if (selectedRowData?.length > 0) {
              const { id, ...rest } = selectedRowData[0];
              setSelect({ contractId: id, ...rest });
            } else {
              setSelect(null);
            }
          }}
          columnVisibilityModel={{
            id: false,
          }}
          sx={{ backgroundColor: "#fff", height: "53%" }}
        />
      </div>
    </div>
  );
};

export default ReportContract;
