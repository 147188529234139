import React from "react";
import { Grid } from "@mui/material";
import { Balance } from "@mui/icons-material";
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import "./ClientPartialInformation.css";

function ClientPartialInformation(props) {
  const { client, title, suppr } = props;

  return (
    <div className="clientPartialInformation" style={{borderRadius:'5px 5px 0px 0px', width:'100%'}}>
      <h3 style={{ fontFamily:'overlockBold' }}>
        {!suppr && <PersonIcon sx={{marginBottom:'10px'}}/>}
        {suppr && <PersonOffIcon sx={{marginBottom:'10px'}}/>}
        {title}</h3>
      <tr style={{borderRadius:'5px 5px 0px 0px'}}>
        <th scope="col" style={{borderRadius:'5px 0px 0px 0px'}}>Champs</th>
        <th scope="col" style={{borderRadius:'0px 5px 0px 0px'}}>Informations</th>
      </tr>
      <tr>
        <th scope="row">Nom</th>
        <td scope="row">{client?.lastnametempo}</td>
      </tr>
      <tr>
        <th scope="row">Prénom</th>
        <td scope="row">{client?.firstnametempo}</td>
      </tr>
          <tr>
        <th scope="row">Surnom</th>
        <td scope="row">{client?.surnametempo}</td>
      </tr>
      <tr>
        <th scope="row">Date de naissance</th>
        <td scope="row">{client?.birthdatetempo}</td>
      </tr>
      <tr>
        <th scope="row">Groupe Client</th>
        <td scope="row">{client?.clientGroup?.description}</td>
      </tr>
      <tr>
        <th scope="row">Message</th>
        <td scope="row">{client?.message}</td>
      </tr>
      <tr style={{borderRadius:'px 0px 5px 5px'}}>
        <th scope="row" style={{borderRadius:'0px 0px 0px 5px'}}>Balance</th>
        <td scope="row" style={{borderRadius:'0px 0px 5px 0px'}}>{client?.balance}</td>
      </tr>
    </div>
  );
}

export default ClientPartialInformation;
