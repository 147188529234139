import React, { useEffect } from "react";
import MaritalStatusListing from "./pages/MaritalStatusListing";

function MaritalStatus(props) {
    const { setActive } = props;
    useEffect(() => {
      //setActive(3);
    }, []);

    return <MaritalStatusListing />
};

export default MaritalStatus;