import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import { alpha, darken, lighten, styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/features/auth/authSlice";
import ButtonManager from "../../../shared/components/Button/ButtonManager";
import { dateIsValid, getISODate, localeISODateTime } from "../../../shared/utils/localeISODateTime";
import { ColorLegend } from "../legend/ColorLegend";
import { ClientService } from "../service/ClientService";
import { ResearchClientService } from "../service/ResearchClientService";


import { PresenceService } from "../../presence/services/PresenceService";
import "./ResearchClient.css";


const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  border: "10px solid rgba(255, 255, 255, 1",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    fontFamily: "Helvetica",
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "50ch",
    },
  },
}));

const columns = [
  {
    field: "id",
    headerName: "Id",
    hideable: true,
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "lastnametempo",
    headerName: "Nom",
    flex: 1,
    headerAlign: "center",
    align: "center",
    headerClassName: "researchColmun",
  },
  {
    field: "firstnametempo",
    headerName: "Prénom",
    flex: 1,
    headerAlign: "center",
    align: "center",
    headerClassName: "researchColmun",
  },
  {
    field: "surnametempo",
    flex: 1,
    headerName: "Surnom",
    headerAlign: "center",
    align: "center",
    headerClassName: "researchColmun",
  },
  {
    field: "birthdatetempo",
    headerName: "Date de naissance",
    flex: 1,
    headerAlign: "center",
    align: "center",
    headerClassName: "researchColmun",
    renderCell: (data) =>
      data?.value ? moment(data.value).format("DD-MM-YYYY") : null,
    filterEditorProps: (props, { index }) => {
      return {
        dateFormat: "DD-MM-YYYY",
        cancelButton: false,
        highlightWeekends: false,
        placeholder:
          index === 1
            ? "Created date is before..."
            : "Created date is after...",
      };
    }
  }
];

const sexes = [
  "Homme",
  "Femme",
  "Autre"
];

const forms = [
  { name: "firstnametempo", label: "Prénom", type: "string", required: true },
  { name: "lastnametempo", label: "Nom", type: "string", required: true },
  { name: "sexe", label: "Sexe", type: "select", 
      options: [
        {name: "Homme", value: "Homme"}, 
        {name: "Femme", value: "Femme"},
        {name: "Autre", value: "Autre"},
      ],
      required: true,
  },
  {
    name: "birthdatetempo",
    label: "Date de naissance",
    type: "date",
    valueDate: "",
    required: true,
  },
  { name: "surnametempo", label: "Surnom", type: "string" },
  { name: "phonetempo", label: "Numéro de tel.", type: "number" },
  { name: "address", label: "Addresse", type: "string" },
  {
    name: "registrationNumbertempo",
    label: "Matricule",
    type: "number",
  },
  {
    name: "costoflivingAllowanceDate",
    label: "Date demande alloc vie chère",
    type: "date",
    valueDate: ""
  },
  {
    name: "workContraindication",
    label: "Contre indication au travail",
    type: "string",
  },
  {
    name: "financialManagement",
    label: "Gestion financière",
    type: "string",
  },
  { name: "otherServices", label: "Autres services", type: "string" },
  { name: "remark", label: "Remarque", type: "string" },
  { name: "message", label: "Message", type: "string" },
];

function ResearchClient(props) {

  const {
    handleSelectClient,
    showButton,
    setAddNewPresence,
    setOpenDrawer,
    openDrawer,
    legalStatus,
    nationality,
    clientGroups,
    residents,
    countries,
    maritalStatus,
    drivers,
    accomodations,
    addNewPresence,
    today,
    presences,
  } = props;

  const dispatch = useDispatch();
  const site = useSelector((state) => state.site.site);
  const user = useSelector((state) => state.auth.user);

  const [selected, setSelected] = useState({});

  const [focus, setFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const [clients, setClients] = useState(null);

  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 40,
  });
  const [rowCountState, setRowCountState] = useState(0);
  const [selectionModel, setSelectionModel] = useState([]);
  const [defaultPageNo, setDefaultPageNo] = useState(0);
  const [defaultPageSize, setDefaultPageSize] = useState(40);

  const [inputs, setInputs] = useState("");

  const [popupMsg, setPopupMsg] = useState("");
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const [openFirstTime, setOpenFirstTime] = useState(false);
  const handleCloseFirstTime = () => setOpenFirstTime(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (event) => {
    const value = event.target.value;
    setInputs(value.replace(/[.*+!§?^${}()@#:<>&£°¨%´""=_\-\|[\]\\]/g, ""));
  };

  const exclusionStatus = (row) => {
    if (row.exclusion) {
      let exclusionDateStart = new Date(row.exclusion?.dateStart + "T00:00:00.000");
      let exclusionDateEnd = new Date(row.exclusion?.dateEnd + "T23:59:59.999");
      if ((exclusionDateStart <= new Date(today)) && (new Date(today) <= exclusionDateEnd)) {
        return "-danger";
      } else {
        return "-warning";
      }
    }
    if (row.firstTime) {
      return "-blue";
    }
    if (row?.isPresent === true) {
      return "-success";
    }
    if ((row.clientGroup.clientGroupId === 'JA') || (row.clientGroup.clientGroupId === 'Og')) {
      return "-tuc";
    }
    return "";
  };

  const refresh = (
    { pageNo, pageSize } = {
      pageNo: rowsState.page,
      pageSize: rowsState.pageSize,
    }
  ) => {
    return PresenceService.getPresenceIdsBySite((dateIsValid(today) ? getISODate(localeISODateTime(today)) : getISODate(localeISODateTime())), site?.siteId)
      .then(({status, data}) => {
        let presenceList = data;
        return presenceList;
      })
      .then((presenceList) => {
          ResearchClientService.getClients(pageNo, pageSize, inputs).then(
          (response) => {
            const data = response.data.content;
            setClients(
              data.map((row) => {
                const { clientId, clientGroup, ...rest } = row;
                return { 
                  id: clientId, 
                  isPresent: presenceList?.includes(clientId),  
                  clientGroup,
                  ...rest 
                };
              })
            );
            let selectedClient = data.filter(
              (client) => client.clientId === selected?.clientId
            )[0];
            setRowCountState(response.data.totalElements);
            setSelected(selectedClient);
            handleSelectClient(selectedClient);
          }
        )
        .catch((err) => {
          if (
            err?.statusCode === 403 ||
            err?.statusCode === 401 ||
            err?.response?.status === 403 ||
            err?.response?.status === 401
          ) {
            dispatch(logout());
          }
        })
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    refresh();
  }, [openDrawer])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
        refresh({
          pageNo: defaultPageNo,
          pageSize: defaultPageSize,
        })
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [inputs, today, presences]);

  if (clients != null) {
    return (
      <>
        <div style={{ padding: 10}}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h3 style={{ textAlign: "center", fontFamily: "overlockBold" }}>
              Recherche :
            </h3>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                border: "1px solid #efefef",
                borderRadius: "4px",
                padding: "0px 5px 0px 10px",
                margin: "5px 0px 5px 0px",
                backgroundColor: "#fff",
              }}
            >
              <Search>
                <SearchIcon />
                <StyledInputBase
                  sx={{ width: "70%" }}
                  id="reasearch"
                  name="research"
                  placeholder="Rechercher un client"
                  inputProps={{ "aria-label": "search" }}
                  onChange={handleChange}
                />
              </Search>
            </div>

            <ColorLegend />

          </div>
          {showButton === true && (
            <ButtonManager
              onlyIcon={true}
              title={"client"}
              id={selected?.clientId}
              selected={selected}
              align={"center"}
              forms={[
                { ...clientGroups, required: true, },
                { ...nationality, required: true, },
                ...forms,
                { ...countries, },
                { ...residents, },
                { ...legalStatus, },
                { ...accomodations, },
                { ...drivers, },
                { ...maritalStatus, },
              ]}
              inputs={inputs}
              requiredFields={[
                "firstnametempo",
                "lastnametempo",
                "birthdatetempo",
                "nationality",
                "clientGroup",
                "sexe",
              ]}
              requiredFieldLabel={[
                "Prénom",
                "Nom",
                "Date de naissance",
                "Nationalité",
                "Groupe client",
                "Sexe",
              ]}
              handleClose={handleClose}
              preSetInputs={{ sites: [site] }}
              createFunction={ClientService.createClient}
              deleteFunction={selected ? ClientService.deleteClient : null}
              refresh={refresh}
            />
          )}
          <div className="researchGrid">
            <Box
              sx={{
                height:'800px',
                width:'100%',
                "& .super-app-theme-warning": {
                  bgcolor: (theme) =>
                    getBackgroundColor(
                      theme.palette.warning.main,
                      theme.palette.mode
                    ),
                  "&:hover": {
                    bgcolor: (theme) =>
                      getHoverBackgroundColor(
                        theme.palette.warning.main,
                        theme.palette.mode
                      ),
                  },
                },
                "& .super-app-theme-danger": {
                  bgcolor: (theme) =>
                    getBackgroundColor(
                      theme.palette.error.main,
                      theme.palette.mode
                    ),
                  "&:hover": {
                    bgcolor: (theme) =>
                      getHoverBackgroundColor(
                        theme.palette.error.main,
                        theme.palette.mode
                      ),
                  },
                },
                "& .super-app-theme-blue": {
                  bgcolor: (theme) =>
                    getBackgroundColor(
                      theme.palette.primary.light,
                      theme.palette.mode
                    ),
                  "&:hover": {
                    bgcolor: (theme) =>
                      getHoverBackgroundColor(
                        theme.palette.primary.light,
                        theme.palette.mode
                      ),
                  },
                },
                "& .super-app-theme-success": {
                  bgcolor: (theme) =>
                    getBackgroundColor(
                      theme.palette.success.light,
                      theme.palette.mode
                    ),
                  "&:hover": {
                    bgcolor: (theme) =>
                      getHoverBackgroundColor(
                        theme.palette.success.light,
                        theme.palette.mode
                      ),
                  },
                },
                "& .super-app-theme-tuc": {
                  bgcolor: (theme) =>
                    getBackgroundColor(
                      theme.palette.secondary.light,
                      theme.palette.mode
                    ),
                  "&:hover": {
                    bgcolor: (theme) =>
                      getHoverBackgroundColor(
                        theme.palette.secondary.light,
                        theme.palette.mode
                      ),
                  },
                },
              }}
            >
              <DataGrid
                rowsPerPageOptions={[rowsState.pageSize]}
                rowHeight={30}
                sx={{ backgroundColor: "#fff" }}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      id: false,
                    },
                  },
                }}
                columns={columns}
                rows={clients}
                getRowClassName={(params) => `super-app-theme${exclusionStatus(params.row)}`}
                rowCount={rowCountState}
                pagination
                {...rowsState}
                showCellRightBorder
                paginationMode="server"
                selectionModel={selectionModel}
                onPageChange={(page) => {
                  setDefaultPageNo(page);
                  setRowsState((prev) => ({ ...prev, page }));
                  refresh({ pageNo: page, pageSize: defaultPageSize });
                }}
                onPageSizeChange={(pageSize) => {
                  setDefaultPageSize(pageSize);
                  setRowsState((prev) => ({ ...prev, pageSize }));
                  refresh({ pageNo: defaultPageNo, pageSize: pageSize });
                }}
                onSelectionModelChange={(newSelectionModel) => {
                  const selectedIDs = new Set(newSelectionModel);
                  const selectedRows = clients.filter((row) =>
                    selectedIDs.has(row.id)
                  );

                  if (selectedRows.length && selectedRows[0] !== null) {
                    const { id, popup, message, firstTime, ...rest } =
                      selectedRows[0];
                    setSelected({
                      clientId: id,
                      popup,
                      message,
                      firstTime,
                      ...rest,
                    });
                    handleSelectClient({
                      clientId: id,
                      popup,
                      message,
                      firstTime,
                      ...rest,
                    });

                    if (
                      popup === true &&
                      typeof setAddNewPresence === "undefined"
                    ) {
                      setPopupMsg(message);
                      setOpen(true);
                    }
                    if (
                      firstTime === true &&
                      typeof setAddNewPresence === "undefined"
                    ) {
                      setOpenFirstTime(true);
                    }

                    if (setOpenDrawer) {
                      setOpenDrawer(false);
                    }
                  } else {
                    setSelected(null);
                    handleSelectClient(null);
                  }
                  setSelectionModel(newSelectionModel);
                }}
                onCellDoubleClick={(params, event) => {
                  event.defaultMuiPrevented = true;
                  const { id, popup, message, firstTime, ...rest } = params.row;
                  setSelected({
                    clientId: id,
                    popup,
                    message,
                    firstTime,
                    ...rest,
                  });
                  handleSelectClient({
                    clientId: id,
                    popup,
                    message,
                    firstTime,
                    ...rest,
                  });
                  if (popup) {
                    setPopupMsg(message);
                    setOpen(true);
                  }
                  if (setAddNewPresence) setAddNewPresence(true);
                }}
              />
            </Box>
          </div>

          <Dialog fullScreen={fullScreen} onClose={handleClose} open={open}>
            <DialogTitle>
              Message
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>{popupMsg}</DialogContent>
          </Dialog>

          <Dialog
            fullScreen={fullScreen}
            onClose={handleCloseFirstTime}
            open={openFirstTime}
          >
            <DialogTitle>
              Présence
              <IconButton
                aria-label="close"
                onClick={handleCloseFirstTime}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>Première présence</DialogContent>
          </Dialog>

        </div>
      </>
    );
  } else {
    return (
      <>
        <Box textAlign="center" mt={40}>
          <LoadingButton loading={true} variant="outlined" disabled>
            Fetch data
          </LoadingButton>
        </Box>
      </>
    );
  }
}
export default ResearchClient;
