import instance from "../../../shared/components/auth/service/AxiosInstance";

export const UserService = {

    getUsers: (pageNo, pageSize) =>
        instance.get(process.env.REACT_APP_API_URL + "user/" +
            '?pageSize=' + pageSize +
            '&pageNo=' + pageNo
        ),

    getUserByUserName: (userName) =>
        instance.get(process.env.REACT_APP_API_URL + "user/name/" +
            userName
        ),

    createUser: (user) =>
        instance.post(process.env.REACT_APP_API_URL + 'user/',
            {
                userName: user.userName,
                name: user.name,
                accountEnabled: (user?.accountEnabled ? user.accountEnabled : false),
                canChangePassword: (user?.canChangePassword ? user.canChangePassword : false),
                passwordHash: (user?.password ? user.password : ''),
                roleList: user.roleList,
                siteList: user.siteList,
                defaultSite: (user?.defaultSite ? user.defaultSite : null),
            }
        ),

    updateUser: (id, user) =>
        instance.put(process.env.REACT_APP_API_URL + 'user/' + id,
            {
                userId: user.userId,
                userName: user.userName,
                name: user.name,
                accountEnabled: (user?.accountEnabled ? user.accountEnabled : false),
                canChangePassword: (user?.canChangePassword ? user.canChangePassword : false),
                passwordHash: (user?.password ? user.password : ''),
                roleList: user.roleList,
                siteList: user.siteList,
                defaultSite: (user?.defaultSite ? user.defaultSite : null),
            }
        ),

    deleteUser: (id) =>
        instance.delete(process.env.REACT_APP_API_URL + 'user/' + id
        ),

    updateUserPassword: (user) =>
        instance.put(process.env.REACT_APP_API_URL + 'user/pwd',
            user
        ),

}