import React, { useState } from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from '@mui/material/IconButton';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";

import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import DoDisturbOffOutlinedIcon from "@mui/icons-material/DoDisturbOffOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SearchIcon from '@mui/icons-material/Search';

import ClientContract from "./tabs/Contract/ClientContract";
import ClientExclusion from "./tabs/Exclusion/ClientExclusion";
import ClientFrequentation from "./tabs/Frequentation/ClientFrequentation";
import ClientHistorique from "./tabs/Historique/ClientHistorique";
import ClientInformation from "./tabs/Information/ClientInformation";
import ClientTransaction from "./tabs/Transaction/ClientTransaction";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 5 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function ClientTab(props) {

  const {
    client,
    setClient,
    clientBalance,
    setClientBalance,
    clientEmergencyContacts,
    setClientEmergencyContacts,
    handleSelectClient,
    handleDrawer,
    drivers,
    legalStatus,
    clientGroups,
    accomodations,
    residents,
    countries,
    nationalities,
    maritalStatus
  } = props;

  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box style={{ height: "100%", width: "100%", margin: '0 auto' }}>
      <AppBar position="relative" sx={{display:'block-flex', alignItems:'center'}}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="scrollable"
        >
          <Tab
            icon={<PersonPinIcon />}
            iconPosition="start"
            label="Informations"
            {...a11yProps(0)}
          />
          <Tab
            icon={<FileCopyOutlinedIcon />}
            iconPosition="start"
            label="Historique"
            {...a11yProps(1)}
          />
          <Tab
            icon={<DoDisturbOffOutlinedIcon />}
            iconPosition="start"
            label="Exclus"
            {...a11yProps(2)}
          />
          <Tab
            icon={<BusinessCenterOutlinedIcon />}
            iconPosition="start"
            label="Contrat"
            {...a11yProps(3)}
          />
          <Tab
            icon={<InfoOutlinedIcon />}
            iconPosition="start"
            label="Fréquentation"
            {...a11yProps(4)}
          />
          <Tab
            icon={<ReceiptLongIcon />}
            iconPosition="start"
            label="Transaction"
            {...a11yProps(5)}
          />
        </Tabs>
        <Tooltip title="Rechercher un client">
          <IconButton onClick={handleDrawer} sx={{ color: '#bc270d' }}>
            <SearchIcon />
          </IconButton>
        </Tooltip>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
        style={{ height: "100%", width: "100%" }}
      >
        <TabPanel
          value={value}
          index={0}
          dir={theme.direction}
          style={{ height: "100%", width: "100%" }}
        >
          <h1 style={{ fontFamily: 'backToSchool', fontSize: '65px', color: '#bb0000' }}>Informations</h1>
          <ClientInformation
            setClient={setClient}
            handleSelectClient={handleSelectClient}
            client={client}
            clientBalance={clientBalance}
            clientEmergencyContacts={clientEmergencyContacts}
            drivers={drivers}
            legalStatus={legalStatus}
            clientGroups={clientGroups}
            accomodations={accomodations}
            residents={residents}
            countries={countries}
            nationalities={nationalities}
            maritalStatus={maritalStatus}
          />
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          dir={theme.direction}
          style={{ height: "100%", width: "100%" }}
        >
          <h1 style={{ fontFamily: 'backToSchool', fontSize: '65px', color: '#bb0000' }}>Historique</h1>
          <ClientHistorique client={client} />
        </TabPanel>
        <TabPanel
          value={value}
          index={2}
          dir={theme.direction}
          style={{ height: "100%", width: "100%" }}
        >
          <h1 style={{ fontFamily: 'backToSchool', fontSize: '65px', color: '#bb0000' }}>Exclusion(s)</h1>
          <ClientExclusion client={client} />
        </TabPanel>
        <TabPanel
          value={value}
          index={3}
          dir={theme.direction}
          style={{ height: "100%", width: "100%" }}
        >
          <h1 style={{ fontFamily: 'backToSchool', fontSize: '65px', color: '#bb0000' }}>Contrat(s)</h1>
          <ClientContract client={client} />
        </TabPanel>
        <TabPanel
          value={value}
          index={4}
          dir={theme.direction}
          style={{ height: "100%", width: "100%" }}
        >
          <h1 style={{ fontFamily: 'backToSchool', fontSize: '65px', color: '#bb0000' }}>Fréquentation</h1>
          <ClientFrequentation client={client} />
        </TabPanel>
        <TabPanel
          value={value}
          index={5}
          dir={theme.direction}
          style={{ height: "100%", width: "100%" }}
        >
          <h1 style={{ fontFamily: 'backToSchool', fontSize: '65px', color: '#bb0000' }}>Transaction</h1>
          <ClientTransaction client={client} />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}

export default ClientTab;
