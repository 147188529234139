import instance from "../../../../shared/components/auth/service/AxiosInstance"

export const LegalStatusService = {

    getLegalStatus: (pageNo, pageSize) =>
        instance.get(process.env.REACT_APP_API_URL + "LegalStatus/" +
            '?pageSize=' + pageSize +
            '&pageNo=' + pageNo
        ),

    getLegalStatusById: (id) => 
        instance.get(process.env.REACT_APP_API_URL + "LegalStatus/" + id),

    createLegalStatus: (legalStatus) =>
        instance.post(process.env.REACT_APP_API_URL + 'LegalStatus/',
            {
                description: legalStatus?.description,
                deleted: (legalStatus?.deleted ? legalStatus.deleted : false)
            }
        ),

    updateLegalStatus: (id, legalStatus) =>
        instance.put(process.env.REACT_APP_API_URL + 'LegalStatus/' + id, 
            {
                legalStatusId: legalStatus.legalStatusId,
                description: legalStatus?.description,
                deleted: (legalStatus?.deleted ? legalStatus.deleted : false)
            }
        ),

    deleteLegalStatus: (id) =>
        instance.delete(process.env.REACT_APP_API_URL + 'LegalStatus/' + id
        ),
}