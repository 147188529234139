import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth/authSlice';
import siteReducer from './features/site/siteSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        site: siteReducer,
    }
});

export default store;