import React, { useState, useEffect } from "react";
import i18n from './i18n';
import { useDispatch, useSelector } from "react-redux";

import { Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom";
import Home from "./components/home/Home";
import Login from "./components/login/Login";
import NavBarSide from "./components/navbar/NavBarSide";

import Client from "./components/client/Client";
import Event from "./components/event/Event";
import Presence from "./components/presence/Presence";
import GestionSelect from "./components/reports/gestionSelect";
import ReportClient from "./components/reports/pages/ReportClient";
import ReportClosing from "./components/reports/pages/ReportClosing";
import ReportContract from "./components/reports/pages/ReportContract";
import ReportExclusion from "./components/reports/pages/ReportExclusion";
import ReportPresence from "./components/reports/pages/ReportPresence";
import Accomodation from "./components/settings/accomodation/Accomodation";
import AgeGroup from "./components/settings/age-group/AgeGroup";
import ClientGroup from "./components/settings/client-group/ClientGroup";
import Country from "./components/settings/country/Country";
import DriverLicence from "./components/settings/driver-licence/DriverLicence";
import ClientFusion from "./components/settings/fusion/ClientFusion";
import Job from "./components/settings/job/Job";
import LaundryItem from "./components/settings/laundry-item/LaundryItem";
import LegalStatus from "./components/settings/legal-status/LegalStatus";
import MaritalStatus from "./components/settings/marital-status/MaritalStatus";
import Nationality from "./components/settings/nationality/Nationality";
import Resident from "./components/settings/resident/Resident";
import SettingsSelect from "./components/settings/settingsSelect";
import Site from "./components/site/Site";
import SiteSelect from "./components/site/pages/SiteSelect";
import User from "./components/user/User";
import UserSettings from "./components/user/pages/UserSettings";
import { Veterinary } from "./features/veterinary/Veterinary";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Scheduler } from "./shared/components/scheduler/Scheduler";

import Stat from "./components/stat/stat";
import { logout } from "./redux/features/auth/authSlice";
import './i18n';

const LanguageSwitcher = () => {
  const location = useLocation();

  useEffect(() => {
    // Si l'URL contient '/Veterinary', on passe la langue sur 'de', sinon sur 'fr'
    if (location.pathname.includes('/Veterinary')) {
      i18n.changeLanguage('de');
    } else {
      i18n.changeLanguage('fr');
    }
  }, [location.pathname]); // Déclenche lorsque l'URL change

  return null; // Ce composant ne rend rien, il gère juste le changement de langue
};

function App() {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state?.auth) || null;
  const site = useSelector((state) => state?.site?.site) || null;
  const tokenRoles = localStorage.getItem("roles");

  const [active, setActive] = useState(-1);

  if (currentUser === null) {
    dispatch(logout());
  }

  if (currentUser === null || currentUser?.token === null) {
    return (
      <>
        <Router>
          <LanguageSwitcher />
          <Routes>
            <Route path="*" element={<Login />} />
          </Routes>
        </Router>
      </>
    );
  } else if (site == null) {
    return (
      <>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Router>
            <LanguageSwitcher />
            <NavBarSide
              currentUser={currentUser}
              site={site}
              active={active}
              setActive={setActive}
            />
            <Routes>
              <Route path="/*" element={<SiteSelect setActive={setActive} />} />
              <Route
                path="/SiteSelect"
                element={<SiteSelect setActive={setActive} />}
              />
            </Routes>
          </Router>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Router>
            <LanguageSwitcher />
            <NavBarSide
              currentUser={currentUser}
              site={site}
              active={active}
              setActive={setActive}
            />
            <Routes>
              <Route path="/*" element={<Home setActive={setActive} />} />
              <Route
                path="/Clients"
                element={<Client setActive={setActive} />}
              />
              <Route
                path="/Presences"
                element={<Presence setActive={setActive} />}
              />
              <Route
                path="/Settings"
                element={<UserSettings setActive={setActive} />}
              />

              <Route
                path="/GestionSelect"
                element={<GestionSelect setActive={setActive} />}
              />
              <Route
                path="/SiteSelect"
                element={<SiteSelect setActive={setActive} />}
              />
              <Route path="/Sites" element={<Site setActive={setActive} />} />
              <Route path="/Event" element={<Event setActive={setActive} />} />
              <Route path="/Veterinary" element={<Veterinary setActive={setActive} />} />
              {tokenRoles?.includes("ADMIN", "EDUCATEUR", "ENCADRE") &&
                <>
                  <Route path="/Users" element={<User />} />

                  <Route
                    path="/SettingsSelect"
                    element={<SettingsSelect setActive={setActive} />}
                  />
                  <Route
                    path="/LegalStatus"
                    element={<LegalStatus setActive={setActive} />}
                  />
                  <Route
                    path="/MaritalStatus"
                    element={<MaritalStatus setActive={setActive} />}
                  />
                  <Route
                    path="/Resident"
                    element={<Resident setActive={setActive} />}
                  />
                  <Route
                    path="/ClientGroup"
                    element={<ClientGroup setActive={setActive} />}
                  />
                  <Route path="/Job" element={<Job setActive={setActive} />} />
                  <Route
                    path="/DriverLicence"
                    element={<DriverLicence setActive={setActive} />}
                  />
                  <Route
                    path="/Accomodation"
                    element={<Accomodation setActive={setActive} />}
                  />
                  <Route
                    path="/LaundryItem"
                    element={<LaundryItem setActive={setActive} />}
                  />
                  <Route
                    path="/Country"
                    element={<Country setActive={setActive} />}
                  />
                  <Route
                    path="/Nationality"
                    element={<Nationality setActive={setActive} />}
                  />
                  <Route
                    path="/AgeGroups"
                    element={<AgeGroup setActive={setActive} />}
                  />
                  <Route
                    path="/ReportClient"
                    element={<ReportClient setActive={setActive} />}
                  />
                  <Route
                    path="/ReportContract"
                    element={<ReportContract setActive={setActive} />}
                  />
                  <Route
                    path="/ReportExclusion"
                    element={<ReportExclusion setActive={setActive} />}
                  />
                  <Route
                    path="/ReportPresence"
                    element={<ReportPresence setActive={setActive} />}
                  />
                  <Route
                    path="/ReportClosing"
                    element={<ReportClosing setActive={setActive} />}
                  />
                  <Route
                    path="/Fusion"
                    element={<ClientFusion setActive={setActive} />}
                  />
                  <Route path="/Planning" element={<Scheduler modal={false} />} />
                </>
              }
              <Route path="/Stat" element={<Stat setActive={setActive} />} />
            </Routes>
          </Router>
        </div>
      </>
    );
  }
}

export default App;
