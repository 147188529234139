import React from "react";
import UserListing from "./pages/UserListing";

function User() {
    return (
        <>
            <UserListing />
        </>
    )
}
export default User;