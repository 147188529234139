import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../../../redux/features/auth/authSlice";
import { ClientGroupService } from "../service/ClientGroupService";

import { DataGrid } from "@mui/x-data-grid";
import ButtonManager from "../../../../shared/components/Button/ButtonManager";
//import "./ClientGroupListing.css";

const columns = [
  { field: "id", headerName: "Id", hideable: true, flex: 1, headerClassName: "researchColmun", headerAlign: "center", align: "left", },
  { field: "description", headerName: "Description", flex: 1, headerClassName: "researchColmun", headerAlign: "center", align: "center", },
];

const forms = [
  { name: "description", label: "Description", required:true },
];

const ClientGroupListing = () => {
  const dispatch = useDispatch();

  const pageSize = 10;
  const [rows, setRows] = useState([]);
  const [select, setSelect] = useState(null);

  const loadData = () => {
    return ClientGroupService.getClientGroup(-1, 0)
      .then((response) => {
        const data = response.data.content.sort((clientGroup1, clientGroup2) =>
          clientGroup1.description.localeCompare(clientGroup2.description)
        );
        setRows(
          data.filter(clientGroup => clientGroup?.deleted === false)
            .map((row) => {
            const { clientGroupId, ...rest } = row;
            return { id: clientGroupId, ...rest };
          })
        );
        let selected = data.filter((clientGroup) => clientGroup.clientGroupId === select.clientGroupId);
        setSelect(selected.length ? selected[0] : null);
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401 
          || err?.response?.status === 403 || err?.response?.status === 401
        ) {
            dispatch(logout());
        }
      });
  };

  useEffect(() => { loadData() }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', width: '100%' }}>
      <h1 style={{ fontFamily: 'backToSchool', fontSize: '65px', color: '#bb0000', marginTop: '4%' }}>Groupes clients</h1>
      <div style={{ position: 'absolute', top: 23, left: 270 }}>
        <Link to="/SettingsSelect">
          <IconButton color='primary'>
            <ArrowBackIosNewIcon />
          </IconButton>
      </Link>
    </div>
      <div className="event-container display-relative" style={{ marginTop:'5%' }}>
        <ButtonManager
          isContained={true}
          title={"groupe client"}
          id={select?.clientGroupId}
          selected={select?.description !== "TUC" ? select : null}
          align={"center"}
          forms={forms}
          createFunction={ClientGroupService.createClientGroup}
          updateFunction={ClientGroupService.updateClientGroup}
          deleteFunction={ClientGroupService.deleteClientGroup}
          requiredFields={["description"]}
          requiredFieldLabel={["Description"]}
          refresh={loadData}
        />

        <div className="EventDataGrid">
          <DataGrid
            rows={rows}
            columns={columns}
            autoPageSize={true}
            showCellRightBorder
            rowsPerPageOptions={[pageSize]}
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRowData = rows.filter((row) =>
                selectedIDs.has(row?.id?.toString())
              );
              if (selectedRowData.length) {
                const { id, ...rest } = selectedRowData[0];
                setSelect({ clientGroupId: id, ...rest });
              } else {
                setSelect(null);
              }
            }}
            columnVisibilityModel={{
              id: false,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ClientGroupListing;
