import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SiteService } from "../../../components/site/service/SiteService";

const site = JSON.parse(localStorage.getItem("site"));

export const select = createAsyncThunk(
    "site/select",
    async (siteId, thunkAPI) => {
        try {
            const response = await SiteService.getSite(siteId);
            localStorage.setItem("site", JSON.stringify(response.data));
            return { site: response };
        } catch (err) {
            console.log(err);
            /*
            const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
            thunkAPI.dispatch(setMessage(message));
            */
            return thunkAPI.rejectWithValue();
        }
    }
);

const initialState = site ? {site} : {site: null};

export const siteSlice = createSlice({
    name: 'site',
    initialState,
    reducers: {
    },
    extraReducers: {
        [select.fulfilled]: (state, action) => {state.site = action.payload.site.data},
        [select.rejected]: (state, action) => {state.site = null},
    },
});

export default siteSlice.reducer;