import React, { useState } from "react";
import PresenceListing from "../../PresenceListing";

function Information(props) {
  const {
    client,
    handleSelectClient,
    addNewPresence,
    setAddNewPresence,
    voucher,
    setVoucher,
    open,
    setOpen,
    handleClose,
    selected,
    handleChangeIndex,
    setSelected,
    today,
    setToday,
    presences,
    setPresences,
  } = props;

  return (
    <>
      <PresenceListing
        voucher={voucher}
        setVoucher={setVoucher}
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        selected={selected}
        handleChangeIndex={handleChangeIndex}
        setSelected={setSelected}
        client={client}
        handleSelectClient={handleSelectClient}
        addNewPresence={addNewPresence}
        setAddNewPresence={setAddNewPresence}
        today={today}
        setToday={setToday}
        presences={presences}
        setPresences={setPresences}
      />
    </>
  );
}
export default Information;
