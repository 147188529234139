import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AuthService } from "../../../shared/components/auth/service/AuthService";
import jwtDecode from "jwt-decode";

const user = JSON.parse(localStorage.getItem("user"));

export const login = createAsyncThunk(
    "auth/login",
    async (authData, thunkAPI) => {
        try {
            const response = await AuthService.signin(authData);
            localStorage.setItem("user", JSON.stringify(response.data));
            const payload = jwtDecode(response.data.token);
            localStorage.setItem("roles", JSON.stringify(payload.roles));
            return { user: response };
        } catch (error) {
            return thunkAPI.rejectWithValue({error});
        }
    }
);

export const logout = createAsyncThunk("auth/logout", async () => {
    await AuthService.logout();
});

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

export const authSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
    },
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload.data;
        },
        [login.rejected]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
        },
        [logout.fulfilled]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
        },
    },
});

export default authSlice.reducer;