import instance from "../../../shared/components/auth/service/AxiosInstance"

export const ResearchClientService = {
    getClients: (pageNo, pageSize, //firstname, lastname, surname, birthdate,
                research) =>
        instance.get(process.env.REACT_APP_API_URL +
            'client/?pageSize=' + pageSize +
            '&pageNo=' + pageNo +
            // '&firstname=' + firstname +
            // '&lastname=' + lastname +
            // '&surname=' + surname +
            // '&birthdate=' + birthdate +
            '&research=' + research,
        ),

    createClient: (Client) =>
        instance.post(process.env.REACT_APP_API_URL + 'client/', Client
    ),

    updateClient: (id, Client) =>
        instance.put(process.env.REACT_APP_API_URL + 'client/' + id, Client),

    deleteClient: (id) =>
        instance.delete(process.env.REACT_APP_API_URL + 'client/' + id),
}