import React, { useState } from "react";
import { Scheduler } from "../../../../../shared/components/scheduler/Scheduler";

function Planning(props) {
  const {today} = props;
  const [openPlanning, setOpenPlanning] = useState(false);
  const handleClosePlanning = () => setOpenPlanning(false);

  return (
    <>
      <div className="col">
        <Scheduler modal={false} today={today} />
      </div>
    </>
  );
}

export default Planning;
