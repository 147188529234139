import { MenuItem, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { Fragment, useState } from "react";
import { Controller } from "react-hook-form";
import "../../../components/client/pages/tabs/Information/ClientInformation.css";

function ControlledField({ control, name, options, rules, setSelected, ...rest }) {
  const [focus, setFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);

  const onFocus = () => setFocused(true);

  const handleChange = event => {

    const { name, value } = event.target;

    let parsedValue;
    try {
      parsedValue = JSON.parse(value);
    } catch (err) {
      parsedValue = value;
    }

    if (setSelected) {
      setSelected(parsedValue);
    }

    return { target: { name: name, value: parsedValue } };
  }

  return (
    <Controller
      defaultValue={null}
      render={({
        field: { onChange, onBlur, value, name, ref },
        formState: { errors, isValid },
      }) => {
        if (rest?.type === 'date') {
          return (
            <LocalizationProvider dateAdapter={AdapterDateFns} sx={{ padding: '0px !important' }}>
              <DesktopDatePicker
                value={value}
                inputFormat="dd/MM/yyyy"
                variant="outlined"
                name={name}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                error={!isValid}
                inputRef={ref}
                renderInput={(params) => <TextField {...params} />}
                type={hasValue || focus ? "date" : "text"}
                sx={{ padding: '2px !important' }}
                {...rest}
              />
            </LocalizationProvider>
          )
        } else {
          return (
            <>
              <TextField 
                id="test"
                onBlur={onBlur}
                onChange={rest?.type === "select" ? (e => { onChange(handleChange(e)); }) : onChange}
                value={(value === null || (value === undefined)) ? "" : ((typeof value === 'object') ? JSON.stringify(value) : value)}
                inputRef={ref}
                color="primary"
                variant="outlined"
                error={!isValid}
                select={Boolean(options)}
                helperText={errors && errors[name]?.message}
                name={name}
                {...rest}
              >
                {options?.map((option, index) => (
                  <MenuItem
                    id="test"
                    key={(option?.name || option) + "" + index}
                    value={option?.value
                      ? ((typeof option?.value === 'object') ? JSON.stringify(option.value) : option.value)
                      : option
                    }
                  >
                    {option?.name || option}
                  </MenuItem>
                )) || (
                    <MenuItem id="test">
                      <Fragment />
                    </MenuItem>
                  )}
              </TextField>
            </>
          )
        }
      }
      }
      name={name}
      control={control}
      rules={{
        required: rest.required && {
          value: true,
          message: rest.label + " est requis",
        },
        ...rules,
      }}
    />
  )
};

export default ControlledField;
