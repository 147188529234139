import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import ModalGrid from "./ModalGrid";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import LoadingButton from "@mui/lab/LoadingButton";
import "./EventDetails.css";

const style = {
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: '10px',
  border: 'none',
  p: 4,
};

function EventDetails(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { value, presence } = props;

  return (
    <>
      <div>
        <Tooltip title="Plus d'informations">
          <div className="moreInfo">
            <LoadingButton onClick={handleOpen} variant="outlined">
              <ReadMoreIcon />
            </LoadingButton>
          </div>
        </Tooltip>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <h1 style={{ fontFamily: 'backToSchool', fontSize: '40px', color: '#bb0000' }}>Liste des présences</h1>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div className="valueComparisonModal">
                <ModalGrid
                  eventId={value.id}
                  event={value}
                  presence={presence}
                />
                <div className="createEventBtn"></div>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default EventDetails;
