import { TextField, makeStyles } from "@material-ui/core";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import AddBoxIcon from "@mui/icons-material/AddBox";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@mui/x-data-grid";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { logout } from "../../../redux/features/auth/authSlice";
import ButtonManager from "../../../shared/components/Button/ButtonManager";
import EventDetails from "../modals/EventDetails";
import { EventService } from "../services/EventService";

import "./EventListing.css";

const forms = [
  { name: "description", label: "Description", type: "string" },
  {
    name: "dateEvent",
    label: "Date de l'événement",
    type: "date",
    required: true,
  },
  {
    name: "dateLimitInscription",
    label: "Date Limite",
    type: "date",
    required: true,
  },
  {
    name: "maxParticipant",
    label: "Participant maximum",
    type: "number",
    required: true,
  },
];

const headerProps = { style: { background: "#72caed" } };

const useStyles = makeStyles(() => ({
  textField: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
  },
}));

function EventListing(props) {

  const {
    presence,
  } = props;

  const location = useLocation();

  const isPresencesPage = location.pathname === "/Presences";

  const columns = [
    {
      field: "id",
      hideable: true,
      headerName: "ID",
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "dateEvent",
      headerName: "Date",
      width: 150,
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (data) => moment(data.value).format("DD-MM-YYYY"),
    },
    {
      field: "dateLimitInscription",
      headerName: "Date limite",
      width: 150,
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (data) => moment(data.value).format("DD-MM-YYYY"),
    },
    {
      field: "maxParticipant",
      headerName: "Participants maximum",
      width: 150,
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "valeurs",
      headerName: "Détail",
      headerClassName: "researchColmun",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <EventDetails value={params.row} presence={presence} />
          </>
        );
      },
      flex: 1,
    },
  ];

  const classes = useStyles();
  const dispatch = useDispatch();

  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1;
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  var todaystr = yyyy + "-" + mm + "-" + dd;

  const [inputs, setInputs] = useState({
    dateEvent: "",
    dateLimitInscription: "",
  });

  const [rowCountState, setRowCountState] = useState(null);
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 10,
  });

  const [events, setEvents] = useState(null);

  const [defaultPageNo, setDefaultPageNo] = useState(0);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [selectionModel, setSelectionModel] = useState([]);

  const [msg, setMsg] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);

  const [select, setSelect] = useState();
  const [selected, setSelected] = useState();

  const [focus, setFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const refresh = () => {
    if (inputs.dateEvent == null) inputs.dateEvent = "";
    if (inputs.dateLimitInscription == null) inputs.dateLimitInscription = "";
    EventService.getEvents(defaultPageNo, defaultPageSize, inputs)
      .then((results) => {
        setRowCountState(results.data.totalElements);
        let rows = results.data.content.map((row) => {
          const { eventId, ...rest } = row;
          return { id: eventId, ...rest };
        });
        setEvents(rows);
      })
      .catch((err) => {
        console.log(err);
        if (
          err?.statusCode === 403 ||
          err?.statusCode === 401 ||
          err?.response?.status === 403 ||
          err?.response?.status === 401
        ) {
          dispatch(logout());
        }
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(
      () =>
        refresh({
          pageNo: defaultPageNo,
          pageSize: defaultPageSize,
          inputs: inputs,
        }),
      500
    );
    return () => clearTimeout(timeoutId);
  }, [inputs]);

  const resetInput = () => {
    setInputs({
      dateEvent: null,
      dateLimitInscription: null,
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleDateEventChange = (newValue) => {
    if (newValue == null) {
      setInputs({
        dateEvent: null,
        dateLimitInscription: inputs.dateLimitInscription,
      });
    } else {
      const yyyy = newValue.getFullYear();
      let mm = newValue.getMonth() + 1;
      let dd = newValue.getDate();
      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;
      var datestr = yyyy + "-" + mm + "-" + dd;
      setInputs({
        dateEvent: datestr,
        dateLimitInscription: inputs.dateLimitInscription,
      });
    }
  };

  const handleDateLimitInscriptionChange = (newValue) => {
    if (newValue == null) {
      setInputs({
        dateEvent: inputs.dateEvent,
        dateLimitInscription: null,
      });
    } else {
      const date = new Date();
      const yyyy = newValue.getFullYear();
      let mm = newValue.getMonth() + 1;
      let dd = newValue.getDate();
      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;
      var datestr = yyyy + "-" + mm + "-" + dd;
      setInputs({
        dateEvent: inputs.dateEvent,
        dateLimitInscription: datestr,
      });
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const createEvent = (inputs) => {
    EventService.createEvent(inputs)
      .then(r => {})
      .catch(err => {
        console.log(err);
        if (err?.statusCode === 400 || err?.response?.status === 400) {
          setMsg('Cet évènement existe déjà.');
          setOpenAlert(true);
        } else if (err?.statusCode === 403 || err?.statusCode === 401
        || err?.response?.status === 403 || err?.response?.status === 401) {
          dispatch(logout());
        }
      });
  };

  if (events == null) {
    return (
      <>
        <Box textAlign="center" mt={20}>
          <LoadingButton loading={true} variant="outlined" disabled>
            Loading
          </LoadingButton>
        </Box>
      </>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          textAlign: "center",
          padding: "2%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          {!isPresencesPage && (
            <Link to="/SettingsSelect">
              <IconButton color="primary">
                <ArrowBackIosNewIcon />
              </IconButton>
            </Link>
          )}
          <h1
            style={{
              fontFamily: "backToSchool",
              fontSize: "65px",
              color: "#bb0000",
              textAlign: "center",
              width: "100%",
            }}
          >
            Evènements
          </h1>
        </div>
        <div className="event-container display-relative">
          {!isPresencesPage && (
          <>
            <h3 style={{ marginTop: "10px", fontFamily: "overlockBold" }}>
              Recherche :
            </h3>
            <ButtonManager
              isContained={true}
              title={"évènement"}
              id={selected?.id}
              selected={selected}
              align={"center"}
              forms={forms}
              requiredFields={[
                "dateEvent",
                "dateLimitInscription",
                "maxParticipant",
              ]}
              requiredFieldLabel={[
                "Date",
                "Date limite d'inscription",
                "Maximum de participant",
              ]}
              createFunction={EventService.createEvent}
              updateFunction={EventService.updateEvent}
              deleteFunction={EventService.deleteEvent}
              refresh={refresh}
            />
          </>
          )}
          <div className="event-form">
            <div className="row" style={{ marginTop: "50px", alignItems:'center' }}>
              <div className="col-5">
                <h6 style={{ marginBottom: "0px" }}>Date de l'évenement :</h6>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    value={inputs.dateEvent}
                    inputFormat="dd/MM/yyyy"
                    onChange={handleDateEventChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    renderInput={(params) => <TextField {...params} />}
                    type={hasValue || focus ? "date" : "text"}
                  />
                </LocalizationProvider>
              </div>
              <div className="col-5">
                <div style={{ marginLeft: "10%" }}>
                  <h6 style={{ marginBottom: "0px" }}>
                    Date limite d'inscription :
                  </h6>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      value={inputs.dateLimitInscription}
                      inputFormat="dd/MM/yyyy"
                      onChange={handleDateLimitInscriptionChange}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      renderInput={(params) => <TextField {...params} />}
                      type={hasValue || focus ? "date" : "text"}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div className="col-2">
                <div className="clearResearch">
                  <Tooltip title="Vider la recherche" placement="top">
                    <button
                      onClick={(e) => {
                        resetInput();
                      }}
                    >
                      <FilterAltOffIcon />
                    </button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>



          <Collapse in={openAlert}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {msg}
            </Alert>
          </Collapse>



          <div className="EventDataGrid">
            <DataGrid
              rowsPerPageOptions={[rowsState.pageSize]}
              sx={{ backgroundColor: "#fff" }}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
                },
              }}
              columns={columns}
              rows={events}
              rowCount={rowCountState}
              showCellRightBorder
              pagination
              {...rowsState}
              paginationMode="server"
              checkboxSelection={false}
              selectionModel={selectionModel}
              onSelectionModelChange={(newSelectionModel) => {
                const selectedIDs = new Set(newSelectionModel);
                const selectedRows = events.filter((row) =>
                  selectedIDs.has(row.id)
                );

                if (selectedRows[0] != null) {
                  setSelect(selectedRows[0]);
                  var selected = {
                    id: selectedRows[0].id,
                    description: selectedRows[0].description,
                    dateEvent: selectedRows[0].dateEvent,
                    dateLimitInscription: selectedRows[0].dateLimitInscription,
                    maxParticipant: selectedRows[0].maxParticipant,
                  };
                  setSelected(selected);
                } else {
                  setSelect(null);
                }

                setSelectionModel(newSelectionModel);
              }}
              onPageChange={(page) => {
                setDefaultPageNo(page);
                setRowsState((prev) => ({ ...prev, page }));
                refresh({
                  pageNo: page,
                  pageSize: defaultPageSize,
                  inputs: inputs,
                });
              }}
              onPageSizeChange={(pageSize) => {
                setDefaultPageSize(pageSize);
                setRowsState((prev) => ({ ...prev, pageSize }));
                refresh({
                  pageNo: defaultPageNo,
                  pageSize: pageSize,
                  inputs: inputs,
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default EventListing;
