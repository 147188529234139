import instance from "../../../../shared/components/auth/service/AxiosInstance";

export const BalanceService = {
  getBalances: (pageNo, pageSize) =>
    instance.get(
      process.env.REACT_APP_API_URL +
        "Prepaid/balance" +
        "?pageSize" +
        pageSize +
        "&pageNo=" +
        pageNo
    ),

  getBalance: (id) =>
    instance.get(process.env.REACT_APP_API_URL + "Prepaid/balance/" + id),

  createBalance: (balance) =>
    instance.post(process.env.REACT_APP_API_URL + "Prepaid/balance", balance),

  updateBalance: (clientId, input, siteId) =>
    instance.put(
      process.env.REACT_APP_API_URL +
      "Prepaid/balance/" +
      clientId +
      "/site/" +
      siteId,
      input,
  ),
};
