import React, { useState } from "react";
import { Box, TextField } from "@mui/material";
import { CustomDatePicker } from "../../../datepicker/CustomDatePicker";
import {
    localeISODateTime,
    getISODate,
    dateIsValid
} from "../../../../shared/utils/localeISODateTime";

export const DateSelector = (props) => {

    const today = getISODate(localeISODateTime());

    const { setInputs } = props;
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);

    const [focus, setFocused] = useState(false);
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    const handleStartDateChange = (date) => {
        if (((dateIsValid(date)) && (date?.getFullYear() > 1000)) || (date === '')) {
            setStartDate(date);
            setInputs((values) => ({ ...values, ["dateStart"]: date }));
        }
    }

    const handleEndDateChange = (date) => {
        if (((dateIsValid(date)) && (date?.getFullYear() > 1000)) || (date === '')) {
            setEndDate(date);
            setInputs((values) => ({ ...values, ["dateEnd"]: date }));
        }
    }

    return (
        <Box sx={{ display: "flex", justifyContent: "center", flexDirection:'column', width:'100%' }} flexGrow={1}>
            <Box sx={{ width: "40%", display: "flex", justifyContent:'center', textAlign:'center', alignItems:'center', width:'100%' }}>
                <span style={{ fontSize:'20px', fontFamily:'overlockBold', width:'30%', wordWrap:'none' }}>Début:</span>
                <CustomDatePicker
                    valueDate={startDate}
                    handleChange={handleStartDateChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
            </Box>
            <Box sx={{ width: "40%", display: "flex", justifyContent:'center', textAlign:'center', alignItems:'center', width:'100%' }}>
                <span style={{ fontSize:'20px', fontFamily:'overlockBold', width:'30%', wordWrap:'none' }}>Fin:</span>
                <CustomDatePicker
                    valueDate={endDate}
                    handleChange={handleEndDateChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />

            </Box>
        </Box>
    );
}