import instance from "../../../shared/components/auth/service/AxiosInstance";

export const ClosingService = {
    getClosings: (pageNo, pageSize, dateStart, dateEnd, sites) => 
        instance.get(process.env.REACT_APP_API_URL + "Closing/" +
            '?pageSize=' + pageSize +
            '&pageNo=' + pageNo +
            '&dateStart=' + dateStart +
            '&dateEnd=' + dateEnd +
            '&site=' + sites
    ),

    createClosing: (Closing) => instance.post(process.env.REACT_APP_API_URL + 'Closing/',
        Closing,
    ),

    updateClosing: (id, Closing) => instance.put(process.env.REACT_APP_API_URL + 'Closing/' + id, 
        Closing
    ),

    deleteClosing: (id) => instance.delete(process.env.REACT_APP_API_URL + 'Closing/' + id
    ),

}