import CloseIcon from '@mui/icons-material/Close';
import { Box } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { localeISODateTime } from "../../shared/utils/localeISODateTime";
import ResearchClient from "../client/pages/ResearchClients";
import { BalanceService } from '../client/service/SubService/BalanceService';
import './Presence.css';
import PresenceTab from "./PresenceTab";

function Presence(props) {

  const [addNewPresence, setAddNewPresence] = useState(false);
  const [presences, setPresences] = useState([]);
  const dispatch = useDispatch();

  const [client, setClient] = useState(null);
  const [clientBalance, setClientBalance] = useState(null);

  const [open, setOpen] = useState(false);
  const [today, setToday] = useState(localeISODateTime());

  const handleDrawer = () => {
    setOpen(!open);
  };

  function handleSelectClient(client) {
    if (client != null) {
      BalanceService.getBalance(client.clientId).then((r) => {
        setClient({ ...client, balance: r.data.balance });
        setClientBalance(r.data.balance);
      });
    }
  }

  useEffect(() => {
    setToday(localeISODateTime());
    if (client === null) {
      setOpen(true);
    }
  }, []);

  return (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Box>
        <Drawer
          sx={{
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: "40%",
              backgroundColor: "#eaeaea",
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <IconButton onClick={handleDrawer} sx={{ maxWidth: "50px" }}>
            <CloseIcon color="error" />
          </IconButton>
          <List>
            <ResearchClient
              handleSelectClient={handleSelectClient}
              addNewPresence={addNewPresence}
              setAddNewPresence={setAddNewPresence}
              today={today}
              presences={presences}
            />
          </List>
        </Drawer>
      </Box>

      <div>
        <div>
          <div>
            <PresenceTab
              client={client}
              handleSelectClient={handleSelectClient}
              addNewPresence={addNewPresence}
              setAddNewPresence={setAddNewPresence}
              handleDrawer={handleDrawer}
              open={open}
              today={today}
              presences={presences}
              setPresences={setPresences}
              setToday={setToday}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Presence;
