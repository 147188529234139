import instance from "../../../shared/components/auth/service/AxiosInstance";

export const VeterinaryService = {

    getAppointements: (siteId, vouchertypeId, date, pageNo, pageSize) =>
        instance.get(process.env.REACT_APP_API_URL + "voucher/site/" + siteId + "/vouchertype/" + vouchertypeId + "/date/" + date +
            '?pageSize=' + pageSize +
            '&pageNo=' + pageNo
        ),

    getAnimals: (clientId) => instance.get(process.env.REACT_APP_API_URL + "animal/client/" + clientId),

    getAnimalById: (animalId) => instance.get(process.env.REACT_APP_API_URL + "animal/" + animalId),

    createAnimal: (animal) =>
        instance.post(process.env.REACT_APP_API_URL + "animal/", animal),

    updateAnimal: (id, animal) =>
        instance.put(process.env.REACT_APP_API_URL + "animal/" + id, animal),

    getConsultations: (animalId, pageNo, pageSize) =>
        instance.get(process.env.REACT_APP_API_URL + "consultation/animal/" + animalId +
            '?pageSize=' + pageSize +
            '&pageNo=' + pageNo
        ),

    createConsultation: (consultation) =>
        instance.post(process.env.REACT_APP_API_URL + "consultation/", consultation),

    updateConsultation: (id, consultation) =>
        instance.put(process.env.REACT_APP_API_URL + "consultation/" + id, consultation),

    getLastConsultation: (animalId) =>
        instance.get(process.env.REACT_APP_API_URL + "consultation/last/animal/" + animalId),

}