import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ExportToFile } from "../reports/utils/ExportToFile";

export const ExportDataGridToExcel = (props) => {
  const { headers, data, filename, setMessage } = props;

  const [rows, setRows] = useState([]);

  const handleDownloadFile = () => {
    let date = new Date().toISOString().split(".")[0];

    ExportToFile(rows, filename + "_" + date);
    if (setMessage !== null && setMessage !== undefined) {
      setMessage("Export de " + (rows?.length ? rows.length : 0) + " ligne(s)");
    }
  };

  useEffect(() => {
    if (
      data?.length > 0 &&
      headers?.length > 0 &&
      typeof data?.[0] === "object"
    ) {
      setRows(
        data?.map((rowData) => {
          let row = {};
          Object.values(rowData)?.forEach((columnData, index) =>
            headers?.[index]?.hideable === true
              ? null
              : (row[headers?.[index]?.headerName] = columnData)
          );
          return row;
        })
      );
    } else if (setMessage !== null && setMessage !== undefined) {
      setMessage(
        "Aucune données à exporter ou format invalide des entêtes/données"
      );
    } else {
      console.log("No data or headers defined!");
    }
  }, [data, headers]);

  return (
    <Button variant="contained" onClick={handleDownloadFile}>
      Exporter
    </Button>
  );
};
