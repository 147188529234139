import LoadingButton from "@mui/lab/LoadingButton";
import Box from '@mui/material/Box';
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { ExportDataGridToExcel } from "../../../../../export/ExportDataGridToExcel";
import { SecretService } from "../../../../service/SecretService";
const baseColumnOptions = {
    sortable: false,
    pinnable: false,
    hideable: false,
};

const maritalStatusColumns = [
    {
        field: "id",
        header: "Id",
        hideable: true,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "siteName",
        headerName: "Site",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        cellClassName: "tableLeftColumn",
    },
    {
        field: "maritalStatus0",
        headerName: "1",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "maritalStatusPercentage0",
        headerName: "Quantité %",
        ...baseColumnOptions,
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        renderCell: (data) => data?.value != null ? Number(data?.value)?.toFixed(2) + " %" : "",
    },
    {
        field: "maritalStatusNb0",
        headerName: "Quantité",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "maritalStatus1",
        headerName: "2",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "maritalStatusPercentage1",
        headerName: "Quantité %",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        renderCell: (data) => data?.value != null ? data?.value.toFixed(2) + " %" : ""
    },
    {
        field: "maritalStatusNb1",
        headerName: "Quantité",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "maritalStatus2",
        headerName: "3",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "maritalStatusPercentage2",
        headerName: "Quantité %",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        renderCell: (data) => data?.value != null ? data?.value.toFixed(2) + " %" : "",
    },
    {
        field: "maritalStatusNb2",
        headerName: "Quantité",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "maritalStatus3",
        headerName: "4",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "maritalStatusPercentage3",
        headerName: "Quantité %",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        renderCell: (data) => data?.value != null ? data?.value.toFixed(2) + " %" : "",
    },
    {
        field: "maritalStatusNb3",
        headerName: "Quantité",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "maritalStatus4",
        headerName: "5",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "maritalStatusPercentage4",
        headerName: "Quantité %",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        renderCell: (data) => data?.value != null ? data?.value.toFixed(2) + " %" : "",
    },
    {
        field: "maritalStatusNb4",
        headerName: "Quantité",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "maritalStatus5",
        headerName: "6",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "maritalStatusPercentage5",
        headerName: "Quantité %",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        renderCell: (data) => data?.value != null ? data?.value.toFixed(2) + " %" : "",
    },
    {
        field: "maritalStatusNb5",
        headerName: "Quantité",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "maritalStatus6",
        headerName: "7",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "maritalStatusPercentage6",
        headerName: "Quantité %",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        renderCell: (data) => data?.value != null ? data?.value.toFixed(2) + " %" : "",
    },
    {
        field: "maritalStatusNb6",
        headerName: "Quantité",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "maritalStatus7",
        headerName: "8",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "maritalStatusPercentage7",
        headerName: "Quantité %",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        renderCell: (data) => data?.value != null ? data?.value.toFixed(2) + " %" : "",
    },
    {
        field: "maritalStatusNb7",
        headerName: "Quantité",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "maritalStatus8",
        headerName: "9",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "maritalStatusPercentage8",
        headerName: "Quantité %",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
        renderCell: (data) => data?.value != null ? data?.value.toFixed(2) + " %" : "",
    },
    {
        field: "maritalStatusNb8",
        headerName: "Quantité",
        headerClassName: "researchColmun",
        headerAlign: "center",
        align: "center",
    },
];

function MaritalStatus(props) {

    const {
        rowsState,
        rowCountState,
        selectionModel,
        setSelectionModel,
        defaultPageSize,
        setDefaultPageSize,
        setDefaultPageNo,
        setRowsState,
        defaultPageNo,
        inputs
    } = props

    const [maritalStatus, setMaritalStatus] = useState();
    const [loadingState, setLoadingState] = useState(false);

    const refresh = () => {
        setLoadingState(true)
        SecretService.getMaritalStat(inputs.startDate, inputs.endDate)
            .then((results) => {
                let datas = results.data;
                datas.forEach((data) => {
                    let inconnuMaritalStatus = false;
                    for (let i = 0; i < data.maritalStatusStats.length; i++) {
                        Object.defineProperty(data, "maritalStatus" + i, {
                            value: (!inconnuMaritalStatus && !data.maritalStatusStats[i].type ? "(non renseigné)" : data.maritalStatusStats[i].type),
                        });
                        if (!inconnuMaritalStatus && !data.maritalStatusStats[i].type) {
                            inconnuMaritalStatus = true;
                        }
                        Object.defineProperty(data, "maritalStatusNb" + i, {
                            value: data.maritalStatusStats[i].number,
                        });
                        Object.defineProperty(data, "maritalStatusPercentage" + i, {
                            value: data.maritalStatusStats[i].percentage,
                        });
                    }
                })
                setMaritalStatus(results.data);
                setLoadingState(false)
            })
            .catch(err => console.log(err));
    }

    useEffect(() => {
        refresh();
    }, [inputs])


    return (
        <>
            <h3
                style={{
                    position: "relative",
                    fontFamily: "backToSchool",
                    fontSize: "45px",
                    color: "#bb0000",
                    marginTop: "20px",
                }}
            >
                Etats civil
            </h3>
            {maritalStatus != null ?
                (
                    <>
                        <div>
                            <ExportDataGridToExcel
                                headers={[
                                    { header: "Id", hideable: true },
                                    { headerName: "Site" },
                                    { headerName: "MS 1" },
                                    { headerName: "1 Quantité %" },
                                    { headerName: "1 Quantité" },
                                    { headerName: "MS 2" },
                                    { headerName: "2 Quantité %" },
                                    { headerName: "2 Quantité" },
                                    { headerName: "MS 3" },
                                    { headerName: "3 Quantité %" },
                                    { headerName: "3 Quantité" },
                                    { headerName: "MS 4" },
                                    { headerName: "4 Quantité %" },
                                    { headerName: "4 Quantité" },
                                    { headerName: "MS 5" },
                                    { headerName: "5 Quantité %" },
                                    { headerName: "5 Quantité" },
                                    { headerName: "MS 6" },
                                    { headerName: "6 Quantité %" },
                                    { headerName: "6 Quantité" },
                                    { headerName: "MS 7" },
                                    { headerName: "7 Quantité %" },
                                    { headerName: "7 Quantité" },
                                    { headerName: "MS 8" },
                                    { headerName: "8 Quantité %" },
                                    { headerName: "8 Quantité" },
                                    { headerName: "MS 9" },
                                    { headerName: "9 Quantité %" },
                                    { headerName: "9 Quantité" }
                                ]}
                                data={maritalStatus.map(row => ({
                                    "Id": "",
                                    "Site": row?.siteName,
                                    "MS 1": row?.maritalStatus0,
                                    "1 Quantité %": row?.maritalStatusPercentage0,
                                    "1 Quantité": row?.maritalStatusNb0,
                                    "MS 2": row?.maritalStatus1,
                                    "2 Quantité %": row?.maritalStatusPercentage1,
                                    "2 Quantité": row?.maritalStatusNb1,
                                    "MS 3": row?.maritalStatus2,
                                    "3 Quantité %": row?.maritalStatusPercentage2,
                                    "3 Quantité": row?.maritalStatusNb2,
                                    "MS 4": row?.maritalStatus3,
                                    "4 Quantité %": row?.maritalStatusPercentage3,
                                    "4 Quantité": row?.maritalStatusNb3,
                                    "MS 5": row?.maritalStatus4,
                                    "5 Quantité %": row?.maritalStatusPercentage4,
                                    "5 Quantité": row?.maritalStatusNb4,
                                    "MS 6": row?.maritalStatus5,
                                    "6 Quantité %": row?.maritalStatusPercentage5,
                                    "6 Quantité": row?.maritalStatusNb5,
                                    "MS 7": row?.maritalStatus6,
                                    "7 Quantité %": row?.maritalStatusPercentage6,
                                    "7 Quantité": row?.maritalStatusNb6,
                                    "MS 8": row?.maritalStatus7,
                                    "8 Quantité %": row?.maritalStatusPercentage7,
                                    "8 Quantité": row?.maritalStatusNb7,
                                    "MS 9": row?.maritalStatus8,
                                    "9 Quantité %": row?.maritalStatusPercentage8,
                                    "9 Quantité": row?.maritalStatusNb8
                                }))}
                                filename={"statistiques_etat-civil"}
                            />
                        </div>
                        <div className="EventDataGrid">
                            <DataGrid
                                rowsPerPageOptions={[rowsState.pageSize]}
                                sx={{ backgroundColor: "#fff" }}
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: {
                                            id: false,
                                        },
                                    },
                                }}
                                columns={maritalStatusColumns}
                                rows={maritalStatus}
                                loading={loadingState}
                                rowCount={rowCountState}
                                showCellRightBorder
                                pagination
                                {...rowsState}
                                paginationMode="server"
                                checkboxSelection={false}
                                selectionModel={selectionModel}
                                onSelectionModelChange={(newSelectionModel) => {
                                    const selectedIDs = new Set(newSelectionModel);
                                    const selectedRows = maritalStatus.filter((row) =>
                                        selectedIDs.has(row.id)
                                    );
                                    setSelectionModel(newSelectionModel);
                                }}
                                onPageChange={(page) => {
                                    setDefaultPageNo(page);
                                    setRowsState((prev) => ({ ...prev, page }));
                                    refresh({
                                        pageNo: page,
                                        pageSize: defaultPageSize,
                                        inputs: inputs,
                                    });
                                }}
                                onPageSizeChange={(pageSize) => {
                                    setDefaultPageSize(pageSize);
                                    setRowsState((prev) => ({ ...prev, pageSize }));
                                    refresh({
                                        pageNo: defaultPageNo,
                                        pageSize: pageSize,
                                        inputs: inputs,
                                    });
                                }}
                            />
                        </div>
                    </>
                ) : (
                    <>
                      <Box textAlign="center" mt={20}>
                            <LoadingButton loading={true} variant="outlined" disabled>
                                Loading
                            </LoadingButton>
                        </Box>
                    </>
                )

            }

        </>
    )
}
export default MaritalStatus;