import { Box } from "@material-ui/core";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import CompressIcon from "@mui/icons-material/Compress";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import EventIcon from "@mui/icons-material/Event";
import FlagIcon from "@mui/icons-material/Flag";
import GroupIcon from "@mui/icons-material/Group";
import HouseIcon from "@mui/icons-material/House";
import LanguageIcon from "@mui/icons-material/Language";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PublicIcon from "@mui/icons-material/Public";
import SettingsIcon from "@mui/icons-material/Settings";
import WorkIcon from "@mui/icons-material/Work";
import Accordion from '@mui/material/Accordion';
import Button from "@mui/material/Button";
import { styled } from '@mui/material/styles';
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

//const general = ["Utilisateurs", "Evènements", "Sites", "Buanderie", "Fusion"];
const general = ["Utilisateurs", "Sites", "Buanderie", "Fusion"];

const person = [
  "Pays",
  "Nationalité",
  "Résident",
  "Tranches d'âge",
  "Statut légal",
  "Etat civil",
];

const activite = [
  "Groupes clients",
  "Postes",
  "Logement",
  "Permis de conduire",
];

//const generalLinkTo = ["/Users", "/Event", "/Sites", "/LaundryItem", "/Fusion"];
const generalLinkTo = ["/Users", "/Sites", "/LaundryItem", "/Fusion"];

const personLinkTo = [
  "/Country",
  "/Nationality",
  "/Resident",
  "/AgeGroups",
  "/LegalStatus",
  "/MaritalStatus",
];

const activiteLinkTo = [
  "/ClientGroup",
  "/Job",
  "/Accomodation",
  "/DriverLicence",
];

const generalIcons = [
  <PersonAddIcon />,
  <EventIcon />,
  <LocationOnIcon />,
  <CheckroomIcon />,
  <CompressIcon />,
];

const personIcons = [
  <PublicIcon />,
  <FlagIcon />,
  <LanguageIcon />,
  <SettingsIcon />,
  <AccountBoxIcon />,
  <AccessibilityNewIcon />,
];

const activiteIcons = [
  <GroupIcon />,
  <WorkIcon />,
  <HouseIcon />,
  <DirectionsCarIcon />,
];

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    {...props}
  />
))(({ theme }) => ({
  '& .MuiAccordionSummary-content': {
    justifyContent:'center',
  },
}));

function SettingsSelect(props) {
  const { setActive } = props;
  useEffect(() => {
    //setActive(3);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        textAlign: "center",
        padding: "2%",
      }}
    >
      <h1
        className="mb-4 text-center"
        style={{
          fontFamily: "backToSchool",
          fontSize: "65px",
          color: "#bb0000",
          textAlign: "center",
          width: "100%",
        }}
      >
        Paramètres
      </h1>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: '5vh',
          width: "100%",
          "& > *": {
            m: 1,
          },
        }}
      >
        <div style={{margin: '0vh 4vh'}}>
          <Accordion sx={{ width: '260px', backgroundColor:'rgba(250, 250, 250, 0.6)', textAlign: 'center', jusitifyContent: 'center', }}>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ display: 'flex', jusitifyContent: 'center', textAlign: 'center', fontWeight: 'bold', width: '100%' }}
            >
              <Typography sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontFamily: 'overlockBold', fontSize: '20px' }}>
                Général <ExpandMoreIcon sx={{color:'#bb0000'}}/>
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
              {general.map((item, index) => {
                return (
                  <Link key={index} to={generalLinkTo[index]}>
                    <Button
                      startIcon={generalIcons[index]}
                      variant="contained"
                      sx={{ width: '220px', margin: '2vh 0vh' }}
                    >
                      {item}
                    </Button>
                  </Link>
                );
              })}
            </AccordionDetails>
          </Accordion>
        </div>

        <div style={{margin: '0vh 4vh'}}>
          <Accordion sx={{ width: '260px', backgroundColor:'rgba(250, 250, 250, 0.6)', textAlign: 'center', jusitifyContent: 'center', }}>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ display: 'flex', jusitifyContent: 'center', textAlign: 'center', width: '100%', fontFamily: 'overlockBold', fontSize: '20px' }}
            >
              Personnes <ExpandMoreIcon sx={{color:'#bb0000'}}/>
            </AccordionSummary>
            <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
              {person.map((item, index) => {
                return (
                  <Link key={index} to={personLinkTo[index]}>
                    <Button
                      startIcon={personIcons[index]}
                      variant="contained"
                      sx={{ width: '220px', margin: '2vh 0vh' }}
                    >
                      {item}
                    </Button>
                  </Link>
                );
              })}
            </AccordionDetails>
          </Accordion>
        </div>

        <div style={{margin: '0vh 4vh'}}>
          <Accordion sx={{ width: '260px', backgroundColor:'rgba(250, 250, 250, 0.6)', textAlign:'center', jusitifyContent: 'center', }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ display: 'none' }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ display: 'flex', flexDirection:'row', jusitifyContent: 'center', textAlign: 'center', alignItems:'center', width: '100%', fontFamily: 'overlockBold', fontSize: '20px' }}
            >
              Activités <ExpandMoreIcon sx={{color:'#bb0000'}}/>
            </AccordionSummary>
            <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
              {activite.map((item, index) => {
                return (
                  <Link key={index} to={activiteLinkTo[index]}>
                    <Button
                      startIcon={activiteIcons[index]}
                      variant="contained"
                      sx={{ width: "220px", margin: "2vh 0vh" }}
                    >
                      {item}
                    </Button>
                  </Link>
                );
              })}
            </AccordionDetails>
          </Accordion>
        </div>
      </Box>
    </div>
  );
}

export default SettingsSelect;
