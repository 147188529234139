import instance from "../../../../shared/components/auth/service/AxiosInstance";

export const TransactionService = {
  getTransaction: (pageNo, pageSize, date) =>
    instance.get(
      process.env.REACT_APP_API_URL +
        "Prepaid/transaction/" +
        "?pageSize=" +
        pageSize +
        "&pageNo=" +
        pageNo +
        "&date=" +
        date
    ),
};
