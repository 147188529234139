import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from "@mui/material/IconButton";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getISOTime, localeISODateTime } from "../../../shared/utils/localeISODateTime";
import { ClientService } from "../../client/service/ClientService";
import { PresenceService } from "../services/PresenceService";


export const ClientsTUCModal = props => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const { setDisabledBtn, handleClose, open, refresh, date, presences } = props;

    const site = useSelector((state) => state.site.site);

    const [clients, setClients] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [clientIdsAlreadyPresent, setClienIdsAlreadyPresent] = useState(presences?.map(presence => presence.clientId)) || [];

    const handleChange = (position) => {
      setCheckedList(checkedList?.map((checkedStatus, index) => index === position ? !checkedStatus : checkedStatus))
    }

    const handleSubmit = (event) => {
      event.preventDefault();
      let selectedClients = clients.filter((client, index) => (checkedList[index] === true) && !clientIdsAlreadyPresent?.includes(client.clientId));
      let presencesToAdd = [];
      selectedClients.forEach((client) => 
        presencesToAdd.push({
          presenceDate: date,
          entryHour: getISOTime(localeISODateTime()),
          meal: 1,
          site: site,
          client: client,
          vouchers: []
        })
      );
      
      PresenceService.createAllPresences(presencesToAdd)
        .then(({data, status}) => {
          refresh();
          handleClose();
        })
        .catch(err => console.log(err));
    }

    useEffect(() => {
      setClienIdsAlreadyPresent(presences?.map(presence => presence.clientId));
      setDisabledBtn((clients?.length === 0) || (clients?.map(c => c.clientId)?.every(c => presences?.map(presence => presence.clientId)?.includes(c))));
    }, [, presences, clients]);

    useEffect(() => {
        ClientService.getClientsTUCBySite(date, site?.siteId)
            .then(({data, status}) => {
              setDisabledBtn((data.length === 0) || (clients?.map(c => c.clientId)?.every(c => presences?.map(presence => presence.clientId)?.includes(c)) && (presences?.length >= clients?.map.length)));
              setCheckedList(new Array(data.length).fill(true));
              setClients(data.sort((c1, c2) => c1?.lastnametempo?.localeCompare(c2?.lastnametempo) || c1?.firstnametempo?.localeCompare(c2?.firstnametempo)));
            })
            .catch(err => console.log(err))
    }, [date]);

    return (
        <>
        <Dialog fullScreen={fullScreen} onClose={handleClose} open={open}>
            <DialogTitle>
              Clients TUC
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
                <form autoComplete="off" onSubmit={handleSubmit}>
                  {clients?.map((client, index) => !clientIdsAlreadyPresent?.includes(client.clientId)
                    && (
                    <FormGroup key={"tuc-" + client.clientId}>
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={checkedList[index]}
                              disabled={clientIdsAlreadyPresent?.includes(client.clientId)}
                              onChange={() => handleChange(index)} 
                            />
                          } 
                          label={`${client?.lastnametempo?.toUpperCase() || ''} ${client?.firstnametempo || ''}`}
                        />
                    </FormGroup>
                  ))}
                  <Button variant="contained" type="submit">
                    Ajouter le(s) client(s) sélectionné(s)
                  </Button>
                </form>
            </DialogContent>
          </Dialog>
        </>
    );

}