import ResidentListing from "./pages/ResidentListing";
import React, { useEffect } from "react";

function Resident(props) {
    const { setActive } = props;
    useEffect(() => {
      //setActive(3);
    }, []);

    return <ResidentListing />
};

export default Resident;