import React, { useEffect } from "react";
import StatTab from "./pages/StatTab";


function Stat(props) {
  const { setActive } = props;
  useEffect(() => {
    //setActive(4);
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <div>
          <StatTab />
        </div>
      </div>
    </>
  );
}

export default Stat;