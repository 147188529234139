import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PresenceService } from "../../../components/presence/services/PresenceService";
import { logout } from "../../../redux/features/auth/authSlice";
import { imageProcess } from "../../utils/Files";
import { dateIsValid, getISODate, localeISODateTime } from "../../utils/localeISODateTime";
import DeleteForm from "../modals/DeleteForm";
import Form from "../modals/Form";
import LoadButtonDialog from "./LoadButtonDialog";

function ButtonManager(props) {
  const dispatch = useDispatch();
  const today = localeISODateTime();
  const { t } = useTranslation();

  const {
    colorC,
    colorU,
    colorD,
    isDisabled,
    btnTitle,
    title,
    selected,
    align,
    id,
    createFunction,
    updateFunction,
    deleteFunction,
    forms,
    preSetInputs,
    refresh,
    onlyIcon,
    isContained,
    requiredFields,
    requiredFieldLabel,
    customError,
    optionalData,
    ...rest
  } = props;

  const [inputs, setInputs] = useState({});

  const site = useSelector((state) => state.site.site);

  const GetTotalMeal = (date) => {
    PresenceService.getPresencesBySite(
      0,
      5000,
      date !== null ? date : inputs?.date,
      site?.siteId,
    )
      .then((response) => {
        const data = response.data.content
        let Clients = 0;
        let ATI = 0;
        let Atelier = 0;
        let other = 0;
        let DayCenter = 0;
        for (let i = 0; i < data.length; i++) {
          if (data[i].meal > 0) {
            switch (data[i].client.clientGroup.clientGroupId) {
              case "Nz":
                Clients += data[i].meal;
                break;
              case "JA":
                ATI += data[i].meal;
                break;
              case "Og":
                ATI += data[i].meal;
                break;
              case "AV":
                Atelier += data[i].meal;
                break;
              default:
                other += data[i].meal;
                break;
            }
          } else {
            DayCenter += 1;
          }
        }
        setInputs((values) => ({ 
          ...values, 
          ["mealClient"]: Clients + other,
          ["mealAti"]: ATI,
          ["mealAtelier"]: Atelier,
          ["mealTotal"]: Clients + ATI + Atelier + other,
          ["dayCenter"]: DayCenter
        }));
      })
      .catch((err) => {
        if (
          err?.statusCode === 403 ||
          err?.statusCode === 401 ||
          err?.response?.status === 403 ||
          err?.response?.status === 401
        ) {
          dispatch(logout());
        }
      })
  };

  const handleChange = (event) => {
    const name = event.target.name;
    let val = event.target.value;
    try {
      val = JSON.parse(event.target.value);
    } catch (err) { }

    let value = event.target.type === "checkbox" ? event.target.checked : val;

    //Checkbox group
    if ((document.querySelectorAll("input[name=" + name + "]").length > 1) && (event?.target?.type === "checkbox")) {
      value = [];
      document
      .querySelectorAll("input[name=" + name + "]:checked")
      .forEach((input) => value.push(JSON.parse(input.value)));
    }

    if (event.target.files && event.target.files[0]) {
      imageProcess(event.target.files[0])
      .then(data => setInputs((values) => ({ ...values, [name]: data })))
      .catch(err => console.log(err));
    }

    // meal total
    if ((name === "date") && (title === 'Clôture')) {
      setInputs((values) => ({ ...values, [name]: value }));
      GetTotalMeal(getISODate(localeISODateTime(value)));
    }

    if ((title === 'Clôture') && (name === "mealClient" || name === "mealAti" || name === "mealAtelier")) {
      value = value === "" ? 0 : value;
      if (value >= 0) {
        setInputs((values) => ({ ...values, [name]: value }))
        if (name === "mealClient") {
          setInputs((values) => ({ ...values, ["mealTotal"]: parseInt(value) + parseInt(inputs?.mealAti) + parseInt(inputs?.mealAtelier)}))
        } else if (name === "mealAti") {
          setInputs((values) => ({ ...values, ["mealTotal"]: parseInt(inputs?.mealClient) + parseInt(value) + parseInt(inputs?.mealAtelier)}))
        } else if (name === "mealAtelier") {
          setInputs((values) => ({ ...values, ["mealTotal"]: parseInt(inputs?.mealClient) + parseInt(inputs?.mealAti) + parseInt(value)}))
        }
      }
    } else if ((title === 'Clôture') && (name === "mealTotal")) {
      //
    } else {
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };

  const resetInput = () => {
    setInputs({});
    if (preSetInputs != null) {
      setInputs(preSetInputs);
    }
  };

  const createEntity = async () => {
    if (inputs === null || inputs === undefined) {
      return { success: false, err: "NO_DATA" };
    }

    let createInputs = { ...inputs };

    forms?.forEach(item => {
      if (item?.type === 'date') {
        if (((inputs?.[item?.name] === undefined) || (inputs?.[item?.name] === null))) {
          if ((item?.valueDate) && dateIsValid(item?.valueDate)) {
            createInputs[item?.name] = getISODate(localeISODateTime(item.valueDate));
          }
        } else {
          createInputs[item.name] = getISODate(localeISODateTime(inputs[item.name]));
        }
      }
    });

    setInputs({ ...createInputs });

    let required = false;
    if (requiredFields.length > 0 && inputs != null) {
      requiredFields.forEach((requiredField) => {
        if ((createInputs[requiredField] === null)
          || (createInputs[requiredField] === undefined)
          || (createInputs[requiredField]?.length === 0)
        ) {
          required = true;
        }
      });
    }

    if (required === true) {
      return { success: false, err: "REQUIRED_FIELDS" };
    } else {
      if ((createFunction !== null) && (createFunction !== undefined)
        && (typeof createFunction === 'function')
      ) {
        return await Promise.resolve(
          createFunction(createInputs)
            .then((r) => {
              if (refresh != null) {
                refresh();
              }
              return { success: true };
            })
            .catch((err) => ({ success: false, err: "REQUEST_FAILED", response: err?.response?.data }))
        );
      } else {
        return {};
      }
    }
  };

  const updateEntity = async () => {
    let result = { success: false, err: 'REQUEST_FAILED' };
    let mergedInputs = Object.assign({}, selected, inputs);

    console.log(selected)
    console.log(inputs)

    //Specific use case, should be rework later
    /*
    if (site != null) {
      mergedInputs = Object.assign({}, inputs);
    }
    */
    return await Promise.resolve(
      updateFunction(id, mergedInputs, site?.siteId).then((r) => {
        if (refresh != null) {
          refresh();
        }
        resetInput();
        return { success: true };
      }).catch(err => ({ ...result, response: err }))
    );
  };

  const deleteEntity = async () => {
    return await Promise.resolve(
      deleteFunction(id).then((r) => {
        if (refresh != null) {
          refresh();
        }
        return { success: true };
      })
        .catch(err => ({ success: false, err: 'REQUEST_FAILED' }))
    );
  };

  useEffect(() => {
      setInputs(preSetInputs);
  }, [preSetInputs]);

  const closingForms=[
    { name: "date", label: "Date", type: "date", valueDate: (dateIsValid(today) ? today : new Date()) },
    { name: "mealClient", label: "Repas Client", type: "number", value: inputs?.mealClient },
    { name: "mealAti", label: "Repas ATI", type: "number", value: inputs?.mealAti },
    { name: "mealAtelier", label: "Repas Atelier", type: "number", value: inputs?.mealAtelier },
    { name: "mealTotal", label: "Total repas", type: "number", value: inputs?.mealTotal },
    { name: "dayCenter", label: "Présences Centre de Jour", type: "number", value: 0 },
    { name: "remark", label: "Remarque", type: "text" }
  ];

  return (
    <Box sx={{ "& > button": { m: 1 }, textAlign: align }}>
      {createFunction != null && (
        <LoadButtonDialog
          onlyIcon={onlyIcon}
          variant="contained"
          isDisabled={isDisabled}
          title={btnTitle ? btnTitle : t('action.create')}
          titleForm={t('action.create') + " " + title}
          color={colorC != null ? colorC : "primary"}
          endIcon={<AddIcon />}
          dialogIcon={<AddIcon color="primary" />}
          inputs={inputs}
          setInputs={setInputs}
          resetInput={resetInput}
          GetTotalMeal={GetTotalMeal}
          handleChange={handleChange}
          handleSumbitAction={createEntity}
          form= {id === "cloture" ?
            <Form
              forms={closingForms.map((item) =>
                (item?.type === 'date')
                  ? { ...item, valueDate: (item?.valueDate || preSetInputs?.[item?.name]) }
                  : item
              )}
              handleChange={handleChange}
              inputs={inputs}
            />
          :
            <>
              {optionalData &&
                <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <Button autoFocus onClick={() => setInputs(optionalData)} color={"info"} variant="contained">{t("action.import-previous-data")}</Button>
                  <Button autoFocus onClick={() => setInputs({})} color={"warning"} variant="contained">{t("action.clear-fields")}</Button>
                </DialogActions>
              }
              <Form
                forms={forms?.map((item) => {
                  if (item?.type === 'date') {
                    return { ...item, valueDate: (item?.valueDate || preSetInputs?.[item?.name]) }
                  } else if ((item?.type === 'select') && (optionalData)) {
                    return { ...item, default: "" }
                  } else if (item?.default) {
                    return { ...item, default: item?.default}
                  } else {
                    return item
                  }
                })}
                handleChange={handleChange}
                inputs={inputs}
              />
            </>
          }
          isContained={isContained}
          requiredFieldLabel={requiredFieldLabel}
          customError={customError}
        />
      )}

      {updateFunction != null && (
        <LoadButtonDialog
          id={id}
          variant="contained"
          onlyIcon={onlyIcon}
          isDisabled={isDisabled || (selected ? false : true)}
          title={t('action.update')}
          titleForm={t('action.update') + " " + title}
          color={colorU != null ? colorU : "warning"}
          endIcon={<EditIcon />}
          dialogIcon={<EditIcon color="warning" />}
          inputs={inputs}
          resetInput={resetInput}
          handleSumbitAction={updateEntity}
          form={
            <Form
              forms={forms?.map((item) => {
                if (item?.type === 'date') {
                  return { ...item, valueDate: (selected ? selected?.[item?.name] : '') }
                } else if ((item?.type === 'select') && (optionalData)) {
                  return { ...item, default: "" }
                } else if (item?.type === 'select') {
                  return { ...item, default: selected?.[item?.name] }
                } else if (item?.default) {
                  return { ...item, default: item?.default}
                } else {
                  return item;
                }
              })}
              defaultValue={selected}
              handleChange={handleChange}
              inputs={inputs}
            />
          }
          requiredFieldLabel={requiredFieldLabel}
          isContained={isContained}
        />
      )}

      {deleteFunction != null && (
        <LoadButtonDialog
          id={id}
          onlyIcon={onlyIcon}
          isDisabled={isDisabled || (selected ? false : true)}
          title={t('action.delete')}
          titleForm={t('action.delete') + " " + title}
          color={colorD != null ? colorD : "error"}
          endIcon={<DeleteIcon />}
          dialogIcon={<DeleteIcon color="error" />}
          inputs={inputs}
          resetInput={resetInput}
          handleSumbitAction={deleteEntity}
          form={<DeleteForm title={title} />}
          requiredFieldLabel={requiredFieldLabel}
          isContained={isContained}
        />
      )}
    </Box>
  );
}

export default ButtonManager;
