import instance from "../../../shared/components/auth/service/AxiosInstance";

export const VoucherService = {
    getVouchers: (pageNo, pageSize) =>
        instance.get(process.env.REACT_APP_API_URL + 'voucher/' + 
            '?pageSize=' + pageSize +
            '&pageNo=' + pageNo
        ),

    getVoucher: (id) =>
        instance.get(process.env.REACT_APP_API_URL + 'voucher/' + id
    ),


    createVoucher: (Voucher) =>
        instance.post(process.env.REACT_APP_API_URL + 'voucher/', 
            Voucher
        ),

    updateVoucher: (id, Voucher) =>
        instance.put(process.env.REACT_APP_API_URL + 'voucher/' + id, 
            Voucher
        ),

    deleteVoucher: (id) =>
        instance.delete(process.env.REACT_APP_API_URL + 'voucher/' + id
        ),
    
    getClientLastVoucher: (clientId, voucherTypeId) =>
        instance.get(process.env.REACT_APP_API_URL + 'voucher/' +
        "client/" + clientId +
        "/type/" + voucherTypeId
    ),

    getVouchersBySiteAndTypeAndDate: (siteId, voucherTypeId, date) =>
        instance.get(process.env.REACT_APP_API_URL + 'voucher/' + 
            'site/' + siteId +
            '/vouchertype/' + voucherTypeId +
            '/date/' + date
    ),

    getClientLastVouchers: (clientId) => 
        instance.get(process.env.REACT_APP_API_URL + 'voucher/client/' + clientId
    ),
}