import instance from "../../../shared/components/auth/service/AxiosInstance";

export const SecretService = {
    getServiceStat: (startDate, endDate) =>
        instance.get(
            process.env.REACT_APP_API_URL +
            "stat/grouped/service?startDate=" +
            startDate +
            "&endDate=" +
            endDate
        ),

    getClientStat: (startDate, endDate) =>
        instance.get(
            process.env.REACT_APP_API_URL +
            "stat/grouped/client?startDate=" +
            startDate +
            "&endDate=" +
            endDate
        ),

    getVoucherStat: (startDate, endDate) =>
        instance.get(
            process.env.REACT_APP_API_URL +
            "stat/grouped/voucher?startDate=" +
            startDate +
            "&endDate=" +
            endDate
        ),

    getNationalityStat: (startDate, endDate) =>
        instance.get(
            process.env.REACT_APP_API_URL +
            "stat/grouped/nationality?startDate=" +
            startDate +
            "&endDate=" +
            endDate
        ),

    getMaritalStat: (startDate, endDate) =>
        instance.get(
            process.env.REACT_APP_API_URL +
            "stat/grouped/marital?startDate=" +
            startDate +
            "&endDate=" +
            endDate
        )
};