import { withStyles } from "@material-ui/core/styles";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import DownloadIcon from "@mui/icons-material/Download";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import ControlledField from "../../../shared/components/controlledField/ControlledField";
import { Scheduler } from "../../../shared/components/scheduler/Scheduler";
import {
  DateYmdToDmY,
  computeDays,
  getISODate,
  getISOTime,
  localeISODateTime,
} from "../../../shared/utils/localeISODateTime";
import { VoucherConfigService } from "../services/SubService/VoucherConfigService";
import { VoucherService } from "../services/VoucherService";
import { LaundryItem } from "./LaundryItem";
import PreviewPdf from "./PreviewPdf";
import "./Voucher.css";

const RedTextTypography = withStyles({
  root: {
    color: "red",
  },
})(Typography);

export const VoucherForm = (props) => {
  const {
    voucher,
    setVoucher,
    presence,
    formAction,
    handleClose,
    handleChangeIndex,
    refresh,
    today,
  } = props;
  const { site } = useSelector((state) => state.site);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [openPlanning, setOpenPlanning] = useState(false);
  const handleClosePlanning = () => setOpenPlanning(false);

  const [voucherData, setVoucherData] = useState(null);
  const [voucherConfig, setVoucherConfig] = useState(null);
  const [voucherLaundryItems, setVoucherLaundryItems] =
    useState(voucher?.voucherLaundryItems) || [];
  const [clientLastVoucher, setClientLastVoucher] = useState(null);
  const [actionBon, setActionBon] = useState("");

  const [defaultNextRdv, setDefaultNextRdv] = useState("01/01/2022");
  const [errMsg, setErrMsg] = useState("");

  const [fields, setFields] = useState([
    { name: "rdvDate", label: "Date RDV", type: "date", required: true },
    { name: "rdvHour", label: "Heure RDV", type: "time" },
    { name: "remark", label: "Remarques", type: "string" },
    { name: "nextRdvDate", label: "Prochaine date RDV", type: "date" },
  ]);

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      ...voucher,
      rdvDate: voucher?.rdvDate || getISODate(localeISODateTime(today)),
      rdvHour:
        voucher?.rdvHour || getISOTime(localeISODateTime()).substring(0, 5),
      nextRdvDate: voucher?.nextRdvDate || null,
    },
  });

  const handleVoucherData = (data) => {
    data.presence = presence;
    setVoucherData(data);
  };

  const rdvDate = useWatch({ control, name: "rdvDate" });

  const setNextDate = (date = new Date(), days = 0) => {
    if (today instanceof Date && !isNaN(today && days > 0)) {
      setValue(
        "nextRdvDate",
        getISODate(localeISODateTime(computeDays(today, days)))
      );
    }
  };

  const handleNextDate = (days) => {
    setValue(
      "nextRdvDate",
      getISODate(localeISODateTime(computeDays(today, days)))
    );
  };

  const executeFormAction = async (data, action) => {
    const actions = {
      add: (voucherData) => {
        VoucherService.createVoucher({
          ...voucherData,
          ...voucher,
          presence: presence,
          site: site,
          voucherLaundryItems: voucherLaundryItems,
        })
          .then((response) => {
            setVoucherLaundryItems(response.data.voucherLaundryItems);
            handleChangeIndex(0);
          })
          .catch((err) =>
            setErrMsg(
              "Echec de la création de bon. Possible conflit avec un autre bon, vérifiez sur le planning."
            )
          )
          .finally(() => {
            if (refresh != null) refresh();
            handleClose();
          });
      },
      upt: (voucher) => {
        VoucherService.updateVoucher(voucher?.voucherId, voucher)
          .then((response) => {
            setVoucherLaundryItems(response.data.voucherLaundryItems);
            handleChangeIndex(0);
          })
          .catch((err) => setErrMsg("Echec de la mise à jour du bon. " + err))
          .finally(() => {
            if (refresh != null) refresh();
            handleClose();
          });
      },
      del: (voucher) => {
        VoucherService.deleteVoucher(voucher?.voucherId)
          .then((response) => {
            handleClose();
            handleChangeIndex(0);
          })
          .catch((err) => setErrMsg("Echec de la suppression du bon. " + err))
          .finally(() => {
            if (refresh != null) refresh();
          });
      },
    };
    if (voucher?.voucherType?.voucherType === "Laundry") {
      data = {
        ...data,
        voucherLaundryItems: voucherLaundryItems?.map((voucherLaundryItem) => ({
          ...voucherLaundryItem,
          voucher: { voucherId: voucher?.voucherId },
        })),
      };
    }
    actions[action]?.(data);
  };

  const saveBonAfterPdf = (voucherData) => {
    VoucherService.createVoucher({
      ...voucherData,
      ...voucher,
      presence: presence,
      site: site,
      voucherLaundryItems: voucherLaundryItems,
    })
      .then((response) => {
        setVoucherLaundryItems(response.data.voucherLaundryItems);
        setVoucher(response.data);
        handleChangeIndex(0);
        if (refresh != null) refresh();
      })
      .catch((err) => setErrMsg("Echec de la création de bon. " + err));
  };

  const [openPreviewPdf, setOpenPreviewPdf] = useState(false);

  const handleOpenPreviewPdf = () => {
    setOpenPreviewPdf(true);
  };

  const handleClosePreviewPdf = () => {
    setOpenPreviewPdf(false);
  };

  useEffect(() => {
    if (voucher) {
      let fieldList = fields;

      VoucherConfigService.getVoucherConfigByVoucherTypeAndSite(
        voucher?.voucherType?.voucherTypeId,
        site?.siteId
      )
        .then((response) => {
          setVoucherConfig(response.data);

          fieldList = fields?.filter(
            (field) => field.name !== "num" && field.name !== "manager"
          );

          let manager = {
            name: "manager",
            label: "Responsable",
            value: response.data?.manager || "",
            disabled: true,
          };

          if (voucher?.voucherType?.voucherType === "Shower") {
            let shower = {
              name: "num",
              label: "Numéro",
              options: Array.from(
                { length: parseInt(site.shower) },
                (_, i) => i + 1
              ),
              defaultValue: formAction === "add" ? 1 : voucher?.num,
            };
            setFields([...fieldList, shower, manager]);
          } else if (voucher?.voucherType?.voucherType === "Laundry") {
            let laundry = {
              name: "num",
              label: "Numéro",
              options: Array.from(
                { length: parseInt(site.laundry) },
                (_, i) => i + 1
              ),
              defaultValue: formAction === "add" ? 1 : voucher?.num,
            };
            setFields([...fieldList, laundry, manager]);
          } else if (voucher?.voucherType?.voucherType === "Lockerroom") {
              let lockerroom = {
                name: "num",
                label: "Numéro",
                options: Array.from(
                  { length: parseInt(site.lockerroom) },
                  (_, i) => i + 1
                ),
                defaultValue: formAction === "add" ? 1 : voucher?.num,
              };
            setFields([...fieldList, lockerroom, manager]);
          } else {
            setFields([...fieldList, manager]);
          }
        })
        .catch((err) => setVoucherConfig(null));

      VoucherService.getClientLastVoucher(
        presence?.client?.clientId,
        voucher?.voucherType?.voucherTypeId
      )
        .then((response) => {
          setClientLastVoucher(response.data);
        })
        .catch((err) => console.log(err));

      if (voucher?.voucherType?.voucherType === "Shower" || voucher?.voucherType?.voucherType === "Lockerroom" || voucher?.voucherType?.voucherType === "Laundry") {
        setValue("num", voucher?.num || 1);
      }
      if (voucher?.rdvDate) {
        setValue("rdvDate", voucher?.rdvDate);
      }
      if (voucher?.rdvHour) {
        setValue("rdvHour", voucher?.rdvHour?.substring(0, 5));
      }
    }
  }, [voucher, site]);

  return (
    <>
      <RedTextTypography>{errMsg}</RedTextTypography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          textAlign: "center",
          alignItems: "center",
          padding: "5px 15px 5px 5px",
          color: "#0d1c40",
          backgroundColor: "#99ccff",
          borderRadius: "25px",
          marginBottom: "2vh",
        }}
      >
        <div
          style={{
            backgroundColor: "#e8e8e8",
            padding: "6px 10px",
            borderRadius: "50%",
            marginRight: "50px",
          }}
        >
          <Tooltip title="Afficher le planning" placement="top">
            <CalendarTodayIcon
              sx={{
                ":hover": { cursor: "pointer", color: "#04519c", opacity: 0.6 },
                fontSize: "large",
                color: "#1976d2",
              }}
              onClick={() => setOpenPlanning(true)}
            />
          </Tooltip>
        </div>

        <Typography sx={{ fontSize: 16 }}>
          {clientLastVoucher?.rdvDate && (
            <span>
              Précédent{" "}
              {clientLastVoucher?.voucherType?.description?.toLowerCase()} :{" "}
              <em>
                {DateYmdToDmY(clientLastVoucher?.rdvDate)},{" "}
                {clientLastVoucher?.rdvHour?.substring(0, 5)} (
                {clientLastVoucher?.site?.description})
              </em>
            </span>
          )}
          {!clientLastVoucher?.rdvDate && (
            <span>
              {"Pas de précédent " +
                voucher?.voucherType?.description.toLowerCase()}
            </span>
          )}
        </Typography>
        <Dialog
          fullScreen={fullScreen}
          open={openPlanning}
          onClose={handleClosePlanning}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle>
            <h3
              style={{
                fontFamily: "backToSchool",
                fontSize: "65px",
                color: "#bb0000",
                textAlign: "center",
              }}
            >
              Planning
            </h3>
            <IconButton
              aria-label="close"
              onClick={handleClosePlanning}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Scheduler
              modal={true}
              voucher={voucher}
              setVoucher={setVoucher}
              selectedVoucherType={voucher?.voucherType}
              handleClosePlanning={handleClosePlanning}
              type="planning"
              today={today}
            />
          </DialogContent>
        </Dialog>
      </Box>

      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((data) => executeFormAction(data, "add"))}
      >
        {fields &&
          fields?.map((field) => (
            <ControlledField key={field?.name} control={control} {...field} />
          ))}
        {voucher?.voucherType?.voucherType === "Laundry" && (
          <LaundryItem
            voucher={voucher}
            voucherLaundryItems={voucherLaundryItems}
            setVoucherLaundryItems={setVoucherLaundryItems}
          />
        )}
        {voucher?.voucherType?.voucherType !== "Sleepingbag" && (
          <Box
            m={1}
            sx={{
              display: "flex",
              flexDirection: "row",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => handleNextDate(7)}
              variant="contained"
              sx={{ margin: "1% 2%" }}
            >
              +7 jours
            </Button>
            <Button
              onClick={() => handleNextDate(15)}
              variant="contained"
              sx={{ margin: "1% 2%" }}
            >
              +15 jours
            </Button>
            <Button
              onClick={() => handleNextDate(30)}
              variant="contained"
              sx={{ margin: "1% 2%" }}
            >
              +30 jours
            </Button>
            <Button
              onClick={() => handleNextDate(92)}
              variant="contained"
              sx={{ margin: "1% 2%" }}
            >
              +3 mois
            </Button>
          </Box>
        )}
        {voucher?.voucherType?.voucherType === "Sleepingbag" && (
          <Box
            m={1}
            sx={{
              display: "flex",
              flexDirection: "row",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => handleNextDate(183)}
              variant="contained"
              sx={{ margin: "1% 2%" }}
            >
              +6 mois
            </Button>
            <Button
              onClick={() => handleNextDate(365)}
              variant="contained"
              sx={{ margin: "1% 2%" }}
            >
              +1 an
            </Button>
          </Box>
        )}
        {(formAction === "add" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              height: 36.5,
              margin: "4% 2%",
              width: "100%",
            }}
          >
            <Button type="submit" color="success" variant="contained">
              Ajouter <ConfirmationNumberIcon sx={{ marginLeft: "15px" }} />
            </Button>
            <Button
              onClick={handleSubmit((data) => {
                handleVoucherData(data);
                setActionBon("create");
                handleOpenPreviewPdf(true);
              })}
              variant="contained"
              sx={{
                backgroundColor: "#266529",
                ":hover": {
                  backgroundColor: "#225724",
                },
              }}
            >
              Ajouter & créer un PDF{" "}
              <PictureAsPdfIcon sx={{ marginLeft: "15px" }} />
            </Button>
          </div>
        )) || (
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              onClick={handleSubmit((data) => executeFormAction(data, "del"))}
              variant="contained"
              color="error"
            >
              Supprimer
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Tooltip title="Générer un PDF" placement="top">
                <IconButton
                  onClick={() => {
                    setActionBon("print");
                    handleOpenPreviewPdf(true);
                  }}
                  sx={{ color: "#1976d2" }}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            </div>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              onClick={handleSubmit((data) => executeFormAction(data, "upt"))}
              variant="contained"
            >
              Mettre à jour
            </Button>
          </Box>
        )}
      </Box>
      <PreviewPdf
        site={site}
        handleClosePreviewPdf={handleClosePreviewPdf}
        handleChangeIndex={handleChangeIndex}
        actionBon={actionBon}
        openPreviewPdf={openPreviewPdf}
        handleClose={handleClose}
        voucher={formAction === "add" ? voucherData : voucher}
        saveBonAfterPdf={saveBonAfterPdf}
        voucherLaundryItems={voucherLaundryItems}
        type="bon"
      />
    </>
  );
};
